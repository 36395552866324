import styled from 'styled-components';

export const Container = styled.div`
  .box {
    border-radius: 20px;
  }
`;

export const Steps = styled.div`
  hr {
    background-color: #333333;
  }
`;

export const Plan = styled.div`
  .btn-delete {
    right: 10px;
  }
`;
