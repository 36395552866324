import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Modal } from './styles';
import Input from '~/components/Input';
import QuestionBox from '~/components/QuestionBox';
import Textarea from '~/components/Textarea';
import Search from '~/components/Search';

interface IQuestion {
  id: string;
  question: string;
  answer: string;
}

interface IQuestionData {
  current_page: number;
  last_page: number;
  data: IQuestion[];
}

interface IFaq {
  id?: string;
  question: string;
  answer: string;
  edited: boolean;
  status: string;
  active?: boolean;
}

interface IFormData {
  question: string;
  answer: string;
}

const FaqList: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [questions, setQuestions] = useState<IFaq[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [show, setShow] = useState(false);

  const loadQuestions = useCallback(
    async (pageData: number, searchData?: string) => {
      const response = await api.get<IQuestionData>(`faqs`, {
        params: {
          page: pageData,
          search: searchData,
        },
      });

      const data = response.data.data.map((faq) => ({
        id: faq.id,
        question: faq.question,
        answer: faq.answer,
        edited: false,
        status: 'old',
      }));

      if (pageData === 1) {
        setQuestions(data);
      } else {
        setQuestions((state) => [...state, ...data]);
      }

      setLastPage(response.data.last_page);
    },
    []
  );

  useEffect(() => {
    loadQuestions(1);
  }, [loadQuestions]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadQuestions(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadQuestions, page]);

  const handleClickQuestion = useCallback(
    (index) => {
      const newQuestions = questions.slice();
      newQuestions[index].active = !newQuestions[index].active;
      setQuestions(newQuestions);
    },
    [questions]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleChangeQuestion = useCallback(
    async (data, index) => {
      const formData = {
        question: data.question,
        answer: data.answer,
      };

      await api.put(`faqs/${data.id}`, formData);

      const newQuestions = questions.slice();
      newQuestions[index] = data;

      setQuestions(newQuestions);
    },
    [questions]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          question: Yup.string().required('A pergunta é obrigatória'),
          answer: Yup.string().required('A resposta é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const formData = {
          question: data.question,
          answer: data.answer,
        };

        const response = await api.post('faqs', formData);

        const newQuestion: IFaq = {
          id: response.data.id,
          question: response.data.question,
          answer: response.data.answer,
          edited: false,
          status: 'new',
        };

        setQuestions((state) => [...state, newQuestion]);
        handleClose();

        Toast.fire({
          icon: 'success',
          title: 'Pergunta do FAQ cadastrada!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [handleClose]
  );

  const handleClickDeleteQuestion = useCallback(
    (question, questionIndex) => {
      Swal.fire({
        title: 'Deseja deletar essa pergunta?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`faqs/${question.id}`);

            const newQuestions = questions.slice();
            newQuestions.splice(questionIndex, 1);
            setQuestions(newQuestions);
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [questions]
  );

  const handleSearch = useCallback(
    (value) => {
      loadQuestions(1, value);
    },
    [loadQuestions]
  );

  return (
    <>
      <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
        <div className="container pt-5">
          <div className="row align-items-center pb-5">
            <div className="col-12 pb-4 d-flex justify-content-between align-items-center">
              <h1 className="fw-semibold text-white ms-4">FAQ</h1>

              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <button
                  type="button"
                  className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
                  onClick={() => setShow(true)}
                >
                  <BsPlus size={24} color="#BBBBBB" />
                  <span className="text-gray fw-bold ms-2">Adicionar</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            {questions.map((question, index) => (
              <QuestionBox
                key={question.id}
                onLoading={setLoading}
                onClickQuestion={() => handleClickQuestion(index)}
                questionData={question}
                indexQuestion={index}
                onChangeQuestion={handleChangeQuestion}
                edit={question.edited}
                onClickDelete={() => handleClickDeleteQuestion(question, index)}
              />
            ))}
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} close>
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0 ps-4 pt-4" closeButton>
            <h4>Nova pergunta</h4>
          </Modal.Header>
          <Modal.Body className="mb-4 px-5">
            <label className="w-100">
              <span>Pergunta</span>
              <Input name="question" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              <span>Resposta</span>
              <Textarea name="answer" className="mt-3" />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0 px-5">
            <button
              type="submit"
              className="btn btn-primary fw-bold w-100 py-2"
            >
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default FaqList;
