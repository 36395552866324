import React, { useCallback, useEffect, useState } from 'react';
import { RiEditFill, RiFolderTransferFill } from 'react-icons/ri';
import { IconType } from 'react-icons/lib';

import {
  IoIosStar,
  IoIosStarOutline,
  IoMdAdd,
  IoMdCheckmark,
} from 'react-icons/io';
import { Container, CourseLink, CourseButton } from './styles';

interface ICourse {
  type?: 'edit' | 'transfer' | 'product' | 'check';
  to?: string;
  thumb: string;
  title: string;
  percent?: number;
  icon?: IconType;
  onClick?(): void;
  className?: string;
  highlight?: boolean;
  onClickHighlight?(): void;
  releaseDate?: string;
  onChangeCheck?(): void;
  checked?: boolean;
}

const Course: React.FC<ICourse> = ({
  type = 'edit',
  to,
  thumb,
  title,
  percent,
  icon: Icon,
  onClick,
  className,
  highlight,
  onClickHighlight,
  releaseDate,
  onChangeCheck,
  checked,
}) => {
  const [CourseContent, setCourseContent] = useState<any>(CourseButton);

  useEffect(() => {
    if (type === 'edit' && to) {
      setCourseContent(CourseLink);
    }
  }, [to, type]);

  const handleMouseEnter = useCallback(
    (e) => {
      if (type !== 'product' && type !== 'check') {
        const element = e.target.closest('.course').querySelector('.icon');
        element.classList.add('hover');
      }
    },
    [type]
  );

  const handleMouseLeave = useCallback(
    (e) => {
      if (type !== 'product' && type !== 'check') {
        const element = e.target.closest('.course').querySelector('.icon');
        element.classList.remove('hover');
      }
    },
    [type]
  );

  return (
    <Container className={`px-2 px-lg-3 pb-xl-4 ${className}`}>
      <CourseContent
        to={to}
        src={thumb}
        percent={percent === 0 ? 1 : percent}
        className="d-block p-relative w-100 course pb-4"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {Icon && (
          <div className="flag">
            <Icon size={24} color="#fff" />
            {releaseDate && (
              <span className="d-flex flex-column">
                <span>Data de lançamento:</span>
                <b>{releaseDate}</b>
              </span>
            )}
          </div>
        )}
        {type === 'product' && (
          <button
            type="button"
            className="btn btn-white btn-highlight d-flex align-items-center justify-content-center"
            onClick={onClickHighlight}
          >
            {highlight ? (
              <IoIosStar size={24} color="#E50914" />
            ) : (
              <IoIosStarOutline size={24} color="#3A3A3A" />
            )}
          </button>
        )}
        {type === 'check' && (
          <button
            type="button"
            className="btn btn-white btn-highlight d-flex align-items-center justify-content-center"
            onClick={onChangeCheck}
          >
            {checked ? (
              <IoMdCheckmark size={24} color="#202020" />
            ) : (
              <IoMdAdd size={24} color="#202020" />
            )}
          </button>
        )}
        <div className="thumb mb-4">
          <div className="progress" />
        </div>
        <div className="d-flex justify-content-between align-items-center course-title">
          <span className="text-gray me-4">{title}</span>
          {type !== 'product' && type !== 'check' && (
            <div className="icon">
              {type === 'edit' && <RiEditFill size={24} />}
              {type === 'transfer' && <RiFolderTransferFill size={24} />}
            </div>
          )}
        </div>
      </CourseContent>
    </Container>
  );
};

export default Course;
