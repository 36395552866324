import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';

interface IFormData {
  name: string;
  email: string;
}

interface IParams {
  admin_id: string;
}

const EmployeesUpdate: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [employee, setEmployee] = useState({} as IFormData);

  useEffect(() => {
    api.get<IFormData>(`admins/${params.admin_id}`).then((response) => {
      setEmployee({
        name: response.data.name,
        email: response.data.email,
      });
    });
  }, [params.admin_id]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('O e-mail é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
        };
        await api.put(`admins/${params.admin_id}`, formData);

        Toast.fire({
          icon: 'success',
          title: 'Funcionário editado!',
        });
        history.push(`${process.env.PUBLIC_URL}/funcionarios`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [history, params.admin_id]
  );

  return (
    <Container className="container py-5">
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="row"
        initialData={employee}
      >
        <div className="col-12 mb-4">
          <h1>Editar funcionario</h1>
        </div>
        <div className="col-lg-6">
          <div className="box bg-dark-2 p-5">
            <label className="w-100">
              Nome <Input name="name" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              E-mail <Input type="email" name="email" className="mt-3" />
            </label>
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default EmployeesUpdate;
