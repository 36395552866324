import React, { useCallback, useEffect, useState } from 'react';

import { RiEditFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import Swal from 'sweetalert2';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Banner } from './styles';
import Resource from '~/components/Resource';

import bannerDownloads from '~/assets/banners/banner-downloads.png';
import Search from '~/components/Search';

interface IResource {
  id: string;
  name: string;
  resource_url: string;
  isBanner?: string;
  link?: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IResourceData {
  current_page: number;
  last_page: number;
  data: IResource[];
}

const Downloads: React.FC = () => {
  const [resources, setResources] = useState<IResource[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadResources = useCallback(async (pageData: number, search = '') => {
    const response = await api.get<IResourceData>(`resources`, {
      params: {
        page: pageData,
        search,
      },
    });

    if (pageData === 1) {
      setResources(response.data.data);
    } else {
      setResources((state) => [...state, ...response.data.data]);
    }
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadResources(1);
  }, [loadResources]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadResources(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadResources, page]);

  const handleClickDeleteResource = useCallback(
    (resource, resourceIndex) => {
      Swal.fire({
        title: 'Deseja deletar esse recurso?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`resources/${resource.id}`);

            const newResources = resources.slice();
            newResources.splice(resourceIndex, 1);
            setResources(newResources);

            Toast.fire({
              icon: 'success',
              title: 'Download deletado!',
            });
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [resources]
  );

  const handleSearch = useCallback(
    (value) => {
      loadResources(1, value);
    },
    [loadResources]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row align-items-center pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4 d-flex justify-content-between align-items-center">
            <h1 className="fw-semibold text-white ms-4">Downloads</h1>
            <div className="d-flex align-items-center">
              <Search onSearch={handleSearch} className="search me-3" />
              <Link
                to={`${process.env.PUBLIC_URL}/downloads/cadastrar`}
                className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
              >
                <BsPlus size={24} color="#BBBBBB" />
                <span className="text-gray fw-bold ms-2">Download</span>
              </Link>
            </div>
          </div>
          {resources.map((resource, index) =>
            resource.isBanner ? (
              <div key={resource.id} className="col-12 mb-5 pb-4 order-0">
                <Banner src={bannerDownloads} className="p-4 p-lg-5">
                  <div className="d-flex flex-column py-4">
                    <h2 className="h5 h4-md h3-lg fw-normal w-md-75 w-lg-50 mb-5">
                      Os materiais mais completos do Brasil disponíveis para
                      você
                    </h2>
                    <button
                      type="button"
                      className="btn btn-primary px-5 py-2 fw-bold align-self-end mb-n4"
                    >
                      Conferir
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-white rounded-circle position-absolute p-2"
                  >
                    <RiEditFill size={24} color="#3A3A3A" />
                  </button>
                </Banner>
              </div>
            ) : (
              <div key={resource.id} className="col-md-6 col-xl-4 mb-3 order-1">
                <Resource
                  thumb={resource.thumbnail.thumbnail_url}
                  title={resource.name}
                  onClick={() => handleClickDeleteResource(resource, index)}
                />
              </div>
            )
          )}
        </div>
      </div>
    </Container>
  );
};

export default Downloads;
