import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error.request.responseURL.split('/').pop() !== 'sessions'
    ) {
      api.defaults.headers.authorization = null;
      localStorage.removeItem('@LeiautAdmin:token');
      if (window) window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
