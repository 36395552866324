import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { IoMdNotifications } from 'react-icons/io';
import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '~/hooks/Auth';
import { Container, MenuButton, Menu, MenuItem, Spacer } from './styles';
import Notifications from './Notifications';

import logo from '~/assets/logos/logo.svg';

const Header: React.FC = () => {
  const { signOut } = useAuth();
  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(false);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);

  useEffect(() => {
    setMenuOpened(false);
  }, [location.pathname]);

  const handleClickNotifications = useCallback(() => {
    setNotificationOpened((state) => !state);
  }, []);

  const handleClickMenu = useCallback(() => {
    setMenuOpened((state) => !state);
  }, []);

  const handleClickSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <>
      <Container className="bg-dark-2">
        <div className="container d-flex align-items-center justify-content-between flex-lg-row">
          <div className="logo px-lg-5">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="d-block d-lg-none d-flex align-items-center">
            <button
              type="button"
              onClick={handleClickNotifications}
              className="bg-transparent border-0 me-3 position-relative btn-notification"
            >
              <IoMdNotifications size={24} color="#BBBBBB" />
              {hasUnread && <span className="news-notifications" />}
            </button>
            <MenuButton opened={menuOpened} onClick={handleClickMenu}>
              <span />
              <span />
              <span />
            </MenuButton>
          </div>
          <Menu
            opened={menuOpened}
            className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start px-8"
          >
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/dashboard`}
                activeClassName="active"
              >
                Home
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/funcionarios`}
                activeClassName="active"
              >
                Funcionários
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/membros`}
                activeClassName="active"
              >
                Membros
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/grupos`}
                activeClassName="active"
              >
                Grupos
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/categorias`}
                activeClassName="active"
              >
                Categorias
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/caminhos`}
                activeClassName="active"
              >
                Caminhos
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/cursos`}
                activeClassName="active"
              >
                Cursos
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/produtos`}
                activeClassName="active"
              >
                Produtos
              </NavLink>
            </MenuItem>
            <MenuItem>
            <NavLink
              to={`${process.env.PUBLIC_URL}/suporte`}
              activeClassName="active"
            >
              Suporte
            </NavLink>
          </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/downloads`}
                activeClassName="active"
              >
                Downloads
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/faq`}
                activeClassName="active"
              >
                Faq
              </NavLink>
            </MenuItem>
            <MenuItem>
              <button
                type="button"
                onClick={handleClickSignOut}
                className="border-0 bg-transparent text-start text-primary"
              >
                <FiLogOut size={24} color="#E50914" /> Sair
              </button>
            </MenuItem>
          </Menu>
        </div>
        <Notifications
          opened={notificationOpened}
          onClose={() => setNotificationOpened(false)}
          setHasUnread={setHasUnread}
        />
      </Container>
      <Spacer />
    </>
  );
};

export default Header;
