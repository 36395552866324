// styles.ts
import styled from 'styled-components';

export const Container = styled.div`
  background: #00FF0000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Container2 = styled.div`
  background: #00FF0000;
  width: 60%;

  @media screen and (max-width: 900px) {
    width: 100%;
  
  }
`;
export const TitleAndAsk = styled.div`
  background: #00FF0000;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    padding-left: 1px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 20px;
    padding-bottom: 20px;
  }
`;

export const Reply = styled.div``;

export const Title = styled.div`
  margin-right: 50px;
  p {
    margin-top: 20px;
    width: 307px;
    height: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #BBBBBB;
    flex: none;
    order: 1;
    flex-grow: 0;
    white-space: nowrap;
  }
`;

export const Potus = styled.div`
color: #00FF0000
`;

export const DivSearch = styled.div`
background: #00FF0000;
display: flex;
justify-content: space-between;

@media screen and (max-width: 900px) {
  padding-right: 20px;
  padding-bottom: 20px;
}
`;

export const Tab = styled.div`
color: #ffffff;
background: #00FF0000;
`;