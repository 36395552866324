import styled from 'styled-components';
import { darken } from 'polished';

import arrowPrev from '~/assets/icons/arrow-prev.svg';
import arrowNext from '~/assets/icons/arrow-next.svg';

interface ICalendar {
  active: boolean;
}

export const Container = styled.div`
  position: relative;

  .date-input {
    input {
      ::placeholder {
        color: #ccc;
      }
    }
  }
`;

export const Calendar = styled.aside<ICalendar>`
  position: absolute;
  width: 380px;
  z-index: 100;
  top: 44px;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};

  .DayPicker {
    background-color: #282828;
    border-radius: 10px;
    padding: 10px 0;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;

    .DayPicker-Weekdays {
      margin-top: 0;
    }

    .DayPicker-Weekdays,
    .DayPicker-Body {
      display: grid;
    }
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: #fff !important;
  }

  .DayPicker-NavButton--prev {
    background-image: url(${arrowPrev}) !important;
    width: 25px;
    height: 25px;
  }

  .DayPicker-NavButton--next {
    background-image: url(${arrowNext}) !important;
    width: 25px;
    height: 25px;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em;
    color: #8c8c8c;

    > div {
      text-align: center;
    }
  }

  .DayPicker-Weekday {
    color: #e4e4e4;
  }

  .DayPicker-Weekday {
    min-width: 42px;
    max-width: 42px;
  }

  .DayPicker-Day {
    min-width: 42px;
    max-width: 42px;
    min-height: 42px;
    max-height: 42px;
    border-radius: 50%;
    font-weight: bold;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    color: #8c8c8c;
    border: 1px solid #8c8c8c;
  }

  .DayPicker-Day--outside,
  .DayPicker-Day--disabled {
    color: ${darken(0.25, '#8c8c8c')} !important;
    border: 1px solid ${darken(0.25, '#8c8c8c')} !important;
  }

  .DayPicker-Day--selected,
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    border-color: #8c8c8c !important;
    background: #8c8c8c !important;
    color: #282828 !important;
  }
`;

export const DayPickerCaption = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    select {
      background-color: transparent;
      border: 0;
      color: #8c8c8c;
      font-weight: 500;

      option {
        color: #fff;
        background-color: #8c8c8c;
      }

      + select {
        margin-left: 5px;
      }
    }
  }
`;
