/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { IoIosVideocam } from 'react-icons/io';

import { AxiosResponse } from 'axios';
import YouTube from 'react-youtube';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Steps, VideoPreview } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import InputVideo from '~/components/InputVideo';
import InputRadio, { IOption as IOptionRadio } from '~/components/InputRadio';
import InputToggle from '~/components/InputToggle';
import Quiz, { IQuestion } from '~/components/Quiz';
import CreateCertificate, {
  ICertificateData,
} from '~/components/CreateCertificate';

interface IPlan {
  id: string;
  name: string;
}

interface ICategory {
  id: string;
  name: string;
}

interface IProduct {
  id: string;
  title: string;
  hasPlans: boolean;
  plans?: IPlan[];
}

interface IFormData {
  title: string;
  category: string;
  description: string;
  video_url: string;
  workload: string;
  certificate_price: string;
  certificate_discount: string;
  certificate_checkout_url?: string;
  course_price: string;
  course_discount: string;
  course_checkout_url?: string;
}

const CoursesCreate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [thumbnailError, setThumbnailError] = useState('');
  const [wallpaper, setWallpaper] = useState<File | undefined>(undefined);
  const [wallpaperError, setWallpaperError] = useState('');
  const [trailer, setTrailer] = useState<File | undefined>(undefined);
  const [trailerError, setTrailerError] = useState('');
  const [sell, setSell] = useState(false);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<IOption[]>([]);
  const [workload, setWorkload] = useState('');
  const [addQuiz, setAddQuiz] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(true);
  const [defaultCertificate, setDefaultCertificate] = useState(true);
  const [sellCertificate, setSellCertificate] = useState(false);
  const [hasCourseDiscount, setHasCourseDiscount] = useState(false);
  const [hasCertificateDiscount, setHasCertificateDiscount] = useState(false);
  const [certificateData, setCertificateData] = useState(
    {} as ICertificateData
  );
  const [certificateError, setCertificateError] = useState('');
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [trailerType, setTrailerType] = useState<IOptionRadio>({
    id: 'upload',
    value: 'Upload',
  });
  const [trailerUrl, setTrailerUrl] = useState('');
  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [products, setProducts] = useState<IOption[]>([]);
  const [productsSelected, setProductsSelected] = useState<IOption[]>([]);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (categoriesSelected.length === 0) {
      api
        .get<ICategory[]>('categories/subcategories', {
          params: {
            all: true,
            noPagination: true,
          },
        })
        .then((response) => {
          const data: IOption[] = response.data.map((category) => ({
            id: category.id,
            value: category.name,
            selected: false,
          }));
          setCategories(data);
        });
    }
  }, [categoriesSelected.length]);

  useEffect(() => {
    if (productsSelected.length === 0) {
      api
        .get<IProduct[]>('products', {
          params: {
            all: true,
            noPagination: true,
          },
        })
        .then((response) => {
          const data: IOption[] = [];
          response.data.forEach((product) => {
            if (product.hasPlans && product.plans) {
              const newDatas: IOption[] = product.plans.map((plan) => ({
                id: plan.id,
                value: `${product.title} - ${plan.name}`,
                selected: false,
              }));

              data.push(...newDatas);
            } else {
              const newData = {
                id: product.id,
                value: product.title,
                selected: false,
              };
              data.push(newData);
            }
          });

          setProducts(data);
          setProductsList(response.data);
        });
    }
  }, [productsSelected.length]);

  const handleChangeSell = useCallback((value) => {
    setSell(value.length > 0);
  }, []);

  const handleChangeThumbnail = useCallback((file) => {
    setThumbnail(file);
  }, []);

  const handleChangeWallpaper = useCallback((file) => {
    setWallpaper(file);
  }, []);

  const handleChangeTrailer = useCallback((file) => {
    setTrailer(file);
  }, []);

  const handleChangeWorkload = useCallback((e) => {
    // eslint-disable-next-line prefer-const
    let [hour, minutes, seconds] = e.target.value.split(':');

    if (seconds > 59) {
      seconds = 59;
    } else if (minutes > 59) {
      minutes = 59;
    }

    setWorkload(`${hour}:${minutes}:${seconds}`);
  }, []);

  const handleChangeAddQuiz = useCallback((value) => {
    setAddQuiz(value);
  }, []);

  const handleChangeHasCertificate = useCallback((value) => {
    setHasCertificate(value);
  }, []);

  const handleChangeDefaultCertificate = useCallback((value) => {
    setDefaultCertificate(value);
  }, []);

  const handleChangeSellCertificate = useCallback((value) => {
    setSellCertificate(value);
  }, []);

  const handleChangeHasCourseDiscount = useCallback((value) => {
    setHasCourseDiscount(value);
  }, []);

  const handleChangeHasCertificateDiscount = useCallback((value) => {
    setHasCertificateDiscount(value);
  }, []);

  const handleChangeCategoriesSelected = useCallback((options: IOption[]) => {
    setCategoriesSelected(options);
  }, []);

  const handleChangeCategoryText = useCallback(
    async (value) => {
      try {
        const response = await api.get<ICategory[]>(
          'categories/subcategories',
          {
            params: {
              all: true,
              noPagination: true,
              search: value,
            },
          }
        );

        const results = response.data.filter((category) => {
          return !categoriesSelected.some((categorySelected) => {
            return category.id === categorySelected.id;
          });
        });

        const data: IOption[] = results.map((category) => ({
          id: category.id,
          value: category.name,
          selected: false,
        }));

        setCategories(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [categoriesSelected]
  );

  const handleChangeProductsSelected = useCallback((options: IOption[]) => {
    setProductsSelected(options);
  }, []);

  const handleChangeProductText = useCallback(
    async (value) => {
      try {
        const response = await api.get<IProduct[]>('products', {
          params: {
            search: value,
            noPagination: true,
          },
        });

        const results = response.data.filter((product) => {
          return !productsSelected.some((productSelected) => {
            return product.id === productSelected.id;
          });
        });

        const data: IOption[] = results.map((product) => ({
          id: product.id,
          value: product.title,
          selected: false,
        }));

        setProducts(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [productsSelected]
  );

  const handleChangeCertificate = useCallback((data: ICertificateData) => {
    setCertificateData(data);
  }, []);

  const handleChangeQuiz = useCallback((questionsData, attemptsData) => {
    setQuestions(questionsData);
    setAttempts(attemptsData);
  }, []);

  const handleChangeTrailerType = useCallback((option: IOptionRadio) => {
    setTrailerType(option);
    setTrailerUrl('');
  }, []);

  const handleChangeTrailerUrl = useCallback((e) => {
    const url = e.target.value;
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length >= 10) {
      if (match[7].length === 10) {
        match[7] = `v${match[7]}`;
      }
      setTrailerUrl(match[7]);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setThumbnailError('');
      setWallpaperError('');
      setTrailerError('');
      setCertificateError('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
          wallpaper: Yup.string().when('$wallpaper', {
            is: (wallpaperCheck: boolean) => wallpaperCheck,
            then: Yup.string().required('A capa é obrigatória'),
            otherwise: Yup.string(),
          }),
          trailer: Yup.string(),
          video_url: Yup.string(),
          categories: Yup.string().when('$categories', {
            is: (categoriesCheck: boolean) => categoriesCheck,
            then: Yup.string().required(
              'Pelo menos uma categoria é obrigatória.'
            ),
            otherwise: Yup.string(),
          }),
          workload: Yup.string()
            .length(8, 'Preencha corretamente')
            .required('O tempo de duração é obrigatório'),
          certificate_price: Yup.string().when('$certificatePrice', {
            is: (certificatePriceCheck: boolean) => certificatePriceCheck,
            then: Yup.string().required(
              'O valor do certificado é obrigatório.'
            ),
            otherwise: Yup.string(),
          }),
          certificate_discount: Yup.string().when('$certificateDiscount', {
            is: (certificateDiscountCheck: boolean) => certificateDiscountCheck,
            then: Yup.string().required(
              'O valor do certificado da promoção é obrigatório.'
            ),
            otherwise: Yup.string(),
          }),
          course_price: Yup.string().when('$coursePrice', {
            is: (coursePriceCheck: boolean) => coursePriceCheck,
            then: Yup.string().required('O valor do curso é obrigatório.'),
            otherwise: Yup.string(),
          }),
          course_discount: Yup.string().when('$courseDiscount', {
            is: (courseDiscountCheck: boolean) => courseDiscountCheck,
            then: Yup.string().required(
              'O valor do curso na promoção é obrigatório.'
            ),
            otherwise: Yup.string(),
          }),
          certificate: Yup.string().when('$certificateData', {
            is: (certificateDataCheck: boolean) => certificateDataCheck,
            then: Yup.string().required(
              'A criação do certificado é obrigatória.'
            ),
            otherwise: Yup.string(),
          }),
          quiz: Yup.string().when('$quiz', {
            is: (quizCheck: boolean) => quizCheck,
            then: Yup.string().required('A criação da prova é obrigatória.'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            thumbnail: !thumbnail,
            wallpaper: !wallpaper,
            categories: categoriesSelected.length === 0,
            certificatePrice: sellCertificate,
            certificateDiscount: hasCertificateDiscount,
            coursePrice: sell,
            courseDiscount: hasCourseDiscount,
            certificateData:
              !defaultCertificate && Object.keys(certificateData).length === 0,
            quiz:
              addQuiz &&
              questions.filter((question) => question.question.length === 0)
                .length > 0,
          },
        });

        const formDataThumbnail = new FormData();
        formDataThumbnail.append('thumbnail', thumbnail as File);
        const responseThumbnail = await api.post(
          'thumbnails',
          formDataThumbnail
        );

        const formDataWallpaper = new FormData();
        formDataWallpaper.append('wallpaper', wallpaper as File);
        const responseWallpaper = await api.post(
          'wallpapers',
          formDataWallpaper
        );

        let responseVideo = {} as AxiosResponse;
        if (trailerUrl) {
          const formDataVideo = {
            link: trailerUrl,
          };
          responseVideo = await api.post('videos', formDataVideo);
        } else if (trailer) {
          const formDataVideo = new FormData();
          formDataVideo.append('video', trailer as File);
          responseVideo = await api.post('videos', formDataVideo);
        }

        const formData = {
          status_id: '643c0fc2-175b-483d-9e4b-64e88655359c',
          wallpaper_id: responseWallpaper.data.id,
          thumbnail_id: responseThumbnail.data.id,
          video_id:
            Object.keys(responseVideo).length > 0
              ? responseVideo.data.id
              : undefined,
          title: data.title,
          description: data.description,
          workload: data.workload,
          has_certificate: hasCertificate,
          sell_certificate: sellCertificate,
        };

        const response = await api.post('courses', formData);

        if (addQuiz) {
          const formDataExam = {
            course_id: response.data.id,
            attempts,
          };
          const examResponse = await api.post('exams', formDataExam);
          const questionsPromise = new Promise<void>((resolve) => {
            if (questions.length > 0) {
              questions.forEach(async (question, index) => {
                const formDataQuestion = {
                  exam_id: examResponse.data.id,
                  content: question.question,
                };
                const responseQuestion = await api.post(
                  'questions',
                  formDataQuestion
                );

                const optionsPromise = new Promise<void>((resolveOptions) => {
                  if (question.options.length > 0) {
                    question.options.forEach(async (option, idx) => {
                      const formDataOption = {
                        question_id: responseQuestion.data.id,
                        content: option.answer,
                        correct: option.correct_answer,
                      };
                      await api.post('answers', formDataOption);
                      if (question.options.length === idx + 1) {
                        resolveOptions();
                      }
                    });
                  } else {
                    resolveOptions();
                  }
                });

                await optionsPromise;

                if (questions.length === index + 1) {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });

          await questionsPromise;
        }

        const categoriesPromise = new Promise<void>((resolve) => {
          if (categoriesSelected.length > 0) {
            categoriesSelected.forEach(async (category, index) => {
              const formDataCourseCategory = {
                course_id: response.data.id,
                category_id: category.id,
                order: index + 1,
              };

              await api.post('courses-categories', formDataCourseCategory);

              if (categoriesSelected.length === index + 1) {
                resolve();
              }
            });
          } else {
            resolve();
          }
        });

        await categoriesPromise;

        if (sell) {
          const formDataCourseSale = {
            course_id: response.data.id,
            price: parseFloat(
              data.course_price
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
            ),
            has_discount: hasCourseDiscount,
            discount: hasCourseDiscount
              ? parseFloat(
                  data.course_discount
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
                )
              : undefined,
            checkout_url: data.course_checkout_url,
          };

          await api.post('courses-sales', formDataCourseSale);
        }

        const formDataCertificate = {
          course_id: response.data.id,
          standard: defaultCertificate,
          html_front: undefined as string | undefined,
          html_back: undefined as string | undefined,
          css_front: undefined as string | undefined,
          css_back: undefined as string | undefined,
          price: undefined as number | undefined,
          has_discount: undefined as boolean | undefined,
          discount: undefined as number | undefined,
          checkout_url: data.certificate_checkout_url || undefined,
        };

        if (!defaultCertificate) {
          formDataCertificate.html_front = certificateData.front.html;
          formDataCertificate.css_front = certificateData.front.css;
          formDataCertificate.html_back = certificateData.back.html;
          formDataCertificate.css_back = certificateData.back.css;
        }

        if (sellCertificate) {
          formDataCertificate.price = parseFloat(
            data.certificate_price
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          );
          formDataCertificate.has_discount = hasCertificateDiscount;

          if (hasCertificateDiscount) {
            formDataCertificate.discount = parseFloat(
              data.certificate_discount
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
            );
          }
        }

        await api.post('certificates', formDataCertificate);

        if (productsSelected.length > 0) {
          const newProductsPromise = new Promise<void>((resolve) => {
            productsSelected.forEach(async (product, index) => {
              let productId = '';
              let planId: string | undefined;

              for (const productData of productsList) {
                if (!productId && !planId) {
                  if (productData.hasPlans && productData.plans) {
                    const planCheck = productData.plans.find(
                      (planData) => planData.id === product.id
                    );
                    if (planCheck) {
                      planId = planCheck.id;
                      productId = productData.id;
                    }
                  } else {
                    productId = productData.id;
                  }
                }
              }

              const formDataCourseProduct = {
                course_id: response.data.id,
                product_id: productId,
                plan_id: planId,
                order: index + 1,
              };

              await api.post('courses-products', formDataCourseProduct);

              if (productsSelected.length === index + 1) {
                resolve();
              }
            });
          });

          await newProductsPromise;
        }

        Toast.fire({
          icon: 'success',
          title: 'Curso cadastrado!',
        });
        history.push(
          `${process.env.PUBLIC_URL}/cursos/${response.data.slug}/modulos`
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.thumbnail) {
            setThumbnailError(errors.thumbnail);
          }

          if (errors.wallpaper) {
            setWallpaperError(errors.wallpaper);
          }

          if (errors.trailer) {
            setTrailerError(errors.trailer);
          }

          if (errors.certificate) {
            setCertificateError(errors.certificate);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [
      addQuiz,
      categoriesSelected,
      certificateData,
      defaultCertificate,
      hasCertificate,
      hasCertificateDiscount,
      hasCourseDiscount,
      history,
      productsList,
      productsSelected,
      questions,
      sell,
      sellCertificate,
      thumbnail,
      trailer,
      trailerType,
      trailerUrl,
      wallpaper,
      attempts,
    ]
  );
  //
  return (
    <Container className="container py-5">
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="row box bg-dark-2 p-5"
      >
        <div className="col-12 mb-4">
          <h1 className="fw-light text-center">
            Adicionar <b className="fw-bold">curso</b>
          </h1>
        </div>
        <div className="col-12">
          <div className="row justify-content-center my-5">
            <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
              <Steps className="d-flex ml-auto align-items-center min-width">
                <div className="w-100 text-center bg-gray rounded-pill">
                  <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                    Sobre do curso
                  </span>
                </div>
                <hr className="w-25 w-md-50 border-gray mx-2" />
                <div className="w-100 text-center bg-dark-3 rounded-pill">
                  <span className="d-block px-3 py-3 text-gray">Módulos</span>
                </div>
                <hr className="w-25 w-md-50 border-gray mx-2" />
                <div className="w-100 text-center bg-dark-3 rounded-pill">
                  <span className="d-block px-3 py-3 text-gray">Aulas</span>
                </div>
              </Steps>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <label className="w-100">
            Título <Input name="title" className="mt-3 input" />
          </label>
        </div>
        <div className="col-lg-6">
          <div className="w-100">
            <label className="w-100">Categorias</label>
            <Select
              name="categories"
              options={categories}
              multiSelect
              className="mt-3 input-select"
              onChange={(options) =>
                handleChangeCategoriesSelected(options as unknown as IOption[])
              }
              onChangeText={handleChangeCategoryText}
            />
          </div>
        </div>
        <div className="col-12">
          <label className="w-100 mt-4">
            Descrição <Textarea name="description" className="mt-3" rows={4} />
          </label>
        </div>
        <div className="col-12 pt-5 pb-4">
          <hr />
        </div>
        <div className="col-lg-6">
          <div className="w-100 mt-4">
            <span>Subir trailer por:</span>
            <InputRadio
              name="trailer_type"
              options={[
                {
                  id: 'link',
                  value: 'Link',
                },
                {
                  id: 'upload',
                  value: 'Upload',
                },
              ]}
              onChange={handleChangeTrailerType}
              selected={trailerType}
              className="justify-content-start mt-3"
            />
          </div>
          {trailerType.id === 'link' && (
            <label className="w-100 mt-4">
              Link do trailer
              <Input
                type="url"
                placeholder="https://www.youtube.com/"
                name="video_url"
                className="mt-3"
                onChange={handleChangeTrailerUrl}
              />
            </label>
          )}
          <label className="w-100 mt-4">
            Tempo de duração do curso{' '}
            <InputMask
              kind="custom"
              options={{
                mask: '99:99:99',
              }}
              placeholder="00:00:00"
              name="workload"
              className="mt-3"
              onChange={handleChangeWorkload}
              value={workload}
            />
          </label>
        </div>
        <div className="col-lg-6">
          <div className="w-100 mt-4">
            <span>
              {trailerType.id === 'link' ? 'Trailer Preview' : 'Trailer'}
            </span>
            {trailerType.id === 'upload' && (
              <InputVideo
                name="trailer"
                placeholder=""
                className="bg-dark-3 mt-3"
                onChange={handleChangeTrailer}
                error={trailerError}
              />
            )}
            {trailerType.id === 'link' && (
              <VideoPreview className="bg-dark-3 mt-3">
                {trailerUrl ? (
                  <YouTube videoId={trailerUrl} className="h-100" />
                ) : (
                  <div className="camera">
                    <IoIosVideocam size={50} color="#BBBBBB" />
                  </div>
                )}
              </VideoPreview>
            )}
          </div>
        </div>
        <div className="col-12 pt-5 pb-4">
          <hr />
        </div>
        <div className="col-lg-6">
          <div className="w-100">
            <span>Thumbnail</span>
            <InputImage
              name="thumbnail"
              placeholder=""
              className="bg-dark-3 mt-3"
              onChange={handleChangeThumbnail}
              cropImage
              aspect={20.44 / 12.63}
              cropOptions={
                !thumbnail
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 12.63 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              error={thumbnailError}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="w-100">
            <span>Capa</span>
            <InputImage
              name="wallpaper"
              placeholder=""
              className="bg-dark-3 mt-3"
              onChange={handleChangeWallpaper}
              cropImage
              aspect={20.44 / 6.4}
              cropOptions={
                !wallpaper
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 6.4 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              error={wallpaperError}
            />
          </div>
        </div>
        <div className="col-12 pt-5 pb-4">
          <hr />
        </div>
        <div className="col-lg-4">
          <div className="w-100 mt-4">
            <label>Adicionar Prova?</label>
            <div className="d-flex text-center mt-3">
              <label
                className={`btn w-100 mx-1 btn-radio ${
                  addQuiz ? 'btn-gray' : 'btn-dark-3'
                }`}
                htmlFor="add_quiz_yes"
              >
                <span className="d-block py-2">YES</span>
                <Input
                  type="radio"
                  onChange={() => handleChangeAddQuiz(true)}
                  name="add_quiz"
                  id="add_quiz_yes"
                  className="d-none"
                  value="yes"
                  checked={addQuiz}
                />
              </label>
              <label
                className={`btn w-100 mx-1 btn-radio ${
                  !addQuiz ? 'btn-gray' : 'btn-dark-3'
                }`}
                htmlFor="add_quiz_no"
              >
                <span className="d-block py-2">NO</span>
                <Input
                  type="radio"
                  onChange={() => handleChangeAddQuiz(false)}
                  name="add_quiz"
                  id="add_quiz_no"
                  className="d-none"
                  value="no"
                  checked={!addQuiz}
                />
              </label>
            </div>
          </div>
          {addQuiz && (
            <div className="w-100 mt-3">
              <Quiz title="Adicionar prova" onChange={handleChangeQuiz} />
            </div>
          )}
        </div>
        <div className="col-lg-4">
          <div className="w-100 mt-4">
            <label>Emitir Certificado?</label>
            <div className="d-flex text-center mt-3">
              <label
                className={`btn w-100 mx-1 btn-radio ${
                  hasCertificate ? 'btn-gray' : 'btn-dark-3'
                }`}
                htmlFor="has_certificate_yes"
              >
                <span className="d-block py-2">YES</span>
                <Input
                  type="radio"
                  onChange={() => handleChangeHasCertificate(true)}
                  name="has_certificate"
                  id="has_certificate_yes"
                  className="d-none"
                  value="yes"
                  checked={hasCertificate}
                />
              </label>
              <label
                className={`btn w-100 mx-1 btn-radio ${
                  !hasCertificate ? 'btn-gray' : 'btn-dark-3'
                }`}
                htmlFor="has_certificate_no"
              >
                <span className="d-block py-2">NO</span>
                <Input
                  type="radio"
                  onChange={() => handleChangeHasCertificate(false)}
                  name="has_certificate"
                  id="has_certificate_no"
                  className="d-none"
                  value="no"
                  checked={!hasCertificate}
                />
              </label>
            </div>
          </div>
        </div>
        {hasCertificate && (
          <>
            <div className="col-lg-4">
              <div className="w-100 mt-4">
                <label>Certificado Padrão?</label>
                <div className="d-flex text-center mt-3">
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      defaultCertificate ? 'btn-gray' : 'btn-dark-3'
                    }`}
                    htmlFor="default_certificate_yes"
                  >
                    <span className="d-block py-2">YES</span>
                    <Input
                      type="radio"
                      onChange={() => handleChangeDefaultCertificate(true)}
                      name="default_certificate"
                      id="default_certificate_yes"
                      className="d-none"
                      value="yes"
                      checked={defaultCertificate}
                    />
                  </label>
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      !defaultCertificate ? 'btn-gray' : 'btn-dark-3'
                    }`}
                    htmlFor="default_certificate_no"
                  >
                    <span className="d-block py-2">NO</span>
                    <Input
                      type="radio"
                      onChange={() => handleChangeDefaultCertificate(false)}
                      name="default_certificate"
                      id="default_certificate_no"
                      className="d-none"
                      value="no"
                      checked={!defaultCertificate}
                    />
                  </label>
                </div>
              </div>
              {!defaultCertificate && (
                <div className="w-100 mt-3">
                  <CreateCertificate
                    data={certificateData}
                    onChange={handleChangeCertificate}
                    error={certificateError}
                  />
                </div>
              )}
            </div>
            <div className="col-lg-4">
              <div className="w-100 mt-4">
                <label>Vender Certificado?</label>
                <div className="d-flex text-center mt-3">
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      sellCertificate ? 'btn-gray' : 'btn-dark-3'
                    }`}
                    htmlFor="sell_certificate_yes"
                  >
                    <span className="d-block py-2">YES</span>
                    <Input
                      type="radio"
                      onChange={() => handleChangeSellCertificate(true)}
                      name="sell_certificate"
                      id="sell_certificate_yes"
                      className="d-none"
                      value="yes"
                      checked={sellCertificate}
                    />
                  </label>
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      !sellCertificate ? 'btn-gray' : 'btn-dark-3'
                    }`}
                    htmlFor="sell_certificate_no"
                  >
                    <span className="d-block py-2">NO</span>
                    <Input
                      type="radio"
                      onChange={() => handleChangeSellCertificate(false)}
                      name="sell_certificate"
                      id="sell_certificate_no"
                      className="d-none"
                      value="no"
                      checked={!sellCertificate}
                    />
                  </label>
                </div>
              </div>
            </div>
            {sellCertificate && (
              <>
                <div className="col-lg-4">
                  <label className="w-100 mt-4">
                    Valor do certificado{' '}
                    <InputMask
                      kind="money"
                      name="certificate_price"
                      className="mt-3 input"
                    />
                  </label>
                </div>
                <div className="col-lg-4">
                  <div className="w-100 mt-4">
                    <label>Certificado em promoção?</label>
                    <div className="d-flex text-center mt-3">
                      <label
                        className={`btn w-100 mx-1 btn-radio ${
                          hasCertificateDiscount ? 'btn-gray' : 'btn-dark-3'
                        }`}
                        htmlFor="has_certificate_discount_yes"
                      >
                        <span className="d-block py-2">YES</span>
                        <Input
                          type="radio"
                          onChange={() =>
                            handleChangeHasCertificateDiscount(true)
                          }
                          name="has_certificate_discount"
                          id="has_certificate_discount_yes"
                          className="d-none"
                          value="yes"
                          checked={hasCertificateDiscount}
                        />
                      </label>
                      <label
                        className={`btn w-100 mx-1 btn-radio ${
                          !hasCertificateDiscount ? 'btn-gray' : 'btn-dark-3'
                        }`}
                        htmlFor="has_certificate_discount_no"
                      >
                        <span className="d-block py-2">NO</span>
                        <Input
                          type="radio"
                          onChange={() =>
                            handleChangeHasCertificateDiscount(false)
                          }
                          name="has_certificate_discount"
                          id="has_certificate_discount_no"
                          className="d-none"
                          value="no"
                          checked={!hasCertificateDiscount}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {hasCertificateDiscount && (
                  <div className="col-lg-4">
                    <label className="w-100 mt-4">
                      Valor do certificado na promoção{' '}
                      <InputMask
                        kind="money"
                        name="certificate_discount"
                        className="mt-3 input"
                      />
                    </label>
                  </div>
                )}
                <div className="col-lg-4">
                  <label className="w-100 mt-4">
                    Link de pagamento do certificado
                    <Input
                      type="url"
                      name="certificate_checkout_url"
                      className="mt-3 input"
                    />
                  </label>
                </div>
              </>
            )}
          </>
        )}
        <div className="col-12 pt-5 pb-4">
          <hr />
        </div>
        <div className="col-12">
          <div className="w-100">
            <label className="w-100">
              Curso destinatário para quais produtos?
            </label>
            <Select
              name="products"
              options={products}
              multiSelect
              className="mt-3 input-select products"
              onChange={(options) =>
                handleChangeProductsSelected(options as unknown as IOption[])
              }
              onChangeText={handleChangeProductText}
              optionsSelected={productsSelected}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <label className="w-100 mt-4">
            <InputToggle
              name="sell"
              options={[
                {
                  id: 'vender',
                  value: 'Vender curso a parte',
                },
              ]}
              className="mt-3 text-gray"
              onChange={handleChangeSell}
            />
          </label>
        </div>
        {sell && (
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6">
                <label className="w-100 mt-4">
                  Valor do curso{' '}
                  <InputMask
                    kind="money"
                    name="course_price"
                    className="mt-3 input"
                  />
                </label>
              </div>
              <div className="col-lg-6">
                <div className="w-100 mt-4">
                  <label>Curso em promoção?</label>
                  <div className="d-flex text-center mt-3">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        hasCourseDiscount ? 'btn-gray' : 'btn-dark-3'
                      }`}
                      htmlFor="has_course_discount_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={() => handleChangeHasCourseDiscount(true)}
                        name="has_course_discount"
                        id="has_course_discount_yes"
                        className="d-none"
                        value="yes"
                        checked={hasCourseDiscount}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        !hasCourseDiscount ? 'btn-gray' : 'btn-dark-3'
                      }`}
                      htmlFor="has_course_discount_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={() => handleChangeHasCourseDiscount(false)}
                        name="has_course_discount"
                        id="has_course_discount_no"
                        className="d-none"
                        value="no"
                        checked={!hasCourseDiscount}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {hasCourseDiscount && (
                <div className="col-lg-6">
                  <label className="w-100 mt-4">
                    Valor do curso na promoção{' '}
                    <InputMask
                      kind="money"
                      name="course_discount"
                      className="mt-3 input"
                    />
                  </label>
                </div>
              )}
              <div className="col-lg-6">
                <label className="w-100 mt-4">
                  Link de pagamento do curso
                  <Input
                    type="url"
                    name="course_checkout_url"
                    className="mt-3 input"
                  />
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-primary py-2 fw-bold mt-5 px-5 ms-auto d-block"
          >
            Próximo
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default CoursesCreate;
