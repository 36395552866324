import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import Swal from 'sweetalert2';

import { RiEditFill } from 'react-icons/ri';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Way } from './styles';

interface IWay {
  id: string;
  title: string;
  description: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IWayData {
  current_page: number;
  last_page: number;
  data: IWay[];
}

const Ways: React.FC = () => {
  const [ways, setWays] = useState<IWay[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadWays = useCallback(async (pageData: number) => {
    const response = await api.get<IWayData>(`ways`, {
      params: {
        all: true,
        page: pageData,
      },
    });

    if (pageData === 1) {
      setWays(response.data.data);
    } else {
      setWays((state) => [...state, ...response.data.data]);
    }
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadWays(1);
  }, [loadWays]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadWays(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadWays, page]);

  const handleClickDelete = useCallback(
    (category_id: string) => {
      Swal.fire({
        title: 'Deseja deletar essa categoria?',
        html: '<small>Ao deletar a categoria os cursos pertecente a ela ficarão em rascunhos, caso o curso não houver outra categoria.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`ways/${category_id}`);

            const newWays = ways.filter(
              (category) => category.id !== category_id
            );
            setWays(newWays);

            Toast.fire({
              icon: 'success',
              title: 'Caminho deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [ways]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4 d-flex justify-content-between align-items-center">
            <h1 className="fw-semibold text-white ms-4">Caminhos</h1>
            <Link
              to={`${process.env.PUBLIC_URL}/caminhos/cadastrar`}
              className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
            >
              <BsPlus size={24} color="#BBBBBB" />
              <span className="text-gray fw-bold ms-2">Caminho</span>
            </Link>
          </div>
          {ways.map((way) => (
            <div key={way.id} className="col-md-6 col-xl-4 mb-4">
              <Way
                src={way.thumbnail.thumbnail_url}
                className="p-4 d-flex flex-column justify-content-between"
              >
                <div className="d-flex w-100 justify-content-between">
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={() => handleClickDelete(way.id)}
                  >
                    <MdDelete size={24} color="#FF333D" />
                  </button>
                  <Link
                    to={`${process.env.PUBLIC_URL}/caminhos/${way.id}/editar`}
                    className="btn-edit bg-white border-0 d-flex align-items-center justify-content-center"
                  >
                    <RiEditFill size={20} color="#3A3A3A" />
                  </Link>
                </div>
                <p className="d-flex justify-content-between align-items-end text-white py-4 w-100 mb-0">
                  <span>
                    <span className="text-gray d-block mb-2 fw-bold">
                      {way.title}
                    </span>
                    <span className="text-gray d-block">{way.description}</span>
                  </span>
                </p>
              </Way>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Ways;
