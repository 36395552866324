import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';

import { MdDelete } from 'react-icons/md';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Steps, Plan } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import InputTag from '~/components/InputTag';
import InputToggle from '~/components/InputToggle';
import InputMask from '~/components/InputMask';
import { formatPrice } from '~/utils/format';

interface IFormData {
  title: string;
  description: string;
  product_price?: string;
  product_discount?: string;
  product_checkout_url?: string;
}

interface IPlans {
  name: string;
  description: string;
  price: number;
  has_discount: boolean;
  discount?: number;
  checkout_url: string;
  features: string[];
  order: number;
  orderError?: string;
}

interface IFormDataProduct {
  thumbnail_id: string;
  title: string;
  description: string;
  features?: string;
  checkout_url?: string;
  price?: number;
  has_discount?: boolean;
  discount?: number;
  hasPlans?: boolean;
  order: number;
}

interface IFormDataPlan {
  product_id: string;
  name: string;
  description: string;
  features: string;
  recommended?: boolean;
  checkout_url: string;
  price: number;
  has_discount?: boolean;
  discount?: number;
  order: number;
}

const ProductsCreate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbail] = useState<File | undefined>(undefined);
  const [thumbnailError, setThumbailError] = useState('');
  const [hasPlans, setHasPlans] = useState(false);
  const [plans, setPlans] = useState<IPlans[]>([
    {
      name: '',
      description: '',
      price: 0,
      has_discount: false,
      checkout_url: '',
      features: [] as string[],
      order: 1,
    },
  ]);
  const [hasProductDiscount, setHasProductDiscount] = useState(false);
  const [features, setFeatures] = useState<string[]>([]);

  const handleChangeThumbnail = useCallback((file) => {
    setThumbailError('');
    setThumbail(file);
  }, []);

  const handleChangeHasPlan = useCallback((value) => {
    setHasPlans(value.length > 0);
  }, []);

  const handleChangeHasProductDiscount = useCallback((value) => {
    setHasProductDiscount(value);
  }, []);

  const handleChangePlanName = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].name = e.target.value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanPrice = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].price = parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      );
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeOrder = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      const order = parseInt(e.target.value, 10);
      const checkPlans = newPlans.find((plan) => plan.order === order);
      if (checkPlans) {
        newPlans[index].orderError = 'Posição já esta ocupada';
      } else {
        newPlans[index].orderError = undefined;
      }
      newPlans[index].order = parseInt(e.target.value, 10);
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanDescritpion = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].description = e.target.value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanFeature = useCallback(
    (tags, index) => {
      const newPlans = plans.slice();
      newPlans[index].features = tags;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeHasPlanDiscount = useCallback(
    (value, index) => {
      const newPlans = plans.slice();
      newPlans[index].has_discount = value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanDiscount = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].discount = parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      );
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanCheckoutUrl = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].checkout_url = e.target.value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleClickAddPlan = useCallback(() => {
    const newPlan = {
      name: '',
      description: '',
      price: 0,
      has_discount: false,
      checkout_url: '',
      features: [] as string[],
      order: plans.length + 1,
    };

    setPlans((state) => [...state, newPlan]);
  }, [plans.length]);

  const handleClickDeletePlan = useCallback(
    (index) => {
      const newPlans = plans.filter((_, idx) => index !== idx);
      if (newPlans.length > 0) {
        setPlans(newPlans);
      } else {
        setPlans([
          {
            name: '',
            description: '',
            price: 0,
            has_discount: false,
            checkout_url: '',
            features: [] as string[],
            order: 1,
          },
        ]);
      }
    },
    [plans]
  );

  const handleChangeFeature = useCallback((tags) => {
    setFeatures(tags);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
          title: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          plans: Yup.string().when('$plans', {
            is: (plansCheck: boolean) => plansCheck,
            then: Yup.string().required(
              'Preencha todos os planos corretamente'
            ),
            otherwise: Yup.string(),
          }),
          product_price: Yup.string().when('$product_price', {
            is: (productPriceCheck: boolean) => productPriceCheck,
            then: Yup.string().required('O preço do produto é obrigatório'),
            otherwise: Yup.string(),
          }),
          product_discount: Yup.string().when('$product_discount', {
            is: (profuctDiscountCheck: boolean) => profuctDiscountCheck,
            then: Yup.string().required(
              'O valor do produto na promoção é obrigatório'
            ),
            otherwise: Yup.string(),
          }),
          product_checkout_url: Yup.string().when('$product_checkout_url', {
            is: (productCheckoutUrlCheck: boolean) => productCheckoutUrlCheck,
            then: Yup.string().required('O link de pagamento é obrigatório'),
            otherwise: Yup.string(),
          }),
          features: Yup.string().when('$features', {
            is: (featuresCheck: boolean) => featuresCheck,
            then: Yup.string().required('As características são obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            thumbnail: !thumbnail,
            plans:
              hasPlans &&
              plans.filter(
                (plan) =>
                  (plan.name.length === 0 ||
                    plan.description.length === 0 ||
                    plan.features.length === 0 ||
                    plan.checkout_url.length === 0 ||
                    !plan.order ||
                    (plan.order && plan.order === 0)) &&
                  !plan.orderError
              ).length > 0,
            product_price: !hasPlans,
            product_discount: !hasPlans && hasProductDiscount,
            product_checkout_url: !hasPlans,
            features: !hasPlans && features.length === 0,
          },
        });

        const formDataThumbail = new FormData();
        formDataThumbail.append('thumbnail', thumbnail as File);
        const responseThumbail = await api.post('thumbnails', formDataThumbail);

        const formData: IFormDataProduct = {
          thumbnail_id: responseThumbail.data.id,
          title: data.title,
          description: data.description,
          order: 1000,
        };

        if (!hasPlans) {
          const featuresData = features.join(';');
          formData.features = featuresData;
          formData.checkout_url = data.product_checkout_url;
          formData.price = data.product_price
            ? parseFloat(
                data.product_price
                  .replace('R$', '')
                  .replaceAll('.', '')
                  .replace(',', '.')
              )
            : undefined;
          formData.has_discount = hasProductDiscount;
          formData.hasPlans = false;
          if (hasProductDiscount) {
            formData.discount = data.product_discount
              ? parseFloat(
                  data.product_discount
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
                )
              : undefined;
          }
        } else {
          formData.hasPlans = true;
        }

        const response = await api.post('products', formData);

        if (hasPlans && plans.length > 0) {
          const plansPromise = new Promise<void>((resolve) => {
            plans.forEach(async (plan, index) => {
              const featuresData = plan.features.join(';');
              const formDataPlan: IFormDataPlan = {
                product_id: response.data.id,
                name: plan.name,
                description: plan.description,
                features: featuresData,
                recommended: false,
                checkout_url: plan.checkout_url,
                price: plan.price,
                has_discount: plan.has_discount,
                discount: plan.discount,
                order: plan.order,
              };

              await api.post(`plans`, formDataPlan);

              if (plans.length === index + 1) {
                resolve();
              }
            });
          });

          await plansPromise;
        }

        Toast.fire({
          icon: 'success',
          title: 'Produto cadastrado!',
        });
        history.push(
          `${process.env.PUBLIC_URL}/produtos/${response.data.slug}/cursos-inclusos`
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          if (errors.thumbnail) {
            setThumbailError(errors.thumbnail);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [features, hasPlans, hasProductDiscount, history, plans, thumbnail]
  );

  return (
    <Container className="container py-5">
      <div className="row">
        <div className="col-12 mb-4">
          <h1>Cadastrar produto</h1>
        </div>
        <div className="col-12">
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="row box bg-dark-2 p-5"
          >
            <div className="col-12">
              <div className="row justify-content-center my-5">
                <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                  <Steps className="d-flex ml-auto align-items-center min-width">
                    <div className="w-100 text-center bg-gray rounded-pill">
                      <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                        Sobre o produto
                      </span>
                    </div>
                    <hr className="w-25 w-md-50 border-gray mx-2" />
                    <div className="w-100 text-center bg-dark-3 rounded-pill">
                      <span className="d-block px-3 py-3 text-gray">
                        Cursos Inclusos
                      </span>
                    </div>
                  </Steps>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="w-100">
                <span>Thumbnail</span>
                <InputImage
                  name="thumbnail"
                  placeholder=""
                  className="bg-dark-3 mt-3"
                  onChange={handleChangeThumbnail}
                  cropImage
                  aspect={20.44 / 12.63}
                  cropOptions={
                    !thumbnail
                      ? {
                          unit: 'px',
                          width: 20.44 * 5,
                          height: 12.63 * 5,
                          x: 0,
                          y: 0,
                        }
                      : undefined
                  }
                  error={thumbnailError}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <label className="w-100">
                    Nome <Input name="title" className="mt-3 input" />
                  </label>
                </div>
                <div className="col-12">
                  <label className="w-100 mt-4">
                    Descrição{' '}
                    <Textarea name="description" className="mt-3" rows={4} />
                  </label>
                </div>
                <div className="col-12 pt-5 pb-4">
                  <hr />
                </div>
                <div className="col-lg-6">
                  <label className="w-100 mt-3">
                    <InputToggle
                      name="has_plans"
                      options={[
                        {
                          id: 'has_plans',
                          value: 'Possui planos',
                        },
                      ]}
                      className="mt-3 text-gray"
                      onChange={handleChangeHasPlan}
                    />
                  </label>
                </div>
                {!hasPlans && (
                  <>
                    <div className="col-12">
                      <label className="w-100 mt-4">
                        Características{' '}
                        <InputTag
                          name="features"
                          className="mt-3 input"
                          tags={features}
                          onChangeTags={handleChangeFeature}
                        />
                      </label>
                    </div>
                    <div className="col-lg-6">
                      <label className="w-100 mt-4">
                        Preço do produto{' '}
                        <InputMask
                          kind="money"
                          name="product_price"
                          className="mt-3 input"
                        />
                      </label>
                    </div>
                    <div className="col-lg-6">
                      <div className="w-100 mt-4">
                        <label>Produto em promoção?</label>
                        <div className="d-flex text-center mt-3">
                          <label
                            className={`btn w-100 mx-1 btn-radio ${
                              hasProductDiscount ? 'btn-gray' : 'btn-dark-3'
                            }`}
                          >
                            <span className="d-block py-2">YES</span>
                            <Input
                              type="radio"
                              onChange={() =>
                                handleChangeHasProductDiscount(true)
                              }
                              name="has_product_discount"
                              id="has_product_discount_yes"
                              className="d-none"
                              value="yes"
                              checked={hasProductDiscount}
                            />
                          </label>
                          <label
                            className={`btn w-100 mx-1 btn-radio ${
                              !hasProductDiscount ? 'btn-gray' : 'btn-dark-3'
                            }`}
                          >
                            <span className="d-block py-2">NO</span>
                            <Input
                              type="radio"
                              onChange={() =>
                                handleChangeHasProductDiscount(false)
                              }
                              name="has_product_discount"
                              id="has_product_discount_no"
                              className="d-none"
                              value="no"
                              checked={!hasProductDiscount}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {hasProductDiscount && (
                      <div className="col-lg-6">
                        <label className="w-100 mt-4">
                          Valor do produto na promoção{' '}
                          <InputMask
                            kind="money"
                            name="product_discount"
                            className="mt-3 input"
                          />
                        </label>
                      </div>
                    )}
                    <div className="col-lg-6">
                      <label className="w-100 mt-4">
                        Link de pagamento
                        <Input
                          type="url"
                          name="product_checkout_url"
                          className="mt-3 input"
                        />
                      </label>
                    </div>
                    <div className="col-12 pt-5 pb-4">
                      <hr />
                    </div>
                  </>
                )}
                {hasPlans && (
                  <>
                    <div className="col-12 pt-5 pb-4">
                      <hr />
                    </div>
                    {plans.map((plan, index) => (
                      <Plan
                        key={index.toString()}
                        className="col-12 position-relative"
                      >
                        <button
                          type="button"
                          className="btn-delete bg-transparent border-0 position-absolute"
                          onClick={() => handleClickDeletePlan(index)}
                        >
                          <MdDelete size={24} color="#FF333D" />
                        </button>
                        <div className="row">
                          <div className="col-lg-6">
                            <label className="w-100 mt-4">
                              Nome do plano{' '}
                              <Input
                                name="planName"
                                className="mt-3 input"
                                value={plan.name}
                                onChange={(e) => handleChangePlanName(e, index)}
                              />
                            </label>
                          </div>
                          <div className="col-lg-3">
                            <label className="w-100 mt-4">
                              Preço do plano{' '}
                              <InputMask
                                kind="money"
                                name="plan_price"
                                className="mt-3 input"
                                value={
                                  plan.price > 0 ? formatPrice(plan.price) : ''
                                }
                                onChange={(e) =>
                                  handleChangePlanPrice(e, index)
                                }
                              />
                            </label>
                          </div>
                          <div className="col-lg-3">
                            <label className="w-100 mt-4">
                              Posição{' '}
                              <Input
                                type="number"
                                name="order"
                                className="mt-3 input"
                                min={1}
                                value={plan.order}
                                onChange={(e) => handleChangeOrder(e, index)}
                              />
                              {plan.orderError && (
                                <span className="small text-error error">
                                  {plan.orderError}
                                </span>
                              )}
                            </label>
                          </div>
                          <div className="col-12">
                            <label className="w-100 mt-4">
                              Descrição do plano{' '}
                              <Textarea
                                name="plan_description"
                                className="mt-3"
                                rows={4}
                                onChange={(e) =>
                                  handleChangePlanDescritpion(e, index)
                                }
                                value={plan.description}
                              />
                            </label>
                          </div>
                          <div className="col-12">
                            <label className="w-100 mt-4">
                              Características{' '}
                              <InputTag
                                name="features"
                                className="mt-3 input"
                                tags={plan.features}
                                onChangeTags={(tags) =>
                                  handleChangePlanFeature(tags, index)
                                }
                              />
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <div className="w-100 mt-4">
                              <label>Plano em promoção?</label>
                              <div className="d-flex text-center mt-3">
                                <label
                                  className={`btn w-100 mx-1 btn-radio ${
                                    plan.has_discount
                                      ? 'btn-gray'
                                      : 'btn-dark-3'
                                  }`}
                                >
                                  <span className="d-block py-2">YES</span>
                                  <Input
                                    type="radio"
                                    onChange={() =>
                                      handleChangeHasPlanDiscount(true, index)
                                    }
                                    name="has_plan_discount"
                                    id="has_plan_discount_yes"
                                    className="d-none"
                                    value="yes"
                                    checked={plan.has_discount}
                                  />
                                </label>
                                <label
                                  className={`btn w-100 mx-1 btn-radio ${
                                    !plan.has_discount
                                      ? 'btn-gray'
                                      : 'btn-dark-3'
                                  }`}
                                >
                                  <span className="d-block py-2">NO</span>
                                  <Input
                                    type="radio"
                                    onChange={() =>
                                      handleChangeHasPlanDiscount(false, index)
                                    }
                                    name="has_plan_discount"
                                    id="has_plan_discount_no"
                                    className="d-none"
                                    value="no"
                                    checked={!plan.has_discount}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                          {plan.has_discount && (
                            <div className="col-lg-6">
                              <label className="w-100 mt-4">
                                Valor do plano na promoção{' '}
                                <InputMask
                                  kind="money"
                                  name="plan_discount"
                                  className="mt-3 input"
                                  value={
                                    plan.discount
                                      ? formatPrice(plan.discount)
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handleChangePlanDiscount(e, index)
                                  }
                                />
                              </label>
                            </div>
                          )}
                          <div className="col-lg-6">
                            <label className="w-100 mt-4">
                              Link de pagamento
                              <Input
                                type="url"
                                name="plan_checkout_url"
                                className="mt-3 input"
                                value={plan.checkout_url}
                                onChange={(e) =>
                                  handleChangePlanCheckoutUrl(e, index)
                                }
                              />
                            </label>
                          </div>
                          <div className="col-12 pt-5 pb-4">
                            <hr />
                          </div>
                          <div
                            className={
                              plans.length === index + 1
                                ? 'd-block mb-3'
                                : 'd-none'
                            }
                          >
                            <button
                              type="button"
                              className="border-0 bg-transparent"
                              onClick={handleClickAddPlan}
                            >
                              <BsPlus size={24} color="#BBBBBB" />
                              <span className="text-gray fw-bold ms-2">
                                Plano
                              </span>
                            </button>
                          </div>
                        </div>
                      </Plan>
                    ))}
                    <Input name="plans" className="d-none" />
                  </>
                )}
                <div className="col-12 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary py-2 fw-bold mt-5 px-5 ms-auto d-block"
                  >
                    Próximo
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default ProductsCreate;
