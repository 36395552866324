import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}

interface IOptions {
  active: boolean;
}

export const Container = styled.div`
  .table-employees {
    padding-top: 0;
    background-color: #242526;

    > div {
      background-color: #242526;
      border: none;

      .content {
        padding-top: 45px;

        .tb-header {
          border: none;

          button {
            color: #ffffff;
          }
        }

        .tb-body {
          background-color: #242526;

          .tb-row:hover {
            transition-duration: 0.3s;
            background: rgba(108, 108, 108, 0.15);
          }

          div {
            color: #bbbbbb;
          }
        }

        .tb-header .tb-row,
        .tb-body .tb-row {
          > button:nth-child(1),
          > div:nth-child(1) {
            width: 100px;
          }

          > button:nth-child(2),
          > div:nth-child(2) {
            width: 250px;
          }

          > button:nth-child(3),
          > div:nth-child(3) {
            width: 375px;
          }

          > button:nth-child(4),
          > div:nth-child(4) {
            width: 451px;
          }

          > button:nth-child(5),
          > div:nth-child(5) {
            width: 50px;
          }
        }
      }
      //
      :after,
      :before {
        border: none;
        background-color: #242526;
      }

      :nth-child(2) {
        select {
          background-color: #242526;
          color: #bbbbbb;
        }

        small {
          color: #bbbbbb;
        }

        button {
          background-color: transparent;
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-add {
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bbbbbb !important;
      border-radius: 7px;

      svg {
        position: unset;
      }
    }

    .table-users {
      background-color: #3a3a3a;

      > div {
        background-color: #3a3a3a;
        border: none;

        .content {
          .tb-header {
            border: none;

            button {
              color: #ffffff;
            }
          }

          .tb-body {
            background-color: #3a3a3a;

            .tb-row-hover:hover {
              background: rgba(108, 108, 108, 0.15);
            }

            div {
              color: #bbbbbb;
            }
          }

          .tb-header .tb-row,
          .tb-body .tb-row {
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 100px;
            }

            > button:nth-child(2),
            > div:nth-child(2) {
              width: 400px;
            }

            > button:nth-child(3),
            > div:nth-child(3) {
              width: 400px;
            }

            > button:nth-child(4),
            > div:nth-child(4) {
              width: 250px;
            }

            > button:nth-child(5),
            > div:nth-child(5) {
              width: 200px;
            }

            > button:nth-child(6),
            > div:nth-child(6) {
              width: 250px;
            }

            > button:nth-child(17),
            > div:nth-child(17) {
              width: 350px;
            }

            > button:nth-child(18),
            > div:nth-child(18) {
              width: 500px;
            }

            > button:nth-child(19),
            > div:nth-child(19) {
              width: 400px;
            }

            > button:nth-child(20),
            > div:nth-child(20) {
              width: 450px;
            }
          }
        }

        :before,
        :after {
          background-color: #3a3a3a;
        }

        :after {
          border: none;
        }

        :nth-child(2) {
          select {
            background-color: #3a3a3a;
            color: #bbbbbb;
          }

          small {
            color: #bbbbbb;
          }

          button {
            background-color: transparent;
          }
        }
      }
    }
  }
`;

export const Options = styled.div<IOptions>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  background: #2f2f30;
  border: 2px solid #242526;
  width: max-content;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -110px;
  left: 10px;
  transform: translateX(-50%);

  a,
  button {
    background: transparent;
    border: none;
    text-align: center;
    color: #fff;
    padding: 8px 0;

    + button {
      border-top: 1px solid #707070;
    }
  }

  :after {
    content: '';
    width: 15px;
    height: 15px;
    background: #2f2f30;
    border-right: 2px solid #242526;
    border-bottom: 2px solid #242526;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: rotateZ(45deg) translateX(-50%);
  }
`;
