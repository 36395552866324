import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Background, Content } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo-white.svg';

interface IFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [signIn]
  );

  return (
    <Container className="container container-lg-fluid">
      <div className="row">
        <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h1 className="fw-semibold">Entrar</h1>
              {/* <button type="button" className="border-0 bg-transparent">
                <IoMdHelpCircleOutline size={24} color="#BBBBBB" />
              </button> */}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="mb-2 fw-medium">
                E-mail
              </label>
              <Input
                type="email"
                placeholder="email@example.com"
                name="email"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="mb-2 fw-medium">
                Senha
              </label>
              <Input type="password" placeholder="******" name="password" />
            </div>
            <Link
              to={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
              className="d-block mt-4 mb-5 text-gray"
            >
              Esqueceu a senha?
            </Link>
            <div className="mb-5">
              <button
                type="submit"
                className="btn btn-primary mb-5 w-100 fw-semibold"
              >
                Entrar
              </button>
            </div>
          </Form>
        </Content>
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4">
          <div className="px-5 pb-5">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Background>
      </div>
    </Container>
  );
};

export default Login;
