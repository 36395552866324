import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useLayoutEffect,
} from 'react';

interface PermissionContextData {
  module: string;
  permission: number;
  url: string;
  setPermission(
    permissionData: number,
    moduleData: string,
    urlData: string
  ): void;
}

export const PermissionContext = createContext<PermissionContextData>(
  {} as PermissionContextData
);

export const PermissionProvider: React.FC = ({ children }) => {
  const [permission, setPermission] = useState(0);
  const [module, setModule] = useState('');
  const [url, setURL] = useState('');

  useLayoutEffect(() => {
    const permissionData = localStorage.getItem('@RMS:permission');
    const moduleData = localStorage.getItem('@RMS:module');
    const urlData = localStorage.getItem('@RMS:url');
    if (permissionData) {
      setPermission(parseInt(permissionData, 10));
    }
    if (moduleData) {
      setModule(moduleData);
    }
    if (urlData) {
      setURL(urlData);
    }
  }, []);

  const handleSetPermission = useCallback(
    (permissionData, moduleData, urlData) => {
      localStorage.setItem('@RMS:permission', permissionData);
      localStorage.setItem('@RMS:module', moduleData);
      localStorage.setItem('@RMS:url', urlData);
      setPermission(permissionData);
      setModule(moduleData);
      setURL(urlData);
    },
    []
  );

  return (
    <PermissionContext.Provider
      value={{ module, permission, url, setPermission: handleSetPermission }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export function usePermission(): PermissionContextData {
  const context = useContext(PermissionContext);

  if (!context) {
    throw new Error('usePermission must be used within an PermissionProvider');
  }

  return context;
}
