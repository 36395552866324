import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { parseISO } from 'date-fns';
import readXlsxFile from 'read-excel-file';
import { BsPlus, BsThreeDotsVertical } from 'react-icons/bs';
import { IoIosArrowRoundDown } from 'react-icons/io';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

import { Form } from '@unform/web';
import api from '~/services/api';
import mask from '~/utils/mask';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Avatar, Modal, Options } from './styles';
import InputFile from '~/components/InputFile';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';

import avatar from '~/assets/defaults/avatar.png';
import Search from '~/components/Search';

interface IMemberResponse {
  id: string;
  name: string;
  email: string;
  created_at: string;
  avatar?: {
    avatar_url: string;
  };
  plan: {
    name: string;
  };
}

interface IMemberData {
  data: IMemberResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IMember {
  id: string;
  user_id: string;
  avatar: string;
  name: string;
  email: string;
  plan: string;
  joined_at: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IUser {
  name: string;
  email: string;
  course: string;
  document: string;
  birthdate?: Date;
  phone: string;
  zipcode: string;
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  occupation?: string;
  origin: string;
  know_leiaut: string;
  other_instagram?: string;
  events?: string;
  college_name: string;
}

interface IFormData {
  name: string;
  email: string;
  password: string;
  foreign: string;
  birthdate: string;
  document: string;
  occupation: string;
  city: string;
  state: string;
}

const Members: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [members, setMembers] = useState<IMember[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [page, setPage] = useState(1);
  const [memberSelected, setMemberSelected] = useState({} as IMember);
  const [showAddMember, setShowAddMember] = useState(false);

  const loadMembers = useCallback(async (pageData, search = '') => {
    const response = await api.get<IMemberData>('users', {
      params: { page: pageData, search },
    });
    const data: IMember[] = response.data.data.map((member, index) => {
      const [firstName, secondName] = member.name.split(' ');
      return {
        id: (index + 1).toString().padStart(5, '0'),
        user_id: member.id,
        avatar: member.avatar ? member.avatar.avatar_url : avatar,
        name: `${firstName} ${secondName}`,
        email: member.email,
        plan: member.plan ? member.plan.name : '-',
        joined_at: format(parseISO(member.created_at), 'dd/MM/yyyy'),
      };
    });

    setMembers(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  const states = useMemo<IOption[]>(
    () => [
      { id: 1, value: 'Selecione', selected: true, notSelectable: true },
      { id: 'AC', value: 'Acre', selected: false },
      { id: 'AC', value: 'Acre', selected: false },
      { id: 'AL', value: 'Alagoas', selected: false },
      { id: 'AP', value: 'Amapá', selected: false },
      { id: 'AM', value: 'Amazonas', selected: false },
      { id: 'BA', value: 'Bahia', selected: false },
      { id: 'CE', value: 'Ceará', selected: false },
      { id: 'DF', value: 'Distrito Federal', selected: false },
      { id: 'ES', value: 'Espírito Santo', selected: false },
      { id: 'GO', value: 'Goiás', selected: false },
      { id: 'MA', value: 'Maranhão', selected: false },
      { id: 'MT', value: 'Mato Grosso', selected: false },
      { id: 'MS', value: 'Mato Grosso do Sul', selected: false },
      { id: 'MG', value: 'Minas Gerais', selected: false },
      { id: 'PA', value: 'Pará', selected: false },
      { id: 'PB', value: 'Paraíba', selected: false },
      { id: 'PR', value: 'Paraná', selected: false },
      { id: 'PE', value: 'Pernambuco', selected: false },
      { id: 'PI', value: 'Piauí', selected: false },
      { id: 'RJ', value: 'Rio de Janeiro', selected: false },
      { id: 'RN', value: 'Rio Grande do Norte', selected: false },
      { id: 'RS', value: 'Rio Grande do Sul', selected: false },
      { id: 'RO', value: 'Rondônia', selected: false },
      { id: 'RR', value: 'Roraima', selected: false },
      { id: 'SC', value: 'Santa Catarina', selected: false },
      { id: 'SP', value: 'São Paulo', selected: false },
      { id: 'SE', value: 'Sergipe', selected: false },
      { id: 'TO', value: 'Tocantins', selected: false },
    ],
    []
  );

  useEffect(() => {
    loadMembers(page);
  }, [loadMembers, page]);

  const handleClickDots = useCallback((member: IMember) => {
    setMemberSelected((state) =>
      state.id === member.id ? ({} as IMember) : member
    );
  }, []);

  const handleClickLoginUser = useCallback(async () => {
    const response = await api.post('tokens/admin-supports', {
      user_id: memberSelected.user_id,
    });
    window.open(
      `${process.env.REACT_APP_APP_URL}/auto-login/${response.data}`,
      '_blank'
    );
  }, [memberSelected.user_id]);

  const handleClickDeleteUser = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse aluno?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#e50914',
      cancelButtonColor: '#303030',
      cancelButtonText: 'Não',
      reverseButtons: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`users/${memberSelected.user_id}`);

          const newMebers = members.filter(
            (membersData) => membersData.user_id !== memberSelected.user_id
          );
          setMembers(newMebers);

          Toast.fire({
            icon: 'success',
            title: 'Aluno deletado!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [memberSelected.id, members]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Usuário',
        selector: 'name',
        cell: (row: IMember) => (
          <div className="d-flex align-items-center">
            <Avatar src={row.avatar} className="me-2" />
            <p className="mb-0">{row.name}</p>
          </div>
        ),
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Plano',
        selector: 'plan',
      },
      {
        name: 'Data de ingresso',
        selector: 'joined_at',
      },
      {
        name: '-',
        selector: 'id',
        cell: (row: IMember) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options active={memberSelected.id === row.id}>
              <Link to={`${process.env.PUBLIC_URL}/membros/${row.user_id}`}>
                Ver pefil
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/membros/${row.user_id}/liberar-acesso/produtos`}
              >
                Liberar acesso
              </Link>
              <button type="button" onClick={handleClickLoginUser}>
                Logar como o usuário
              </button>
              <button type="button" onClick={handleClickDeleteUser}>
                Deletar usuário
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [handleClickDots, handleClickLoginUser, memberSelected.id]
  );

  const modalColumns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'name',
        cell: (_, rowIndex) => (rowIndex + 1).toString().padStart(2, '0'),
      },
      {
        name: 'Usuário',
        selector: 'name',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Curso',
        selector: 'course',
      },
      {
        name: 'Documento',
        selector: 'document',
        cell: (row: IUser) =>
          mask({
            kind: 'cpf',
            value: row.document,
          }),
      },
      {
        name: 'Data de nascimento',
        selector: 'birthdate',
        cell: (row: IUser) =>
          row.birthdate ? format(row.birthdate, 'dd/MM/yyyy') : '-',
      },
      {
        name: 'Telefone/Celular',
        selector: 'phone',
        cell: (row: IUser) =>
          mask({
            kind: 'cel-phone',
            value: row.phone,
          }),
      },
      {
        name: 'CEP',
        selector: 'zipcode',
        cell: (row: IUser) =>
          mask({
            kind: 'zip-code',
            value: row.zipcode,
          }),
      },
      {
        name: 'Endereço',
        selector: 'street',
      },
      {
        name: 'Número',
        selector: 'number',
      },
      {
        name: 'Complemento',
        selector: 'complement',
        cell: (row: IUser) => row.complement || '-',
      },
      {
        name: 'Bairro',
        selector: 'neighborhood',
      },
      {
        name: 'Cidade',
        selector: 'city',
      },
      {
        name: 'Estado',
        selector: 'state',
      },
      {
        name: 'Profissão',
        selector: 'occupation',
        cell: (row: IUser) => row.occupation || '-',
      },
      {
        name: 'Origem',
        selector: 'origin',
      },
      {
        name: 'Como você conheceu a escola?',
        selector: 'know_leiaut',
      },
      {
        name: 'Se você selecionou "outro instagram" diga qual.',
        selector: 'other_instagram',
        cell: (row: IUser) => row.other_instagram || '-',
      },
      {
        name: 'Se você escolheu "Eventos" diga qual.',
        selector: 'events',
        cell: (row: IUser) => row.events || '-',
      },
      {
        name: 'Qual o nome de sua Faculdade/Universidade?',
        selector: 'college_name',
      },
    ],
    []
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowAddMember(false);
    setUsers([]);
  }, []);

  const handleChange = useCallback(async (file) => {
    try {
      setLoadingMessage('Verificando arquivo...');
      setLoading(true);
      const rows = await readXlsxFile(file);
      if (rows.length > 0) {
        const usersData: IUser[] = [];
        const rowsPromise = new Promise<void>((resolve) => {
          rows.forEach(async (row, index) => {
            if (index > 0) {
              const newUser = {} as IUser;
              const rowPromise = new Promise<void>((resolveRow) => {
                row.forEach((data, idx) => {
                  switch (idx) {
                    case 0:
                      newUser.name = data as string;
                      break;
                    case 1:
                      newUser.email = data as string;
                      break;
                    case 2:
                      newUser.document = data
                        ? `${data}`.replaceAll('.', '').replace('-', '')
                        : '-';
                      break;
                    case 3:
                      newUser.birthdate = data
                        ? new Date(`${data}`)
                        : undefined;
                      break;
                    case 4:
                      newUser.phone = data
                        ? `${data}`
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('-', '')
                        : '-';
                      break;
                    case 5:
                      newUser.street = data ? `${data}` : '-';
                      break;
                    case 6:
                      newUser.number = data ? `${data}` : '-';
                      break;
                    case 7:
                      newUser.complement = data ? `${data}` : undefined;
                      break;
                    case 8:
                      newUser.neighborhood = data ? `${data}` : '-';
                      break;
                    case 9:
                      newUser.zipcode = data ? `${data}`.replace('-', '') : '-';
                      break;
                    case 10:
                      newUser.city = data ? `${data}` : '-';
                      break;
                    case 11:
                      newUser.state = data ? `${data}` : '-';
                      break;
                    case 12:
                      newUser.occupation = data ? `${data}` : '-';
                      break;
                    case 13:
                      newUser.course = data ? `${data}` : '-';
                      break;
                    case 14:
                      newUser.origin = data ? `${data}` : '-';
                      break;
                    case 15:
                      newUser.know_leiaut = data ? `${data}` : '-';
                      break;
                    case 16:
                      newUser.other_instagram = data ? `${data}` : '-';
                      break;
                    case 17:
                      newUser.events = data ? `${data}` : '-';
                      break;
                    case 18:
                      newUser.college_name = data ? `${data}` : '-';
                      break;
                    default:
                      break;
                  }
                  if (row.length === idx + 1) {
                    resolveRow();
                  }
                });
              });
              await rowPromise;
              usersData.push(newUser);
            }
            if (rows.length === index + 1) {
              resolve();
            }
          });
        });
        await rowsPromise;
        setUsers(usersData);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }, []);

  const handleClickSave = useCallback(async () => {
    try {
      setLoadingMessage('Importando membros...');
      setLoading(true);
      const formData = users.map((user) => ({
        name: user.name,
        email: user.email,
        document: user.document !== '-' ? user.document : undefined,
        birthdate: user.birthdate,
        phone: user.phone !== '-' ? user.phone : undefined,
        street: user.street !== '-' ? user.street : undefined,
        number: user.number !== '-' ? user.number : undefined,
        complement: user.complement,
        neighborhood: user.neighborhood !== '-' ? user.neighborhood : undefined,
        zipcode: user.zipcode !== '-' ? user.zipcode : undefined,
        city: user.city !== '-' ? user.city : undefined,
        state: user.state !== '-' ? user.state : undefined,
        occupation: user.occupation !== '-' ? user.occupation : undefined,
        course: user.course !== '-' ? user.course : undefined,
        origin: user.origin !== '-' ? user.origin : undefined,
        know_leiaut: user.know_leiaut !== '-' ? user.know_leiaut : undefined,
        other_instagram:
          user.other_instagram !== '-' ? user.other_instagram : undefined,
        events: user.events !== '-' ? user.events : undefined,
        college_name: user.college_name !== '-' ? user.college_name : undefined,
      }));
      await api.post('users/import', formData);
      await loadMembers(page);
      handleClose();
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }, [handleClose, loadMembers, page, users]);

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleClickAddMember = useCallback(() => {
    setShowAddMember(true);
  }, []);

  const handleChangeName = useCallback((e) => {
    if (formRef.current) {
      const nameParts = e.target.value.trim().split(' ');
      if (nameParts.length === 1) {
        formRef.current.setErrors({
          name: 'Preencha seu nome completo',
        });
      } else {
        formRef.current.setErrors({});
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
          birthdate: Yup.string().required(
            'A data de nascimento é obrigatória'
          ),
          document: Yup.string().required('O CPF é obrigatório'),
          occupation: Yup.string().required('A profissão é obrigatória'),
          city: Yup.string().required('A cidade é obrigatória'),
          state: Yup.string().required('O estado é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const [day, month, year] = data.birthdate.split('/');
        const formData = {
          name: data.name,
          email: data.email,
          password: data.password,
          foreign: !!data.foreign,
          birthdate: new Date(
            parseInt(year, 10),
            parseInt(month, 10) - 1,
            parseInt(day, 10)
          ),
          document: data.document.replaceAll('.', '').replace('-', ''),
          occupation: data.occupation,
        };

        const response = await api.post('users', formData);

        const formDataAddress = {
          user_id: response.data.id,
          city: data.city,
          state: data.state,
        };

        await api.post('addresses', formDataAddress);

        if (members.length < 10) {
          const newMembers = members.slice();
          const [firstName, secondName] = response.data.name.split(' ');
          newMembers.push({
            id: (members.length + 1).toString().padStart(5, '0'),
            user_id: response.data.id,
            avatar,
            name: `${firstName} ${secondName}`,
            email: response.data.email,
            plan: '-',
            joined_at: format(parseISO(response.data.created_at), 'dd/MM/yyyy'),
          });

          setMembers(newMembers);
        }

        handleClose();
        Toast.fire({
          icon: 'success',
          title: 'Pergunta editada!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [members, handleClose]
  );

  const handleSearch = useCallback(
    (value) => {
      loadMembers(1, value);
    },
    [loadMembers]
  );

  const handleClickDownloadExcelExample = useCallback(() => {
    const ws = XLSX.utils.json_to_sheet([
      {
        Nome: 'John Doe',
        'E-mail': 'john.doe@examplo.com',
        Documento: '',
        'Data de nascimento': '',
        Celular: '',
        Rua: '',
        Número: '',
        Complemento: '',
        Bairro: '',
        CEP: '',
        Cidade: '',
        Estado: '',
        Profissão: '',
        Curso: '',
        Origem: '',
        'Como conheceu a Leiaut': '',
        'Outros Instagrams': '',
        Eventos: '',
        'Nome da Faculdade/Universidade': '',
      },
    ]);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(excelData, 'membros-exemplo.xlsx');
  }, []);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h1>Membros</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <button
                  type="button"
                  className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
                  onClick={handleClickAddMember}
                >
                  <BsPlus size={24} color="#BBBBBB" />
                  <span className="text-gray fw-bold ms-2">Membro</span>
                </button>
                <button
                  type="button"
                  className="border-0 bg-transparent d-flex align-items-center ms-3"
                  onClick={() => setShow(true)}
                >
                  <IoIosArrowRoundDown size={24} color="#FFFFFF" />
                  <span className="fw-semibold text-white">
                    Importar usuário
                  </span>
                </button>
              </div>
            </div>
            <Table
              columns={columns}
              data={members}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-members mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="ml-auto">Importar membros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputFile
            name="list-members"
            onChange={handleChange}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            noForm
          />
          <small>
            Baixe um exemplo{' '}
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={handleClickDownloadExcelExample}
            >
              clicando aqui
            </button>
          </small>
          {users.length > 0 && (
            <Table
              columns={modalColumns}
              data={users}
              className="table-users mt-5"
            />
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          {users.length > 0 && (
            <button
              type="button"
              className="btn btn-primary btn-submit px-5"
              onClick={handleClickSave}
            >
              Salvar
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal size="xl" show={showAddMember} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Novo membro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-3">
                <h5>Dados pessoais</h5>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="w-100 fw-medium">
                  Nome Completo
                  <Input
                    placeholder="Ex: João Silva"
                    name="name"
                    onChange={handleChangeName}
                    className="mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="w-100 fw-medium">
                  E-mail
                  <Input
                    type="email"
                    placeholder="email@example.com"
                    name="email"
                    className="mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="w-100 fw-medium">
                  Senha
                  <Input
                    type="password"
                    placeholder="******"
                    name="password"
                    className="mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="w-100 fw-medium">
                  Data de nascimento
                  <InputMask
                    kind="datetime"
                    options={{
                      format: 'DD/MM/YYYY',
                    }}
                    name="birthdate"
                    placeholder="00/00/0000"
                    className="mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="w-100 fw-medium">
                  CPF
                  <InputMask
                    kind="cpf"
                    name="document"
                    placeholder="000.000.000-00"
                    className="mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-4 mb-4">
                <label className="w-100 fw-medium">
                  Profissão
                  <Input name="occupation" className="mt-2" />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <h5>Localização</h5>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Cidade
                  <Input name="city" className="mt-2" />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label htmlFor="state" className="w-100 fw-medium">
                  Estado
                </label>
                <Select name="state" options={states} className="mt-2" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary btn-submit px-5">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading show={loading} message={loadingMessage} />
    </Container>
  );
};

export default Members;
