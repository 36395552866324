import React, { useState } from 'react';
import { Doubt, DoubtAnswer } from '../CommentSupport';
import {
  Conteiner,
  ModuleAndClassroom,
  CommentSupport,
  PhotoAndUser,
  Photo,
  InfoComment,
  NameAndTime,
  Module,
  Class,
} from './styles';
import { formatDistanceToNow } from 'date-fns';
import { pt } from 'date-fns/locale';

interface CardCommentSupportProps {
  doubt: Doubt;
  doubtAnswers: DoubtAnswer[];
  onReply: (doubtId: string, reply: string) => void;
  searchTerm: string;
}

const CardCommentSupport: React.FC<CardCommentSupportProps> = ({
  doubt,
  doubtAnswers,
  onReply,
  searchTerm,
}) => {
  const [reply, setReply] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReply(event.target.value);
  };

  const handleReplyClick = () => {
    if (reply.trim() !== '') {
      onReply(doubt.id, reply);
      setReply('');
      setShowInput(false); // Ocultar el input después de responder
    } else if (showInput) {
      setShowInput(false); // Ocultar el input si está vacío y el botón de responder es presionado
    } else {
      setShowInput(true); // Mostrar el input cuando se presiona el botón de responder
    }
  };

  const highlightText = (text: string | undefined) => {
    const parts = (text || '').split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <b key={i} style={{ backgroundColor: 'purple' }}>
              {part}
            </b>
          ) : (
            part
          )
        )}{' '}
      </span>
    );
  };

  return (
    <Conteiner>
      <ModuleAndClassroom>
        <Module>
          <h3>{highlightText('Modulo')}</h3>
          <p>
            {highlightText(doubt.lesson.module.title)}
          </p>
        </Module>
        <Class>
          <h3>{highlightText('Aula')}</h3>
          <p>{highlightText(doubt.lesson.title)}</p>
        </Class>
      </ModuleAndClassroom>
      <CommentSupport>
        <PhotoAndUser>
          <Photo>
            <img
              src={doubt.user.avatar.avatar_url}
              alt={doubt.user_id}
              className="avatar"
            />
          </Photo>
          <InfoComment>
          {doubtAnswers.length === 0 && <h2>Nova pergunta</h2>}
          <p>
            <strong>{highlightText(doubt.lesson.module.course.title)}</strong>
          </p>
          <p>{highlightText(doubt.content)}</p>
          {doubtAnswers.map((answer, index) => (
            <p key={index}>{highlightText(answer.content)}</p>
          ))}
            <NameAndTime>
              <p>
                <strong>{highlightText(doubt.user.name)}</strong>
              </p>
              <p>
                •{' '}
                {formatDistanceToNow(new Date(doubt.created_at), {
                  addSuffix: true,
                  locale: pt,
                })}
              </p>
            </NameAndTime>
            {showInput && (
              <input
                type="text"
                value={reply}
                onChange={handleInputChange}
              />
            )}
            <button type="button" onClick={handleReplyClick}>
              Responder
            </button>
          </InfoComment>
        </PhotoAndUser>
      </CommentSupport>
    </Conteiner>
  );
};

export default CardCommentSupport;