import styled from 'styled-components';

export const Conteiner = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #676767;
  display: flex;
  padding-bottom: 2rem;
  padding-top: 2rem;

  h5 {
    display: none
  }

  h2 {
    color: #f00;
    font-size: 22px;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 768px) {
    display: contents;
    border-bottom: 1px solid #676767;

    // h2 {
    //   display: none;
    // }

    h5 {
      display: block;
      color: #f00;
      font-size: 22px;
      padding-left: 1rem;
      margin-top: 2rem;
    }
  }

`;

export const ModuleAndClassroom = styled.div`
  padding: 1rem;
  border-right: 1px solid #6d6d6d;

  h3 {
    color: #676767;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    border-right: none;
    gap: 3rem;
    margin-right: 0;
  }
`;

export const Module = styled.div`
  padding: 1rem;
  p {
    width: 15ch;
    word-wrap: break-word;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    p {
      width: auto;
    }
  }
`;

export const Class = styled.div`
  padding: 1rem;
  p {
    width: 15ch;
    word-wrap: break-word;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    p {
      width: auto;
    }
  }
`;

export const CommentSupport = styled.div`
  margin-left: 1rem;

  button {
    display: flex;
    width: 190px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #f00;
    border: none;
  }

  input {
    width: 100%;
    height: 84px;
    border-radius: 20px;
    border: none;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 768px){
    border-bottom: 1px solid #676767;
    padding-bottom: 1.5rem;
    margin-left: 0;
  }
`;

export const PhotoAndUser = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Photo = styled.div`

`;

export const InfoComment = styled.div`

`;

export const NameAndTime = styled.div`
display: flex;
gap: 0.5rem;
`;