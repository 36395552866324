import styled from 'styled-components';
import List from 'react-chatview';
import { GridDropZone as GridDropZoneComponent } from 'react-grid-dnd';

interface IGridDropZone {
  height: number;
}

interface IProduct {
  src: string;
}

export const Container = styled(List)`
  height: calc(100vh - 61px);
`;

export const GridDropZone = styled(GridDropZoneComponent)<IGridDropZone>`
  height: ${(props) => (props.height > 0 ? props.height : 200)}px;

  .cursor-grab {
    cursor: grab;
  }

  .cursor-grabbing {
    cursor: grabbing;
  }
`;

export const Product = styled.div<IProduct>`
  position: relative;
  background: linear-gradient(
      188.52deg,
      rgba(44, 44, 44, 0.38) 33.3%,
      #2b2b2b 95.76%
    ),
    url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 26px;
  height: 325px;

  a {
    span {
      width: calc(100% - 30px);
    }
  }

  button {
    top: 15px;
    right: 30px;
  }

  .btn-edit {
    bottom: 15px;
    right: 30px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
  }
`;
