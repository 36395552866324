import React, { useCallback, useEffect, useState } from 'react';
import { FiPaperclip } from 'react-icons/fi';

import { Container } from './styles';
import Input from '~/components/Input';

interface IInputFile {
  name: string;
  className?: string;
  onChange?(value: File): void;
  value?: string;
  accept?: string;
  noForm?: boolean;
}

const InputFile: React.FC<IInputFile> = ({
  name,
  className,
  onChange,
  value,
  accept,
  noForm,
}) => {
  const [valuePreview, setValuePreview] = useState('');
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (value) {
      setValuePreview(value);
    }
  }, [value]);

  const handleChange = useCallback(
    (e) => {
      if (e.target.files.length > 0) {
        setValuePreview(e.target.files[0].name);
        if (onChange) {
          onChange(e.target.files[0]);
        }
      } else {
        setValuePreview('');
      }
    },
    [onChange]
  );

  return (
    <Container
      htmlFor={name}
      className={className}
      isErrored={isError}
      isFilled={isFilled}
      isFocuses={isFocuses}
    >
      <p className="mb-0 border-0 bg-transparent">
        {valuePreview || 'Insira aqui o arquivo'}
      </p>
      {!noForm ? (
        <Input
          type="file"
          id={name}
          name={name}
          className="d-none"
          onChange={handleChange}
          onFocus={() => {
            setIsFocuses(true);
            setIsFilled(false);
            setIsError(false);
          }}
          onBlur={() => {
            setIsFocuses(false);
            setIsFilled(true);
            setIsError(false);
          }}
          accept={accept}
        />
      ) : (
        <input
          type="file"
          id={name}
          name={name}
          className="d-none"
          onChange={handleChange}
          onFocus={() => {
            setIsFocuses(true);
            setIsFilled(false);
            setIsError(false);
          }}
          onBlur={() => {
            setIsFocuses(false);
            setIsFilled(true);
            setIsError(false);
          }}
          accept={accept}
        />
      )}
      <FiPaperclip size={24} color="#BBBBBB" />
    </Container>
  );
};

export default InputFile;
