import styled from 'styled-components';
import List from 'react-chatview';

interface IProduct {
  src: string;
}

interface ICourse {
  src: string;
}

interface ITabsGroup {
  opened: boolean;
}

interface ITab {
  active: boolean;
}

export const Container = styled(List)`
  height: calc(100vh - 61px);

  a {
    color: #fff;
  }

  .search {
    width: 30%;
  }
`;

export const Product = styled.div<IProduct>`
  border-radius: 10px;
  background-color: #242526;
  overflow: hidden;

  div.thumb {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    display: flex;
    align-items: end;
  }

  div.infos {
    span {
      display: block;
      width: calc(100% - 200px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    button {
      width: 190px;
    }
  }
`;

export const Course = styled.div<ICourse>`
  border-radius: 10px;
  background-color: #242526;
  overflow: hidden;

  div.thumb {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    display: flex;
    align-items: end;
  }

  div.infos {
    span {
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    button {
      width: 190px;
    }
  }
`;

export const Tabs = styled.div`
  border: 1px solid #333333;
  border-radius: 15px;

  @media screen and (min-width: 768px) {
    border: none;
    border-radius: 0;
  }
`;

export const TabsButton = styled.button`
  border: 1px solid #333333;
  border-radius: 15px;
`;

export const TabsGroup = styled.div<ITabsGroup>`
  transition-duration: 0.3s;
  height: ${(props) => (props.opened ? '200px' : '0')};
  overflow: hidden;
  padding-top: ${(props) => (props.opened ? '20px' : '0')};
  margin-top: ${(props) => (props.opened ? '20px' : '0')};
  border-top: ${(props) => (props.opened ? '1px solid #333333' : 'none')};

  @media screen and (min-width: 768px) {
    height: unset;
    overflow: unset;
  }
`;

export const Tab = styled.button<ITab>`
  position: relative;
  transition-duration: 0.3s;
  color: ${(props) => (props.active ? '#ffffff' : '#bbbbbb')};

  :after {
    content: '';
    transition-duration: 0.3s;
    background: ${(props) => (props.active ? '#FF0000' : '#FF000000')};
    width: 80%;
    height: 1px;
    border-radius: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  :hover {
    color: #ffffff;

    :after {
      background: #ff0000;
    }
  }

  + button {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    + button {
      margin-top: 0;
      margin-left: 40px;
    }
  }
`;
