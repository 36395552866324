/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import InputRadio, { IOption as IOptionRadio } from '~/components/InputRadio';
import InputFile from '~/components/InputFile';
import InputImage from '~/components/InputImage';

interface IFormData {
  name: string;
  video_type: string;
  url?: string;
  file?: string;
}

const CategoriesCreate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnailType, setThumbnailType] = useState<IOptionRadio>({
    id: 'upload',
    value: 'Upload',
  });
  const [resource, setResource] = useState<File | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [thumbnailError, setThumbnailError] = useState('');

  const handleChangeVideoType = useCallback((option: IOptionRadio) => {
    setThumbnailType(option);
  }, []);

  const handleChangeFile = useCallback((file) => {
    setResource(file);
  }, []);

  const handleChangeThumbnail = useCallback((file) => {
    setThumbnail(file);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setThumbnailError('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          url: Yup.string().when('$url', {
            is: (urlCheck: boolean) => urlCheck,
            then: Yup.string().required('O link é obrigatório'),
            otherwise: Yup.string(),
          }),
          file: Yup.string().when('$file', {
            is: (fileCheck: boolean) => fileCheck,
            then: Yup.string().required('O arquivo é obrigatório'),
            otherwise: Yup.string(),
          }),
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            url: data.url === '' && !resource,
            file: typeof data.url === 'undefined' && !resource,
            thumbnail: !thumbnail,
          },
        });

        const formDataThumbnail = new FormData();
        formDataThumbnail.append('thumbnail', thumbnail as File);
        const responseThumbnail = await api.post(
          'thumbnails',
          formDataThumbnail
        );

        if (resource) {
          const formData = new FormData();
          formData.append('thumbnail_id', responseThumbnail.data.id);
          formData.append('name', data.name);
          formData.append('resource', resource);

          await api.post('resources', formData);
        } else if (data.url) {
          const formData = {
            thumbnail_id: responseThumbnail.data.id,
            name: data.name,
            link: data.url,
          };

          await api.post('resources', formData);
        }

        Toast.fire({
          icon: 'success',
          title: 'Curso cadastrado!',
        });

        history.push(`${process.env.PUBLIC_URL}/downloads`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          if (errors.thumbnail) {
            setThumbnailError(errors.thumbnail);
          }

          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [history, resource, thumbnail]
  );

  return (
    <Container className="container py-5">
      <Form ref={formRef} onSubmit={handleSubmit} className="row">
        <div className="col-12 mb-4">
          <h1>Adicionar download</h1>
        </div>
        <div className="col-lg-6">
          <div className="box bg-dark-2 p-5">
            <label className="w-100">
              Nome <Input name="name" className="mt-3" />
            </label>
            <div className="w-100 mt-4">
              <span>Subir por:</span>
              <InputRadio
                name="video_type"
                options={[
                  {
                    id: 'link',
                    value: 'Link',
                  },
                  {
                    id: 'upload',
                    value: 'Upload',
                  },
                ]}
                onChange={handleChangeVideoType}
                selected={thumbnailType}
                className="justify-content-start mt-3"
              />
            </div>
            {thumbnailType.id === 'link' && (
              <label className="w-100 mt-4">
                Link <Input type="url" name="url" className="mt-3" />
              </label>
            )}
            {thumbnailType.id === 'upload' && (
              <label className="w-100 mt-4">
                Upload{' '}
                <InputFile
                  name="file"
                  className="mt-3 input-file"
                  onChange={handleChangeFile}
                />
              </label>
            )}
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="w-100 h-100 box bg-dark-2 p-5">
            <span className="h5 mb-5 d-block">Thumbnail</span>
            <InputImage
              name="thumbnail"
              onChange={handleChangeThumbnail}
              cropImage
              aspect={20.44 / 12.63}
              cropOptions={
                !thumbnail
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 12.63 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              error={thumbnailError}
            />
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default CategoriesCreate;
