import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0.5rem;

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;
