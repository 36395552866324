import React, { useState } from 'react';
import {
  TitleAndAsk,
  Container,
  Title,
  Container2,
  Potus,
  DivSearch,
  Tab,
} from './styles';
import Search from '~/components/Search';
import TabSupport from '~/components/TabSupport';

const Support: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const funtionSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <Container>
      <Container2>
        <TitleAndAsk>
          <Title>
            <h1>Suporte</h1>
            <p>Resposta dos professores</p>
          </Title>
          <Potus>
            <p>H</p>
          </Potus>
        </TitleAndAsk>
        <DivSearch>
          <Potus>
            <p>H</p>
          </Potus>
          <Search onSearch={funtionSearch} />
        </DivSearch>
        <Tab>
          <TabSupport searchTerm={searchTerm} />
        </Tab>
      </Container2>
    </Container>
  );
};

export default Support;