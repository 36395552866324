import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Search from '~/components/Search';
import api from '~/services/api';
import Toast from '~/utils/toast';

import {
  Container,
  Product,
  Course,
  Tabs,
  TabsButton,
  TabsGroup,
  Tab,
} from './styles';

interface IParams {
  user_id: string;
  type: 'produtos' | 'cursos';
}

interface IUserProduct {
  id: string;
  user_id: string;
  product_id: string;
  plan_id?: string;
}

interface IPlan {
  id: string;
  name: string;
  released?: boolean;
}

interface IProduct {
  id: string;
  title: string;
  thumbnail: {
    thumbnail_url: string;
  };
  plans: IPlan[];
  usersProducts: IUserProduct[];
  released?: boolean;
}

interface IProductData {
  current_page: number;
  last_page: number;
  data: IProduct[];
}

interface IUserCourse {
  id: string;
  user_id: string;
  course_id: string;
}

interface ICourse {
  id: string;
  title: string;
  thumbnail: string;
  released?: boolean;
  usersCourses: IUserCourse[];
}

interface ICourseData {
  current_page: number;
  last_page: number;
  data: ICourse[];
}

interface IUser {
  id: string;
  way_id: string;
}

const ReleaseAccess: React.FC = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState('');
  const [openTabs, setOpenTabs] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [user, setUser] = useState({} as IUser);

  useEffect(() => {
    setTabSelected(params.type);
  }, [params.type]);

  useEffect(() => {
    api.get<IUser>(`users/${params.user_id}`).then((response) => {
      setUser(response.data);
    });
  }, [params.user_id]);

  const loadProducts = useCallback(
    async (pageData: number, searchData?: string) => {
      const response = await api.get<IProductData>(`products`, {
        params: {
          all: true,
          page: pageData,
          search: searchData,
        },
      });

      const data: IProduct[] = response.data.data.map((product) => ({
        ...product,
        released: !!product.usersProducts.find(
          (userProduct) => userProduct.user_id === params.user_id
        ),
        plans: product.plans.map((plan) => ({
          ...plan,
          released: !!product.usersProducts.find(
            (userProduct) =>
              userProduct.user_id === params.user_id &&
              userProduct.plan_id === plan.id
          ),
        })),
      }));

      if (pageData === 1) {
        setProducts(data);
      } else {
        setProducts((state) => [...state, ...data]);
      }
      setLastPage(response.data.last_page);
    },
    [params.user_id]
  );

  const loadCourses = useCallback(
    async (pageData: number, searchData?: string) => {
      const response = await api.get<ICourseData>('courses', {
        params: {
          page: pageData,
          status: ['Published', 'Unlisted'],
          all: true,
          search: searchData,
          usersCourses: true,
        },
      });

      const data = response.data.data.map((course) => ({
        ...course,
        released: !!course.usersCourses.find(
          (userCourse) => userCourse.user_id === params.user_id
        ),
      }));

      if (pageData === 1) {
        setCourses(data);
      } else {
        setCourses((state) => [...state, ...data]);
      }
      setLastPage(response.data.last_page);
    },
    [params.user_id]
  );

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }

    if (params.type === 'produtos') {
      loadProducts(1);
    }

    if (params.type === 'cursos') {
      loadCourses(1);
    }
  }, [loadCourses, loadProducts, params.type]);

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  const handleSelectTab = useCallback(
    (value) => {
      setTabSelected(value);
      setOpenTabs(false);
      switch (value) {
        case 'cursos':
          history.push(
            `${process.env.PUBLIC_URL}/membros/${params.user_id}/liberar-acesso/cursos`
          );
          break;

        default:
          history.push(
            `${process.env.PUBLIC_URL}/membros/${params.user_id}/liberar-acesso/produtos`
          );
          break;
      }
    },
    [history, params.user_id]
  );

  const handleRealeaseAccessProduct = useCallback(
    async (product: IProduct, plan?: IPlan) => {
      try {
        await api.post('users-products', {
          user_id: user.id,
          product_id: product.id,
          plan_id: plan?.id,
        });

        const newProducts = products.slice();
        const productIndex = newProducts.findIndex(
          (productData) => product.id === productData.id
        );

        if (productIndex >= 0) {
          if (plan) {
            const planIndex = newProducts[productIndex].plans.findIndex(
              (planData) => planData.id === plan.id
            );
            if (planIndex >= 0) {
              newProducts[productIndex].plans[planIndex].released = true;
            }
          } else {
            newProducts[productIndex].released = true;
          }
          setProducts(newProducts);
        }

        Toast.fire({
          icon: 'success',
          title: 'Acesso liberado!',
        });
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [products, user.id]
  );

  const handleRealeaseBlockProduct = useCallback(
    async (product: IProduct, plan?: IPlan) => {
      try {
        if (plan) {
          await api.delete(
            `users-products/${user.id}/${product.id}/${plan.id}`
          );
        } else {
          await api.delete(`users-products/${user.id}/${product.id}`);
        }

        const newProducts = products.slice();
        const productIndex = newProducts.findIndex(
          (productData) => product.id === productData.id
        );

        if (productIndex >= 0) {
          if (plan) {
            const planIndex = newProducts[productIndex].plans.findIndex(
              (planData) => planData.id === plan.id
            );
            if (planIndex >= 0) {
              newProducts[productIndex].plans[planIndex].released = false;
            }
          } else {
            newProducts[productIndex].released = false;
          }
          setProducts(newProducts);
        }

        Toast.fire({
          icon: 'success',
          title: 'Acesso liberado!',
        });
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [products, user.id]
  );

  const handleRealeaseAccessCourse = useCallback(
    async (course: ICourse) => {
      try {
        await api.post('users-courses', {
          user_id: user.id,
          way_id: user.way_id,
          course_id: course.id,
        });

        const newCourses = courses.slice();
        const courseIndex = newCourses.findIndex(
          (courseData) => course.id === courseData.id
        );

        if (courseIndex >= 0) {
          newCourses[courseIndex].released = true;
          setCourses(newCourses);
        }

        Toast.fire({
          icon: 'success',
          title: 'Acesso liberado!',
        });
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [courses, user.id, user.way_id]
  );

  const handleBlockAccessCourse = useCallback(
    async (course: ICourse) => {
      try {
        await api.delete(
          `users-courses/${user.id}/${user.way_id}/${course.id}`
        );

        const newCourses = courses.slice();
        const courseIndex = newCourses.findIndex(
          (courseData) => course.id === courseData.id
        );

        if (courseIndex >= 0) {
          newCourses[courseIndex].released = false;
          setCourses(newCourses);
        }

        Toast.fire({
          icon: 'success',
          title: 'Acesso bloqueado!',
        });
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [courses, user.id, user.way_id]
  );

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        if (params.type === 'produtos') {
          loadProducts(page + 1);
        }

        if (params.type === 'cursos') {
          loadCourses(page + 1);
        }
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadCourses, loadProducts, page, params.type]);

  const handleSearch = useCallback(
    (value) => {
      if (params.type === 'produtos') {
        loadProducts(1, value);
      }

      if (params.type === 'cursos') {
        loadCourses(1, value);
      }
    },
    [loadCourses, loadProducts, params.type]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h1>Liberar acesso</h1>
              <Search
                ref={searchRef}
                onSearch={handleSearch}
                className="search"
              />
              <div>
                <Tabs className="py-3">
                  <TabsButton
                    type="button"
                    onClick={handleClickTabsButton}
                    className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
                  >
                    {tabSelected === 'produtos' && 'Produtos'}
                    {tabSelected === 'cursos' && 'cursos'}
                    <IoIosArrowDown
                      size={24}
                      color="#bbbbbb"
                      className="ms-2"
                    />
                  </TabsButton>
                  <TabsGroup
                    opened={openTabs}
                    className="w-100 d-flex flex-column flex-md-row justify-content-md-start align-items-center"
                  >
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('produtos')}
                      active={tabSelected === 'produtos'}
                      className="border-0 bg-transparent"
                    >
                      Produtos
                    </Tab>
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('cursos')}
                      active={tabSelected === 'cursos'}
                      className="border-0 bg-transparent"
                    >
                      Cursos
                    </Tab>
                  </TabsGroup>
                </Tabs>
              </div>
            </div>
            {params.type === 'produtos' && (
              <div className="row">
                {products.map((product) => (
                  <div key={product.id} className="col-lg-4 mb-4">
                    <Product
                      src={product.thumbnail.thumbnail_url}
                      className="h-100"
                    >
                      <div className="thumb" />
                      <div className="infos d-block p-relative w-100 course p-4">
                        <div className="d-flex justify-content-between align-items-center course-title">
                          <span className="h5 fw-semibold me-4">
                            {product.title}
                          </span>
                        </div>
                        {product.plans.length > 0 ? (
                          <>
                            {product.plans.map((plan) => (
                              <div
                                key={plan.id}
                                className="d-flex justify-content-between mt-4"
                              >
                                <span className="text-gray">{plan.name}</span>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-submit"
                                  onClick={() =>
                                    plan.released
                                      ? handleRealeaseBlockProduct(
                                          product,
                                          plan
                                        )
                                      : handleRealeaseAccessProduct(
                                          product,
                                          plan
                                        )
                                  }
                                >
                                  {plan.released
                                    ? 'Bloquear acesso'
                                    : 'Liberar acesso'}
                                </button>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="mt-4">
                            <button
                              type="button"
                              className="btn btn-primary btn-submit w-100"
                              onClick={() =>
                                product.released
                                  ? handleRealeaseBlockProduct(product)
                                  : handleRealeaseAccessProduct(product)
                              }
                            >
                              {product.released
                                ? 'Bloquear acesso'
                                : 'Liberar acesso'}
                            </button>
                          </div>
                        )}
                      </div>
                    </Product>
                  </div>
                ))}
              </div>
            )}
            {params.type === 'cursos' && (
              <div className="row">
                {courses.map((course) => (
                  <div key={course.id} className="col-lg-3 mb-4">
                    <Course src={course.thumbnail}>
                      <div className="thumb" />
                      <div className="infos d-block p-relative w-100 course p-4">
                        <div className="d-flex justify-content-between align-items-center course-title">
                          <span className="h5 fw-semibold me-4">
                            {course.title}
                          </span>
                        </div>
                        <div className="mt-4">
                          <button
                            type="button"
                            className="btn btn-primary btn-submit w-100"
                            onClick={() =>
                              course.released
                                ? handleBlockAccessCourse(course)
                                : handleRealeaseAccessCourse(course)
                            }
                          >
                            {course.released
                              ? 'Bloquear acesso'
                              : 'Liberar acesso'}
                          </button>
                        </div>
                      </div>
                    </Course>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReleaseAccess;
