import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IRadioButton {
  selected?: boolean;
}

export const Container = styled.div`
  .box {
    border-radius: 20px;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-submit {
      height: 50px;
    }
  }
`;

export const Box = styled.div`
  border: 1px solid #bbbbbb;
  border-radius: 18px;
  overflow: hidden;
  background: #303030;

  > div:first-child {
    background: #3a3a3a;
    border-bottom: 1px solid #bbbbbb;
  }

  > div:last-child {
    overflow: auto;
    height: 200px;
  }
`;

export const RadioButton = styled.button<IRadioButton>`
  border: none;
  background: transparent;
  display: flex;
  text-align: left;

  > div {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) => (props.selected ? '#FFFFFF' : 'transparent')};
    border: ${(props) =>
      props.selected ? '5px solid #E50914' : '1px solid #585858;'};
  }

  span {
    line-height: 18px;
    color: #bbbbbb;

    small {
      font-size: 12px;
      color: #6a6a6a;
    }
  }

  + button {
    margin-top: 10px;
  }
`;
