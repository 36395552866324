import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { MdFacebook } from 'react-icons/md';
import { RiEditFill, RiInstagramFill } from 'react-icons/ri';
import { ImLinkedin2, ImYoutube } from 'react-icons/im';
import {
  IoIosAdd,
  IoIosArrowDown,
  IoIosBriefcase,
  IoIosMail,
  IoMdLink,
} from 'react-icons/io';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { HiCamera } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
import { Form } from '@unform/web';
import { differenceInYears, format, parseISO } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { IconType } from 'react-icons/lib';
import * as icons from 'react-icons';
import { useParams } from 'react-router-dom';

import axios, { AxiosResponse } from 'axios';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import mask from '~/utils/mask';
import { formatPrice } from '~/utils/format';

import InputToggle from '~/components/InputToggle';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputRadio from '~/components/InputRadio';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import InputPhoto from '~/components/InputPhoto';
import Table, { IColumn } from '~/components/Table';

import avatar from '~/assets/defaults/avatar.png';
import Resource from '~/components/Resource';
import Loading from '~/components/Loading';

import {
  Container,
  Box,
  Banner,
  Avatar,
  Tabs,
  TabsButton,
  TabsGroup,
  Tab,
  PersonalData,
  Address,
  Payments,
  Notifications,
  Certificates,
  Modal,
  SocialMedias,
} from './styles';

interface IProfileData {
  id: string;
  name: string;
  email: string;
  birthdate: string;
  occupation: string;
  biography?: string;
  socialEmail?: string;
  youtube?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  portfolio?: string;
  twitter?: string;
}

interface IPersonalData {
  email: string;
  password?: string;
  phone?: string;
  foreign: string;
  document?: string;
}

interface IAddressData {
  id?: string;
  zipcode?: string;
  street?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  complement?: string;
}

interface INotificationOptionResponse {
  id: string;
  content: string;
  userNotificationOption?: {
    user_id: string;
    notification_option_id: string;
    active?: boolean;
  };
}

interface INotificationOption {
  id: string;
  value: string;
}

interface IOrder {
  id: string;
  amount_paid: number;
  payment_type: string;
  is_recurrent: boolean;
  status: string;
  created_at: string;
  courseSale: {
    course: {
      title: string;
    };
  };
  certificate: {
    course: {
      title: string;
    };
  };
  product: {
    title: string;
  };
  plan: {
    name: string;
  };
}

interface IOrderResponse {
  data: IOrder[];
  from: number;
  to: number;
  total: number;
}

interface IPayment {
  id: number;
  title: string;
  amount_paid: string;
  payment_type: string;
  is_recurrent: string;
  status: string;
  date: string;
}

interface IAddress {
  id: string;
  zipcode?: string;
  street?: string;
  number?: string;
  neighborhood?: string;
  city: string;
  state: string;
  complement?: string;
}

interface ISocialNetwork {
  id: string;
  type: string;
  icon?: string | IconType;
  link: string;
}

interface IProfileResponse {
  id: string;
  name: string;
  email: string;
  birthdate: string;
  occupation: string;
  biography?: string;
  phone?: string;
  foreign: boolean;
  document: string;
  wallpaper?: {
    wallpaper_url: string;
  };
  avatar: {
    id: string;
    avatar_url: string;
  };
  address: IAddress;
  socialNetworks: ISocialNetwork[];
}

interface IProfile {
  id: string;
  wallpaper: string;
  avatar_id?: string;
  avatar: string;
  name: string;
  email: string;
  age?: number;
  birthdate?: string;
  occupation?: string;
  biography?: string;
  phone?: string;
  foreign?: boolean;
  document?: string;
  address?: IAddress;
  socialNetworks?: ISocialNetwork[];
}

interface IProfileData {
  name: string;
  email: string;
  birthdate: string;
  occupation: string;
  biography?: string;
  socialEmail?: string;
  youtube?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  portfolio?: string;
  twitter?: string;
}

interface IParams {
  user_id: string;
}

interface ICertificate {
  id: string;
  certificate_id: string;
  title: string;
  certificate_url: string;
  checkout_url?: string;
  price?: number;
  purchased: boolean;
}

const Profile: React.FC = () => {
  const params = useParams<IParams>();
  const formEditProfileRef = useRef<FormHandles>(null);
  const formEditPersonalDataRef = useRef<FormHandles>(null);
  const formEditAddressRef = useRef<FormHandles>(null);
  const [tabSelected, setTabSelected] = useState('personal-data');
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditPersonalData, setShowEditPersonalData] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showEditBanner, setShowEditBanner] = useState(false);
  const [showEditAvatar, setShowEditAvatar] = useState(false);
  const [showSocialEmail, setShowSocialEmail] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showFacebook, setShowFacebook] = useState(false);
  const [showInstagram, setShowInstagram] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [openTabs, setOpenTabs] = useState(false);
  const [biographyQtd, setBiographyQtd] = useState(0);
  const [spacesQtd, setSpacesQtd] = useState(0);
  const [foreign, setForeign] = useState(false);
  const [address, setAddress] = useState<IAddressData>({} as IAddressData);
  const [wallpaper, setWallpaper] = useState<File | undefined>(undefined);
  const [avatarData, setAvatarData] = useState<File | undefined>(undefined);
  const [notificationOptions, setNotificationOptions] = useState<
    INotificationOption[]
  >([]);
  const [notificationOptionsSelected, setNotificationOptionsSelected] =
    useState<string[]>([]);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [paymentsFrom, setPaymentsFrom] = useState(0);
  const [paymentsTo, setPaymentsTo] = useState(0);
  const [paymentsTotal, setPaymentsTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [profile, setProfile] = useState({} as IProfile);
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get<IProfileResponse>(`users/${params.user_id}`).then((response) => {
      const Icons = icons as any;
      const socialNetworks = response.data.socialNetworks.map(
        (socialNetwork) => ({
          id: socialNetwork.id,
          type: socialNetwork.type,
          link: socialNetwork.link,
          icon: socialNetwork.icon
            ? Icons[socialNetwork.icon as string]
            : undefined,
        })
      );

      const data: IProfile = {
        id: response.data.id,
        wallpaper: response.data.wallpaper
          ? response.data.wallpaper.wallpaper_url
          : '',
        avatar_id: response.data.avatar ? response.data.avatar.id : undefined,
        avatar: response.data.avatar ? response.data.avatar.avatar_url : avatar,
        name: response.data.name,
        email: response.data.email,
        age: response.data.birthdate
          ? differenceInYears(new Date(), parseISO(response.data.birthdate))
          : 0,
        birthdate: response.data.birthdate,
        occupation: response.data.occupation,
        biography: response.data.biography,
        phone:
          response.data.phone &&
          mask({ kind: 'cel-phone', value: response.data.phone }),
        foreign: response.data.foreign,
        document:
          response.data.document &&
          mask({ kind: 'cpf', value: response.data.document }),
        address: response.data.address && {
          id: response.data.address.id,
          zipcode:
            response.data.address.zipcode &&
            mask({
              kind: 'zip-code',
              value: response.data.address.zipcode,
            }),
          street: response.data.address.street,
          number: response.data.address.number,
          neighborhood: response.data.address.neighborhood,
          city: response.data.address.city,
          state: response.data.address.state,
          complement: response.data.address.complement,
        },
        socialNetworks,
      };

      setProfile(data);
    });
  }, [params.user_id]);

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      api
        .get<IOrderResponse>(`orders/admins/${profile.id}`, {
          params: {
            page,
          },
        })
        .then((response) => {
          const data: IPayment[] = response.data.data.map((payment, index) => {
            let title = '';
            if (payment.courseSale) {
              title = payment.courseSale.course.title;
            }

            if (payment.certificate) {
              title = `Certificado do curso ${payment.certificate.course.title}`;
            }

            if (payment.product) {
              title = payment.product.title;
            }

            if (payment.plan && payment.product) {
              title = `Plano ${payment.plan.name} do produto ${payment.product.title}`;
            }

            let status = 'Aguardando Pagamento';
            if (payment.status === 'paid') {
              status = 'Pago';
            }

            return {
              id: index + 1,
              title,
              amount_paid:
                payment.amount_paid > 0
                  ? formatPrice(payment.amount_paid)
                  : 'Grátis',
              payment_type:
                payment.payment_type === 'Credit card'
                  ? 'Cartão de Crédito'
                  : 'Boleto',
              is_recurrent: payment.is_recurrent ? 'Sim' : 'Não',
              status,
              date: format(parseISO(payment.created_at), 'dd/MM/yyyy'),
            };
          });

          setPayments(data);
          setPaymentsFrom(response.data.from);
          setPaymentsTo(response.data.to);
          setPaymentsTotal(response.data.total);
        });
    }
  }, [page, profile]);

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      if (profile.address) {
        setAddress({
          id: profile.address.id,
          zipcode: profile.address.zipcode,
          street: profile.address.street,
          number: profile.address.number,
          neighborhood: profile.address.neighborhood,
          city: profile.address.city,
          state: profile.address.state,
          complement: profile.address.complement,
        });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      api
        .get<INotificationOptionResponse[]>(
          `notification-options/admins/${profile.id}`
        )
        .then((response) => {
          const data: INotificationOption[] = response.data.map(
            (notificationOption) => ({
              id: notificationOption.id,
              value: notificationOption.content,
            })
          );

          const userNotificationOptionsId = response.data
            .filter(
              (notificationOption) =>
                notificationOption.userNotificationOption &&
                notificationOption.userNotificationOption.active
            )
            .map((notificationOption) => notificationOption.id);

          setNotificationOptionsSelected(userNotificationOptionsId);
          setNotificationOptions(data);
        });
    }
  }, [profile]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Título',
        selector: 'title',
      },
      {
        name: 'Valor pago',
        selector: 'amount_paid',
      },
      {
        name: 'Tipo de pagamento',
        selector: 'payment_type',
      },
      {
        name: 'É um plano?',
        selector: 'is_recurrent',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Data de pagamento',
        selector: 'date',
      },
    ],
    []
  );

  const age = useMemo(() => {
    if (Object.keys(profile).length > 0) {
      return profile.birthdate
        ? differenceInYears(new Date(), parseISO(profile.birthdate))
        : 0;
    }
    return 0;
  }, [profile]);

  const profileData = useMemo(() => {
    if (Object.keys(profile).length > 0) {
      return {
        name: profile.name,
        email: profile.email,
        birthdate: profile.birthdate
          ? format(parseISO(profile.birthdate), 'dd/MM/yyyy')
          : 'Não informado',
        occupation: profile.occupation,
        biography: profile.biography,
        socialEmail: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'socialEmail'
        )?.link,
        youtube: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'youtube'
        )?.link,
        linkedin: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'linkedin'
        )?.link,
        facebook: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'facebook'
        )?.link,
        instagram: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'instagram'
        )?.link,
        portfolio: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'portfolio'
        )?.link,
        twitter: profile.socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === 'twitter'
        )?.link,
      };
    }

    return undefined;
  }, [profile]);

  const personalData = useMemo(() => {
    if (Object.keys(profile).length > 0) {
      if (profile.foreign) {
        setForeign(profile.foreign);
      }
      return {
        email: profile.email,
        phone: profile.phone,
        foreign: profile.foreign,
        document: profile.document?.replaceAll('.', '').replace('-', ''),
      };
    }

    return undefined;
  }, [profile]);

  const states = useMemo<IOption[]>(
    () => [
      {
        id: 0,
        value: 'Selecione',
        selected: !address.state,
        notSelectable: true,
      },
      { id: 'AC', value: 'Acre', selected: address.state === 'AC' },
      { id: 'AL', value: 'Alagoas', selected: address.state === 'AL' },
      { id: 'AP', value: 'Amapá', selected: address.state === 'AP' },
      { id: 'AM', value: 'Amazonas', selected: address.state === 'AM' },
      { id: 'BA', value: 'Bahia', selected: address.state === 'BA' },
      { id: 'CE', value: 'Ceará', selected: address.state === 'CE' },
      { id: 'DF', value: 'Distrito Federal', selected: address.state === 'DF' },
      { id: 'ES', value: 'Espírito Santo', selected: address.state === 'ES' },
      { id: 'GO', value: 'Goiás', selected: address.state === 'GO' },
      { id: 'MA', value: 'Maranhão', selected: address.state === 'MA' },
      { id: 'MT', value: 'Mato Grosso', selected: address.state === 'MT' },
      {
        id: 'MS',
        value: 'Mato Grosso do Sul',
        selected: address.state === 'MS',
      },
      { id: 'MG', value: 'Minas Gerais', selected: address.state === 'MG' },
      { id: 'PA', value: 'Pará', selected: address.state === 'PA' },
      { id: 'PB', value: 'Paraíba', selected: address.state === 'PB' },
      { id: 'PR', value: 'Paraná', selected: address.state === 'PR' },
      { id: 'PE', value: 'Pernambuco', selected: address.state === 'PE' },
      { id: 'PI', value: 'Piauí', selected: address.state === 'PI' },
      { id: 'RJ', value: 'Rio de Janeiro', selected: address.state === 'RJ' },
      {
        id: 'RN',
        value: 'Rio Grande do Norte',
        selected: address.state === 'RN',
      },
      {
        id: 'RS',
        value: 'Rio Grande do Sul',
        selected: address.state === 'RS',
      },
      { id: 'RO', value: 'Rondônia', selected: address.state === 'RO' },
      { id: 'RR', value: 'Roraima', selected: address.state === 'RR' },
      { id: 'SC', value: 'Santa Catarina', selected: address.state === 'SC' },
      { id: 'SP', value: 'São Paulo', selected: address.state === 'SP' },
      { id: 'SE', value: 'Sergipe', selected: address.state === 'SE' },
      { id: 'TO', value: 'Tocantins', selected: address.state === 'TO' },
    ],
    [address.state]
  );

  const portfolioSite = useMemo(() => {
    if (Object.keys(profile).length > 0) {
      return profile.socialNetworks?.find(
        (socialNetwork) => socialNetwork.type === 'portfolio'
      );
    }

    return undefined;
  }, [profile]);

  useEffect(() => {
    api
      .get<ICertificate[]>(`users-certificates/${params.user_id}`)
      .then((response) => {
        setCertificates(response.data);
      });
  }, [params.user_id]);

  const handleSelectTab = useCallback((value) => {
    setTabSelected(value);
    setOpenTabs(false);
  }, []);

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  const handleClose = useCallback(() => {
    setShowEditProfile(false);
    setShowEditPersonalData(false);
    setShowEditAddress(false);
    setShowEditBanner(false);
    setShowEditBanner(false);
    setShowEditAvatar(false);
    setWallpaper(undefined);
    setAvatarData(undefined);
  }, []);

  const handleChangeBiography = useCallback((e) => {
    setBiographyQtd(e.target.value.replace(/\s/g, '').length);
    setSpacesQtd(e.target.value.split(' ').length - 1);
  }, []);

  const handleClickLink = useCallback((socialMedia) => {
    switch (socialMedia) {
      case 'socialEmail':
        setShowSocialEmail(true);
        break;
      case 'youtube':
        setShowYoutube(true);
        break;
      case 'linkedin':
        setShowLinkedin(true);
        break;
      case 'facebook':
        setShowFacebook(true);
        break;
      case 'instagram':
        setShowInstagram(true);
        break;
      case 'portfolio':
        setShowPortfolio(true);
        break;
      default:
        setShowTwitter(true);
        break;
    }
  }, []);

  const handleUnlink = useCallback(
    async (socialMedia) => {
      if (Object.keys(profile).length > 0) {
        const newUser = { ...profile };
        let { socialNetworks } = newUser;

        const socialNetworkSelected = socialNetworks?.find(
          (socialNetwork) => socialNetwork.type === socialMedia
        );
        socialNetworks = socialNetworks?.filter(
          (socialNetwork) => socialNetwork.type !== socialMedia
        );
        // oldSocialNetworks = oldSocialNetworks.filter(
        //   (socialNetwork) => socialNetwork.type !== socialMedia
        // );

        if (socialNetworkSelected) {
          await api.delete(`social-networks/${socialNetworkSelected.id}`);
        }

        switch (socialMedia) {
          case 'socialEmail':
            setShowSocialEmail(false);
            break;
          case 'youtube':
            setShowYoutube(false);
            break;
          case 'linkedin':
            setShowLinkedin(false);
            break;
          case 'facebook':
            setShowFacebook(false);
            break;
          case 'instagram':
            setShowInstagram(false);
            break;
          case 'portfolio':
            setShowPortfolio(false);
            break;
          default:
            setShowTwitter(false);
            break;
        }

        newUser.socialNetworks = socialNetworks;
        setProfile(newUser);
      }
    },
    [profile]
  );

  const handleClickUnlink = useCallback(
    (socialMedia, link) => {
      if (link && link.length > 0) {
        Swal.fire({
          text: `Deseja desvincular seu ${socialMedia}?`,
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: '#e50914',
          cancelButtonColor: '#bbbbbb',
          cancelButtonText: 'Não',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            handleUnlink(socialMedia);
          }
        });
      } else {
        handleUnlink(socialMedia);
      }
    },
    [handleUnlink]
  );

  const handleChangeForeign = useCallback((value) => {
    setForeign(value.id === 'Sim');
  }, []);

  const handleChangeZipcode = useCallback(
    async (e) => {
      const zipCode = e.target.value.replace('-', '');
      if (zipCode.length === 8) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zipCode}/json/`
        );

        setAddress((state) => ({
          id: profile.address?.id,
          zipcode: response.data.cep,
          street: response.data.logradouro,
          number: state.number,
          neighborhood: response.data.bairro,
          city: response.data.localidade,
          state: response.data.uf,
          complement: response.data.complemento,
        }));
      }
    },
    [profile]
  );

  const handleChangeStreet = useCallback(
    (e) => {
      setAddress((state) => ({
        id: profile.address?.id,
        zipcode: state.zipcode,
        street: e.target.value,
        number: state.number,
        neighborhood: state.neighborhood,
        city: state.city,
        state: state.state,
        complement: state.complement,
      }));
    },
    [profile]
  );

  const handleChangeNeighborhood = useCallback(
    (e) => {
      setAddress((state) => ({
        id: profile.address?.id,
        zipcode: state.zipcode,
        street: state.street,
        number: state.number,
        neighborhood: e.target.value,
        city: state.city,
        state: state.state,
        complement: state.complement,
      }));
    },
    [profile]
  );

  const handleChangeCity = useCallback(
    (e) => {
      setAddress((state) => ({
        id: profile.address?.id,
        zipcode: state.zipcode,
        street: state.street,
        number: state.number,
        neighborhood: state.neighborhood,
        city: e.target.value,
        state: state.state,
        complement: state.complement,
      }));
    },
    [profile]
  );

  const handleChangeState = useCallback((value) => {
    setAddress((state) => ({ ...state, state: value.id }));
  }, []);

  const handleChangeWallpaper = useCallback((file) => {
    setWallpaper(file);
  }, []);

  const handleChangeAvatar = useCallback((file) => {
    setAvatarData(file);
  }, []);

  const handleSubmitEditProfile = useCallback(
    async (data: IProfileData) => {
      try {
        if (Object.keys(profile).length > 0) {
          formEditProfileRef.current?.setErrors({});
          const schema = Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string()
              .email('Informe um e-mail válido')
              .required('O e-mail é obrigatório'),
            birthdate: Yup.string().required('O nascimento é obrigatório'),
            occupation: Yup.string().required('O nascimento é obrigatório'),
            biography: Yup.string(),
            socialEmail: Yup.string(),
            youtube: Yup.string(),
            linkedin: Yup.string(),
            facebook: Yup.string(),
            instagram: Yup.string(),
            portfolio: Yup.string(),
            twitter: Yup.string(),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const {
            name,
            email,
            birthdate,
            occupation,
            biography,
            socialEmail,
            youtube,
            linkedin,
            facebook,
            instagram,
            portfolio,
            twitter,
          } = data;

          const [day, month, year] = birthdate.split('/');

          const formData = {
            name,
            email,
            birthdate: new Date(
              parseInt(year, 10),
              parseInt(month, 10) - 1,
              parseInt(day, 10)
            ),
            occupation,
            biography,
          };

          const response = await api.put(`users/${profile.id}`, formData);

          const newUser = { ...profile };

          if (profile.socialNetworks) {
            const socialNetworks = profile.socialNetworks.slice();
            // const oldSocialNetworks = user.oldSocialNetworks.slice();

            if (socialEmail) {
              const checkSocialEmail = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'socialEmail'
              );
              if (checkSocialEmail) {
                if (checkSocialEmail.link !== socialEmail) {
                  const formSocialNewordData = {
                    type: checkSocialEmail.type,
                    icon: checkSocialEmail.icon,
                    link: socialEmail,
                  };
                  await api.put(
                    `social-networks/admins/${checkSocialEmail.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkSocialEmail.id
                  );
                  socialNetworks[socialNetworkIndex].link = socialEmail;
                }
              } else {
                const formSocialNewordData = {
                  type: 'socialEmail',
                  icon: 'IoIosMail',
                  link: socialEmail,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: IoIosMail,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            if (youtube) {
              const checkYoutube = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'youtube'
              );
              if (checkYoutube) {
                if (checkYoutube.link !== youtube) {
                  const formSocialNewordData = {
                    type: checkYoutube.type,
                    icon: checkYoutube.icon,
                    link: youtube,
                  };
                  await api.put(
                    `social-networks/admins/${checkYoutube.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkYoutube.id
                  );
                  socialNetworks[socialNetworkIndex].link = youtube;
                }
              } else {
                const formSocialNewordData = {
                  type: 'youtube',
                  icon: 'ImYoutube',
                  link: youtube,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: ImYoutube,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            if (linkedin) {
              const checkLinkedin = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'linkedin'
              );
              if (checkLinkedin) {
                if (checkLinkedin.link !== linkedin) {
                  const formSocialNewordData = {
                    type: checkLinkedin.type,
                    icon: checkLinkedin.icon,
                    link: linkedin,
                  };
                  await api.put(
                    `social-networks/admins/${checkLinkedin.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkLinkedin.id
                  );
                  socialNetworks[socialNetworkIndex].link = linkedin;
                }
              } else {
                const formSocialNewordData = {
                  type: 'linkedin',
                  icon: 'ImLinkedin2',
                  link: linkedin,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: ImLinkedin2,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            if (facebook) {
              const checkFacebook = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'facebook'
              );
              if (checkFacebook) {
                if (checkFacebook.link !== facebook) {
                  const formSocialNewordData = {
                    type: checkFacebook.type,
                    icon: checkFacebook.icon,
                    link: facebook,
                  };
                  await api.put(
                    `social-networks/admins/${checkFacebook.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkFacebook.id
                  );
                  socialNetworks[socialNetworkIndex].link = facebook;
                }
              } else {
                const formSocialNewordData = {
                  type: 'facebook',
                  icon: 'MdFacebook',
                  link: facebook,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: MdFacebook,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            if (instagram) {
              const checkInstagram = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'instagram'
              );
              if (checkInstagram) {
                if (checkInstagram.link !== instagram) {
                  const formSocialNewordData = {
                    type: checkInstagram.type,
                    icon: checkInstagram.icon,
                    link: instagram,
                  };
                  await api.put(
                    `social-networks/admins/${checkInstagram.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkInstagram.id
                  );
                  socialNetworks[socialNetworkIndex].link = instagram;
                }
              } else {
                const formSocialNewordData = {
                  type: 'instagram',
                  icon: 'RiInstagramFill',
                  link: instagram,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: RiInstagramFill,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            if (portfolio) {
              const checkPortfolio = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'portfolio'
              );
              if (checkPortfolio) {
                if (checkPortfolio.link !== portfolio) {
                  const formSocialNewordData = {
                    type: checkPortfolio.type,
                    icon: checkPortfolio.icon,
                    link: portfolio,
                  };
                  await api.put(
                    `social-networks/admins/${checkPortfolio.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkPortfolio.id
                  );
                  socialNetworks[socialNetworkIndex].link = portfolio;
                }
              } else {
                const formSocialNewordData = {
                  type: 'portfolio',
                  icon: 'IoMdLink',
                  link: portfolio,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: IoMdLink,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            if (twitter) {
              const checkTwitter = profile.socialNetworks.find(
                (socialNetwork) => socialNetwork.type === 'twitter'
              );
              if (checkTwitter) {
                if (checkTwitter.link !== twitter) {
                  const formSocialNewordData = {
                    type: checkTwitter.type,
                    icon: checkTwitter.icon,
                    link: twitter,
                  };
                  await api.put(
                    `social-networks/admins/${checkTwitter.id}/${profile.id}`,
                    formSocialNewordData
                  );
                  const socialNetworkIndex = socialNetworks.findIndex(
                    (socialNetwork) => socialNetwork.id === checkTwitter.id
                  );
                  socialNetworks[socialNetworkIndex].link = twitter;
                }
              } else {
                const formSocialNewordData = {
                  type: 'twitter',
                  icon: 'AiFillTwitterCircle',
                  link: twitter,
                };
                const responseSocialNetword = await api.post(
                  `social-networks/admins/${profile.id}`,
                  formSocialNewordData
                );
                socialNetworks.push({
                  ...responseSocialNetword.data,
                  icon: AiFillTwitterCircle,
                });
                // oldSocialNetworks.push(responseSocialNetword.data);
              }
            }

            newUser.socialNetworks = socialNetworks;
          }

          newUser.name = response.data.name;
          newUser.email = response.data.email;
          newUser.birthdate = response.data.birthdate;
          newUser.occupation = response.data.occupation;
          newUser.biography = response.data.biography;
          // newUser.oldSocialNetworks = oldSocialNetworks;

          setProfile(newUser);

          handleClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditProfileRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [handleClose, profile]
  );

  const handleSubmitEditPersonalData = useCallback(
    async (data: IPersonalData) => {
      try {
        if (Object.keys(profile).length > 0) {
          formEditPersonalDataRef.current?.setErrors({});
          const schema = Yup.object().shape({
            email: Yup.string()
              .email('Informe um e-mail válido')
              .required('O e-mail é obrigatório'),
            password: Yup.string(),
            phone: Yup.string().nullable(),
            foreign: Yup.string(),
            document: Yup.string(),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const formData = {
            email: data.email,
            password: data.password,
            phone:
              data.phone &&
              data.phone
                .replace('(', '')
                .replace(')', '')
                .replace(' ', '')
                .replace('-', ''),
            foreign,
            document: data.document
              ? data.document.replaceAll('.', '').replace('-', '')
              : undefined,
          };

          const response = await api.put(`users/${profile.id}`, formData);

          const newUser = { ...profile };
          newUser.email = response.data.email;
          newUser.phone = data.phone
            ? mask({ kind: 'cel-phone', value: data.phone })
            : '-';
          newUser.foreign = response.data.foreign;
          newUser.document = data.document
            ? mask({ kind: 'cpf', value: data.document })
            : '-';

          setProfile(newUser);

          handleClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditPersonalDataRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [handleClose, profile, foreign]
  );

  const handleSubmitEditAddress = useCallback(
    async (data: IAddressData) => {
      try {
        if (Object.keys(profile).length > 0) {
          formEditAddressRef.current?.setErrors({});
          const schema = Yup.object().shape({
            zipcode: Yup.string(),
            street: Yup.string(),
            number: Yup.string(),
            neighborhood: Yup.string(),
            city: Yup.string().required('A cidade é obrigatória'),
            state: Yup.string().required('O estado é obrigatório'),
            complement: Yup.string(),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const formData = {
            user_id: profile.id,
            zipcode: data.zipcode?.replace('-', ''),
            street: data.street,
            number: data.number,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            complement: data.complement,
          };

          const newUser = { ...profile };

          if (profile.address) {
            const response = await api.put(
              `addresses/${profile.address.id}`,
              formData
            );

            if (newUser.address) {
              newUser.address.zipcode = data.zipcode
                ? mask({ kind: 'zip-code', value: data.zipcode })
                : '-';
              newUser.address.street = response.data.street;
              newUser.address.number = response.data.number;
              newUser.address.neighborhood = response.data.neighborhood;
              newUser.address.city = response.data.city;
              newUser.address.state = response.data.state;
              newUser.address.complement = response.data.complement;
            }
          }

          setProfile(newUser);

          handleClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditAddressRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [handleClose, profile]
  );

  const handleSubmitEditWallpaper = useCallback(async () => {
    try {
      if (Object.keys(profile).length > 0) {
        if (wallpaper) {
          const formData = new FormData();
          formData.append('wallpaper', wallpaper);

          const response = await api.post(`wallpapers`, formData);

          const formDataUser = {
            wallpaper_id: response.data.id,
          };
          await api.put(`users/${profile.id}`, formDataUser);

          const newUser = { ...profile };
          newUser.wallpaper = response.data.wallpaper_url;

          setProfile(newUser);
        }

        handleClose();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formEditAddressRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    }
  }, [handleClose, profile, wallpaper]);

  const handleSubmitEditAvatar = useCallback(async () => {
    try {
      if (Object.keys(profile).length > 0) {
        if (avatarData) {
          const formData = new FormData();
          formData.append('avatar', avatarData);

          let response: AxiosResponse;
          if (profile.avatar_id) {
            response = await api.put(`avatars/${profile.avatar_id}`, formData);
          } else {
            response = await api.post(`avatars`, formData);
          }

          const formDataUser = {
            avatar_id: response.data.id,
          };
          await api.put(`users/${profile.id}`, formDataUser);

          const newUser = { ...profile };

          newUser.avatar_id = response.data.id;
          newUser.avatar = response.data.avatar_url;

          setProfile(newUser);
        }

        handleClose();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formEditAddressRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    }
  }, [avatarData, handleClose, profile]);

  const handleChangeNotificationOption = useCallback(
    (notificationOptionsId: string[]) => {
      if (Object.keys(profile).length > 0) {
        const notificationOptionsInactive = notificationOptions.filter(
          (notificationOption) => {
            return !notificationOptionsId.some((notificationOptionId) => {
              return notificationOption.id === notificationOptionId;
            });
          }
        );

        const notificationOptionsActive = notificationOptions.filter(
          (notificationOption) => {
            return notificationOptionsId.some((notificationOptionId) => {
              return notificationOption.id === notificationOptionId;
            });
          }
        );

        notificationOptionsInactive.forEach(async (notificationOption) => {
          const formData = {
            notification_option_id: notificationOption.id,
            active: false,
          };
          await api.post(
            `users-notification-options/admins/${profile.id}`,
            formData
          );
        });

        notificationOptionsActive.forEach(async (notificationOption) => {
          const formData = {
            notification_option_id: notificationOption.id,
            active: true,
          };
          await api.post(
            `users-notification-options/admins/${profile.id}`,
            formData
          );
        });

        setNotificationOptionsSelected(notificationOptionsId);
      }
    },
    [notificationOptions, profile]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleClickDelete = useCallback(
    async (certificate) => {
      await api.delete(`users-certificates/${certificate.id}`);

      const newCertificates = certificates.filter(
        (certificateData) => certificateData.id !== certificate.id
      );

      setCertificates(newCertificates);
    },
    [certificates]
  );

  const handleClickUpdate = useCallback(
    async (certificate) => {
      setLoading(true);
      try {
        const response = await api.put(`users-certificates/${certificate.id}`);

        const newCertificates = certificates.slice();

        const certificateIndex = newCertificates.findIndex(
          (certificateData) => certificateData.id === certificate.id
        );

        if (certificateIndex >= 0) {
          newCertificates[certificateIndex].certificate_url =
            response.data.certificate_url;
        }

        setCertificates(newCertificates);
      } finally {
        setLoading(false);
      }
    },
    [certificates]
  );

  return (
    <Container>
      {Object.keys(profile).length > 0 && (
        <div className="container pt-5">
          <div className="row align-items-center pt-5 pb-5">
            <div className="col-12">
              <Box>
                <Banner
                  type="button"
                  src={profile.wallpaper}
                  onClick={() => setShowEditBanner(true)}
                >
                  <div className="btn btn-dark-3 rounded-pill m-4">
                    <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                    <span>Editar</span>
                  </div>
                </Banner>
                <div className="px-3 px-sm-4 px-lg-5 py-5 position-relative">
                  <Avatar
                    src={profile.avatar}
                    onClick={() => setShowEditAvatar(true)}
                    className="position-absolute"
                  >
                    <span>
                      <HiCamera size={16} color="#fff" className="me-1" />
                      Editar
                    </span>
                  </Avatar>
                  <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-sm-start mt-4 mt-sm-2 mt-lg-4">
                    <h1 className="mb-0 h3 h2-lg order-1 order-lg-0 my-5 my-sm-3 my-lg-0">
                      {profile.name}
                    </h1>
                    <div className="d-flex align-items-center align-self-sm-end order-0 order-lg-1 mt-3 mt-lg-0">
                      {profile.socialNetworks?.map((socialNetwork) => (
                        <>
                          {socialNetwork.type !== 'portfolio' && (
                            <a
                              key={socialNetwork.id}
                              href={
                                socialNetwork.type === 'socialEmail'
                                  ? `mailto:${socialNetwork.link}`
                                  : socialNetwork.link
                              }
                              className="btn btn-dark-3 rounded-circle p-0 social-media d-flex justify-content-center align-items-center"
                            >
                              {socialNetwork.icon && (
                                <socialNetwork.icon size={22} color="#fff" />
                              )}
                            </a>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row align-items-center my-4 mb-sm-5 mb-lg-4">
                    <div className="d-flex align-items-center py-1">
                      <IoIosBriefcase
                        size={20}
                        color="#bbbbbb"
                        className="me-2"
                      />
                      <p className="mb-0 text-gray">{profile.occupation}</p>
                    </div>
                    <div className="d-flex align-items-center py-1 borders mx-sm-3 mx-lg-4 px-sm-3 px-lg-4">
                      <FaMapMarkerAlt
                        size={20}
                        color="#bbbbbb"
                        className="me-2"
                      />
                      <p className="mb-0 text-gray">
                        {profile.address ? (
                          <>
                            {profile.address.city}, {profile.address.state}
                          </>
                        ) : (
                          'Não informado'
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center py-1">
                      <p className="mb-0 text-gray">
                        {age === 0 ? 'Idade não informada' : `${age} anos`}
                      </p>
                    </div>
                  </div>
                  <p className="mb-0 text-gray mb-1">{profile.biography}</p>
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mt-5">
                    {portfolioSite && (
                      <a href={portfolioSite.link} className="text-white">
                        {portfolioSite.link}
                      </a>
                    )}
                    <button
                      type="button"
                      onClick={() => setShowEditProfile(true)}
                      className="btn btn-dark-3 rounded-pill mt-4 mt-sm-0 ms-auto"
                    >
                      <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                      <span>Editar</span>
                    </button>
                  </div>
                </div>
              </Box>
            </div>
            <div className="col-12 mt-5">
              <Box className="px-3 px-lg-5 py-5">
                <Tabs className="px-5 py-3">
                  <TabsButton
                    type="button"
                    onClick={handleClickTabsButton}
                    className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
                  >
                    {tabSelected === 'personal-data' && 'Dados pessoais'}
                    {tabSelected === 'address' && 'Endereço'}
                    {tabSelected === 'payments' && 'Pagamentos'}
                    {tabSelected === 'notifications' && 'Notificações'}
                    {tabSelected === 'certificates' && 'Certificados'}
                    <IoIosArrowDown
                      size={24}
                      color="#bbbbbb"
                      className="ms-2"
                    />
                  </TabsButton>
                  <TabsGroup
                    opened={openTabs}
                    className="w-100 d-flex flex-column flex-md-row justify-content-md-between align-items-center"
                  >
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('personal-data')}
                      active={tabSelected === 'personal-data'}
                      className="border-0 bg-transparent"
                    >
                      Dados pessoais
                    </Tab>
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('address')}
                      active={tabSelected === 'address'}
                      className="border-0 bg-transparent"
                    >
                      Endereço
                    </Tab>
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('payments')}
                      active={tabSelected === 'payments'}
                      className="border-0 bg-transparent"
                    >
                      Pagamentos
                    </Tab>
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('notifications')}
                      active={tabSelected === 'notifications'}
                      className="border-0 bg-transparent"
                    >
                      Notificações
                    </Tab>
                    <Tab
                      type="button"
                      onClick={() => handleSelectTab('certificates')}
                      active={tabSelected === 'certificates'}
                      className="border-0 bg-transparent"
                    >
                      Certificados
                    </Tab>
                  </TabsGroup>
                </Tabs>
                <hr className="mt-3 mb-5" />
                {tabSelected === 'personal-data' && (
                  <>
                    <PersonalData className="row px-2 px-md-4 px-lg-5">
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">E-mail</p>
                        <p className="fw-normal text-gray">{profile.email}</p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Senha</p>
                        <p className="fw-normal text-gray">•••••••</p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Telefone</p>
                        <p className="fw-normal text-gray">
                          {profile.phone || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Estrangeiro</p>
                        <p className="fw-normal text-gray">
                          {profile.foreign ? 'Sim' : 'Não'}
                        </p>
                      </div>
                      {!profile.foreign && (
                        <div className="col-md-6 col-lg-4">
                          <p className="fw-semibold">CPF</p>
                          <p className="fw-normal text-gray">
                            {profile.document || '-'}
                          </p>
                        </div>
                      )}
                    </PersonalData>
                    <button
                      type="button"
                      onClick={() => setShowEditPersonalData(true)}
                      className="btn btn-dark-3 rounded-pill d-block ms-auto me-sm-2"
                    >
                      <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                      <span>Editar</span>
                    </button>
                    <Modal
                      show={showEditPersonalData}
                      onHide={handleClose}
                      close
                      size="lg"
                    >
                      <Form
                        ref={formEditPersonalDataRef}
                        initialData={personalData}
                        onSubmit={handleSubmitEditPersonalData}
                      >
                        <Modal.Header className="border-0" closeButton>
                          <h4 className="h6 h5-md h4-lg">
                            Editar dados pessoais
                          </h4>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                E-mail
                                <Input
                                  type="email"
                                  name="email"
                                  className="mt-2"
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Senha
                                <Input
                                  type="password"
                                  name="password"
                                  className="mt-2"
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Telefone
                                <InputMask
                                  kind="cel-phone"
                                  name="phone"
                                  className="mt-2"
                                  value={
                                    personalData
                                      ? personalData.phone
                                      : undefined
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Estrangeiro
                                <InputRadio
                                  name="foreign"
                                  options={[
                                    {
                                      id: 'Sim',
                                      value: 'Sim',
                                    },
                                    {
                                      id: 'Não',
                                      value: 'Não',
                                    },
                                  ]}
                                  selected={
                                    foreign
                                      ? { id: 'Sim', value: 'Sim' }
                                      : { id: 'Não', value: 'Não' }
                                  }
                                  onChange={handleChangeForeign}
                                  className="mt-3 justify-content-start"
                                />
                              </label>
                            </div>
                            {!foreign && (
                              <div className="col-lg-6 mb-4">
                                <label className="w-100 fw-medium">
                                  CPF
                                  <InputMask
                                    kind="cpf"
                                    name="document"
                                    className="mt-2"
                                    value={
                                      personalData
                                        ? personalData.document
                                        : undefined
                                    }
                                  />
                                </label>
                              </div>
                            )}
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                          <button
                            type="submit"
                            className="btn btn-primary px-5 py-2"
                          >
                            Salvar
                          </button>
                        </Modal.Footer>
                      </Form>
                    </Modal>
                  </>
                )}
                {tabSelected === 'address' && (
                  <>
                    <Address className="row px-2 px-md-4 px-lg-5">
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">CEP</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.zipcode) || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Endereço</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.street) || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Nº</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.number) || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Bairro</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.neighborhood) ||
                            '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Cidade</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.city) || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Estado</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.state) || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Complemento</p>
                        <p className="fw-normal text-gray">
                          {(profile.address && profile.address.complement) ||
                            '-'}
                        </p>
                      </div>
                    </Address>
                    <button
                      type="button"
                      className="btn btn-dark-3 rounded-pill d-block ms-auto me-sm-2"
                      onClick={() => setShowEditAddress(true)}
                    >
                      <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                      <span>Editar</span>
                    </button>
                    <Modal
                      show={showEditAddress}
                      onHide={handleClose}
                      close
                      size="lg"
                    >
                      <Form
                        ref={formEditAddressRef}
                        initialData={address}
                        onSubmit={handleSubmitEditAddress}
                      >
                        <Modal.Header className="border-0" closeButton>
                          <h4 className="h6 h5-md h4-lg">Editar endereço</h4>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                CEP
                                <InputMask
                                  kind="zip-code"
                                  name="zipcode"
                                  className="mt-2"
                                  value={address.zipcode}
                                  onChange={handleChangeZipcode}
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Endereço
                                <Input
                                  name="street"
                                  className="mt-2"
                                  value={address.street}
                                  onChange={handleChangeStreet}
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Nº
                                <Input name="number" className="mt-2" />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Bairro
                                <Input
                                  name="neighborhood"
                                  className="mt-2"
                                  value={address.neighborhood}
                                  onChange={handleChangeNeighborhood}
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Cidade
                                <Input
                                  name="city"
                                  className="mt-2"
                                  value={address.city}
                                  onChange={handleChangeCity}
                                />
                              </label>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                Estado
                                <Select
                                  name="state"
                                  options={states}
                                  className="mt-2"
                                  onChange={handleChangeState}
                                />
                              </label>
                            </div>
                            <div className="col-12 mb-4">
                              <label className="w-100 fw-medium">
                                Complemento
                                <Input name="complement" className="mt-2" />
                              </label>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                          <button
                            type="submit"
                            className="btn btn-primary px-5 py-2"
                          >
                            Salvar
                          </button>
                        </Modal.Footer>
                      </Form>
                    </Modal>
                  </>
                )}
                {tabSelected === 'payments' && (
                  <>
                    <Payments className="row px-2 px-md-4 px-lg-5">
                      <Table
                        data={payments}
                        columns={columns}
                        className="table-users"
                        fromData={paymentsFrom}
                        toData={paymentsTo}
                        totalData={paymentsTotal}
                        pagination
                        selectedPage={page}
                        onChangePage={handleChangePage}
                      />
                    </Payments>
                  </>
                )}
                {tabSelected === 'notifications' && (
                  <Notifications className="row px-2 px-md-4 px-lg-5">
                    <InputToggle
                      name="notification"
                      options={notificationOptions}
                      onChange={handleChangeNotificationOption}
                      checkedValues={notificationOptionsSelected}
                    />
                  </Notifications>
                )}
                {tabSelected === 'certificates' && (
                  <Certificates className="row px-2 px-md-4 px-lg-5">
                    {certificates.map((certificate) => (
                      <div
                        key={certificate.id}
                        className="col-md-6 col-xl-4 mb-3"
                      >
                        <Resource
                          type="certificate"
                          thumb={certificate.certificate_url}
                          title={certificate.title}
                          onClick={() => handleClickDelete(certificate)}
                          onClickUpdate={() => handleClickUpdate(certificate)}
                        />
                      </div>
                    ))}
                  </Certificates>
                )}
              </Box>
            </div>
          </div>
        </div>
      )}
      <Modal show={showEditProfile} onHide={handleClose} close size="lg">
        <Form
          ref={formEditProfileRef}
          initialData={profileData}
          onSubmit={handleSubmitEditProfile}
        >
          <Modal.Header className="border-0" closeButton>
            <h4 className="h6 h5-md h4-lg">Editar perfil</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Nome Completo
                  <Input name="name" className="mt-2" />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  E-mail
                  <Input type="email" name="email" className="mt-2" />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Data de nascimento
                  <InputMask
                    kind="datetime"
                    options={{ format: 'DD/MM/YYYY' }}
                    name="birthdate"
                    className="mt-2"
                    value={profileData ? profileData.birthdate : undefined}
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Profissão
                  <Input name="occupation" className="mt-2" />
                </label>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100 fw-medium">
                  Biografia
                  <Textarea
                    name="biography"
                    rows={4}
                    maxLength={450 + spacesQtd}
                    className="mt-2"
                    onChange={handleChangeBiography}
                  />
                </label>
                <span className="small text-gray d-block text-end px-3 mt-3">
                  {biographyQtd}/450
                </span>
              </div>
            </div>
            <SocialMedias className="row">
              <div className="col-12 mb-3 mb-lg-0">
                <h4 className="h6 h5-md h4-lg">Redes sociais</h4>
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <MdFacebook size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Facebook:</p>
                </div>
                {(profileData && profileData.facebook) || showFacebook ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="facebook"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.facebook &&
                          profileData.facebook.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('facebook', profileData?.facebook)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('facebook')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <RiInstagramFill size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Instagram:</p>
                </div>
                {(profileData && profileData.instagram) || showInstagram ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="instagram"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.instagram &&
                          profileData.instagram.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('instagram', profileData?.instagram)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('instagram')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <ImLinkedin2 size={18} color="#fff" />
                  </div>
                  <p className="mb-0">LinkedIn:</p>
                </div>
                {(profileData && profileData.linkedin) || showLinkedin ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="linkedin"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.linkedin &&
                          profileData.linkedin.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('linkedin', profileData?.linkedin)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('linkedin')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <IoIosMail size={18} color="#fff" />
                  </div>
                  <p className="mb-0">E-mail:</p>
                </div>
                {(profileData && profileData.socialEmail) || showSocialEmail ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="email"
                      name="socialEmail"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.socialEmail &&
                          profileData.socialEmail.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink(
                          'socialEmail',
                          profileData?.socialEmail
                        )
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('socialEmail')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <AiFillTwitterCircle size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Twitter:</p>
                </div>
                {(profileData && profileData.twitter) || showTwitter ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="twitter"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.twitter &&
                          profileData.twitter.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('twitter', profileData?.twitter)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('twitter')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <ImYoutube size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Youtube:</p>
                </div>
                {(profileData && profileData.youtube) || showYoutube ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="youtube"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.youtube &&
                          profileData.youtube.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('youtube', profileData?.youtube)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('youtube')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <IoMdLink size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Site/ Portifólio:</p>
                </div>
                {(profileData && profileData.portfolio) || showPortfolio ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="portfolio"
                      className="bg-transparent border-0"
                      disabled={
                        !!(
                          profileData &&
                          profileData.portfolio &&
                          profileData.portfolio.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('portfolio', profileData?.portfolio)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('portfolio')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </SocialMedias>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showEditBanner}
        onHide={handleClose}
        close
        backdrop="static"
        size="lg"
      >
        <Form onSubmit={handleSubmitEditWallpaper}>
          <Modal.Header className="border-0" closeButton>
            <h4 className="h6 h5-md h4-lg">Editar capa</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <InputPhoto
                  name="wallpaper"
                  value={profile.wallpaper}
                  cropImage
                  aspect={9.3 / 2.08}
                  cropOptions={
                    !wallpaper
                      ? {
                          unit: 'px',
                          width: 200,
                          height: 44.8,
                          x: 0,
                          y: 0,
                        }
                      : undefined
                  }
                  onChange={handleChangeWallpaper}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showEditAvatar}
        onHide={handleClose}
        close
        backdrop="static"
        size="lg"
      >
        <Form onSubmit={handleSubmitEditAvatar}>
          <Modal.Header className="border-0" closeButton>
            <h4 className="h6 h5-md h4-lg">Editar avatar</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <InputPhoto
                  name="avatar"
                  value={profile.avatar}
                  cropImage
                  takePhoto
                  aspect={1}
                  cropOptions={
                    !avatarData
                      ? {
                          unit: 'px',
                          width: 200,
                          height: 200,
                          x: 0,
                          y: 0,
                        }
                      : undefined
                  }
                  onChange={handleChangeAvatar}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading show={loading} message="Atualizando certificado..." />
    </Container>
  );
};

export default Profile;
