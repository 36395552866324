/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { RiEditFill } from 'react-icons/ri';
import { BsPlus } from 'react-icons/bs';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Banner, SubCategory, Modal } from './styles';
import Input from '~/components/Input';

interface IParams {
  slug: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
  icon: string;
  slug: string;
  wallpaper: {
    wallpaper_url: string;
  };
}

interface ISubCategory {
  id: string;
  name: string;
  slug: string;
}

interface ISubCategoryData {
  last_page: number;
  data: ISubCategory[];
  total: number;
}

interface IFormData {
  name: string;
}

const Category: React.FC = () => {
  const params = useParams<IParams>();
  const formRefAddSubcategory = useRef<FormHandles>(null);
  const formRefEditSubcategory = useRef<FormHandles>(null);
  const [totalSubCategories, setTotalSubCategories] = useState(0);
  const [category, setCategory] = useState({} as ICategory);
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [page, setPage] = useState(2);
  const [lastPage, setLastPage] = useState(0);
  const [showAddSubCategory, setShowAddSubCategory] = useState(false);
  const [showEditSubCategory, setShowEditSubCategory] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState('');
  const [subCategory, setSubCategory] = useState({} as IFormData);

  const loadSubCategories = useCallback(
    async (pageData: number, category_id: string) => {
      const response = await api.get<ISubCategoryData>(
        `categories/subcategories/${category_id}`,
        {
          params: {
            page: pageData,
          },
        }
      );

      setSubCategories((state) => [...state, ...response.data.data]);
      setLastPage(response.data.last_page);
      setTotalSubCategories(response.data.total);
    },
    []
  );

  useEffect(() => {
    api.get<ICategory>(`categories/${params.slug}`).then(async (response) => {
      await loadSubCategories(1, response.data.id);
      await loadSubCategories(2, response.data.id);
      setCategory(response.data);
    });
  }, [loadSubCategories, params.slug]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadSubCategories(page + 1, category.id);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [category.id, lastPage, loadSubCategories, page]);

  const handleClickAddSubCategory = useCallback(() => {
    setShowAddSubCategory(true);
  }, []);

  const handleClickEditSubCategory = useCallback((subCategoryData) => {
    setSubCategoryId(subCategoryData.id);
    setSubCategory(subCategoryData);
    setShowEditSubCategory(true);
  }, []);

  const handleClickDeleteSubCategory = useCallback(
    (subCategoryData) => {
      Swal.fire({
        title: 'Deseja deletar essa subcategoria?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await api.get(
              `courses/categories/${subCategoryData.id}`
            );
            if (response.data.data.length) {
              Swal.fire(
                'Opss...',
                'Não é possível deletar esta subcategoria, pois há cursos nela, para deletar, transfira para outra subcategoria.',
                'error'
              );
            } else {
              await api.delete(`categories/${subCategoryData.id}`);

              const newSubCategories = subCategories.filter(
                (categoryData) => categoryData.id !== subCategoryData.id
              );
              setSubCategories(newSubCategories);

              Toast.fire({
                icon: 'success',
                title: 'Categoria deletada!',
              });
            }
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [subCategories]
  );

  const handleClose = useCallback(() => {
    setShowAddSubCategory(false);
    setShowEditSubCategory(false);
  }, []);

  const handleSubmitAddSubcategory = useCallback(
    async (data: IFormData) => {
      try {
        formRefAddSubcategory.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          category_id: category.id,
          name: data.name,
        };
        const response = await api.post('categories', formData);

        const newSubCategory: ISubCategory = {
          id: response.data.id,
          name: response.data.name,
          slug: response.data.slug,
        };

        setSubCategories((state) => [...state, newSubCategory]);

        Toast.fire({
          icon: 'success',
          title: 'Subcategoria cadastrada!',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefAddSubcategory.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [category.id, handleClose]
  );

  const handleSubmitEditSubcategory = useCallback(
    async (data: IFormData) => {
      try {
        formRefEditSubcategory.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          category_id: category.id,
          name: data.name,
        };

        const response = await api.put(`categories/${subCategoryId}`, formData);
        const newSubCategories = subCategories.slice();
        const subCategoryIndex = newSubCategories.findIndex(
          (subCategoryData) => subCategoryData.id === subCategoryId
        );

        if (subCategoryIndex >= 0) {
          const newSubCategory: ISubCategory = {
            id: response.data.id,
            name: response.data.name,
            slug: response.data.slug,
          };
          newSubCategories[subCategoryIndex] = newSubCategory;
          setSubCategories(newSubCategories);
        }

        setSubCategoryId('');
        setSubCategory({} as IFormData);
        Toast.fire({
          icon: 'success',
          title: 'Subcategoria alterada!',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRefEditSubcategory.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [category.id, handleClose, subCategories, subCategoryId]
  );

  return Object.keys(category).length > 0 ? (
    <Container
      scrollLoadThreshold={200}
      onInfiniteLoad={handleLoad}
      className="pb-5"
    >
      <Banner src={category.wallpaper.wallpaper_url} />
      <div className="container px-4 px-lg-3 pt-5">
        <div className="row align-items-center pt-5 pb-5">
          <div className="col-lg-8 col-xl-6 pt-5 pt-lg-0">
            <img src={category.icon} alt={category.name} />
            <div className="d-flex my-4">
              <Link to={`${process.env.PUBLIC_URL}/categorias`}>
                <IoIosArrowBack size={45} color="#fff" />
              </Link>
              <h1 className="fw-semibold text-white ms-4">{category.name}</h1>
            </div>
            <p className="description">{category.description}</p>
          </div>
        </div>
      </div>
      <div className="container-xl px-0 px-lg-3 pb-5">
        <div className="row">
          <div className="col-12 mb-3 pb-4 d-flex justify-content-between align-items-center">
            <h2 className="fw-semibold text-white ms-4">Subcategorias</h2>
            <button
              type="button"
              className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
              onClick={handleClickAddSubCategory}
            >
              <BsPlus size={24} color="#BBBBBB" />
              <span className="text-gray fw-bold ms-2">Subcategoria</span>
            </button>
          </div>
          {subCategories.map((subCategoryData) => (
            <div key={subCategoryData.id} className="col-12 mb-4">
              <SubCategory className="d-flex align-items-center justify-content-between w-100 px-5 py-3 bg-dark-2">
                <div className="d-flex">
                  <h3 className="text-gray h5 mb-0">{subCategoryData.name}</h3>
                  <button
                    type="button"
                    className="btn-edit bg-white border-0 d-flex align-items-center justify-content-center ms-4"
                    onClick={() => handleClickEditSubCategory(subCategoryData)}
                  >
                    <RiEditFill size={20} color="#3A3A3A" />
                  </button>
                </div>
                <div className="d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent text-error"
                    onClick={() =>
                      handleClickDeleteSubCategory(subCategoryData)
                    }
                  >
                    <span>Deletar subcategoria</span>
                    <MdDelete size={24} color="#ff333d" />
                  </button>
                  <Link
                    to={`${process.env.PUBLIC_URL}/categorias/${params.slug}/${subCategoryData.slug}/courses`}
                    className="border-0 bg-transparent text-error ms-4"
                  >
                    <IoIosArrowForward size={24} color="#ffffff" />
                  </Link>
                </div>
              </SubCategory>
            </div>
          ))}
        </div>
      </div>
      <Modal show={showAddSubCategory} onHide={handleClose}>
        <Form
          ref={formRefAddSubcategory}
          onSubmit={handleSubmitAddSubcategory}
          className="px-4"
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">
              Adicionar subcategoria
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="w-100">
              Nome <Input name="name" className="w-100 mt-3" />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary w-100 btn-submit">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showEditSubCategory} onHide={handleClose}>
        <Form
          ref={formRefEditSubcategory}
          initialData={subCategory}
          onSubmit={handleSubmitEditSubcategory}
          className="px-4"
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Alterar subcategoria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="w-100">
              Nome <Input name="name" className="w-100 mt-3" />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary w-100 btn-submit">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  ) : (
    <></>
  );
};

export default Category;
