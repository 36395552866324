import React, { useCallback, useRef, useState } from 'react';
import { MdDelete, MdRefresh } from 'react-icons/md';
import Pdf from '@mikecousins/react-pdf';
import Swal from 'sweetalert2';

import { CertificateContent, Container, ResourceContent } from './styles';

interface IResource {
  type?: 'img' | 'pdf' | 'certificate';
  thumb: string;
  title: string;
  onClick?(): void;
  onClickUpdate?(): void;
}

const Resource: React.FC<IResource> = ({
  type = 'img',
  thumb,
  title,
  onClick,
  onClickUpdate,
}) => {
  const pdfRef = useRef<HTMLCanvasElement>(null);
  const [pdfImg, setPdfImg] = useState('');

  const handlePageLoadSuccess = useCallback(async () => {
    try {
      const pdfBlob: Blob = await new Promise((resolve: any) => {
        if (pdfRef.current) {
          pdfRef.current.toBlob(resolve);
        } else {
          resolve();
        }
      });

      if (pdfBlob) {
        setPdfImg(URL.createObjectURL(pdfBlob));
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, []);

  return (
    <Container className="px-lg-3 pb-4">
      {(type === 'pdf' || type === 'certificate') && (
        <Pdf
          ref={pdfRef}
          file={thumb}
          page={1}
          onPageRenderSuccess={handlePageLoadSuccess}
          className="d-none"
        />
      )}
      {type === 'certificate' ? (
        <CertificateContent
          src={pdfImg}
          className="d-block p-relative w-100 pb-4"
        >
          <div className="thumb mb-4" />
          <div className="d-flex justify-content-between align-items-center resource-title">
            <span className="text-gray me-4">{title}</span>
            <div className="d-flex">
              <button
                type="button"
                className="icon me-2 btn"
                onClick={onClickUpdate}
              >
                <MdRefresh size={24} color="#FFF" />
              </button>
              <button type="button" className="icon btn" onClick={onClick}>
                <MdDelete size={24} color="#FF333D" />
              </button>
            </div>
          </div>
        </CertificateContent>
      ) : (
        <ResourceContent
          type="button"
          src={type === 'pdf' ? pdfImg : thumb}
          className="d-block p-relative w-100 pb-4"
          onClick={onClick}
        >
          <div className="thumb mb-4" />
          <div className="d-flex justify-content-between align-items-center resource-title">
            <span className="text-gray me-4">{title}</span>
            <div className="icon">
              <MdDelete size={24} color="#FF333D" />
            </div>
          </div>
        </ResourceContent>
      )}
    </Container>
  );
};

export default Resource;
