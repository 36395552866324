import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  format,
  parseISO,
  lastDayOfMonth,
  subMonths,
  differenceInMonths,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Swal from 'sweetalert2';

import { Form } from '@unform/web';
import api from '~/services/api';

import { Container } from './styles';
import Graph, { ISerie } from '~/components/Graph';
import InputDate from '~/components/InputDate';

interface IPlanResponse {
  name: string;
}

interface IProductResponse {
  title: string;
  plans?: IPlanResponse[];
}

interface IOrder {
  id: string;
  amount_paid: string;
  certificate_id?: string;
  course_sale_id?: string;
  product_id?: string;
  plan_id?: string;
  created_at: string;
}

interface IPeriodDate {
  initalDate: Date;
  endDate: Date;
}

interface IMonth {
  id: number;
  name: string;
}

interface IPeriod {
  year: number;
  month: IMonth;
}

interface IFormData {
  initalDate: string;
  endDate: string;
}

interface IPlanOrder {
  planName: string;
  orders: IOrder[];
}

interface IProductOrder {
  name: string;
  orders: IOrder[];
  planOrders: IPlanOrder[];
}

interface IProductOrderSerie {
  name: string;
  data?: number[];
  plans?: ISerie[];
}

const Dashboard: React.FC = () => {
  const [overviewSeries, setOverviewSeries] = useState<ISerie[]>([]);
  const [periodDate, setPeriodData] = useState<IPeriodDate>(() => {
    const currentDate = new Date();
    const lastDay = lastDayOfMonth(currentDate).getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    return {
      initalDate: subMonths(new Date(year, month, 1, 0, 0, 0, 0), 11),
      endDate: new Date(year, month, lastDay, 23, 59, 59, 999),
    };
  });
  const [errorDate, setErrorDate] = useState('');
  const [productsOrdersSeries, setProductsOrdersSeries] = useState<
    IProductOrderSerie[]
  >([]);

  const period = useMemo<IPeriod[]>(() => {
    const selectedMonth = periodDate.endDate.getMonth();
    const selectedYear = periodDate.endDate.getFullYear();

    const data: IPeriod[] = [];
    for (let i = 11; i >= 0; i -= 1) {
      const monthCalc = selectedMonth - i;
      let year = selectedYear;
      if (monthCalc < 0) {
        year -= 1;
      }
      const date = new Date(year, monthCalc);
      const name = format(date, 'MMMM', {
        locale: ptBR,
      });
      const newMonth: IMonth = {
        id: date.getMonth(),
        name: `${name[0].toUpperCase()}${name.slice(1, 10)}`,
      };
      data.push({
        year: date.getFullYear(),
        month: newMonth,
      });
    }

    return data;
  }, [periodDate.endDate]);

  const subPeriod = useMemo<IPeriod[]>(() => {
    const selectedMonth = periodDate.endDate.getMonth();
    const selectedYear = periodDate.endDate.getFullYear();

    const data: IPeriod[] = [];
    for (let i = 5; i >= 0; i -= 1) {
      const monthCalc = selectedMonth - i;
      let year = selectedYear;
      if (monthCalc < 0) {
        year -= 1;
      }
      const date = new Date(year, monthCalc);
      const name = format(date, 'MMMM', {
        locale: ptBR,
      });
      const newMonth: IMonth = {
        id: date.getMonth(),
        name: `${name[0].toUpperCase()}${name.slice(1, 10)}`,
      };
      data.push({
        year: date.getFullYear(),
        month: newMonth,
      });
    }

    return data;
  }, [periodDate.endDate]);

  const loadOrdersProducts = useCallback(
    async (periodDateData: IPeriodDate, periodData: IPeriod[]) => {
      try {
        const dataPeriod = { ...periodDateData };
        if (differenceInMonths(dataPeriod.endDate, dataPeriod.initalDate) > 6) {
          dataPeriod.initalDate = subMonths(dataPeriod.endDate, 5);
        }
        const ordersProductsResponse = await api.get<IProductOrder[]>(
          'products/orders',
          {
            params: {
              initalDate: dataPeriod.initalDate,
              endDate: dataPeriod.endDate,
              status: 'Paid',
            },
          }
        );

        const productsResponse = await api.get<IProductResponse[]>('products', {
          params: {
            noPagination: true,
          },
        });

        const data: IProductOrderSerie[] = [];
        ordersProductsResponse.data.forEach((productOrder) => {
          if (productOrder.planOrders.length > 0) {
            productOrder.planOrders.forEach((planOrder) => {
              const dataPlans = [0, 0, 0, 0, 0, 0];
              periodData.forEach((dataPeriodAux, index) => {
                planOrder.orders.forEach((order) => {
                  const orderDate = parseISO(order.created_at);
                  if (orderDate.getMonth() === dataPeriodAux.month.id) {
                    dataPlans[index] += parseFloat(order.amount_paid);
                  }
                });
              });

              const newPlanOrder = {
                name: planOrder.planName,
                data: dataPlans,
              };

              const orderIndex = data.findIndex(
                (orderData) => orderData.name === productOrder.name
              );
              if (orderIndex >= 0) {
                data[orderIndex].plans?.push(newPlanOrder);
              } else {
                data.push({
                  name: productOrder.name,
                  plans: [newPlanOrder],
                });
              }
            });
          } else {
            const dataProduct = [0, 0, 0, 0, 0, 0];
            periodData.forEach((dataPeriodAux, index) => {
              productOrder.orders.forEach((order) => {
                const orderDate = parseISO(order.created_at);
                if (orderDate.getMonth() === dataPeriodAux.month.id) {
                  dataProduct[index] += parseFloat(order.amount_paid);
                }
              });
            });

            data.push({
              name: productOrder.name,
              data: dataProduct,
            });
          }
        });

        const productsNoData = productsResponse.data.filter((productData) => {
          return !data.some(
            (productOrderSerie) => productOrderSerie.name === productData.title
          );
        });

        const productsData: IProductOrderSerie[] = [];
        productsNoData.forEach((product) => {
          if (product.plans && product.plans.length > 0) {
            product.plans.forEach((plan) => {
              const orderIndex = productsData.findIndex(
                (orderData) => orderData.name === product.title
              );

              const newPlanOrder = {
                name: plan.name,
                data: [0, 0, 0, 0, 0, 0],
              };

              if (orderIndex >= 0) {
                productsData[orderIndex].plans?.push(newPlanOrder);
              } else {
                productsData.push({
                  name: product.title,
                  plans: [newPlanOrder],
                });
              }
            });
          } else {
            productsData.push({
              name: product.title,
              data: [0, 0, 0, 0, 0, 0],
            });
          }
        });

        data.push(...productsData);

        data.sort((itemA, itemB) => {
          const textA = itemA.name.toUpperCase();
          const textB = itemB.name.toUpperCase();
          if (textA < textB) {
            return -1;
          }

          if (textA > textB) {
            return 1;
          }

          return 0;
        });

        setProductsOrdersSeries(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    []
  );

  const loadOrders = useCallback(
    async (periodDateData: IPeriodDate, periodData: IPeriod[]) => {
      try {
        const response = await api.get<IOrder[]>('orders/admins', {
          params: {
            initalDate: periodDateData.initalDate,
            endDate: periodDateData.endDate,
            status: 'Paid',
          },
        });

        const certificatesOrders = response.data.filter(
          (order) => !!order.certificate_id
        );
        const coursesOrders = response.data.filter(
          (order) => !!order.course_sale_id
        );
        const productsOrders = response.data.filter(
          (order) => !!order.product_id && !order.plan_id
        );
        const plansOrders = response.data.filter((order) => !!order.plan_id);

        const dataCertificates = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        periodData.forEach((dataPeriod, index) => {
          certificatesOrders.forEach((order) => {
            const orderDate = parseISO(order.created_at);
            if (orderDate.getMonth() === dataPeriod.month.id) {
              dataCertificates[index] += parseFloat(order.amount_paid);
            }
          });
        });
        const certificatesData: ISerie = {
          name: 'Certificado',
          data: dataCertificates,
        };

        const dataCourses = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        periodData.forEach((dataPeriod, index) => {
          coursesOrders.forEach((order) => {
            const orderDate = parseISO(order.created_at);
            if (orderDate.getMonth() === dataPeriod.month.id) {
              dataCourses[index] += parseFloat(order.amount_paid);
            }
          });
        });
        const coursesData: ISerie = {
          name: 'Cursos',
          data: dataCourses,
        };

        const dataProducts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        periodData.forEach((dataPeriod, index) => {
          productsOrders.forEach((order) => {
            const orderDate = parseISO(order.created_at);
            if (orderDate.getMonth() === dataPeriod.month.id) {
              dataProducts[index] += parseFloat(order.amount_paid);
            }
          });
        });
        const productsData: ISerie = {
          name: 'Produtos',
          data: dataProducts,
        };

        const dataPlans = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        periodData.forEach((dataPeriod, index) => {
          plansOrders.forEach((order) => {
            const orderDate = parseISO(order.created_at);
            if (orderDate.getMonth() === dataPeriod.month.id) {
              dataPlans[index] += parseFloat(order.amount_paid);
            }
          });
        });
        const plansData: ISerie = {
          name: 'Planos',
          data: dataPlans,
        };

        const seriesData: ISerie[] = [
          certificatesData,
          coursesData,
          productsData,
          plansData,
        ];

        setOverviewSeries(seriesData);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    []
  );

  useEffect(() => {
    loadOrders(periodDate, period);
    loadOrdersProducts(periodDate, subPeriod);
  }, [loadOrders, loadOrdersProducts, period, periodDate, subPeriod]);

  const handleSubmitPeriod = useCallback((data: IFormData) => {
    setErrorDate('');
    const [initialDay, initialMonth, initialYear] = data.initalDate.split('/');
    const [endDay, endMonth, endYear] = data.endDate.split('/');

    const initialDate = new Date(
      parseInt(initialYear, 10),
      parseInt(initialMonth, 10) - 1,
      parseInt(initialDay, 10),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      parseInt(endYear, 10),
      parseInt(endMonth, 10) - 1,
      parseInt(endDay, 10),
      23,
      59,
      59,
      999
    );

    if (differenceInMonths(endDate, initialDate) >= 12) {
      setErrorDate('Escolha um periodo de até 12 meses');
    } else {
      setPeriodData({
        initalDate: initialDate,
        endDate,
      });
    }
  }, []);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="d-flex justify-content-between">
              <h1>Gráficos de vendas</h1>
              <div>
                <Form
                  onSubmit={handleSubmitPeriod}
                  className="d-flex align-items-center"
                >
                  <div>
                    <InputDate
                      name="initalDate"
                      value={periodDate.initalDate}
                      monthYearSelectable
                    />
                  </div>
                  <div className="mx-3">
                    <InputDate
                      name="endDate"
                      value={periodDate.endDate}
                      monthYearSelectable
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-submit px-4"
                  >
                    <span className="px-2">Filtrar</span>
                  </button>
                </Form>
                {errorDate && (
                  <span className="small text-error error">{errorDate}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <Graph
              title="Visão geral (Anual)"
              series={overviewSeries}
              categories={period.map((periodData) => periodData.month.name)}
              height={450}
            />
          </div>
          <div className="col-12 my-4">
            <h2>Produtos/Planos</h2>
          </div>
          {productsOrdersSeries.map((productOrderSerie) => (
            <div key={productOrderSerie.name} className="col-lg-6 mb-4">
              <Graph
                title={productOrderSerie.name}
                series={
                  productOrderSerie.plans
                    ? productOrderSerie.plans
                    : [
                        {
                          name: productOrderSerie.name,
                          data: productOrderSerie.data as number[],
                        },
                      ]
                }
                categories={subPeriod.map(
                  (subPeriodData) => subPeriodData.month.name
                )}
                height={350}
                className="sub-graphs"
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
