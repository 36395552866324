import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { CgClose } from 'react-icons/cg';
import { Container, Tag } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  tags: string[];
  hasError?(hasError: boolean): void;
  onChangeTags?(tags: string[]): void;
}

const Input: FC<InputProps> = ({
  name,
  hasError,
  className,
  onFocus,
  onBlur,
  tags,
  onChangeTags,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [values, setValues] = useState<string[]>([]);
  const [value, setValue] = useState('');

  const { fieldName, defaultValue, error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  useEffect(() => {
    setValues(tags);
  }, [tags]);

  const handleInputFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleKeyUp = useCallback(
    (e) => {
      if (e.keyCode === 191 || e.which === 191 || e.key === ';') {
        if (onChangeTags) {
          const newValues = values.slice();
          newValues.push(value.replace(';', ''));
          onChangeTags(newValues);
        }
        setValue('');
      }
    },
    [onChangeTags, value, values]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (
        (e.keyCode === 8 || e.which === 8 || e.key === 'Backspace') &&
        typeof value === 'string' &&
        value.length === 0
      ) {
        const newvalues = values.filter(
          (_, index) => values.length - 1 !== index
        );
        setValue(values[values.length - 1]);
        setValues(newvalues);
        if (onChangeTags) {
          onChangeTags(newvalues);
        }
      }
    },
    [onChangeTags, value, values]
  );

  const handleClickDelete = useCallback(
    (index) => {
      const newValues = values.filter((_, idx) => idx !== index);
      setValues(newValues);
      if (onChangeTags) {
        onChangeTags(newValues);
      }
    },
    [onChangeTags, values]
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div className="d-flex flex-wrap">
          {values.map((valueData, index) => (
            <Tag key={index.toString()} className="bg-dark-2 px-4 py-1 m-1">
              <span>{valueData}</span>
              <button
                type="button"
                onMouseDown={() => handleClickDelete(index)}
                className="border-0 bg-transparent"
              >
                <CgClose size={17} color="#BBBBBB" className="ms-2" />
              </button>
            </Tag>
          ))}
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            type="text"
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            value={value}
            className="m-1"
            {...rest}
          />
        </div>
      </Container>
      <div className="d-flex justify-content-between">
        {error && <span className="small text-error error">{error}</span>}
        <span className="small text-gray ps-2">
          <small>Use ; para fazer a separação.</small>
        </span>
      </div>
    </>
  );
};

export default Input;
