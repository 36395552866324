/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import List from 'react-chatview';

import Swal from 'sweetalert2';
import api from '~/services/api';

import { Container, Notification } from './styles';

interface INotification {
  id: string;
  title: string;
  content: string;
  link?: string;
  read: boolean;
}

interface INotificationData {
  last_page: number;
  data: INotification[];
}

interface INotificationsProps {
  opened: boolean;
  onClose(): void;
  setHasUnread(value: boolean): void;
}

const Notifications: React.FC<INotificationsProps> = ({
  opened,
  onClose,
  setHasUnread,
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadNotifications = useCallback(async (pageData: number) => {
    const response = await api.get<INotificationData>(`notifications`, {
      params: {
        page: pageData,
      },
    });

    setNotifications((state) => [...state, ...response.data.data]);
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadNotifications(1);
  }, [loadNotifications]);

  const checkNotification = useCallback(async () => {
    const unread = notifications.find((notification) => !notification.read);
    if (unread && opened) {
      const newNotification: INotification[] = [];
      for (const notification of notifications) {
        if (!notification.read) {
          const formData = {
            title: notification.title,
            content: notification.content,
            link: notification.link,
            read: true,
          };
          const response = await api.put(
            `notifications/${notification.id}`,
            formData
          );
          newNotification.push(response.data);
        } else {
          newNotification.push(notification);
        }
      }
      setNotifications(newNotification);
      setHasUnread(false);
    } else if (unread) {
      setHasUnread(true);
    } else {
      setHasUnread(false);
    }
  }, [notifications, opened, setHasUnread]);

  useEffect(() => {
    checkNotification();
  }, [checkNotification]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadNotifications(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadNotifications, page]);

  return (
    <Container
      opened={opened}
      className="position-absolute d-flex justify-content-end"
    >
      <button type="button" onClick={onClose} />
      <List
        scrollLoadThreshold={100}
        onInfiniteLoad={handleLoad}
        className="bg-dark-2 p-4"
      >
        <button
          type="button"
          onClick={onClose}
          className="d-block border-0 bg-transparent ms-auto mb-4"
        >
          <CgClose size={24} color="#BBBBBB" />
        </button>
        <h4>Notificações</h4>
        <div className="mt-5">
          {notifications.length > 0 ? (
            <>
              {notifications.map((notification) => (
                <Notification key={notification.id} className="p-3">
                  <small className="text-gray small mb-1 text-end d-block">
                    Há 2s
                  </small>
                  <h6>{notification.title}</h6>
                  <p className="text-gray small py-2">{notification.content}</p>
                  {notification.link && (
                    <button
                      type="button"
                      className="btn btn-primary px-4 ms-auto d-block"
                    >
                      Ver agora
                    </button>
                  )}
                </Notification>
              ))}
            </>
          ) : (
            <h5>Nenhuma notificação</h5>
          )}
        </div>
      </List>
    </Container>
  );
};

export default Notifications;
