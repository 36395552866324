/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IoIosVideocam } from 'react-icons/io';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { AxiosResponse } from 'axios';
import { BsCalendarEvent } from 'react-icons/bs';
import { format, addDays } from 'date-fns';
import { MdDelete } from 'react-icons/md';

import YouTube from 'react-youtube';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Steps, VideoPreview, Modal, Calendar } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import InputVideo from '~/components/InputVideo';
import InputRadio, { IOption as IOptionRadio } from '~/components/InputRadio';
import InputToggle from '~/components/InputToggle';
import Quiz, { IQuestion } from '~/components/Quiz';
import CreateCertificate, {
  ICertificateData,
} from '~/components/CreateCertificate';
import { formatPrice } from '~/utils/format';

interface ICategory {
  id: string;
  name: string;
  icon?: string;
}

interface IPlan {
  id: string;
  name: string;
}

interface IProduct {
  id: string;
  title: string;
  hasPlans: boolean;
  plans?: IPlan[];
}

interface ICourseCategory {
  category: ICategory;
}

interface ICourseProduct {
  plan_id?: string;
  product: IProduct;
  plan: IPlan;
}

interface IAnswer {
  id: string;
  content: string;
  correct: boolean;
}

interface IQuestionResponse {
  id: string;
  content: string;
  answers: IAnswer[];
}

interface ICourseResponse {
  id: string;
  status_id: string;
  title: string;
  description: string;
  workload: string;
  has_certificate: boolean;
  sell_certificate: boolean;
  release_date?: Date;
  thumbnail: {
    id: string;
    thumbnail_url: string;
  };
  wallpaper: {
    id: string;
    wallpaper_url: string;
  };
  video: {
    id: string;
    video_url?: string;
    link?: string;
  };
  certificate?: {
    id: string;
    standard: boolean;
    html_front?: string;
    css_front?: string;
    html_back?: string;
    css_back?: string;
    checkout_url?: string;
    price: number;
    has_discount: boolean;
    discount: number;
  };
  courseSale?: {
    id: string;
    checkout_url: string;
    price: number;
    has_discount: boolean;
    discount: number;
  };
  exam?: {
    id: string;
    average?: number;
    show_answers?: boolean;
    duration?: string;
    attempts?: number;
    release?: string;
    questions: IQuestionResponse[];
  };
  coursesCategories: ICourseCategory[];
  coursesProducts: ICourseProduct[];
}

interface IFormData {
  title: string;
  description: string;
  workload: string;
  video_url: string;
  certificate_price: string;
  certificate_discount: string;
  certificate_checkout_url?: string;
  course_price: string;
  course_discount: string;
  course_checkout_url?: string;
}

interface IStatus {
  id: string;
  content: string;
}

interface IParams {
  slug: string;
}

const CoursesUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [thumbnailId, setThumbnailId] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [thumbnailError, setThumbnailError] = useState('');
  const [wallpaper, setWallpaper] = useState<File | undefined>(undefined);
  const [wallpaperId, setWallpaperId] = useState('');
  const [wallpaperUrl, setWallpaperUrl] = useState('');
  const [wallpaperError, setWallpaperError] = useState('');
  const [trailer, setTrailer] = useState<File | undefined>(undefined);
  const [trailerId, setTrailerId] = useState('');
  const [trailerUrl, setTrailerUrl] = useState('');
  const [trailerError, setTrailerError] = useState('');
  const [sell, setSell] = useState(false);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [oldCategories, setOldCategories] = useState<IOption[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<IOption[]>([]);
  const [workload, setWorkload] = useState('');
  const [addQuiz, setAddQuiz] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(true);
  const [defaultCertificate, setDefaultCertificate] = useState(true);
  const [sellCertificate, setSellCertificate] = useState(false);
  const [hasCourseDiscount, setHasCourseDiscount] = useState(false);
  const [hasCertificateDiscount, setHasCertificateDiscount] = useState(false);
  const [certificateData, setCertificateData] = useState(
    {} as ICertificateData
  );
  const [certificateError, setCertificateError] = useState('');
  const [certificateId, setCertificateId] = useState('');
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [examId, setExamId] = useState('');
  const [course, setCourse] = useState({} as IFormData);
  const [courseId, setCourseId] = useState('');
  const [trailerType, setTrailerType] = useState<IOptionRadio>({
    id: 'upload',
    value: 'Upload',
  });
  const [trailerLink, setTrailerLink] = useState('');
  const [courseSaleId, setCourseSaleId] = useState('');
  const [status, setStatus] = useState<IOption[]>([]);
  const [statusSelected, setStatusSelected] = useState({} as IOption);
  const [showButtonCalendar, setShowButtonCalendar] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(addDays(new Date(), 1));
  const [releaseDate, setReleaseDate] = useState('');
  const [time, setTime] = useState('');
  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [products, setProducts] = useState<IOption[]>([]);
  const [oldProducts, setOldProducts] = useState<IOption[]>([]);
  const [productsSelected, setProductsSelected] = useState<IOption[]>([]);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    api
      .get<ICourseResponse>(`courses/${params.slug}`)
      .then(async (response) => {
        const responseStatus = await api.get<IStatus[]>('status');

        const statusData: IOption[] = responseStatus.data.map((dataStatus) => {
          let value = '';
          switch (dataStatus.content) {
            case 'Draft':
              value = 'Rascunho';
              break;
            case 'Unlisted':
              value = 'Não listado';
              break;
            case 'Scheduled':
              value = 'Agendado';
              break;
            default:
              value = 'Publicado';
              break;
          }

          return {
            id: dataStatus.id,
            value,
            selected: dataStatus.id === response.data.status_id,
          };
        });

        const statusSelectedData = statusData.find(
          (dataStatus) => dataStatus.id === response.data.status_id
        );

        if (statusSelectedData) {
          if (
            response.data.release_date &&
            statusSelectedData.value === 'Agendado'
          ) {
            setShowButtonCalendar(true);
            const releaseDateData = format(
              new Date(response.data.release_date),
              "dd/MM/yyyy 'ás' HH:mm:ss"
            );
            setReleaseDate(releaseDateData);
            setTime(releaseDateData.slice(14));
            setSelectedDate(new Date(response.data.release_date));
          }
          setStatusSelected(statusSelectedData);
        }

        setStatus(statusData);
        setCourseId(response.data.id);
        setCourse({
          title: response.data.title,
          description: response.data.description,
          workload: response.data.workload,
          video_url:
            response.data.video && response.data.video.link
              ? `https://www.youtube.com/embed/${response.data.video.link}`
              : '',
          certificate_price: response.data.certificate
            ? formatPrice(response.data.certificate.price)
            : '',
          certificate_discount: response.data.certificate
            ? formatPrice(response.data.certificate.discount)
            : '',
          certificate_checkout_url: response.data.certificate
            ? response.data.certificate.checkout_url
            : undefined,
          course_price: response.data.courseSale
            ? formatPrice(response.data.courseSale.price)
            : '',
          course_discount: response.data.courseSale
            ? formatPrice(response.data.courseSale.discount)
            : '',
          course_checkout_url: response.data.courseSale
            ? response.data.courseSale.checkout_url
            : undefined,
        });

        if (response.data.courseSale) {
          setSell(true);
          setHasCourseDiscount(response.data.courseSale.has_discount);
          setCourseSaleId(response.data.courseSale.id);
        }

        setWorkload(response.data.workload);
        setHasCertificate(response.data.has_certificate);
        setAddQuiz(!!response.data.exam);

        if (response.data.certificate) {
          setCertificateId(response.data.certificate.id);
          setCertificateData({
            front: {
              html: response.data.certificate.html_front as string,
              css: response.data.certificate.css_front as string,
            },
            back: {
              html: response.data.certificate.html_back as string,
              css: response.data.certificate.css_back as string,
            },
          });
          setSellCertificate(response.data.sell_certificate);
          setDefaultCertificate(response.data.certificate.standard);
          setHasCertificateDiscount(response.data.certificate.has_discount);
        }

        const categoriesData: IOption[] = response.data.coursesCategories.map(
          (courseCategory) => ({
            id: courseCategory.category.id,
            value: courseCategory.category.name,
            selected: true,
          })
        );

        let productsData: IOption[] = [];
        response.data.coursesProducts.forEach((courseProduct) => {
          console.log(courseProduct.product.hasPlans);
          if (courseProduct.product.hasPlans && courseProduct.plan_id) {
            productsData.push({
              id: courseProduct.plan_id,
              value: `${courseProduct.product.title} - ${courseProduct.plan.name}`,
              selected: true,
            });
          }

          if (!courseProduct.product.hasPlans) {
            console.log(courseProduct.product.id);
            productsData.push({
              id: courseProduct.product.id,
              value: courseProduct.product.title,
              selected: true,
            });
          }
        });

        productsData = productsData.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((el) => el.id === obj.id && el.value === obj.value)
        );

        console.log(productsData);

        setOldCategories(categoriesData);
        setCategoriesSelected(categoriesData);
        setOldProducts(productsData);
        setProductsSelected(productsData);
        setThumbnailId(response.data.thumbnail.id);
        setThumbnailUrl(response.data.thumbnail.thumbnail_url);
        setWallpaperId(response.data.wallpaper.id);
        setWallpaperUrl(response.data.wallpaper.wallpaper_url);
        if (response.data.video) {
          setTrailerId(response.data.video.id);

          if (response.data.video.video_url) {
            setTrailerUrl(response.data.video.video_url);
          } else if (response.data.video.link) {
            setTrailerLink(response.data.video.link);
            setTrailerType({
              id: 'link',
              value: 'Link',
            });
          }
        }

        if (response.data.exam) {
          const questionsData: IQuestion[] = response.data.exam.questions.map(
            (question) => {
              const answerData = question.answers.map((answer) => ({
                id: answer.id,
                answer: answer.content,
                correct_answer: answer.correct,
              }));

              return {
                id: question.id,
                question: question.content,
                options: answerData,
              };
            }
          );

          setQuestions(questionsData);
          setAttempts(response.data.exam.attempts || 0);
          setExamId(response.data.exam.id);
        }
      });
  }, [params.slug]);

  useEffect(() => {
    if (categoriesSelected.length === 0) {
      api
        .get<ICategory[]>('categories/subcategories', {
          params: {
            all: true,
            noPagination: true,
          },
        })
        .then((response) => {
          const data: IOption[] = response.data.map((category) => ({
            id: category.id,
            value: category.name,
            selected: false,
          }));
          setCategories(data);
        });
    }
  }, [categoriesSelected.length]);

  useEffect(() => {
    if (productsSelected.length === 0) {
      api
        .get<IProduct[]>('products', {
          params: {
            all: true,
            noPagination: true,
          },
        })
        .then((response) => {
          const data: IOption[] = [];
          response.data.forEach((product) => {
            if (product.hasPlans && product.plans) {
              const newDatas: IOption[] = product.plans.map((plan) => ({
                id: plan.id,
                value: `${product.title} - ${plan.name}`,
                selected: false,
              }));

              data.push(...newDatas);
            } else {
              const newData = {
                id: product.id,
                value: product.title,
                selected: false,
              };
              data.push(newData);
            }
          });

          setProducts(data);
          setProductsList(response.data);
        });
    }
  }, [productsSelected.length]);

  useEffect(() => {
    setCategories((state) => {
      if (state.length > 0 && categoriesSelected.length > 0) {
        const results = state.filter((category) => {
          return !categoriesSelected.some((categorySelected) => {
            return category.id === categorySelected.id;
          });
        });
        return results;
      }

      return state;
    });
  }, [categoriesSelected]);

  useEffect(() => {
    setProducts((state) => {
      if (state.length > 0 && productsSelected.length > 0) {
        const results = state.filter((product) => {
          return !productsSelected.some((productSelected) => {
            return product.id === productSelected.id;
          });
        });
        return results;
      }

      return state;
    });
  }, [productsSelected]);

  const handleChangeSell = useCallback((value) => {
    setSell(value.length > 0);
  }, []);

  const handleChangeThumbnail = useCallback((file) => {
    setThumbnail(file);
  }, []);

  const handleRemoveThumbnail = useCallback(() => {
    setThumbnail(undefined);
    setThumbnailUrl('');
  }, []);

  const handleChangeWallpaper = useCallback((file) => {
    setWallpaper(file);
  }, []);

  const handleRemoveWallpaper = useCallback(() => {
    setWallpaper(undefined);
    setWallpaperUrl('');
  }, []);

  const handleChangeTrailer = useCallback((file) => {
    setTrailer(file);
  }, []);

  const handleRemoveTrailer = useCallback(() => {
    setTrailer(undefined);
    setTrailerUrl('');
  }, []);

  const handleChangeWorkload = useCallback((e) => {
    // eslint-disable-next-line prefer-const
    let [hour, minutes, seconds] = e.target.value.split(':');

    if (seconds > 59) {
      seconds = 59;
    } else if (minutes > 59) {
      minutes = 59;
    }

    setWorkload(`${hour}:${minutes}:${seconds}`);
  }, []);

  const handleChangeAddQuiz = useCallback((value) => {
    setAddQuiz(value);
  }, []);

  const handleChangeHasCertificate = useCallback((value) => {
    setHasCertificate(value);
  }, []);

  const handleChangeDefaultCertificate = useCallback((value) => {
    setDefaultCertificate(value);
  }, []);

  const handleChangeSellCertificate = useCallback((value) => {
    setSellCertificate(value);
  }, []);

  const handleChangeHasCourseDiscount = useCallback((value) => {
    setHasCourseDiscount(value);
  }, []);

  const handleChangeHasCertificateDiscount = useCallback((value) => {
    setHasCertificateDiscount(value);
  }, []);

  const handleChangeCategoriesSelected = useCallback((options: IOption[]) => {
    setCategoriesSelected(options);
  }, []);

  const handleChangeCategoryText = useCallback(
    async (value) => {
      try {
        const response = await api.get<ICategory[]>(
          'categories/subcategories',
          {
            params: {
              all: true,
              noPagination: true,
              search: value,
            },
          }
        );

        const results = response.data.filter((category) => {
          return !categoriesSelected.some((categorySelected) => {
            return category.id === categorySelected.id;
          });
        });

        const data: IOption[] = results.map((category) => ({
          id: category.id,
          value: category.name,
          selected: false,
        }));

        setCategories(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [categoriesSelected]
  );

  const handleChangeProductsSelected = useCallback((options: IOption[]) => {
    console.log(options);
    setProductsSelected(options);
  }, []);

  const handleChangeProductText = useCallback(
    async (value) => {
      try {
        const response = await api.get<IProduct[]>('products', {
          params: {
            search: value,
            noPagination: true,
          },
        });

        const results = response.data.filter((product) => {
          return !productsSelected.some((productSelected) => {
            return product.id === productSelected.id;
          });
        });

        const data: IOption[] = results.map((product) => ({
          id: product.id,
          value: product.title,
          selected: false,
        }));

        setProducts(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [productsSelected]
  );

  const handleChangeCertificate = useCallback((data: ICertificateData) => {
    setCertificateData(data);
  }, []);

  const handleChangeQuiz = useCallback(
    (questionsData, attemptsData) => {
      if (
        questionsData[0].question.length > 0 &&
        JSON.stringify(questionsData) !== JSON.stringify(questions)
      ) {
        setQuestions(questionsData);
      }
      setAttempts(attemptsData);
    },
    [questions]
  );

  const handleChangeTrailerUrl = useCallback((e) => {
    const url = e.target.value;
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    console.log(match);
    if (match && match[7].length >= 10) {
      if (match[7].length === 10) {
        console.log(match[7]);
        match[7] = `v${match[7]}`;
        console.log(match[7]);
      }
      setTrailerLink(match[7]);
    }
  }, []);

  const handleChangeTrailerType = useCallback(
    (option: IOptionRadio) => {
      setTrailerType(option);
      if (course.video_url) {
        const videoData = {
          target: {
            value: course.video_url,
          },
        };
        handleChangeTrailerUrl(videoData);
      } else {
        setTrailerLink('');
      }
    },
    [course.video_url, handleChangeTrailerUrl]
  );

  const handleChangeStatus = useCallback(
    (option: IOption) => {
      const newStatus = status.map((statusData) => {
        if (statusData.id === option.id) {
          return { ...statusData, selected: true };
        }

        return { ...statusData, selected: false };
      });

      setStatusSelected(option);
      setStatus(newStatus);

      if (option.value === 'Agendado') {
        setShowButtonCalendar(true);
      } else {
        setShowButtonCalendar(false);
      }
    },
    [status]
  );

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleClose = useCallback(() => {
    setShowCalendar(false);
  }, []);

  const handleChangeTime = useCallback((e) => {
    let [hour, minutes, seconds] = e.target.value.split(':');

    if (seconds > 59) {
      seconds = 59;
    } else if (minutes > 59) {
      minutes = 59;
    } else if (hour > 23) {
      hour = 23;
    }

    setTime(`${hour}:${minutes}:${seconds}`);
  }, []);

  const handleSave = useCallback(() => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (time) {
      const [hoursAux, minutesAux, secondsAux] = time.split(':');
      if (hoursAux && hoursAux !== 'undefined') {
        hours = parseInt(hoursAux, 10);
      }
      if (minutesAux && minutesAux !== 'undefined') {
        minutes = parseInt(minutesAux, 10);
      }
      if (secondsAux && secondsAux !== 'undefined') {
        seconds = parseInt(secondsAux, 10);
      }
    }

    const newDate = new Date(year, month, day, hours, minutes, seconds);

    setReleaseDate(format(newDate, "dd/MM/yyyy 'ás' HH:mm:ss"));
    setSelectedDate(newDate);
    handleClose();
  }, [handleClose, selectedDate, time]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setThumbnailError('');
      setWallpaperError('');
      setTrailerError('');
      setCertificateError('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
          wallpaper: Yup.string().when('$wallpaper', {
            is: (wallpaperCheck: boolean) => wallpaperCheck,
            then: Yup.string().required('A capa é obrigatória'),
            otherwise: Yup.string(),
          }),
          trailer: Yup.string(),
          video_url: Yup.string(),
          categories: Yup.string().when('$categories', {
            is: (categoriesCheck: boolean) => categoriesCheck,
            then: Yup.string().required(
              'Pelo menos uma categoria é obrigatória.'
            ),
            otherwise: Yup.string(),
          }),
          workload: Yup.string()
            .length(8, 'Preencha corretamente')
            .required('O tempo de duração é obrigatório'),
          certificate_price: Yup.string().when('$certificatePrice', {
            is: (certificatePriceCheck: boolean) => certificatePriceCheck,
            then: Yup.string().required(
              'O valor do certificado é obrigatório.'
            ),
            otherwise: Yup.string(),
          }),
          certificate_discount: Yup.string().when('$certificateDiscount', {
            is: (certificateDiscountCheck: boolean) => certificateDiscountCheck,
            then: Yup.string().required(
              'O valor do certificado da promoção é obrigatório.'
            ),
            otherwise: Yup.string(),
          }),
          course_price: Yup.string().when('$coursePrice', {
            is: (coursePriceCheck: boolean) => coursePriceCheck,
            then: Yup.string().required('O valor do curso é obrigatório.'),
            otherwise: Yup.string(),
          }),
          course_discount: Yup.string().when('$courseDiscount', {
            is: (courseDiscountCheck: boolean) => courseDiscountCheck,
            then: Yup.string().required(
              'O valor do curso na promoção é obrigatório.'
            ),
            otherwise: Yup.string(),
          }),
          certificate: Yup.string().when('$certificateData', {
            is: (certificateDataCheck: boolean) => certificateDataCheck,
            then: Yup.string().required(
              'A criação do certificado é obrigatória.'
            ),
            otherwise: Yup.string(),
          }),
          quiz: Yup.string().when('$quiz', {
            is: (quizCheck: boolean) => quizCheck,
            then: Yup.string().required('A criação da prova é obrigatória.'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            thumbnail: !thumbnail && !thumbnailUrl,
            wallpaper: !wallpaper && !wallpaperUrl,
            quiz:
              addQuiz &&
              questions.filter((question) => question.question.length === 0)
                .length > 0,
            categories: categoriesSelected.length === 0,
            certificatePrice: sellCertificate,
            certificateDiscount: hasCertificateDiscount,
            coursePrice: sell,
            courseDiscount: sell && hasCourseDiscount,
            certificateData:
              !defaultCertificate && Object.keys(certificateData).length === 0,
          },
        });

        let thumbnailIdData = thumbnailId;
        if (thumbnail) {
          const formDataThumbnail = new FormData();
          formDataThumbnail.append('thumbnail', thumbnail);
          const responseThumbnail = await api.post(
            'thumbnails',
            formDataThumbnail
          );
          thumbnailIdData = responseThumbnail.data.id;
        }

        let wallpaperIdData = wallpaperId;
        if (wallpaper) {
          const formDataWallpaper = new FormData();
          formDataWallpaper.append('wallpaper', wallpaper as File);
          const responseWallpaper = await api.post(
            'wallpapers',
            formDataWallpaper
          );
          wallpaperIdData = responseWallpaper.data.id;
        }

        let trailerIdData = trailerId;
        if (trailer || trailerLink) {
          let responseVideo = {} as AxiosResponse;
          if (trailerType.id === 'link' && trailerLink) {
            const formDataVideo = {
              link: trailerLink,
            };
            responseVideo = await api.post('videos', formDataVideo);
          } else if (trailerType.id === 'upload' && trailer) {
            const formDataVideo = new FormData();
            formDataVideo.append('video', trailer);
            responseVideo = await api.post('videos', formDataVideo);
          }
          trailerIdData = responseVideo.data.id;
        }

        const formData = {
          status_id: statusSelected.id,
          wallpaper_id: wallpaperIdData,
          thumbnail_id: thumbnailIdData,
          video_id: trailerIdData || undefined,
          title: data.title,
          description: data.description,
          workload: data.workload,
          has_certificate: hasCertificate,
          sell_certificate: sellCertificate,
          release_date:
            statusSelected.value === 'Agendado' ? selectedDate : undefined,
        };

        const response = await api.put(`courses/${courseId}`, formData);

        if (sell) {
          const formDataCourseSale = {
            course_id: response.data.id,
            price: parseFloat(
              data.course_price
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
            ),
            has_discount: hasCourseDiscount,
            discount: hasCourseDiscount
              ? parseFloat(
                  data.course_discount
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
                )
              : undefined,
            checkout_url: data.course_checkout_url,
          };

          if (courseSaleId) {
            await api.put(`courses-sales/${courseSaleId}`, formDataCourseSale);
          } else {
            await api.post('courses-sales', formDataCourseSale);
          }
        } else if (courseSaleId) {
          await api.delete(`courses-sales/${courseSaleId}`);
        }

        const formDataCertificate = {
          course_id: response.data.id,
          standard: defaultCertificate,
          html_front: undefined as string | undefined,
          html_back: undefined as string | undefined,
          css_front: undefined as string | undefined,
          css_back: undefined as string | undefined,
          price: undefined as number | undefined,
          has_discount: undefined as boolean | undefined,
          discount: undefined as number | undefined,
          checkout_url: data.certificate_checkout_url || undefined,
        };

        if (!defaultCertificate) {
          formDataCertificate.html_front = certificateData.front.html;
          formDataCertificate.css_front = certificateData.front.css;
          formDataCertificate.html_back = certificateData.back.html;
          formDataCertificate.css_back = certificateData.back.css;
        }

        if (sellCertificate) {
          formDataCertificate.price = parseFloat(
            data.certificate_price
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          );
          formDataCertificate.has_discount = hasCertificateDiscount;

          if (hasCertificateDiscount) {
            formDataCertificate.discount = parseFloat(
              data.certificate_price
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
            );
          }
        }

        if (certificateId) {
          await api.put(`certificates/${certificateId}`, formDataCertificate);
        } else {
          await api.post('certificates', formDataCertificate);
        }

        if (addQuiz) {
          const formDataExam = {
            course_id: response.data.id,
            attempts,
          };

          if (examId) {
            const examResponse = await api.put(`exams/${examId}`, formDataExam);

            const questionsPromise = new Promise<void>((resolve) => {
              if (questions.length > 0) {
                questions.forEach(async (question, index) => {
                  if (question.status === 'DELETED') {
                    await api.delete(`questions/${question.id}`);
                  } else {
                    const formDataQuestion = {
                      exam_id: examResponse.data.id,
                      content: question.question,
                    };

                    if (question.status === 'NEW') {
                      const responseQuestion = await api.post(
                        'questions',
                        formDataQuestion
                      );

                      const optionsPromise = new Promise<void>(
                        (resolveOptions) => {
                          if (question.options.length > 0) {
                            question.options.forEach(async (option, idx) => {
                              const formDataOption = {
                                question_id: responseQuestion.data.id,
                                content: option.answer,
                                correct: option.correct_answer,
                              };
                              await api.post('answers', formDataOption);

                              if (question.options.length === idx + 1) {
                                resolveOptions();
                              }
                            });
                          } else {
                            resolveOptions();
                          }
                        }
                      );

                      await optionsPromise;
                    } else if (question.status === 'UPDATED') {
                      const responseQuestion = await api.put(
                        `questions/${question.id}`,
                        formDataQuestion
                      );

                      const optionsPromise = new Promise<void>(
                        (resolveOptions) => {
                          if (question.options.length > 0) {
                            question.options.forEach(async (option, idx) => {
                              if (option.status === 'DELETED') {
                                await api.delete(`answers/${option.id}`);
                              } else {
                                const formDataOption = {
                                  question_id: responseQuestion.data.id,
                                  content: option.answer,
                                  correct: option.correct_answer,
                                };
                                if (option.status === 'NEW') {
                                  await api.post('answers', formDataOption);
                                } else if (option.status === 'UPDATED') {
                                  await api.put(
                                    `answers/${option.id}`,
                                    formDataOption
                                  );
                                }
                              }
                              if (question.options.length === idx + 1) {
                                resolveOptions();
                              }
                            });
                          } else {
                            resolveOptions();
                          }
                        }
                      );

                      await optionsPromise;
                    } else {
                      const optionsPromise = new Promise<void>(
                        (resolveOptions) => {
                          if (question.options.length > 0) {
                            question.options.forEach(async (option, idx) => {
                              if (option.status === 'DELETED') {
                                await api.delete(`answers/${option.id}`);
                              } else {
                                const formDataOption = {
                                  question_id: question.id,
                                  content: option.answer,
                                  correct: option.correct_answer,
                                };
                                if (option.status === 'NEW') {
                                  await api.post('answers', formDataOption);
                                } else if (option.status === 'UPDATED') {
                                  await api.put(
                                    `answers/${option.id}`,
                                    formDataOption
                                  );
                                }
                              }
                              if (question.options.length === idx + 1) {
                                resolveOptions();
                              }
                            });
                          } else {
                            resolveOptions();
                          }
                        }
                      );

                      await optionsPromise;
                    }
                  }

                  if (questions.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            await questionsPromise;
          } else {
            const examResponse = await api.post('exams', formDataExam);
            const questionsPromise = new Promise<void>((resolve) => {
              if (questions.length > 0) {
                questions.forEach(async (question, index) => {
                  const formDataQuestion = {
                    exam_id: examResponse.data.id,
                    content: question.question,
                  };
                  const responseQuestion = await api.post(
                    'questions',
                    formDataQuestion
                  );

                  const optionsPromise = new Promise<void>((resolveOptions) => {
                    if (question.options.length > 0) {
                      question.options.forEach(async (option, idx) => {
                        const formDataOption = {
                          question_id: responseQuestion.data.id,
                          content: option.answer,
                          correct: option.correct_answer,
                        };
                        await api.post('answers', formDataOption);
                        if (question.options.length === idx + 1) {
                          resolveOptions();
                        }
                      });
                    } else {
                      resolveOptions();
                    }
                  });

                  await optionsPromise;

                  if (questions.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            await questionsPromise;
          }
        } else if (examId) {
          await api.delete(`exams/${examId}`);
        }

        if (categoriesSelected.length > 0) {
          const categoriesPromise = new Promise<void>((generalResolve) => {
            const deleteCategories = oldCategories.filter((category) => {
              return !categoriesSelected.some(
                (categoryData) => categoryData.id === category.id
              );
            });

            const newCategories = categoriesSelected.filter((category) => {
              return !oldCategories.some(
                (categoryData) => categoryData.id === category.id
              );
            });

            const deleteCategoriesPromise = new Promise<void>((resolve) => {
              if (deleteCategories.length > 0) {
                deleteCategories.forEach(async (category, index) => {
                  await api.delete(
                    `courses-categories/${category.id}/${courseId}`
                  );

                  if (deleteCategories.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            const newCategoriesPromise = new Promise<void>((resolve) => {
              if (newCategories.length > 0) {
                newCategories.forEach(async (category, index) => {
                  const formDataCourseCategory = {
                    course_id: response.data.id,
                    category_id: category.id,
                    order: index + 1,
                  };

                  await api.post('courses-categories', formDataCourseCategory);

                  if (newCategories.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            deleteCategoriesPromise.then(() => {
              newCategoriesPromise.then(() => {
                generalResolve();
              });
            });
          });

          await categoriesPromise;
        }

        if (productsSelected.length > 0) {
          const productsPromise = new Promise<void>((generalResolve) => {
            const deleteProducts = oldProducts.filter((product) => {
              return !productsSelected.some(
                (productData) => productData.id === product.id
              );
            });

            const newProducts = productsSelected.filter((product) => {
              return !oldProducts.some(
                (productData) => productData.id === product.id
              );
            });

            const deleteProductsPromise = new Promise<void>((resolve) => {
              if (deleteProducts.length > 0) {
                deleteProducts.forEach(async (product, index) => {
                  let productId = '';
                  let planId: string | undefined;

                  const productsData = productsList.filter(
                    (productData) => productData.id === product.id
                  );

                  for (const productData of productsData) {
                    if (productData.hasPlans && productData.plans) {
                      const planCheck = productData.plans.find(
                        (planData) => planData.id === product.id
                      );
                      if (planCheck) {
                        planId = planCheck.id;
                        productId = productData.id;
                      }
                    } else {
                      productId = productData.id;
                    }
                  }

                  await api.delete(
                    `courses-products/${productId}/${courseId}`,
                    {
                      params: {
                        plan_id: planId,
                      },
                    }
                  );

                  if (deleteProducts.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            const newProductsPromise = new Promise<void>((resolve) => {
              if (newProducts.length > 0) {
                newProducts.forEach(async (product, index) => {
                  let productId = '';
                  let planId: string | undefined;

                  const productsData = productsList.filter(
                    (productData) => productData.id === product.id
                  );

                  for (const productData of productsData) {
                    if (!productId && !planId) {
                      if (productData.hasPlans && productData.plans) {
                        const planCheck = productData.plans.find(
                          (planData) => planData.id === product.id
                        );
                        if (planCheck) {
                          planId = planCheck.id;
                          productId = productData.id;
                        }
                      } else {
                        productId = productData.id;
                      }
                    }
                  }

                  const formDataCourseProduct = {
                    course_id: response.data.id,
                    product_id: productId,
                    plan_id: planId,
                    order: index + 1,
                  };

                  await api.post('courses-products', formDataCourseProduct);

                  if (newProducts.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            deleteProductsPromise.then(() => {
              newProductsPromise.then(() => {
                generalResolve();
              });
            });
          });

          await productsPromise;
        }

        Toast.fire({
          icon: 'success',
          title: 'Curso editado!',
        });

        history.push(
          `${process.env.PUBLIC_URL}/cursos/${response.data.slug}/modulos`
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.thumbnail) {
            setThumbnailError(errors.thumbnail);
          }

          if (errors.wallpaper) {
            setWallpaperError(errors.wallpaper);
          }

          if (errors.trailer) {
            setTrailerError(errors.trailer);
          }

          if (errors.certificate) {
            setCertificateError(errors.certificate);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [
      thumbnail,
      thumbnailUrl,
      wallpaper,
      wallpaperUrl,
      trailerType,
      trailer,
      trailerLink,
      addQuiz,
      questions,
      categoriesSelected,
      sellCertificate,
      hasCertificateDiscount,
      sell,
      hasCourseDiscount,
      defaultCertificate,
      certificateData,
      thumbnailId,
      wallpaperId,
      trailerId,
      statusSelected,
      hasCertificate,
      selectedDate,
      courseId,
      courseSaleId,
      certificateId,
      examId,
      productsSelected,
      history,
      oldCategories,
      oldProducts,
      productsList,
      attempts,
    ]
  );

  const handleClickDelete = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse curso?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#e50914',
      cancelButtonColor: '#303030',
      cancelButtonText: 'Não',
      reverseButtons: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`courses/${courseId}`);

          history.push(`${process.env.PUBLIC_URL}/cursos`);

          Toast.fire({
            icon: 'success',
            title: 'Curso deletado!',
          });
        }
      })
      .catch((error) => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [history, courseId]);

  return (
    <>
      <Container className="container py-5">
        <Form
          ref={formRef}
          initialData={course}
          onSubmit={handleSubmit}
          className="row box bg-dark-2 p-5"
        >
          <div className="col-12 mb-4 position-relative">
            <h1 className="fw-light text-center">
              Atualizar <b className="fw-bold">curso</b>
            </h1>
            <button
              type="button"
              className="bg-transparent border-0 d-flex align-items-center justify-content-center text-error btn-delete"
              onClick={handleClickDelete}
            >
              Deletar curso{' '}
              <MdDelete size={21} color="#FF333D" className="ms-2" />
            </button>
          </div>
          <div className="col-12">
            <div className="row justify-content-center my-5">
              <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                <Steps className="d-flex ml-auto align-items-center min-width">
                  <div className="w-100 text-center bg-gray rounded-pill">
                    <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                      Sobre do curso
                    </span>
                  </div>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/cursos/${params.slug}/modulos`}
                    className="w-100 text-center bg-dark-3 rounded-pill"
                  >
                    <span className="d-block px-3 py-3 text-gray">Módulos</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/cursos/${params.slug}/aulas`}
                    className="w-100 text-center bg-dark-3 rounded-pill"
                  >
                    <span className="d-block px-3 py-3 text-gray">Aulas</span>
                  </Link>
                </Steps>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <label className="w-100">
              Título <Input name="title" className="mt-3 input" />
            </label>
          </div>
          <div className="col-lg-6">
            <div className="w-100">
              <label className="w-100">Categorias</label>
              <Select
                name="categories"
                options={categories}
                optionsSelected={categoriesSelected}
                multiSelect
                className="mt-3 input-select"
                onChange={(options) =>
                  handleChangeCategoriesSelected(
                    options as unknown as IOption[]
                  )
                }
                onChangeText={handleChangeCategoryText}
              />
            </div>
          </div>
          <div className="col-12">
            <label className="w-100 mt-4">
              Descrição{' '}
              <Textarea name="description" className="mt-3" rows={4} />
            </label>
          </div>
          <div className="col-12 pt-5 pb-4">
            <hr />
          </div>
          <div className="col-lg-6">
            <div className="w-100 mt-4">
              <span>Subir trailer por:</span>
              <InputRadio
                name="trailer_type"
                options={[
                  {
                    id: 'link',
                    value: 'Link',
                  },
                  {
                    id: 'upload',
                    value: 'Upload',
                  },
                ]}
                onChange={handleChangeTrailerType}
                selected={trailerType}
                className="justify-content-start mt-3"
              />
            </div>
            {trailerType.id === 'link' && (
              <label className="w-100 mt-4">
                Link do trailer
                <Input
                  type="url"
                  placeholder="https://www.youtube.com/"
                  name="video_url"
                  className="mt-3"
                  onChange={handleChangeTrailerUrl}
                />
              </label>
            )}
            <label className="w-100 mt-4">
              Tempo de duração do curso{' '}
              <InputMask
                kind="custom"
                options={{
                  mask: '99:99:99',
                }}
                placeholder="00:00:00"
                name="workload"
                className="mt-3"
                onChange={handleChangeWorkload}
                value={workload}
              />
            </label>
          </div>
          <div className="col-lg-6">
            <div className="w-100 mt-4">
              <span>
                {trailerType.id === 'link' ? 'Trailer Preview' : 'Trailer'}
              </span>
              {trailerType.id === 'upload' && (
                <InputVideo
                  name="trailer"
                  placeholder=""
                  className="bg-dark-3 mt-3"
                  onChange={handleChangeTrailer}
                  value={trailerUrl}
                  error={trailerError}
                  onRemove={handleRemoveTrailer}
                />
              )}
              {trailerType.id === 'link' && (
                <VideoPreview className="bg-dark-3 mt-3">
                  {trailerLink ? (
                    <YouTube videoId={trailerLink} className="h-100" />
                  ) : (
                    <div className="camera">
                      <IoIosVideocam size={50} color="#BBBBBB" />
                    </div>
                  )}
                </VideoPreview>
              )}
            </div>
          </div>
          <div className="col-12 pt-5 pb-4">
            <hr />
          </div>
          <div className="col-lg-6">
            <div className="w-100">
              <span>Thumbnail</span>
              <InputImage
                name="thumbnail"
                placeholder=""
                className="bg-dark-3 mt-3"
                onChange={handleChangeThumbnail}
                value={thumbnailUrl}
                cropImage
                aspect={20.44 / 12.63}
                cropOptions={
                  !thumbnail
                    ? {
                        unit: 'px',
                        width: 20.44 * 5,
                        height: 12.63 * 5,
                        x: 0,
                        y: 0,
                      }
                    : undefined
                }
                error={thumbnailError}
                onRemove={handleRemoveThumbnail}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="w-100">
              <span>Capa</span>
              <InputImage
                name="wallpaper"
                placeholder=""
                className="bg-dark-3 mt-3"
                onChange={handleChangeWallpaper}
                value={wallpaperUrl}
                cropImage
                aspect={20.44 / 6.4}
                cropOptions={
                  !wallpaper
                    ? {
                        unit: 'px',
                        width: 20.44 * 5,
                        height: 6.4 * 5,
                        x: 0,
                        y: 0,
                      }
                    : undefined
                }
                error={wallpaperError}
                onRemove={handleRemoveWallpaper}
              />
            </div>
          </div>
          <div className="col-12 pt-5 pb-4">
            <hr />
          </div>
          <div className="col-lg-4">
            <div className="w-100 mt-4">
              <label>Adicionar Prova?</label>
              <div className="d-flex text-center mt-3">
                <label
                  className={`btn w-100 mx-1 btn-radio ${
                    addQuiz ? 'btn-gray' : 'btn-dark-3'
                  }`}
                  htmlFor="add_quiz_yes"
                >
                  <span className="d-block py-2">YES</span>
                  <Input
                    type="radio"
                    onChange={() => handleChangeAddQuiz(true)}
                    name="add_quiz"
                    id="add_quiz_yes"
                    className="d-none"
                    value="yes"
                    checked={addQuiz}
                  />
                </label>
                <label
                  className={`btn w-100 mx-1 btn-radio ${
                    !addQuiz ? 'btn-gray' : 'btn-dark-3'
                  }`}
                  htmlFor="add_quiz_no"
                >
                  <span className="d-block py-2">NO</span>
                  <Input
                    type="radio"
                    onChange={() => handleChangeAddQuiz(false)}
                    name="add_quiz"
                    id="add_quiz_no"
                    className="d-none"
                    value="no"
                    checked={!addQuiz}
                  />
                </label>
              </div>
            </div>
            {addQuiz && (
              <div className="w-100 mt-3">
                <Quiz
                  title="Adicionar prova"
                  dataQuestions={questions}
                  dataAttempts={attempts}
                  onChange={handleChangeQuiz}
                />
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <div className="w-100 mt-4">
              <label>Emitir Certificado?</label>
              <div className="d-flex text-center mt-3">
                <label
                  className={`btn w-100 mx-1 btn-radio ${
                    hasCertificate ? 'btn-gray' : 'btn-dark-3'
                  }`}
                  htmlFor="has_certificate_yes"
                >
                  <span className="d-block py-2">YES</span>
                  <Input
                    type="radio"
                    onChange={() => handleChangeHasCertificate(true)}
                    name="has_certificate"
                    id="has_certificate_yes"
                    className="d-none"
                    value="yes"
                    checked={hasCertificate}
                  />
                </label>
                <label
                  className={`btn w-100 mx-1 btn-radio ${
                    !hasCertificate ? 'btn-gray' : 'btn-dark-3'
                  }`}
                  htmlFor="has_certificate_no"
                >
                  <span className="d-block py-2">NO</span>
                  <Input
                    type="radio"
                    onChange={() => handleChangeHasCertificate(false)}
                    name="has_certificate"
                    id="has_certificate_no"
                    className="d-none"
                    value="no"
                    checked={!hasCertificate}
                  />
                </label>
              </div>
            </div>
          </div>
          {hasCertificate && (
            <>
              <div className="col-lg-4">
                <div className="w-100 mt-4">
                  <label>Certificado Padrão?</label>
                  <div className="d-flex text-center mt-3">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        defaultCertificate ? 'btn-gray' : 'btn-dark-3'
                      }`}
                      htmlFor="default_certificate_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={() => handleChangeDefaultCertificate(true)}
                        name="default_certificate"
                        id="default_certificate_yes"
                        className="d-none"
                        value="yes"
                        checked={defaultCertificate}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        !defaultCertificate ? 'btn-gray' : 'btn-dark-3'
                      }`}
                      htmlFor="default_certificate_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={() => handleChangeDefaultCertificate(false)}
                        name="default_certificate"
                        id="default_certificate_no"
                        className="d-none"
                        value="no"
                        checked={!defaultCertificate}
                      />
                    </label>
                  </div>
                </div>
                {!defaultCertificate && (
                  <div className="w-100 mt-3">
                    <CreateCertificate
                      data={certificateData}
                      onChange={handleChangeCertificate}
                      error={certificateError}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <div className="w-100 mt-4">
                  <label>Vender Certificado?</label>
                  <div className="d-flex text-center mt-3">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        sellCertificate ? 'btn-gray' : 'btn-dark-3'
                      }`}
                      htmlFor="sell_certificate_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={() => handleChangeSellCertificate(true)}
                        name="sell_certificate"
                        id="sell_certificate_yes"
                        className="d-none"
                        value="yes"
                        checked={sellCertificate}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        !sellCertificate ? 'btn-gray' : 'btn-dark-3'
                      }`}
                      htmlFor="sell_certificate_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={() => handleChangeSellCertificate(false)}
                        name="sell_certificate"
                        id="sell_certificate_no"
                        className="d-none"
                        value="no"
                        checked={!sellCertificate}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {sellCertificate && (
                <>
                  <div className="col-lg-4">
                    <label className="w-100 mt-4">
                      Valor do certificado{' '}
                      <InputMask
                        kind="money"
                        name="certificate_price"
                        className="mt-3 input"
                      />
                    </label>
                  </div>
                  <div className="col-lg-4">
                    <div className="w-100 mt-4">
                      <label>Certificado em promoção?</label>
                      <div className="d-flex text-center mt-3">
                        <label
                          className={`btn w-100 mx-1 btn-radio ${
                            hasCertificateDiscount ? 'btn-gray' : 'btn-dark-3'
                          }`}
                          htmlFor="has_certificate_discount_yes"
                        >
                          <span className="d-block py-2">YES</span>
                          <Input
                            type="radio"
                            onChange={() =>
                              handleChangeHasCertificateDiscount(true)
                            }
                            name="has_certificate_discount"
                            id="has_certificate_discount_yes"
                            className="d-none"
                            value="yes"
                            checked={hasCertificateDiscount}
                          />
                        </label>
                        <label
                          className={`btn w-100 mx-1 btn-radio ${
                            !hasCertificateDiscount ? 'btn-gray' : 'btn-dark-3'
                          }`}
                          htmlFor="has_certificate_discount_no"
                        >
                          <span className="d-block py-2">NO</span>
                          <Input
                            type="radio"
                            onChange={() =>
                              handleChangeHasCertificateDiscount(false)
                            }
                            name="has_certificate_discount"
                            id="has_certificate_discount_no"
                            className="d-none"
                            value="no"
                            checked={!hasCertificateDiscount}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  {hasCertificateDiscount && (
                    <div className="col-lg-4">
                      <label className="w-100 mt-4">
                        Valor do certificado na promoção{' '}
                        <InputMask
                          kind="money"
                          name="certificate_discount"
                          className="mt-3 input"
                        />
                      </label>
                    </div>
                  )}
                  <div className="col-lg-4">
                    <label className="w-100 mt-4">
                      Link de pagamento do certificado
                      <Input
                        type="url"
                        name="certificate_checkout_url"
                        className="mt-3 input"
                      />
                    </label>
                  </div>
                </>
              )}
            </>
          )}
          <div className="col-12 pt-5 pb-4">
            <hr />
          </div>
          <div className="col-lg-8">
            <div className="w-100">
              <label className="w-100">
                Curso destinatário para quais produtos?
              </label>
              <Select
                name="products"
                options={products}
                multiSelect
                className="mt-3 input-select products"
                onChange={(options) =>
                  handleChangeProductsSelected(options as unknown as IOption[])
                }
                onChangeText={handleChangeProductText}
                optionsSelected={productsSelected}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="w-100">
              <label className="w-100">Status</label>
              <Select
                name="status"
                options={status}
                className="mt-3 input-select"
                onChange={(options) =>
                  handleChangeStatus(options as unknown as IOption)
                }
              />
            </div>
            {showButtonCalendar && (
              <div className="w-100">
                <button
                  type="button"
                  className="border-0 bg-transparent mt-3 px-3 d-flex align-items-center"
                  onClick={() => setShowCalendar(true)}
                >
                  <BsCalendarEvent size={24} color="#fff" className="me-3" />{' '}
                  <span className="d-flex flex-column align-items-start">
                    <span className="text-decoration-underline">
                      Data de lançamento{releaseDate && `:`}
                    </span>
                    {releaseDate && (
                      <b className="w-100 text-decoration-underline mt-1">
                        {releaseDate}
                      </b>
                    )}
                  </span>
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <label className="w-100 mt-4">
              <InputToggle
                name="sell"
                options={[
                  {
                    id: 'vender',
                    value: 'Vender curso a parte',
                  },
                ]}
                checkedValues={sell ? ['vender'] : undefined}
                className="mt-3 text-gray"
                onChange={handleChangeSell}
              />
            </label>
          </div>
          {sell && (
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <label className="w-100 mt-4">
                    Valor do curso{' '}
                    <InputMask
                      kind="money"
                      name="course_price"
                      className="mt-3 input"
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <div className="w-100 mt-4">
                    <label>Curso em promoção?</label>
                    <div className="d-flex text-center mt-3">
                      <label
                        className={`btn w-100 mx-1 btn-radio ${
                          hasCourseDiscount ? 'btn-gray' : 'btn-dark-3'
                        }`}
                        htmlFor="has_course_discount_yes"
                      >
                        <span className="d-block py-2">YES</span>
                        <Input
                          type="radio"
                          onChange={() => handleChangeHasCourseDiscount(true)}
                          name="has_course_discount"
                          id="has_course_discount_yes"
                          className="d-none"
                          value="yes"
                          checked={hasCourseDiscount}
                        />
                      </label>
                      <label
                        className={`btn w-100 mx-1 btn-radio ${
                          !hasCourseDiscount ? 'btn-gray' : 'btn-dark-3'
                        }`}
                        htmlFor="has_course_discount_no"
                      >
                        <span className="d-block py-2">NO</span>
                        <Input
                          type="radio"
                          onChange={() => handleChangeHasCourseDiscount(false)}
                          name="has_course_discount"
                          id="has_course_discount_no"
                          className="d-none"
                          value="no"
                          checked={!hasCourseDiscount}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {hasCourseDiscount && (
                  <div className="col-lg-6">
                    <label className="w-100 mt-4">
                      Valor do curso na promoção{' '}
                      <InputMask
                        kind="money"
                        name="course_discount"
                        className="mt-3 input"
                      />
                    </label>
                  </div>
                )}
                <div className="col-lg-6">
                  <label className="w-100 mt-4">
                    Link de pagamento do curso
                    <Input
                      type="url"
                      name="course_checkout_url"
                      className="mt-3 input"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary py-2 fw-bold mt-5 px-5 ms-auto d-block"
            >
              Próximo
            </button>
          </div>
        </Form>
      </Container>
      <Modal show={showCalendar} onHide={handleClose} close>
        <Form onSubmit={handleSave}>
          <Modal.Header className="border-0 ps-4 pt-4" closeButton>
            <h4>Data de lançamento</h4>
          </Modal.Header>
          <Modal.Body className="mb-4">
            <Calendar className="mx-auto">
              <DayPicker
                months={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                weekdaysShort={[
                  'Dom',
                  'Seg',
                  'Ter',
                  'Qua',
                  'Qui',
                  'Sex',
                  'Sab',
                ]}
                weekdaysLong={[
                  'Domingo',
                  'Segunda-feira',
                  'Terça-feira',
                  'Quarta-feira',
                  'Quinta-feira',
                  'Sexta-feira',
                  'Sábado',
                ]}
                showOutsideDays
                fromMonth={new Date()}
                initialMonth={selectedDate}
                selectedDays={selectedDate}
                modifiers={{
                  available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                }}
                onDayClick={handleDateChange}
                disabledDays={{ before: addDays(new Date(), 1) }}
              />
            </Calendar>
            <label className="w-100 mt-4 px-4">
              Hora do lançamento{' '}
              <InputMask
                kind="custom"
                options={{
                  mask: '99:99:99',
                }}
                placeholder="00:00:00"
                name="time"
                className="mt-3"
                onChange={handleChangeTime}
                value={time}
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary btn-save mx-auto">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CoursesUpdate;
