import styled from 'styled-components';
import List from 'react-chatview';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBanner {
  src: string;
}

export const Container = styled(List)`
  position: relative;
  height: calc(100vh - 61px);

  img {
    width: 70px;
    height: 70px;
  }

  .description {
    line-height: 33.5px;
  }
`;

export const Banner = styled.div<IBanner>`
  position: absolute;
  background: linear-gradient(2.91deg, #202020 10.56%, rgba(1, 1, 1, 0) 95.75%),
    linear-gradient(0deg, rgba(32, 32, 32, 0.4), rgba(32, 32, 32, 0.4)),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  z-index: -1;
`;

export const SubCategory = styled.div`
  border-radius: 20px;

  .btn-edit {
    width: 27px;
    height: 27px;
    border-radius: 50%;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-submit {
      height: 50px;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #3a3a3a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonDown = styled.button`
  background: none;
  border: none;
  div {
    background: rgba(32, 32, 32, 0.9);
    border: 1px solid #bbbbbb;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  svg {
    width: 20px;
    height: 20px;
    color: #bbbbbb;
  }
  ::before {
    display: none;
  }
`;
