import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { IoIosArrowDown } from 'react-icons/io';
import { RiEditFill } from 'react-icons/ri';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import Textarea from '../Textarea';

interface IFaq {
  id?: string;
  question: string;
  answer: string;
  edited: boolean;
  status: string;
  active?: boolean;
}

interface IQuestionBoxProps {
  questionData: IFaq;
  onLoading(data: boolean): void;
  onClickQuestion?(data: any): void;
  onChangeQuestion?(data: IFaq, index?: number): void;
  indexQuestion?: number;
  edit?: boolean;
  onClickDelete?(): void;
}

const QuestionBox: React.FC<IQuestionBoxProps> = ({
  questionData,
  onClickQuestion,
  onChangeQuestion,
  onLoading,
  indexQuestion,
  edit,
  onClickDelete,
}) => {
  const formRef = useRef<FormHandles>(null);
  const boxRef = useRef<any>(null);
  const [inEdit, setInEdit] = useState(edit || false);

  const handleEditQuestion = useCallback(() => {
    if (boxRef.current) {
      const filterFlip = boxRef.current;

      if (filterFlip) {
        filterFlip.querySelector('.front').classList.add('flip');
      }
      setTimeout(() => {
        setInEdit(true);
        if (filterFlip) {
          try {
            filterFlip.querySelector('.back').classList.add('flip');
          } catch (error) {
            filterFlip.querySelector('.front').classList.remove('flip');
          }
        }
      }, 350);
    }
  }, []);

  const handleCloseQuestion = useCallback(() => {
    if (boxRef.current) {
      const filterFlip = boxRef.current;

      if (filterFlip) {
        filterFlip.querySelector('.back').classList.remove('flip');
      }

      setTimeout(() => {
        setInEdit(false);
        if (filterFlip) {
          try {
            filterFlip.querySelector('.front').classList.remove('flip');
          } catch (error) {
            filterFlip.querySelector('.back').classList.add('flip');
          }
        }
      }, 350);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFaq) => {
      onLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          question: Yup.string().required('Question is a required'),
          answer: Yup.string().required('Answer is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { question, answer } = data;

        // const formData = { question, answer };

        // const response = await api.put(`faq/${data.id}`, formData);

        if (onChangeQuestion) {
          const dataQuestion: IFaq = {
            id: data.id,
            question,
            answer,
            edited: false,
            status: 'old',
            active: true,
          };
          onChangeQuestion(dataQuestion, indexQuestion);
        }

        handleCloseQuestion();
        Toast.fire({
          icon: 'success',
          title: 'Pergunta editada!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      } finally {
        onLoading(false);
      }
    },
    [handleCloseQuestion, indexQuestion, onChangeQuestion, onLoading]
  );

  return (
    <Container className="col-md-6 mt-2 mt-lg-4">
      <div className="p-2" ref={boxRef}>
        {inEdit ? (
          <div
            className={`${
              questionData.status === 'edit' ? 'flip' : ''
            } question-box active back`}
          >
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={questionData}
            >
              <div className="py-2 px-3 question w-100 border-0">
                <div className="d-flex justify-content-between align-items-center px-3">
                  <p className="mb-0 fw-semibold">Editar pergunta</p>
                  <button
                    className="btn btn-primary px-4 mr-3 py-1"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="answer d-flex align-items-center">
                <div className="w-100 d-flex justify-content-center">
                  <div className="pb-3 w-75">
                    <Input name="id" type="hidden" className="d-none" />
                    <Input
                      name="status"
                      type="hidden"
                      className="d-none"
                      value={questionData.status}
                    />
                    <label className="w-100">
                      <span>Pergunta</span>
                      <Input name="question" className="mt-2" />
                    </label>
                    <label className="w-100 mt-4">
                      <span>Resposta</span>
                      <Textarea name="answer" className="mt-2" />
                    </label>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <>
            <div
              className={`${
                questionData.status === 'edit' ? 'flip' : ''
              } question-box front ${questionData.active ? 'active' : ''}`}
            >
              <div className="d-flex justify-content-between align-items-center py-2 px-3 question w-100 border-0">
                <button
                  type="button"
                  className="d-flex text-left justify-content-between align-items-center py-1 px-3 question w-100 border-0"
                  onClick={onClickQuestion}
                >
                  <span className="fw-semibold">{questionData.question}</span>
                  <IoIosArrowDown size={28} color="#BBBBBB" />
                </button>
              </div>
              <div className="answer px-4 p-relative">
                <p className="px-2">{questionData.answer}</p>
                <button
                  type="button"
                  className="btn-delete border-0 bg-transparent position-absolute p-2"
                  onClick={onClickDelete}
                >
                  <MdDelete size={24} color="#FF333D" />
                </button>
                <button
                  type="button"
                  className="btn btn-white btn-edit rounded-circle position-absolute p-2"
                  onClick={handleEditQuestion}
                >
                  <RiEditFill size={24} color="#3A3A3A" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default QuestionBox;
