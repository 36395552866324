import React, { useCallback, useEffect, useState } from 'react';
import { IoMdCalendar } from 'react-icons/io';
import Swal from 'sweetalert2';

import { Container } from './styled';
import Course from '~/components/Course';

import api from '~/services/api';

interface ICourse {
  id: string;
  title: string;
  thumbnail: string;
  slug: string;
  release_date?: string;
}

interface ICourseData {
  current_page: number;
  last_page: number;
  data: ICourse[];
}

interface IScheduledProps {
  onReady(onLoad: () => Promise<void>): void;
  search?: string;
}

const Scheduled: React.FC<IScheduledProps> = ({ onReady, search }) => {
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadCourses = useCallback(
    async (pageData: number, searchData?: string) => {
      const response = await api.get<ICourseData>('courses', {
        params: {
          page: pageData,
          status: 'Scheduled',
          all: true,
          search: searchData,
        },
      });

      if (pageData === 1) {
        setCourses(response.data.data);
      } else {
        setCourses((state) => [...state, ...response.data.data]);
      }
      setLastPage(response.data.last_page);
    },
    []
  );

  useEffect(() => {
    loadCourses(1, search);
  }, [loadCourses, search]);

  const onLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadCourses(page + 1, search);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadCourses, page, search]);

  useEffect(() => {
    onReady(onLoad);
  }, [onLoad, onReady]);

  return (
    <Container className="row">
      {courses.map((course) => (
        <div key={course.id} className="col-lg-4">
          <Course
            to={`${process.env.PUBLIC_URL}/cursos/${course.slug}`}
            thumb={course.thumbnail}
            title={course.title}
            icon={IoMdCalendar}
            releaseDate={course.release_date}
          />
        </div>
      ))}
    </Container>
  );
};

export default Scheduled;
