import styled, { css } from 'styled-components';

interface IPhotoContainer {
  src?: string;
}

export const Container = styled.div`
  > div {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .btn-remove-photo {
      position: absolute;
      bottom: 0px;
      left: 20px;
      color: #fc5d4a;
    }
  }
`;

export const PhotoContainer = styled.div<IPhotoContainer>`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  > label {
    width: 100%;
  }

  ${(props) =>
    props.src
      ? css`
          position: relative;
          border: 2px dashed #d9d9d9;
          box-sizing: border-box;
          height: 100px;
          border-radius: 10px;
          background: url(${props.src}), #fff;
          background-position: center;
          background-size: 110%;
          background-repeat: no-repeat;
        `
      : css`
          display: flex;
        `}
`;

export const UploadPhoto = styled.div`
  position: relative;
  box-sizing: border-box;

  .camera {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      padding: 0 10px;
      font-weight: normal;
      width: 100%;
      color: #d9d9d9;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    height: 250px;

    .camera {
      right: 0;
      bottom: 0;
    }
  }
`;

export const CropContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;

  div {
    height: 100%;

    img {
      min-height: 110px;
      min-width: 110px;
    }
  }
`;
