/* eslint-disable prefer-destructuring */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { MdDelete, MdPlayLesson } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { GridContextProvider, GridItem, move, swap } from 'react-grid-dnd';

import { RiEditFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '~/services/api';

import {
  Container,
  Steps,
  GridDropZone,
  Module,
  Lesson,
  Modal,
} from './styles';
import Input from '~/components/Input';
import Toast from '~/utils/toast';
import Search from '~/components/Search';

interface ILesson {
  id: string;
  module_id: string;
  video_id: string;
  status_id: string;
  title: string;
  description: string;
  duration: string;
  slug: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IModule {
  id: string;
  title: string;
  slug: string;
  lessons: ILesson[];
}

interface IModuleData {
  current_page: number;
  last_page: number;
  data: IModule[];
}

interface IParams {
  slug: string;
}

const Lessons: React.FC = () => {
  const params = useParams<IParams>();
  const [modules, setModules] = useState<IModule[]>([]);
  const [lessons, setLessons] = useState<ILesson[][]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(0);
  const [showPerRow, setShowPerRow] = useState(1);
  const [isGrabing, setIsGrabing] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [searchData, setSearchData] = useState('');

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    if (width >= 992) {
      setShowPerRow(4);
    } else {
      setShowPerRow(1);
    }
  }, [width]);

  const loadModules = useCallback(
    async (course_id: string, pageData: number, search = '') => {
      const response = await api.get<IModuleData>(
        `modules/courses/${course_id}`,
        {
          params: {
            page: pageData,
            searchLesson: search,
          },
        }
      );

      if (pageData > 1) {
        setModules((state) => [...state, ...response.data.data]);
      } else {
        setModules(response.data.data);
      }
      setLastPage(response.data.last_page);
    },
    []
  );

  useEffect(() => {
    const lessonsArr = modules.map((module) => module.lessons);
    setLessons((state) => {
      let stateData = state;
      if (typeof stateData === 'object') {
        stateData = Object.values(stateData);
      }
      if (page === 1) {
        return lessonsArr;
      }
      if (JSON.stringify(stateData) !== JSON.stringify(lessonsArr)) {
        return [...stateData, ...lessonsArr];
      }
      return [];
    });
  }, [modules, page]);

  useEffect(() => {
    api.get(`courses/${params.slug}`).then((response) => {
      loadModules(response.data.id, 1);
      setCourseId(response.data.id);
    });
  }, [loadModules, params.slug]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadModules(courseId, page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [courseId, lastPage, loadModules, page]);

  const handleClickCreateModule = useCallback(() => {
    setShow(true);
  }, []);

  const handleMove = useCallback(
    (
      lessonsSource: ILesson[],
      lessonsTarget: ILesson[],
      moduleIdSource: string,
      moduleIdTarget: string
    ) => {
      lessonsSource.forEach(async (lesson, index) => {
        const formData = {
          module_id: moduleIdSource,
          video_id: lesson.video_id,
          status_id: lesson.status_id,
          title: lesson.title,
          description: lesson.description,
          duration: lesson.duration,
          order: index + 1,
        };
        await api.put(`lessons/${lesson.id}`, formData);
      });
      lessonsTarget.forEach(async (lesson, index) => {
        const formData = {
          module_id: moduleIdTarget,
          video_id: lesson.video_id,
          status_id: lesson.status_id,
          title: lesson.title,
          description: lesson.description,
          duration: lesson.duration,
          order: index + 1,
        };
        await api.put(`lessons/${lesson.id}`, formData);
      });
    },
    []
  );

  const handleSwap = useCallback((lessonsSource: ILesson[]) => {
    lessonsSource.forEach(async (lesson, index) => {
      const formData = {
        module_id: lesson.module_id,
        video_id: lesson.video_id,
        status_id: lesson.status_id,
        title: lesson.title,
        description: lesson.description,
        duration: lesson.duration,
        order: index + 1,
      };
      await api.put(`lessons/${lesson.id}`, formData);
    });
  }, []);

  const handleChange = useCallback(
    (sourceIdData, sourceIndex, targetIndex, targetIdData) => {
      const [moduleIdSource, sourceId] = sourceIdData.split('|');
      if (targetIdData) {
        const [moduleIdTarget, targetId] = targetIdData.split('|');
        const [lessonsSource, lessonsTarget] = move(
          lessons[sourceId],
          lessons[targetId],
          sourceIndex - 1,
          targetIndex - 1
        );

        handleMove(
          lessonsSource,
          lessonsTarget,
          moduleIdSource,
          moduleIdTarget
        );

        return setLessons({
          ...lessons,
          [sourceId]: lessonsSource,
          [targetId]: lessonsTarget,
        });
      }

      const result = swap(lessons[sourceId], sourceIndex - 1, targetIndex - 1);

      const lessonsData = result;
      handleSwap(lessonsData);

      return setLessons({
        ...lessons,
        [sourceId]: lessonsData,
      });
    },
    [handleMove, handleSwap, lessons]
  );

  const handleClickDelete = useCallback(
    (module_id: string, lesson_id: string) => {
      Swal.fire({
        title: 'Deseja deletar essa aula?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`lessons/${lesson_id}`);

            const newModules = modules.slice();
            const moduleIndex = newModules.findIndex(
              (module) => module.id === module_id
            );
            if (moduleIndex >= 0) {
              const newLessonsModule = newModules[moduleIndex].lessons.filter(
                (lesson) => lesson.id !== lesson_id
              );

              let lessonsData = lessons as ILesson[][];
              if (typeof lessonsData === 'object') {
                lessonsData = Object.values(lessonsData);
              }

              const newLessonsData = lessonsData.map(
                (lessonsGroup: ILesson[]) => {
                  const newLessons = lessonsGroup.filter(
                    (lesson) => lesson.id !== lesson_id
                  );
                  return newLessons;
                }
              );
              newModules[moduleIndex].lessons = newLessonsModule;
              setLessons(newLessonsData);
              setModules(newModules);
            }

            Toast.fire({
              icon: 'success',
              title: 'Aula deletada!',
            });
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [modules, lessons]
  );

  const handleGrab = useCallback(() => {
    setIsGrabing(true);
  }, []);

  const handleDrop = useCallback(() => {
    setIsGrabing(false);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      setSearchData(value);
      loadModules(courseId, 1, value);
    },
    [courseId, loadModules]
  );

  return (
    <>
      <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
        <div className="container py-5">
          <div className="box bg-dark-2 p-5">
            <div className="col-12">
              <div className="row align-items-center justify-content-end pb-5">
                <div className="col-12 mb-4">
                  <h1 className="fw-light text-center">
                    Atualizar <b className="fw-bold">curso</b>
                  </h1>
                  <Search
                    onSearch={handleSearch}
                    className="search w-lg-25 mt-3 mx-auto"
                    placeholder="Buscar Aulas"
                  />
                </div>
                <div className="col-12">
                  <div className="row justify-content-center my-5">
                    <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                      <Steps className="d-flex ml-auto align-items-center min-width">
                        <Link
                          to={`${process.env.PUBLIC_URL}/cursos/${params.slug}`}
                          className="w-100 text-center bg-dark-3 rounded-pill"
                        >
                          <span className="d-block px-3 py-3 text-gray">
                            Sobre do curso
                          </span>
                        </Link>
                        <hr className="w-25 w-md-50 border-gray mx-2" />
                        <Link
                          to={`${process.env.PUBLIC_URL}/cursos/${params.slug}/modulos`}
                          className="w-100 text-center bg-dark-3 rounded-pill"
                        >
                          <span className="d-block px-3 py-3 text-gray">
                            Módulos
                          </span>
                        </Link>
                        <hr className="w-25 w-md-50 border-gray mx-2" />
                        <div className="w-100 text-center bg-gray rounded-pill">
                          <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                            Aulas
                          </span>
                        </div>
                      </Steps>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 modules-dnd">
                  <GridContextProvider onChange={handleChange}>
                    {modules.map((module, index) => (
                      <Fragment key={module.id}>
                        {(module.lessons.length > 0 || !searchData) && (
                          <>
                            <h2 className="h4 mb-3">Modulo: {module.title}</h2>
                            <Module className="p-3 mb-5">
                              <GridDropZone
                                id={`${module.id}|${index}`}
                                boxesPerRow={showPerRow}
                                rowHeight={200}
                                height={
                                  Object.keys(lessons).length > 0 ||
                                  lessons.length > 0
                                    ? Math.ceil(
                                        (lessons[index].length + 1) / 4
                                      ) * 200
                                    : 200
                                }
                              >
                                <GridItem
                                  className="p-2 bg-transparent"
                                  aria-disabled
                                >
                                  <Lesson>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/cursos/${params.slug}/${module.slug}/aulas/cadastrar`}
                                      className="add-lesson d-flex align-items-center justify-content-center border-0 btn-dark-4 px-4 py-3 w-100 h-100"
                                      onClick={handleClickCreateModule}
                                    >
                                      <BsPlus size={24} color="#BBBBBB" />
                                      <span className="text-gray fw-bold ms-2">
                                        Adicionar aula
                                      </span>
                                    </Link>
                                  </Lesson>
                                </GridItem>
                                {(Object.keys(lessons).length > 0 ||
                                  lessons.length > 0) &&
                                  lessons[index].map((lesson) => (
                                    <GridItem
                                      key={lesson.id}
                                      className={`p-2 bg-transparent ${
                                        isGrabing
                                          ? 'cursor-grabbing'
                                          : 'cursor-grab'
                                      }`}
                                    >
                                      <Lesson
                                        onMouseDown={handleGrab}
                                        onMouseUp={handleDrop}
                                      >
                                        <div className="d-flex position-absolute btn-group align-items-center justify-content-between w-100 p-2">
                                          <button
                                            type="button"
                                            className="bg-transparent border-0 d-flex align-items-center justify-content-center"
                                            onClick={() =>
                                              handleClickDelete(
                                                module.id,
                                                lesson.id
                                              )
                                            }
                                          >
                                            <MdDelete
                                              size={21}
                                              color="#FF333D"
                                            />
                                          </button>
                                          <Link
                                            to={`${process.env.PUBLIC_URL}/cursos/${params.slug}/${module.slug}/aulas/${lesson.slug}`}
                                            className="bg-white border-0 rounded-circle d-flex align-items-center justify-content-center"
                                          >
                                            <RiEditFill
                                              size={13}
                                              color="#3A3A3A"
                                            />
                                          </Link>
                                        </div>
                                        <div className="d-flex flex-column align-items-center bg-dark-2 text-white text-center py-4 px-2 w-100 h-100">
                                          <MdPlayLesson
                                            size={50}
                                            color="#bbbbbb"
                                            className="mt-3 mb-4"
                                          />
                                          <span className="text-gray">
                                            {lesson.title}
                                          </span>
                                        </div>
                                      </Lesson>
                                    </GridItem>
                                  ))}
                              </GridDropZone>
                            </Module>
                          </>
                        )}
                      </Fragment>
                    ))}
                  </GridContextProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Lessons;
