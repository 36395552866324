import React, { useCallback, useEffect, useState, Fragment } from 'react';
import Input from '~/components/Input';
import { IoIosArrowForward } from 'react-icons/io';
import { BsPlus } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import {
  Container,
  Question,
  QuestionButton,
  Modal,
  InputRadio,
} from './styles';

import Textarea from '../Textarea';

export interface IOption {
  id?: string;
  answer: string;
  correct_answer: boolean;
  status?: 'NEW' | 'UPDATED' | 'DELETED';
}

export interface IQuestion {
  id?: string;
  question: string;
  options: IOption[];
  status?: 'NEW' | 'UPDATED' | 'DELETED';
}

interface QuizProps {
  title: string;
  onChange?(questions: IQuestion[], attempts: number): void;
  hasValidateError?: boolean;
  required?: boolean;
  dataQuestions?: IQuestion[];
  dataAttempts?: number;
  setHasAnswers?(data: boolean): void;
}

const Quiz: React.FC<QuizProps> = ({
  title,
  onChange,
  hasValidateError,
  required,
  dataQuestions,
  dataAttempts,
  setHasAnswers,
}) => {
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>([
    {
      question: '',
      options: [
        {
          answer: 'true',
          correct_answer: true,
          status: 'NEW',
        },
      ],
      status: 'NEW',
      id: new Date().getTime().toString(),
    },
  ]);
  const [error, setError] = useState('');
  const [answersHasBeenAdd, setAnswersHasBeenAdd] = useState(false);
  const [questionSelectedIndex, setQuestionSelectedIndex] = useState(0);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (dataQuestions && dataQuestions.length > 0) {
      setQuestions(dataQuestions);
      const checkQuestion = dataQuestions.find(
        (dataQuestion) =>
          dataQuestion.status !== 'DELETED' && dataQuestion.question.length > 0
      );
      if (checkQuestion) {
        setAnswersHasBeenAdd(true);
      }
    }
  }, [dataQuestions]);

  useEffect(() => {
    if (dataAttempts) {
      setAttempts(dataAttempts);
    }
  }, [dataAttempts]);

  useEffect(() => {
    if (onChange) {
      onChange(questions, attempts);
    }
  }, [questions, onChange, attempts]);

  useEffect(() => {
    if (setHasAnswers) {
      setHasAnswers(answersHasBeenAdd);
    }
  }, [answersHasBeenAdd, setHasAnswers]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const handleAddMoreQuestions = useCallback(() => {
    setQuestions((state) => [
      ...state,
      {
        question: '',
        options: [
          {
            answer: '',
            correct_answer: true,
            status: 'NEW',
          },
        ],
        status: 'NEW',
        id: new Date().getTime().toString(),
      },
    ]);
  }, []);

  const handleAddMoreAnswers = useCallback(
    (question) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const newQuestions = questions.slice();
        newQuestions[questionIndex].options.push({
          answer: '',
          correct_answer: false,
          status: 'NEW',
        });
        if (
          newQuestions[questionIndex].status &&
          newQuestions[questionIndex].status !== 'NEW'
        ) {
          newQuestions[questionIndex].status = 'UPDATED';
        }
        setQuestions(newQuestions);
      }
    },
    [questions]
  );

  const handleRemoveQuestions = useCallback(
    (question: IQuestion, indexQuestion: number) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData.id === question.id
      );
      if (questionIndex >= 0) {
        const newQuestions = questions.slice();
        newQuestions[questionIndex].status = 'DELETED';
        const checkQuestions = newQuestions.find(
          (questionData) => questionData.status !== 'DELETED'
        );
        if (!checkQuestions) {
          const newQuestion: IQuestion = {
            question: '',
            options: [
              {
                answer: '',
                correct_answer: true,
                status: 'NEW',
              },
            ],
            status: 'NEW',
          };
          newQuestions.push(newQuestion);
          setAnswersHasBeenAdd(false);
        }

        if (indexQuestion !== 0) {
          setQuestionSelectedIndex(indexQuestion - 1);
        }

        setQuestions(newQuestions);
      }
    },
    [questions]
  );

  const handleRemoveAnswers = useCallback(
    (question, option) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        if (questions[questionIndex].options.length > 1) {
          const optionIndex = questions[questionIndex].options.findIndex(
            (optionData) => optionData === option
          );
          if (optionIndex >= 0) {
            const newQuestions = questions.slice();
            newQuestions[questionIndex].status = 'UPDATED';
            newQuestions[questionIndex].options[optionIndex].status = 'DELETED';

            const checkOptions = newQuestions[questionIndex].options.find(
              (optionData) => optionData.status !== 'DELETED'
            );

            if (!checkOptions) {
              const newOption: IOption = {
                answer: '',
                correct_answer: true,
                status: 'NEW',
              };

              newQuestions[questionIndex].options.push(newOption);
            }

            setQuestions(newQuestions);
          }
        }
      }
    },
    [questions]
  );

  const handleChangeQuestion = useCallback(
    (e, question) => {
      const data = e.target.value;

      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const newQuestions = questions.slice();
        newQuestions[questionIndex].question = data;
        if (newQuestions[questionIndex].status !== 'NEW') {
          newQuestions[questionIndex].status = 'UPDATED';
        }
        setQuestions(newQuestions);
      }
    },
    [questions]
  );

  const handleChangeOption = useCallback(
    (e, question, option) => {
      const data = e.target.value;

      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const optionIndex = questions[questionIndex].options.findIndex(
          (optionData) => optionData === option
        );
        if (optionIndex >= 0) {
          const newQuestions = questions.slice();
          newQuestions[questionIndex].options[optionIndex].answer = data;
          if (
            newQuestions[questionIndex].options[optionIndex].status !== 'NEW'
          ) {
            newQuestions[questionIndex].options[optionIndex].status = 'UPDATED';
          }
          if (newQuestions[questionIndex].status !== 'NEW') {
            newQuestions[questionIndex].status = 'UPDATED';
          }
          setQuestions(newQuestions);
        }
      }
    },
    [questions]
  );

  const handleChangeAnswersCorrect = useCallback(
    (question, option) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const optionIndex = questions[questionIndex].options.findIndex(
          (optionData) => optionData === option
        );
        if (optionIndex >= 0) {
          const newQuestions = questions.slice();
          newQuestions[questionIndex].options.forEach(
            (otherOption: IOption) => {
              if (otherOption.correct_answer) {
                // eslint-disable-next-line no-param-reassign
                otherOption.status = 'UPDATED';
              }

              // eslint-disable-next-line no-param-reassign
              otherOption.correct_answer = false;
            }
          );
          newQuestions[questionIndex].options[optionIndex].correct_answer =
            true;
          if (
            newQuestions[questionIndex].options[optionIndex].status !== 'NEW'
          ) {
            newQuestions[questionIndex].options[optionIndex].status = 'UPDATED';
          }
          if (newQuestions[questionIndex].status !== 'NEW') {
            newQuestions[questionIndex].status = 'UPDATED';
          }
          setQuestions(newQuestions);
        }
      }
    },
    [questions]
  );

  const handleDone = useCallback(() => {
    setError('');

    let hasError = false;
    let hasErrorAux = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const question of questions) {
      if (!question.question) {
        setError('Fill in all the fields or remove the blanks');
        hasError = true;
        hasErrorAux = true;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const option of question.options) {
        if (!option.answer) {
          setError('Fill in all the fields or remove the blanks');
          hasError = true;
          hasErrorAux = true;
        }
      }
    }

    if (!required) {
      setError('');
      hasError = false;
    }

    if (!hasError) {
      if (!hasErrorAux) {
        setAnswersHasBeenAdd(true);
      }
      handleClose();
    } else {
      setAnswersHasBeenAdd(false);
    }
  }, [handleClose, questions, required]);

  const handleChangeattempts = useCallback((e) => {
    if (e.target.value) {
      setAttempts(parseInt(e.target.value, 10));
    }
  }, []);

  return (
    <Container
      hasValidateError={hasValidateError}
      className="order-2 order-lg-1 col-12"
    >
      <div className="mt-3">
        <button
          type="button"
          className="d-flex align-items-center justify-content-between w-100 py-2 px-4 btn btn-dark-3"
          onClick={handleShow}
        >
          {answersHasBeenAdd ? 'Editar perguntas' : 'Adicionar perguntas'}
          <IoIosArrowForward size={24} color="#BBBBBB" />
        </button>
      </div>
      <Input
        name="quiz"
        value={answersHasBeenAdd ? 'answersHasBeenAdd' : ''}
        className="d-none order-2 order-lg-1"
      />
      <Modal className="modal-Quiz" size="lg" show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="ml-auto">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column flex-md-row mb-5">
            <div className="questions-group overflow-auto me-2">
              <div className="d-flex">
                {questions
                  .filter((question) => question.status !== 'DELETED')
                  .map((_, index) => (
                    <QuestionButton
                      key={index.toString()}
                      type="button"
                      active={questionSelectedIndex === index}
                      onClick={() => setQuestionSelectedIndex(index)}
                    >
                      {(index + 1).toString().padStart(2, '0')}
                    </QuestionButton>
                  ))}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-dark-3 btn-add"
              onClick={handleAddMoreQuestions}
            >
              <BsPlus size={24} color="#BBBBBB" />
              Adicionar pergunta
            </button>
          </div>
          {questions
            .filter((question) => question.status !== 'DELETED')
            .map((question, indexQuestion) => (
              <Question
                key={indexQuestion.toString()}
                className={`questions ${
                  questionSelectedIndex === indexQuestion ? 'd-block' : 'd-none'
                }`}
              >
                <div className="mb-5">
                  <div className="d-flex justify-content-between mb-3">
                    <label
                      htmlFor={`question_${indexQuestion}`}
                      className="small"
                    >
                      Questão
                    </label>
                    <button
                      type="button"
                      onClick={() =>
                        handleRemoveQuestions(question, indexQuestion)
                      }
                      className="border-0 bg-transparent text-error"
                    >
                      Deletar questão{' '}
                      <MdDelete size={20} color="#FF333D" className="ms-2" />
                    </button>
                  </div>
                  <Textarea
                    id={`question_${indexQuestion}`}
                    name={`question_${indexQuestion}`}
                    onChange={(e) => handleChangeQuestion(e, question)}
                    value={question.question}
                  />
                </div>
                <div className="mb-3">
                  <p>Respostas</p>
                  {question.options.map((option, indexOption) => (
                    <Fragment key={indexOption.toString()}>
                      {option.status !== 'DELETED' && (
                        <div className="row align-items-center answer">
                          <div className="col-lg-9 d-flex align-items-center">
                            <InputRadio
                              htmlFor={`answers_correct_${indexQuestion}_${indexOption}`}
                              checked={option.correct_answer}
                              className="mb-0 me-0"
                            >
                              <Input
                                type="radio"
                                id={`answers_correct_${indexQuestion}_${indexOption}`}
                                name={`answers_correct_${indexQuestion}`}
                                className="checkbox mr-1"
                                onChange={() =>
                                  handleChangeAnswersCorrect(question, option)
                                }
                                checked={option.correct_answer}
                              />
                            </InputRadio>
                            <Input
                              id={`answers_${indexQuestion}_${indexOption}`}
                              placeholder="Insira uma resposta"
                              name={`answers_${indexQuestion}_${indexOption}`}
                              onChange={(e) =>
                                handleChangeOption(e, question, option)
                              }
                              className="border-0 bg-transparent input-answers"
                              value={option.answer}
                            />
                          </div>
                          <div className="col-lg-3 pl-0 d-flex align-items-center">
                            <button
                              type="button"
                              onClick={() =>
                                handleRemoveAnswers(question, option)
                              }
                              className="border-0 bg-transparent me-3"
                            >
                              <MdDelete size={20} color="#FF333D" />
                            </button>
                            {option.correct_answer && (
                              <label
                                htmlFor={`answers_correct_${indexQuestion}_${indexOption}`}
                                className="small mb-0 ml-2 text-gray"
                              >
                                Correct Answer
                              </label>
                            )}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                  <button
                    type="button"
                    onClick={() => handleAddMoreAnswers(question)}
                    className="mt-3 ms-n1 border-0 bg-transparent text-gray"
                  >
                    <BsPlus size={24} color="#bbbbbb" className="mr-1" />
                    <span className="px-2 py-1 small">Adicionar resposta</span>
                  </button>
                </div>
              </Question>
            ))}
          <label className="small mt-4">
            Número de tentativas
            <Input
              type="number"
              name="attempts"
              className="mt-3 mb-1"
              placeholder="0"
              min={0}
              onChange={handleChangeattempts}
              value={attempts}
            />
            <span className="small text-gray">
              Se tiver zero serão infinitas tentativas.
            </span>
          </label>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div
            className={`row w-100 ${
              error ? 'justify-content-between' : 'justify-content-end'
            }`}
          >
            {error && (
              <div className="alert-danger d-flex align-items-center px-2 py-1 rounded">
                <span className="mb-0">{error}</span>
              </div>
            )}
            <div className="col-lg-6 text-end">
              <button
                type="button"
                onClick={handleDone}
                className="mt-2 btn btn-primary px-5 py-2 fw-bold"
              >
                <span className="py-1 px-2">Salvar</span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Quiz;
