import styled from 'styled-components';

export const Container = styled.div`
  .box {
    border-radius: 20px;

    .text-gray label span {
      color: #bbb;
    }

    .input,
    .btn-radio {
      height: 52px;
    }

    .input-select {
      min-height: 52px;
    }

    .input-select.products {
      > div > div > div.bg-dark-2 {
        background-color: #e50914 !important;

        > span {
          color: #ffffff;
        }

        > button {
          svg {
            color: #ffffff !important;
          }
        }
      }
    }
  }
`;

export const Steps = styled.div`
  hr {
    background-color: #333333;
  }
`;

export const VideoPreview = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  .camera {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      padding: 0 10px;
      font-weight: normal;
      width: 100%;
      color: #d9d9d9;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    height: 250px;

    .camera {
      right: 0;
      bottom: 0;
    }
  }
`;
