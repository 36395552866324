import styled from 'styled-components';

export const Container = styled.div`
  border: 3px solid #444;
  width: 100%;
  aspect-ratio: 2/1;

  /* Reset some default styling */
  .gjs-cv-canvas {
    top: 0;
    width: 100%;
    height: 100%;
  }

  .gjs-block {
  }
`;
