import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';

interface ICategory {
  id: string;
  name: string;
  description: string;
  icon: string;
  wallpaper: {
    id: string;
    wallpaper_url: string;
  };
}

interface IFormData {
  name: string;
  description: string;
}

interface IParams {
  slug: string;
}

const CategoriesCreate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [categoryId, setCategoryId] = useState('');
  const [category, setCategory] = useState({} as IFormData);
  const [iconUrl, setIconUrl] = useState('');
  const [icon, setIcon] = useState<File | undefined>(undefined);
  const [iconError, setIconError] = useState('');
  const [wallpaperId, setWallpaperId] = useState('');
  const [wallpaperUrl, setWallpaperUrl] = useState('');
  const [wallpaper, setWallpaper] = useState<File | undefined>(undefined);
  const [wallpaperError, setWallpaperError] = useState('');

  useEffect(() => {
    api.get<ICategory>(`categories/${params.slug}`).then((response) => {
      setCategoryId(response.data.id);
      setCategory({
        name: response.data.name,
        description: response.data.description,
      });
      setIconUrl(response.data.icon);
      setWallpaperId(response.data.wallpaper.id);
      setWallpaperUrl(response.data.wallpaper.wallpaper_url);
    });
  }, [params.slug]);

  const handleChangeIcon = useCallback((file) => {
    setIconError('');
    setIcon(file);
  }, []);

  const handleRemoveIcon = useCallback(() => {
    setIcon(undefined);
    setIconUrl('');
  }, []);

  const handleChangeWallpaper = useCallback((file) => {
    setWallpaperError('');
    setWallpaper(file);
  }, []);

  const handleRemoveWallpaper = useCallback(() => {
    setWallpaper(undefined);
    setWallpaperUrl('');
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          icon: Yup.string().when('$icon', {
            is: (iconCheck: boolean) => iconCheck,
            then: Yup.string().required('O icone é obrigatório'),
            otherwise: Yup.string(),
          }),
          wallpaper: Yup.string().when('$wallpaper', {
            is: (wallpaperCheck: boolean) => wallpaperCheck,
            then: Yup.string().required('A capa é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            icon: !icon && !iconUrl,
            wallpaper: !wallpaper && !wallpaperUrl,
          },
        });

        let wallpaperIdData = wallpaperId;
        if (wallpaper) {
          const formDataWallpaper = new FormData();
          formDataWallpaper.append('wallpaper', wallpaper as File);
          const responseWallpaper = await api.post(
            'wallpapers',
            formDataWallpaper
          );
          wallpaperIdData = responseWallpaper.data.id;
        }

        const formData = new FormData();
        if (icon) {
          formData.append('icon', icon);
        }
        formData.append('wallpaper_id', wallpaperIdData);
        formData.append('name', data.name);
        formData.append('description', data.description);
        await api.put(`categories/${categoryId}`, formData);

        Toast.fire({
          icon: 'success',
          title: 'Categoria editada!',
        });
        history.push(`${process.env.PUBLIC_URL}/categorias`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.icon) {
            setIconError(errors.icon);
          }

          if (errors.wallpaper) {
            setWallpaperError(errors.wallpaper);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [categoryId, history, icon, iconUrl, wallpaper, wallpaperId, wallpaperUrl]
  );

  return (
    <Container className="container py-5">
      <Form
        ref={formRef}
        initialData={category}
        onSubmit={handleSubmit}
        className="row"
      >
        <div className="col-12 mb-4">
          <h1>Editar categoria</h1>
        </div>
        <div className="col-lg-6">
          <div className="box bg-dark-2 p-5">
            <label className="w-100">
              Nome <Input name="name" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              Descrição <Textarea name="description" className="mt-3" />
            </label>
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="w-100 box bg-dark-2 p-5">
            <span className="h5 mb-3 d-block">Icone</span>
            <InputImage
              name="icon"
              className="mt-4"
              cropImage
              aspect={1}
              cropOptions={
                !icon
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 20.44 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              onChange={handleChangeIcon}
              onRemove={handleRemoveIcon}
              error={iconError}
              value={iconUrl}
            />
          </div>
          <div className="w-100 box bg-dark-2 p-5 mt-4">
            <span className="h5 mb-3 d-block">Capa</span>
            <InputImage
              name="wallpaper"
              className="mt-4"
              cropImage
              aspect={20.44 / 6.4}
              cropOptions={
                !wallpaper
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 6.4 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              onChange={handleChangeWallpaper}
              onRemove={handleRemoveWallpaper}
              error={wallpaperError}
              value={wallpaperUrl}
            />
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default CategoriesCreate;
