import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';
import loading from '~/assets/animations/loading.json';

interface ILoading {
  show: boolean;
  message?: string;
}
const Loading: React.FC<ILoading> = ({ show, message }) => {
  return (
    <Container show={show}>
      <Lottie
        options={{
          animationData: loading,
          autoplay: true,
          loop: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={200}
        width={200}
      />
      <h4 className="text-white tw-semibold">{message || 'Carregando...'}</h4>
    </Container>
  );
};

export default Loading;
