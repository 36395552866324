/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { IoIosArrowDown, IoIosVideocam, IoMdLink } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import YouTube from 'react-youtube';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import {
  Container,
  VideoPreview,
  Infos,
  Tabs,
  TabsButton,
  TabsGroup,
  Tab,
  Downloads,
} from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputVideo from '~/components/InputVideo';
import Textarea from '~/components/Textarea';
import Quiz, { IQuestion, IOption } from '~/components/Quiz';
import InputRadio, { IOption as IOptionRadio } from '~/components/InputRadio';
import { convertSecondsToHours } from '~/utils/convertTime';

interface IAnswer {
  id: string;
  content: string;
  correct: boolean;
}

interface IQuestionResponse {
  id: string;
  content: string;
  answers: IAnswer[];
}

interface IResourceResponse {
  id: string;
  name: string;
}

interface ILesson {
  id: string;
  status_id: string;
  title: string;
  description: string;
  duration: string;
  order: number;
  video: {
    id: string;
    link?: string;
    video_url?: string;
  };
  exam?: {
    id: string;
    attempts: number;
    average: number;
    duration: string;
    release: boolean;
    show_answers: boolean;
    questions: IQuestionResponse[];
  };
  resources: IResourceResponse[];
}

interface IFormData {
  title: string;
  description: string;
  duration: string;
  video_url?: string;
}

interface IParams {
  slug: string;
  moduleSlug: string;
  lessonSlug: string;
}

interface IResource {
  file?: File;
  link?: string;
}

const LessonsUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const history = useHistory();
  const params = useParams<IParams>();
  const [moduleId, setModuleId] = useState('');
  const [addQuiz, setAddQuiz] = useState(false);
  const [tabSelected, setTabSelected] = useState('about');
  const [openTabs, setOpenTabs] = useState(false);
  const [videoType, setVideoType] = useState<IOptionRadio>({
    id: 'upload',
    value: 'Upload',
  });
  const [videoUrl, setVideoUrl] = useState('');
  const [video, setVideo] = useState<File | undefined>(undefined);
  const [videoError, setVideoError] = useState('');
  const [duration, setDuration] = useState('');
  const [link, setLink] = useState('');
  const [oldResources, setOldResources] = useState<IResource[]>([]);
  const [deletedResources, setDeletedResources] = useState<IResource[]>([]);
  const [resources, setResources] = useState<IResource[]>([]);
  const [descriptionError, setDescriptionError] = useState('');
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [lesson, setLesson] = useState({} as ILesson);
  const [lessonFormData, setLessonFormData] = useState({} as IFormData);
  const [lessonTitle, setLessonTitle] = useState('');
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    api.get(`courses/${params.slug}`).then(async (responseCourse) => {
      const responseModule = await api.get(
        `modules/${responseCourse.data.id}/${params.moduleSlug}`
      );
      const response = await api.get<ILesson>(
        `lessons/${responseCourse.data.id}/${responseModule.data.id}/${params.lessonSlug}`
      );

      const formData: IFormData = {
        title: response.data.title,
        description: response.data.description,
        duration: response.data.duration,
        video_url: response.data.video.link
          ? `https://www.youtube.com/embed/${response.data.video.link}`
          : '',
      };

      if (response.data.exam) {
        const questionsData: IQuestion[] = response.data.exam.questions.map(
          (question) => {
            const options: IOption[] = question.answers.map((answer) => ({
              id: answer.id,
              answer: answer.content,
              correct_answer: answer.correct,
            }));

            return {
              id: question.id,
              question: question.content,
              options,
            };
          }
        );

        setQuestions(questionsData);
        setAttempts(response.data.exam.attempts);
      }

      if (response.data.video.link) {
        setVideoUrl(response.data.video.link);
        setVideoType({
          id: 'link',
          value: 'Link',
        });
      } else if (response.data.video.video_url) {
        setVideoType({
          id: 'upload',
          value: 'Upload',
        });
      }

      const resourcesData: IResource[] = response.data.resources.map(
        (resource) => ({
          link: resource.name,
        })
      );

      setLessonTitle(response.data.title);
      setOldResources(resourcesData);
      setLessonFormData(formData);
      setLesson(response.data);
      setAddQuiz(!!response.data.exam);
      setModuleId(responseModule.data.id);
    });
  }, [params.lessonSlug, params.moduleSlug, params.slug]);

  const handleChangeVideo = useCallback((file) => {
    setVideo(file);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (video && videoRef.current) {
        const hours = parseInt(
          (videoRef.current.duration / 3600).toString(),
          10
        );
        const minutes = parseInt(
          ((videoRef.current.duration % 3600) / 60).toString(),
          10
        );
        const seconds = parseInt(
          (videoRef.current.duration % 60).toString(),
          10
        );
        const durationData = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        setDuration(durationData);
      }
    }, 500);
  }, [video]);

  const handleRemoveVideo = useCallback(() => {
    setDuration('');
  }, []);

  const handleChangeVideoUrl = useCallback((e) => {
    const url = e.target.value;
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length >= 10) {
      if (match[7].length === 10) {
        match[7] = `v${match[7]}`;
      }
      setVideoUrl(match[7]);
    }
  }, []);

  const handleChangeVideoType = useCallback(
    (option: IOptionRadio) => {
      setVideoType(option);
      if (lesson.video.link) {
        const videoData = {
          target: {
            value: lesson.video.link,
          },
        };
        handleChangeVideoUrl(videoData);
      } else {
        setVideoUrl('');
      }
    },
    [handleChangeVideoUrl, lesson]
  );

  const handleChangeAddQuiz = useCallback((value) => {
    setAddQuiz(value);
  }, []);

  const handleSelectTab = useCallback((value) => {
    setTabSelected(value);
    setOpenTabs(false);
  }, []);

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  const handleChangeDuration = useCallback((e) => {
    // eslint-disable-next-line prefer-const
    let [hour, minutes, seconds] = e.target.value.split(':');

    if (seconds > 59) {
      seconds = 59;
    } else if (minutes > 59) {
      minutes = 59;
    }

    setDuration(`${hour}:${minutes}:${seconds}`);
  }, []);

  const handleChangeLink = useCallback((e) => {
    setLink(e.target.value);
  }, []);

  const handleClickSaveLink = useCallback(() => {
    if (link) {
      const newResource: IResource = {
        link,
      };
      setResources((state) => [...state, newResource]);
    }
    setLink('');
  }, [link]);

  const handleChangeFile = useCallback((e) => {
    const { files } = e.target;
    const newResources: IResource[] = Object.values<File>(files).map(
      (file) => ({
        file,
      })
    );
    e.target.value = '';
    setResources((state) => [...state, ...newResources]);
  }, []);

  const handleClickDeleteOldResource = useCallback(
    (resourceIndex) => {
      Swal.fire({
        title: 'Deseja deletar esse recurso?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const newResources = oldResources.slice();
            const deletedResourcesData = newResources.splice(resourceIndex, 1);
            setOldResources(newResources);
            setDeletedResources((state) => [...state, ...deletedResourcesData]);
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [oldResources]
  );

  const handleClickDeleteResource = useCallback(
    (resourceIndex) => {
      Swal.fire({
        title: 'Deseja deletar esse recurso?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const newResources = resources.slice();
            newResources.splice(resourceIndex, 1);
            setResources(newResources);
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [resources]
  );

  const handleChangeQuiz = useCallback(
    (questionsData, attemptsData) => {
      if (
        questionsData[0].question.length > 0 &&
        JSON.stringify(questionsData) !== JSON.stringify(questions)
      ) {
        setQuestions(questionsData);
      }
      setAttempts(attemptsData);
    },
    [questions]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setVideoError('');
      setDescriptionError('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          description: Yup.string(),
          duration: Yup.string()
            .length(8, 'Preencha corretamente')
            .required('O tempo de duração é obrigatório'),
          quiz: Yup.string().when('$quiz', {
            is: (quizCheck: boolean) => quizCheck,
            then: Yup.string().required('A criação do quiz é obrigatória.'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            quiz:
              addQuiz &&
              questions.filter((question) => question.question.length === 0)
                .length > 0,
          },
        });

        let video_id = lesson.video.id;
        if (video || videoUrl) {
          let responseVideo = {} as AxiosResponse;
          if (videoType.id === 'link' && videoUrl) {
            const formDataVideo = {
              link: videoUrl,
            };
            responseVideo = await api.post('videos', formDataVideo);
          } else if (videoType.id === 'upload' && video) {
            const formDataVideo = new FormData();
            formDataVideo.append('video', video);
            responseVideo = await api.post('videos', formDataVideo);
          }
          video_id = responseVideo.data.id;
        }

        const formData = {
          module_id: moduleId,
          video_id,
          status_id: lesson.status_id,
          title: data.title,
          description: data.description,
          duration: data.duration,
          order: lesson.order,
        };

        const response = await api.put(`lessons/${lesson.id}`, formData);

        if (addQuiz) {
          const formDataExam = {
            lesson_id: response.data.id,
            attempts,
          };

          if (lesson.exam) {
            const examResponse = await api.put(
              `exams/${lesson.exam.id}`,
              formDataExam
            );

            if (questions.length > 0) {
              const questionsPromise = new Promise<void>((resolve) => {
                questions.forEach(async (question, index) => {
                  if (question.status === 'DELETED') {
                    await api.delete(`questions/${question.id}`);
                  } else {
                    const formDataQuestion = {
                      exam_id: examResponse.data.id,
                      content: question.question,
                    };

                    if (question.status === 'NEW') {
                      const responseQuestion = await api.post(
                        'questions',
                        formDataQuestion
                      );

                      const optionsPromise = new Promise<void>(
                        (resolveOptions) => {
                          if (question.options.length > 0) {
                            console.log(question.options);
                            question.options.forEach(async (option, idx) => {
                              const formDataOption = {
                                question_id: responseQuestion.data.id,
                                content: option.answer,
                                correct: option.correct_answer,
                              };
                              await api.post('answers', formDataOption);

                              if (question.options.length === idx + 1) {
                                resolveOptions();
                              }
                            });
                          } else {
                            resolveOptions();
                          }
                        }
                      );

                      await optionsPromise;
                    } else if (question.status === 'UPDATED') {
                      const responseQuestion = await api.put(
                        `questions/${question.id}`,
                        formDataQuestion
                      );

                      const optionsPromise = new Promise<void>(
                        (resolveOptions) => {
                          if (question.options.length > 0) {
                            question.options.forEach(async (option, idx) => {
                              if (option.status === 'DELETED') {
                                await api.delete(`answers/${option.id}`);
                              } else {
                                const formDataOption = {
                                  question_id: responseQuestion.data.id,
                                  content: option.answer,
                                  correct: option.correct_answer,
                                };
                                if (option.status === 'NEW') {
                                  await api.post('answers', formDataOption);
                                } else if (option.status === 'UPDATED') {
                                  await api.put(
                                    `answers/${option.id}`,
                                    formDataOption
                                  );
                                }
                              }
                              if (question.options.length === idx + 1) {
                                resolveOptions();
                              }
                            });
                          } else {
                            resolveOptions();
                          }
                        }
                      );

                      await optionsPromise;
                    } else {
                      const optionsPromise = new Promise<void>(
                        (resolveOptions) => {
                          if (question.options.length > 0) {
                            question.options.forEach(async (option, idx) => {
                              if (option.status === 'DELETED') {
                                await api.delete(`answers/${option.id}`);
                              } else {
                                const formDataOption = {
                                  question_id: question.id,
                                  content: option.answer,
                                  correct: option.correct_answer,
                                };
                                if (option.status === 'NEW') {
                                  await api.post('answers', formDataOption);
                                } else if (option.status === 'UPDATED') {
                                  await api.put(
                                    `answers/${option.id}`,
                                    formDataOption
                                  );
                                }
                              }
                              if (question.options.length === idx + 1) {
                                resolveOptions();
                              }
                            });
                          } else {
                            resolveOptions();
                          }
                        }
                      );

                      await optionsPromise;
                    }
                  }

                  if (questions.length === index + 1) {
                    resolve();
                  }
                });
              });

              await questionsPromise;
            }
          } else {
            const examResponse = await api.post('exams', formDataExam);
            if (questions.length > 0) {
              const questionsPromise = new Promise<void>((resolve) => {
                questions.forEach(async (question, index) => {
                  const formDataQuestion = {
                    exam_id: examResponse.data.id,
                    content: question.question,
                  };
                  const responseQuestion = await api.post(
                    'questions',
                    formDataQuestion
                  );

                  const optionsPromise = new Promise<void>((resolveOptions) => {
                    if (question.options.length > 0) {
                      question.options.forEach(async (option, idx) => {
                        const formDataOption = {
                          question_id: responseQuestion.data.id,
                          content: option.answer,
                          correct: option.correct_answer,
                        };
                        await api.post('answers', formDataOption);
                        if (question.options.length === idx + 1) {
                          resolveOptions();
                        }
                      });
                    } else {
                      resolveOptions();
                    }
                  });

                  await optionsPromise;

                  if (questions.length === index + 1) {
                    resolve();
                  }
                });
              });

              await questionsPromise;
            }
          }
        } else if (lesson.exam) {
          await api.delete(`exams/${lesson.exam.id}`);
        }

        if (deletedResources.length > 0) {
          const resourcesPromise = new Promise<void>((resolve) => {
            resources.forEach(async (resource, index) => {
              if (resource.link) {
                const resourceSelected = lesson.resources.find(
                  (resourceData) => resourceData.name === resource.link
                );
                if (resourceSelected) {
                  await api.delete(`resources/${resourceSelected.id}`);
                }
              }

              if (resources.length === index + 1) {
                resolve();
              }
            });
          });

          await resourcesPromise;
        }

        if (resources.length > 0) {
          const resourcesPromise = new Promise<void>((resolve) => {
            resources.forEach(async (resource, index) => {
              if (resource.file) {
                const formDataResource = new FormData();
                formDataResource.append('resource', resource.file);
                formDataResource.append('lesson_id', response.data.id);
                await api.post('resources', formDataResource);
              } else if (resource.link) {
                const formDataResource = {
                  lesson_id: response.data.id,
                  link: resource.link,
                };
                await api.post('resources', formDataResource);
              }

              if (resources.length === index + 1) {
                resolve();
              }
            });
          });

          await resourcesPromise;
        }

        Toast.fire({
          icon: 'success',
          title: 'Aula atualizada!',
        });

        history.push(`${process.env.PUBLIC_URL}/cursos/${params.slug}/aulas`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          if (errors.video) {
            setVideoError(errors.video);
          }

          if (errors.description) {
            setDescriptionError(errors.description);
          }

          delete errors.description;
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [
      addQuiz,
      deletedResources.length,
      history,
      lesson,
      moduleId,
      params.slug,
      questions,
      resources,
      video,
      videoType.id,
      videoUrl,
      attempts,
    ]
  );

  const handleYoutubeReady = useCallback(
    (e) => {
      if (e && e.target) {
        setDuration(convertSecondsToHours(e.target.getDuration()));
        if (!lessonTitle) {
          const { title } = e.target.getVideoData();
          setLessonTitle(title);
        }
      }
    },
    [lessonTitle]
  );

  const handleChangeTitle = useCallback((e) => {
    if (e && e.target) {
      setLessonTitle(e.target.value);
    }
  }, []);

  return (
    <>
      <Container className="container py-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="fw-semibold">Atualizar aula</h1>
          </div>
        </div>
        {Object.keys(lesson).length > 0 && (
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={lessonFormData}
            className="row box bg-dark-2 p-5"
          >
            <div className="col-lg-4">
              <div className="w-100">
                <span>Subir vídeo por:</span>
                <InputRadio
                  name="video_type"
                  options={[
                    {
                      id: 'link',
                      value: 'Link',
                    },
                    {
                      id: 'upload',
                      value: 'Upload',
                    },
                  ]}
                  onChange={handleChangeVideoType}
                  selected={videoType}
                  className="justify-content-start mt-3"
                />
              </div>
              {videoType.id === 'link' && (
                <label className="w-100 mt-4">
                  Link do vídeo
                  <Input
                    type="url"
                    placeholder="https://www.youtube.com/"
                    name="video_url"
                    className="mt-3"
                    onChange={handleChangeVideoUrl}
                  />
                </label>
              )}
              <div className="w-100 mt-4">
                <span>
                  {videoType.id === 'link' ? 'Vídeo preview' : 'Vídeo'}
                </span>
                {videoType.id === 'upload' && (
                  <InputVideo
                    refVideoPreview={videoRef}
                    name="video"
                    placeholder=""
                    className="bg-dark-3 mt-3"
                    onChange={handleChangeVideo}
                    error={videoError}
                    onRemove={handleRemoveVideo}
                    value={lesson.video.video_url}
                  />
                )}
                {videoType.id === 'link' && (
                  <VideoPreview className="bg-dark-3 mt-3">
                    {videoUrl ? (
                      <YouTube
                        videoId={videoUrl}
                        onReady={handleYoutubeReady}
                        className="h-100"
                      />
                    ) : (
                      <div className="camera">
                        <IoIosVideocam size={50} color="#BBBBBB" />
                      </div>
                    )}
                  </VideoPreview>
                )}
              </div>
              <label className="w-100 mt-4">
                Tempo de duração{' '}
                <InputMask
                  kind="custom"
                  options={{
                    mask: '99:99:99',
                  }}
                  placeholder="00:00:00"
                  name="duration"
                  className="mt-3"
                  onChange={handleChangeDuration}
                  value={duration}
                />
              </label>
              <div className="w-100 mt-4">
                <label>Adicionar QUIZ</label>
                <div className="d-flex text-center mt-3">
                  <label
                    className={`btn w-100 mx-1 ${
                      addQuiz ? 'btn-gray' : 'btn-dark-3'
                    }`}
                    htmlFor="add_quiz_yes"
                  >
                    <span className="d-block py-2">YES</span>
                    <Input
                      type="radio"
                      onChange={() => handleChangeAddQuiz(true)}
                      name="add_quiz"
                      id="add_quiz_yes"
                      className="d-none"
                      value="yes"
                      checked={addQuiz}
                    />
                  </label>
                  <label
                    className={`btn w-100 mx-1 ${
                      !addQuiz ? 'btn-gray' : 'btn-dark-3'
                    }`}
                    htmlFor="add_quiz_no"
                  >
                    <span className="d-block py-2">NO</span>
                    <Input
                      type="radio"
                      onChange={() => handleChangeAddQuiz(false)}
                      name="add_quiz"
                      id="add_quiz_no"
                      className="d-none"
                      value="no"
                      checked={!addQuiz}
                    />
                  </label>
                </div>
              </div>
              {addQuiz && (
                <div className="w-100 mt-4">
                  Quiz
                  <Quiz
                    title={lesson.exam ? 'Editar quiz' : 'Adicionar novo quiz'}
                    dataQuestions={questions}
                    dataAttempts={attempts}
                    onChange={handleChangeQuiz}
                  />
                </div>
              )}
            </div>
            <div className="col-lg-8 ps-lg-5 d-flex flex-column justify-content-between">
              <div>
                <label className="w-100">
                  Título{' '}
                  <Input
                    name="title"
                    className="mt-3"
                    value={lessonTitle}
                    onChange={handleChangeTitle}
                  />
                </label>
                <hr className="my-5" />
                <Infos className="w-100 bg-dark-3">
                  <Tabs className="px-5 py-3 tabs">
                    <TabsButton
                      type="button"
                      onClick={handleClickTabsButton}
                      className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
                    >
                      {tabSelected === 'about' && 'Sobre o curso'}
                      {tabSelected === 'downloads' && 'Downloads'}
                      <IoIosArrowDown
                        size={24}
                        color="#bbbbbb"
                        className="ms-2"
                      />
                    </TabsButton>
                    <TabsGroup
                      opened={openTabs}
                      className="w-100 d-flex flex-column flex-md-row justify-content-md-start align-items-center"
                    >
                      <Tab
                        type="button"
                        onClick={() => handleSelectTab('about')}
                        active={tabSelected === 'about'}
                        className="border-0 bg-transparent"
                      >
                        Sobre o curso
                      </Tab>
                      <Tab
                        type="button"
                        onClick={() => handleSelectTab('downloads')}
                        active={tabSelected === 'downloads'}
                        className="border-0 bg-transparent"
                      >
                        Downloads
                      </Tab>
                    </TabsGroup>
                  </Tabs>
                  <div
                    className={tabSelected === 'about' ? 'd-block' : 'd-none'}
                  >
                    <Textarea
                      name="description"
                      className="bg-transparent border-0 px-3 py-4 text-area"
                      rows={14}
                      placeholder="Digite aqui..."
                    />
                  </div>
                  <Downloads
                    className={`px-2 py-4 ${
                      tabSelected === 'downloads' ? 'd-block' : 'd-none'
                    }`}
                  >
                    <div className="px-3">
                      <label className="bg-dark-4 d-block d-flex align-items-center px-4 py-2">
                        <IoMdLink size={24} color="#bbbbbb" className="me-2" />
                        <input
                          type="url"
                          name="link"
                          placeholder="Subir por link"
                          className="border-0 bg-transparent input-url"
                          onChange={handleChangeLink}
                          value={link}
                        />
                        {link && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleClickSaveLink}
                          >
                            Salvar
                          </button>
                        )}
                      </label>
                      <label className="bg-dark-4 d-block d-flex align-items-center px-4 py-3">
                        <span>
                          <BsPlus size={24} color="#bbbbbb" /> Subir arquivo(s)
                        </span>
                        <input
                          type="file"
                          name="file"
                          className="d-none"
                          onChange={handleChangeFile}
                          multiple
                        />
                      </label>
                    </div>
                    <div className="px-3 pb-4 resources">
                      {oldResources.map((resource, index) => (
                        <div
                          key={index.toString()}
                          className="d-block d-flex justify-content-between align-items-center px-4 py-3 resource"
                        >
                          <p className="mb-0">
                            {resource.file ? resource.file.name : resource.link}
                          </p>
                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() => handleClickDeleteOldResource(index)}
                          >
                            <MdDelete size={24} color="#FF333D" />
                          </button>
                        </div>
                      ))}
                      {resources.map((resource, index) => (
                        <div
                          key={index.toString()}
                          className="d-block d-flex justify-content-between align-items-center px-4 py-3 resource"
                        >
                          <p className="mb-0">
                            {resource.file ? resource.file.name : resource.link}
                          </p>
                          <button
                            type="button"
                            className="border-0 bg-transparent"
                            onClick={() => handleClickDeleteResource(index)}
                          >
                            <MdDelete size={24} color="#FF333D" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </Downloads>
                </Infos>
              </div>
              <div className="d-flex">
                {descriptionError && (
                  <span className="error small text-danger mt-2 ps-3">
                    {descriptionError}
                  </span>
                )}
                <button
                  type="submit"
                  className="btn btn-primary py-2 fw-bold px-5 ms-auto d-block mt-4"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Container>
    </>
  );
};

export default LessonsUpdate;
