import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ITabsGroup {
  opened: boolean;
}

interface ITab {
  active: boolean;
}

export const Container = styled.div`
  .box {
    border-radius: 20px;

    .text-gray label span {
      color: #bbb;
    }

    .error {
      bottom: 15px;
    }
  }

  label button:not(.btn-primary) {
    color: #bbbbbb !important;
  }
`;

export const VideoPreview = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  .camera {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      padding: 0 10px;
      font-weight: normal;
      width: 100%;
      color: #d9d9d9;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    height: 250px;

    .camera {
      right: 0;
      bottom: 0;
    }
  }
`;

export const Infos = styled.div`
  border-radius: 15px;

  > div:not(.tabs) {
    height: 630px;

    .text-area {
      height: 100%;
    }
  }
`;

export const Tabs = styled.div`
  border: 1px solid #565656;
  border-radius: 15px;
`;

export const TabsButton = styled.button`
  border: 1px solid #333333;
  border-radius: 15px;
`;

export const TabsGroup = styled.div<ITabsGroup>`
  transition-duration: 0.3s;
  height: ${(props) => (props.opened ? '200px' : '0')};
  overflow: hidden;
  padding-top: ${(props) => (props.opened ? '20px' : '0')};
  margin-top: ${(props) => (props.opened ? '20px' : '0')};
  border-top: ${(props) => (props.opened ? '1px solid #333333' : 'none')};

  @media screen and (min-width: 768px) {
    height: unset;
    overflow: unset;
  }
`;

export const Tab = styled.button<ITab>`
  position: relative;
  transition-duration: 0.3s;
  color: ${(props) => (props.active ? '#ffffff' : '#bbbbbb')};

  :after {
    content: '';
    transition-duration: 0.3s;
    background: ${(props) => (props.active ? '#FF0000' : '#FF000000')};
    width: 80%;
    height: 1px;
    border-radius: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  :hover {
    color: #ffffff;

    :after {
      background: #ff0000;
    }
  }

  + button {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    + button {
      margin-top: 0;
      margin-left: 40px;
    }
  }
`;

export const Downloads = styled.div`
  label,
  div {
    border-radius: 20px;

    p,
    span {
      color: #bbbbbb;
    }

    + div {
      margin-top: 16px;
    }
  }

  label {
    cursor: pointer;
    height: 58px;

    .input-url {
      width: calc(100% - 100px);

      color: #fff;

      ::placeholder {
        color: #bbbbbb;
      }
    }

    + label {
      margin-top: 16px;
    }
  }
  .resources {
    height: calc(100% - 135px);
    overflow: auto;

    .resource {
      border: 1px solid #565656;
    }
  }
`;
