import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import CardCommentSupport from '../CardCommentSupport';
import { Container } from './styles';

export interface Avatar {
  id: string;
  avatar_url: string;
}

export interface Course {
  id: string;
  title: string;
  // Add other properties of course if needed
}

export interface Module {
  id: string;
  title: string;
  course: Course;
  // Add other properties of module if needed
}

export interface Lesson {
  id: string;
  title: string;
  module: Module;
}

export interface User {
  id: string;
  name: string;
  avatar: Avatar;
}

export interface Doubt {
  id: string;
  user_id: string;
  user: User;
  lesson: Lesson;
  lesson_id: string;
  content: string;
  created_at: string;
}

export interface DoubtAnswer {
  id: string;
  doubt_id: string;
  user_id: string;
  user: User;
  content: string;
  created_at: string;
}

interface CommentSupportProps {
  filter: 'new' | 'unanswered' | 'answered';
  searchTerm: string;
}

const CommentSupport: React.FC<CommentSupportProps> = ({
  filter,
  searchTerm,
}) => {
  const [doubts, setDoubts] = useState<Doubt[]>([]);
  const [doubtAnswers, setDoubtAnswers] = useState<DoubtAnswer[]>([]);

  useEffect(() => {
    const fetchDoubtsAndAnswers = async () => {
      try {
        const responseDoubts = await api.get<Doubt[]>('/doubts');
        const responseDoubtAnswers = await api.get<DoubtAnswer[]>(
          '/doubts-answers'
        );
        let unansweredDoubts = responseDoubts.data.filter(
          (doubt) =>
            !responseDoubtAnswers.data.find(
              (answer) => answer.doubt_id === doubt.id
            )
        );
        let answeredDoubts = responseDoubts.data.filter((doubt) =>
          responseDoubtAnswers.data.find(
            (answer) => answer.doubt_id === doubt.id
          )
        );

        // Filtrar doubts según el término de búsqueda
        if (searchTerm) {
          const lowerCaseSearchTerm = searchTerm.toLowerCase();
          unansweredDoubts = unansweredDoubts.filter((doubt) =>
            doubt.content.toLowerCase().includes(lowerCaseSearchTerm)
          );
          answeredDoubts = answeredDoubts.filter((doubt) =>
            doubt.content.toLowerCase().includes(lowerCaseSearchTerm)
          );
        }

        let filteredDoubts = [];
        if (filter === 'new') {
          filteredDoubts = [...unansweredDoubts, ...answeredDoubts];
        } else if (filter === 'unanswered') {
          filteredDoubts = unansweredDoubts;
        } else if (filter === 'answered') {
          filteredDoubts = answeredDoubts;
        } else {
          filteredDoubts = [...unansweredDoubts, ...answeredDoubts];
        }

        setDoubts(filteredDoubts);
        setDoubtAnswers(responseDoubtAnswers.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDoubtsAndAnswers();
  }, [filter, searchTerm]);

  const handleReply = async (doubtId: string, content: string) => {
    try {
      await api.post('/doubts-answers', { doubt_id: doubtId, content });

      // Fetch doubts and answers again to update the list
      const responseDoubts = await api.get<Doubt[]>('/doubts');
      const responseDoubtAnswers = await api.get<DoubtAnswer[]>(
        '/doubts-answers'
      );
      setDoubts(responseDoubts.data);
      setDoubtAnswers(responseDoubtAnswers.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <p>
        {doubts.map((doubt) => (
          <CardCommentSupport
            key={doubt.id}
            doubt={doubt}
            doubtAnswers={doubtAnswers.filter(
              (answer) => answer.doubt_id === doubt.id
            )}
            onReply={handleReply}
            searchTerm={searchTerm}
          />
        ))}
      </p>
    </Container>
  );
};

export default CommentSupport;
