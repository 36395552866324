import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';

interface IFormData {
  name: string;
  email: string;
  password: string;
}

const EmployeesCreate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
          password: data.password,
        };
        await api.post('admins', formData);

        Toast.fire({
          icon: 'success',
          title: 'Funcionário cadastrado!',
        });
        history.push(`${process.env.PUBLIC_URL}/funcionarios`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [history]
  );

  return (
    <Container className="container py-5">
      <Form ref={formRef} onSubmit={handleSubmit} className="row">
        <div className="col-12 mb-4">
          <h1>Adicionar funcionario</h1>
        </div>
        <div className="col-lg-6">
          <div className="box bg-dark-2 p-5">
            <label className="w-100">
              Nome <Input name="name" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              E-mail <Input type="email" name="email" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              Senha <Input type="password" name="password" className="mt-3" />
            </label>
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default EmployeesCreate;
