/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useEffect, useState } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';

import { Container } from './styles';

interface IOnSave {
  html: string;
  css: string;
}

interface ICanvasDraw {
  onCanvasDrawReady(onSave: () => IOnSave | undefined): void;
  html?: string;
  css?: string;
  step?: string;
}

const CanvasDraw: React.FC<ICanvasDraw> = ({
  onCanvasDrawReady,
  html,
  css,
  step,
}) => {
  const [editor, setEditor] = useState({} as grapesjs.Editor);

  useEffect(() => {
    const editorData = grapesjs.init({
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: '#gjs',
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: true,
      // Size of the editor
      height: '745.13px',
      width: '1054px',
      // Disable the storage manager for the moment
      storageManager: false,
      // Avoid any default panel
      panels: { defaults: [] },

      dragMode: 'absolute' as any,

      blockManager: {
        appendTo: '#blocks',
        blocks: [
          {
            id: 'courseName',
            category: 'Variáveis',
            label: 'Nome do curso',
            content: `<div id="courseName" style="font-family: sans-serif;font-size: 2rem;font-weight: 500;" data-gjs-editable="false">$Nome do curso</div>`,
          },
          {
            id: 'studentName',
            category: 'Variáveis',
            label: 'Nome do aluno',
            content:
              '<div id="studentName" style="font-family: sans-serif;font-size: 4rem;font-weight: 600;"  data-gjs-editable="false">$Nome do aluno</div>',
          },
          {
            id: 'startDate',
            category: 'Variáveis',
            label: 'Data de inicio',
            content:
              '<div id="startDate" style="font-family: sans-serif;font-size: 1rem;font-weight: 600;"  data-gjs-editable="false">$00/00/0000</div>',
          },
          {
            id: 'conclusionDate',
            category: 'Variáveis',
            label: 'Data de conclusão',
            content:
              '<div id="conclusionDate" style="font-family: sans-serif;font-size: 1rem;font-weight: 600;"  data-gjs-editable="false">$00/00/0000</div>',
          },
          {
            id: 'average',
            category: 'Variáveis',
            label: 'Média',
            content:
              '<div id="average" style="font-family: sans-serif;font-size: 1rem;font-weight: 600;"  data-gjs-editable="false">$0,0</div>',
          },
          {
            id: 'paragraph',
            label: 'Texto',
            category: 'Textos e imagens',
            content:
              '<p style="font-family: sans-serif;font-size: 1rem;font-weight: 400;">Digite aqui</p>',
          },
          {
            id: 'image',
            label: 'Imagem',
            category: 'Textos e imagens',
            select: true,
            content: { type: 'image' },
            activate: true,
          },
        ],
      },

      styleManager: {
        appendTo: '#styles',
        sectors: [
          {
            name: 'Posição',
            open: false,
            properties: ['top', 'right', 'left', 'bottom'],
          },
          {
            name: 'Tipografia',
            open: false,
            properties: [
              'font-family',
              'font-size',
              'font-weight',
              'letter-spacing',
              'color',
              'line-height',
              'text-align',
              'text-shadow',
            ],
          },
          {
            name: 'Decoração',
            open: false,
            properties: [
              'background-color',
              'border-radius',
              'border',
              'box-shadow',
              'background',
            ],
          },
        ],
      },
    });

    setEditor(editorData);
  }, []);

  useEffect(() => {
    if (Object.keys(editor).length > 0) {
      if (step && html && css) {
        editor.setComponents(html, { keepIds: '' });
        editor.setStyle(css);
      } else {
        editor.setComponents('<body></body>', { keepIds: '' });
        editor.setStyle('* { box-sizing: border-box; } body {margin: 0;}');
      }
    }
  }, [css, editor, html, step]);

  const onSave = useCallback(() => {
    if (Object.keys(editor).length > 0) {
      const htmlData = {
        html: editor.getHtml(),
        css: editor.getCss() as string,
      };
      return htmlData;
    }

    return undefined;
  }, [editor]);

  useEffect(() => {
    onCanvasDrawReady(onSave);
  }, [onCanvasDrawReady, onSave]);

  return (
    <Container>
      <div id="blocks" />
      <div id="gjs" />
      <div id="styles" />
    </Container>
  );
};

export default CanvasDraw;
