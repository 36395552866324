import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

import arrowPrev from '~/assets/icons/arrow-prev.svg';
import arrowNext from '~/assets/icons/arrow-next.svg';

export const Container = styled.div`
  .box {
    border-radius: 20px;

    .text-gray label span {
      color: #bbb;
    }

    .btn-delete {
      position: absolute;
      right: 0;
      top: 10px;
    }

    .input,
    .btn-radio {
      height: 52px;
    }

    .input-select {
      min-height: 52px;
    }

    .input-select.products {
      > div > div > div.bg-dark-2 {
        background-color: #e50914 !important;

        > span {
          color: #ffffff;
        }

        > button {
          svg {
            color: #ffffff;
          }
        }
      }
    }
  }
`;

export const Steps = styled.div`
  hr {
    background-color: #333333;
  }
`;

export const VideoPreview = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  .camera {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      padding: 0 10px;
      font-weight: normal;
      width: 100%;
      color: #d9d9d9;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    height: 250px;

    .camera {
      right: 0;
      bottom: 0;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-save {
      width: 380px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    background-color: #282828;
    border-radius: 10px;
    padding: 10px 0;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;

    .DayPicker-Weekdays {
      margin-top: 0;
    }

    .DayPicker-Weekdays,
    .DayPicker-Body {
      display: grid;
    }
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: #fff !important;
  }

  .DayPicker-NavButton--prev {
    background-image: url(${arrowPrev}) !important;
    width: 25px;
    height: 25px;
  }

  .DayPicker-NavButton--next {
    background-image: url(${arrowNext}) !important;
    width: 25px;
    height: 25px;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em;
    color: #8c8c8c;

    > div {
      text-align: center;
    }
  }

  .DayPicker-Weekday {
    color: #e4e4e4;
  }

  .DayPicker-Weekday {
    min-width: 42px;
    max-width: 42px;
  }

  .DayPicker-Day {
    min-width: 42px;
    max-width: 42px;
    min-height: 42px;
    max-height: 42px;
    border-radius: 50%;
    font-weight: bold;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    color: #8c8c8c;
    border: 1px solid #8c8c8c;
  }

  .DayPicker-Day--outside,
  .DayPicker-Day--disabled {
    color: ${darken(0.25, '#8c8c8c')} !important;
    border: 1px solid ${darken(0.25, '#8c8c8c')} !important;
  }

  .DayPicker-Day--selected,
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    border-color: #8c8c8c !important;
    background: #8c8c8c !important;
    color: #282828 !important;
  }
`;
