import styled from 'styled-components';
import List from 'react-chatview';
import { Modal as ModalComponent } from 'react-bootstrap';
import { GridDropZone as GridDropZoneComponent } from 'react-grid-dnd';
import { number } from 'yup';

interface IGridDropZone {
  height: number;
}

export const Container = styled(List)`
  height: calc(100vh - 61px);

  .box {
    border-radius: 20px;

    .modules-dnd {
      > div {
        position: unset !important;
      }
    }
  }
`;

export const Steps = styled.div`
  hr {
    background-color: #333333;
  }
`;

export const GridDropZone = styled(GridDropZoneComponent)<IGridDropZone>`
  height: ${(props) => (props.height > 0 ? props.height : 200)}px;

  .cursor-grab {
    cursor: grab;
  }

  .cursor-grabbing {
    cursor: grabbing;
  }
`;

export const Module = styled.div`
  background: #303030;
  border-radius: 10px;
`;

export const Lesson = styled.div`
  position: relative;
  height: 100%;

  div,
  .add-lesson {
    border-radius: 10px;
  }

  div {
    pointer-events: none;
  }

  .btn-group {
    top: 0;
    left: 0;
    pointer-events: unset;

    a,
    button {
      width: 21px;
      height: 21px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }
  }
`;
