import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import { BsCalendarEvent } from 'react-icons/bs';
import { format, addDays } from 'date-fns';
import DayPicker, { DayModifiers } from 'react-day-picker';

import { MdDelete } from 'react-icons/md';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Steps, Plan, Modal, Calendar } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import InputTag from '~/components/InputTag';
import InputToggle from '~/components/InputToggle';
import InputMask from '~/components/InputMask';
import { formatPrice } from '~/utils/format';
import Select, { IOption } from '~/components/Select';

interface IPlanResponse {
  id?: string;
  name: string;
  description: string;
  price: number;
  has_discount: boolean;
  discount?: number;
  checkout_url: string;
  features: string;
  order: number;
}

interface IThumbnail {
  id: string;
  thumbnail_url: string;
}

interface IProductResponse {
  id: string;
  status_id: string;
  title: string;
  description: string;
  hasPlans: boolean;
  features?: string;
  price?: number;
  has_discount: boolean;
  discount?: number;
  checkout_url?: string;
  release_date?: Date;
  order: number;
  plans: IPlanResponse[];
  thumbnail: IThumbnail;
}

interface IFormData {
  title: string;
  description: string;
  product_price?: string;
  product_discount?: string;
  product_checkout_url?: string;
}

interface IPlan {
  id?: string;
  name: string;
  description: string;
  price: number;
  has_discount: boolean;
  discount?: number;
  checkout_url: string;
  features: string[];
  recommended?: boolean;
  order: number;
  orderError?: string;
}

interface IFormDataProduct {
  thumbnail_id: string;
  status_id: string;
  title: string;
  description: string;
  features?: string;
  checkout_url?: string;
  price?: number;
  has_discount?: boolean;
  discount?: number;
  hasPlans?: boolean;
  order: number;
  release_date?: Date;
}

interface IFormDataPlan {
  product_id: string;
  name: string;
  description: string;
  features: string;
  recommended?: boolean;
  checkout_url: string;
  price: number;
  has_discount?: boolean;
  discount?: number;
  order: number;
}

interface IStatus {
  id: string;
  content: string;
}

interface IParams {
  slug: string;
}

const ProductsCreate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbail] = useState<File | undefined>(undefined);
  const [thumbnailId, setThumbailId] = useState('');
  const [thumbnailUrl, setThumbailUrl] = useState('');
  const [thumbnailError, setThumbailError] = useState('');
  const [hasPlans, setHasPlans] = useState(false);
  const [oldPlans, setOldPlans] = useState<IPlan[]>([]);
  const [plans, setPlans] = useState<IPlan[]>([
    {
      name: '',
      description: '',
      price: 0,
      has_discount: false,
      checkout_url: '',
      features: [] as string[],
      order: 1,
    },
  ]);
  const [hasProductDiscount, setHasProductDiscount] = useState(false);
  const [features, setFeatures] = useState<string[]>([]);
  const [product, setProduct] = useState({} as IFormData);
  const [productId, setProductId] = useState('');
  const [status, setStatus] = useState<IOption[]>([]);
  const [statusSelected, setStatusSelected] = useState({} as IOption);
  const [showButtonCalendar, setShowButtonCalendar] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(addDays(new Date(), 1));
  const [releaseDate, setReleaseDate] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    api
      .get<IProductResponse>(`products/${params.slug}`)
      .then(async (response) => {
        const responseStatus = await api.get<IStatus[]>('status');
        const statusData: IOption[] = responseStatus.data.map((dataStatus) => {
          let value = '';
          switch (dataStatus.content) {
            case 'Draft':
              value = 'Rascunho';
              break;
            case 'Unlisted':
              value = 'Não listado';
              break;
            case 'Scheduled':
              value = 'Agendado';
              break;
            default:
              value = 'Publicado';
              break;
          }

          return {
            id: dataStatus.id,
            value,
            selected: dataStatus.id === response.data.status_id,
          };
        });

        const statusSelectedData = statusData.find(
          (dataStatus) => dataStatus.id === response.data.status_id
        );

        if (statusSelectedData) {
          if (
            response.data.release_date &&
            statusSelectedData.value === 'Agendado'
          ) {
            setShowButtonCalendar(true);
            const releaseDateData = format(
              new Date(response.data.release_date),
              "dd/MM/yyyy 'ás' HH:mm:ss"
            );
            setReleaseDate(releaseDateData);
            setTime(releaseDateData.slice(14));
            setSelectedDate(new Date(response.data.release_date));
          }
          setStatusSelected(statusSelectedData);
        }

        setStatus(statusData);

        const data: IFormData = {
          title: response.data.title,
          description: response.data.description,
          product_price: response.data.price
            ? formatPrice(response.data.price)
            : undefined,
          product_discount: response.data.discount
            ? formatPrice(response.data.discount)
            : undefined,
          product_checkout_url: response.data.checkout_url,
        };

        const plansData: IPlan[] = response.data.plans.map((plan) => ({
          id: plan.id,
          name: plan.name,
          description: plan.description,
          price: plan.price,
          has_discount: plan.has_discount,
          discount: plan.discount,
          checkout_url: plan.checkout_url,
          features: plan.features.split(';'),
          order: plan.order,
        }));

        setFeatures(
          response.data.features ? response.data.features.split(';') : []
        );
        setProduct(data);
        if (plansData.length > 0) {
          setPlans(plansData);
          setOldPlans(plansData);
        }
        setProductId(response.data.id);
        setHasPlans(response.data.hasPlans);
        setThumbailId(response.data.thumbnail.id);
        setThumbailUrl(response.data.thumbnail.thumbnail_url);
      });
  }, [params.slug]);

  const handleChangeThumbnail = useCallback((file) => {
    setThumbailError('');
    setThumbail(file);
  }, []);

  const handleRemoveThumbnail = useCallback(() => {
    setThumbail(undefined);
    setThumbailUrl('');
  }, []);

  const handleChangeHasPlan = useCallback((value) => {
    setHasPlans(value.length > 0);
  }, []);

  const handleChangeHasProductDiscount = useCallback((value) => {
    setHasProductDiscount(value);
  }, []);

  const handleChangePlanName = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].name = e.target.value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanPrice = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].price = parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      );
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeOrder = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      const order = parseInt(e.target.value, 10);
      const checkPlans = newPlans.find((plan) => plan.order === order);
      if (checkPlans) {
        newPlans[index].orderError = 'Posição já esta ocupada';
      } else {
        newPlans[index].orderError = undefined;
      }
      newPlans[index].order = parseInt(e.target.value, 10);
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanDescritpion = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].description = e.target.value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanFeature = useCallback(
    (tags, index) => {
      const newPlans = plans.slice();
      newPlans[index].features = tags;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeHasPlanDiscount = useCallback(
    (value, index) => {
      const newPlans = plans.slice();
      newPlans[index].has_discount = value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanDiscount = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].discount = parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      );
      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangePlanCheckoutUrl = useCallback(
    (e, index) => {
      const newPlans = plans.slice();
      newPlans[index].checkout_url = e.target.value;
      setPlans(newPlans);
    },
    [plans]
  );

  const handleClickAddPlan = useCallback(() => {
    const newPlan = {
      name: '',
      description: '',
      price: 0,
      has_discount: false,
      checkout_url: '',
      features: [] as string[],
      order: plans.length + 1,
    };

    setPlans((state) => [...state, newPlan]);
  }, [plans.length]);

  const handleClickDeletePlan = useCallback(
    (index) => {
      const newPlans = plans.filter((_, idx) => index !== idx);
      if (newPlans.length > 0) {
        const newPlansAux = newPlans.map((plan, idx) => ({
          ...plan,
          order: idx + 1,
        }));
        setPlans(newPlansAux);
      } else {
        setPlans([
          {
            name: '',
            description: '',
            price: 0,
            has_discount: false,
            checkout_url: '',
            features: [] as string[],
            order: 1,
          },
        ]);
      }
    },
    [plans]
  );

  const handleChangeFeature = useCallback((tags) => {
    setFeatures(tags);
  }, []);

  const handleChangeStatus = useCallback(
    (option: IOption) => {
      const newStatus = status.map((statusData) => {
        if (statusData.id === option.id) {
          return { ...statusData, selected: true };
        }

        return { ...statusData, selected: false };
      });

      setStatusSelected(option);
      setStatus(newStatus);

      if (option.value === 'Agendado') {
        setShowButtonCalendar(true);
      } else {
        setShowButtonCalendar(false);
      }
    },
    [status]
  );

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleClose = useCallback(() => {
    setShowCalendar(false);
  }, []);

  const handleChangeTime = useCallback((e) => {
    let [hour, minutes, seconds] = e.target.value.split(':');

    if (seconds > 59) {
      seconds = 59;
    } else if (minutes > 59) {
      minutes = 59;
    } else if (hour > 23) {
      hour = 23;
    }

    setTime(`${hour}:${minutes}:${seconds}`);
  }, []);

  const handleSave = useCallback(() => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (time) {
      const [hoursAux, minutesAux, secondsAux] = time.split(':');
      if (hoursAux && hoursAux !== 'undefined') {
        hours = parseInt(hoursAux, 10);
      }
      if (minutesAux && minutesAux !== 'undefined') {
        minutes = parseInt(minutesAux, 10);
      }
      if (secondsAux && secondsAux !== 'undefined') {
        seconds = parseInt(secondsAux, 10);
      }
    }

    const newDate = new Date(year, month, day, hours, minutes, seconds);

    setReleaseDate(format(newDate, "dd/MM/yyyy 'ás' HH:mm:ss"));
    setSelectedDate(newDate);
    handleClose();
  }, [handleClose, selectedDate, time]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          plans: Yup.string().when('$plans', {
            is: (plansCheck: boolean) => plansCheck,
            then: Yup.string().required(
              'Preencha todos os planos corretamente'
            ),
            otherwise: Yup.string(),
          }),
          product_price: Yup.string().when('$product_price', {
            is: (productPriceCheck: boolean) => productPriceCheck,
            then: Yup.string().required('O preço do produto é obrigatório'),
            otherwise: Yup.string(),
          }),
          product_discount: Yup.string().when('$product_discount', {
            is: (profuctDiscountCheck: boolean) => profuctDiscountCheck,
            then: Yup.string().required(
              'O valor do produto na promoção é obrigatório'
            ),
            otherwise: Yup.string(),
          }),
          product_checkout_url: Yup.string().when('$product_checkout_url', {
            is: (productCheckoutUrlCheck: boolean) => productCheckoutUrlCheck,
            then: Yup.string().required('O link de pagamento é obrigatório'),
            otherwise: Yup.string(),
          }),
          features: Yup.string().when('$features', {
            is: (featuresCheck: boolean) => featuresCheck,
            then: Yup.string().required('As características são obrigatória'),
            otherwise: Yup.string(),
          }),
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            thumbnail: !thumbnail && !thumbnailUrl,
            plans:
              hasPlans &&
              plans.filter(
                (plan) =>
                  (plan.name.length === 0 ||
                    plan.description.length === 0 ||
                    plan.features.length === 0 ||
                    plan.checkout_url.length === 0 ||
                    !plan.order ||
                    (plan.order && plan.order === 0)) &&
                  !plan.orderError
              ).length > 0,
            product_price: !hasPlans,
            product_discount: !hasPlans && hasProductDiscount,
            product_checkout_url: !hasPlans,
            features: !hasPlans && features.length === 0,
          },
        });

        let thumbnail_id = thumbnailId;
        if (thumbnail) {
          const formDataThumbnail = new FormData();
          formDataThumbnail.append('thumbnail', thumbnail);
          const responseThumbnail = await api.post(
            'thumbnails',
            formDataThumbnail
          );
          thumbnail_id = responseThumbnail.data.id;
        }

        const formData: IFormDataProduct = {
          thumbnail_id,
          status_id: statusSelected.id as string,
          title: data.title,
          description: data.description,
          order: 1000,
          release_date:
            statusSelected.value === 'Agendado' ? selectedDate : undefined,
        };

        if (!hasPlans) {
          const featuresData = features.join(';');
          formData.features = featuresData;
          formData.checkout_url = data.product_checkout_url;
          formData.price = data.product_price
            ? parseFloat(
                data.product_price
                  .replace('R$', '')
                  .replaceAll('.', '')
                  .replace(',', '.')
              )
            : undefined;
          formData.has_discount = hasProductDiscount;
          formData.hasPlans = false;
          if (hasProductDiscount) {
            formData.discount = data.product_discount
              ? parseFloat(
                  data.product_discount
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
                )
              : undefined;
          }
        } else {
          formData.hasPlans = true;
        }

        const response = await api.put(`products/${productId}`, formData);

        if (hasPlans) {
          if (plans.length > 0) {
            const plansPromise = new Promise<void>((generalResolve) => {
              const deletedPlans = oldPlans.filter((oldPlan) => {
                return !plans.some((plan) => plan.id === oldPlan.id);
              });

              const newPlans = plans.filter((plan) => {
                return !oldPlans.some((oldPlan) => oldPlan.id === plan.id);
              });

              const plansAux = [...newPlans, ...deletedPlans];

              const plansEdit = plans.filter((plan) => {
                return !plansAux.some((oldPlan) => oldPlan.id === plan.id);
              });

              const deletedPlansPromise = new Promise<void>((resolve) => {
                if (deletedPlans.length > 0) {
                  deletedPlans.forEach(async (plan, index) => {
                    await api.delete(`plans/${plan.id}`);

                    if (deletedPlans.length === index + 1) {
                      resolve();
                    }
                  });
                } else {
                  resolve();
                }
              });

              const newPlansPromise = new Promise<void>((resolve) => {
                if (newPlans.length > 0) {
                  newPlans.forEach(async (plan, index) => {
                    const featuresData = plan.features.join(';');
                    const formDataPlan: IFormDataPlan = {
                      product_id: response.data.id,
                      name: plan.name,
                      description: plan.description,
                      features: featuresData,
                      recommended: false,
                      checkout_url: plan.checkout_url,
                      price: plan.price,
                      has_discount: plan.has_discount,
                      discount: plan.discount,
                      order: plan.order,
                    };

                    await api.post(`plans`, formDataPlan);

                    if (newPlans.length === index + 1) {
                      resolve();
                    }
                  });
                } else {
                  resolve();
                }
              });

              const plansEditPromise = new Promise<void>((resolve) => {
                if (plansEdit.length > 0) {
                  plansEdit.forEach(async (plan, index) => {
                    const featuresData = plan.features.join(';');
                    const formDataPlan: IFormDataPlan = {
                      product_id: response.data.id,
                      name: plan.name,
                      description: plan.description,
                      features: featuresData,
                      recommended: plan.recommended,
                      checkout_url: plan.checkout_url,
                      price: plan.price,
                      has_discount: plan.has_discount,
                      discount: plan.discount,
                      order: plan.order,
                    };

                    await api.put(`plans/${plan.id}`, formDataPlan);

                    if (plansEdit.length === index + 1) {
                      resolve();
                    }
                  });
                } else {
                  resolve();
                }
              });

              deletedPlansPromise.then(() => {
                newPlansPromise.then(() => {
                  plansEditPromise.then(() => {
                    generalResolve();
                  });
                });
              });
            });

            await plansPromise;
          }
        }

        Toast.fire({
          icon: 'success',
          title: 'Produto editado!',
        });
        history.push(
          `${process.env.PUBLIC_URL}/produtos/${response.data.slug}/cursos-inclusos`
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          if (errors.thumbnail) {
            setThumbailError(errors.thumbnail);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [
      features,
      hasPlans,
      hasProductDiscount,
      history,
      oldPlans,
      plans,
      productId,
      thumbnail,
      thumbnailId,
      thumbnailUrl,
      statusSelected,
      selectedDate,
    ]
  );

  return (
    <>
      <Container className="container py-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h1>Atualizar produto</h1>
          </div>
          <div className="col-12">
            <Form
              ref={formRef}
              initialData={product}
              onSubmit={handleSubmit}
              className="row box bg-dark-2 p-5"
            >
              <div className="col-12">
                <div className="row justify-content-center my-5">
                  <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                    <Steps className="d-flex ml-auto align-items-center min-width">
                      <div className="w-100 text-center bg-gray rounded-pill">
                        <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                          Sobre o produto
                        </span>
                      </div>
                      <hr className="w-25 w-md-50 border-gray mx-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/produtos/${params.slug}/cursos-inclusos`}
                        className="w-100 text-center bg-dark-3 rounded-pill"
                      >
                        <span className="d-block px-3 py-3 text-gray">
                          Cursos Inclusos
                        </span>
                      </Link>
                    </Steps>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="w-100">
                  <span>Thumbnail</span>
                  <InputImage
                    name="thumbnail"
                    placeholder=""
                    className="bg-dark-3 mt-3"
                    onChange={handleChangeThumbnail}
                    cropImage
                    aspect={20.44 / 12.63}
                    cropOptions={
                      !thumbnail
                        ? {
                            unit: 'px',
                            width: 20.44 * 5,
                            height: 12.63 * 5,
                            x: 0,
                            y: 0,
                          }
                        : undefined
                    }
                    value={thumbnailUrl}
                    error={thumbnailError}
                    onRemove={handleRemoveThumbnail}
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-12">
                    <label className="w-100">
                      Nome <Input name="title" className="mt-3 input" />
                    </label>
                  </div>
                  <div className="col-12">
                    <label className="w-100 mt-4">
                      Descrição{' '}
                      <Textarea name="description" className="mt-3" rows={4} />
                    </label>
                  </div>
                  <div className="col-12 pt-5 pb-4">
                    <hr />
                  </div>
                  <div className="col-lg-6">
                    <div className="w-100">
                      <label className="w-100">Status</label>
                      <Select
                        name="status"
                        options={status}
                        className="mt-3 input-select"
                        onChange={(options) =>
                          handleChangeStatus(options as unknown as IOption)
                        }
                      />
                    </div>
                    {showButtonCalendar && (
                      <div className="w-100">
                        <button
                          type="button"
                          className="border-0 bg-transparent mt-3 px-3 d-flex align-items-center"
                          onClick={() => setShowCalendar(true)}
                        >
                          <BsCalendarEvent
                            size={24}
                            color="#fff"
                            className="me-3"
                          />{' '}
                          <span className="d-flex flex-column align-items-start">
                            <span className="text-decoration-underline">
                              Data de lançamento{releaseDate && `:`}
                            </span>
                            {releaseDate && (
                              <b className="w-100 text-decoration-underline mt-1">
                                {releaseDate}
                              </b>
                            )}
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label className="w-100 mt-3">
                      <InputToggle
                        name="has_plans"
                        options={[
                          {
                            id: 'has_plans',
                            value: 'Possui planos',
                          },
                        ]}
                        className="mt-3 text-gray"
                        onChange={handleChangeHasPlan}
                        checkedValues={hasPlans ? ['has_plans'] : undefined}
                      />
                    </label>
                  </div>
                  {!hasPlans && (
                    <>
                      <div className="col-12">
                        <label className="w-100 mt-4">
                          Características{' '}
                          <InputTag
                            name="features"
                            className="mt-3 input"
                            tags={features}
                            onChangeTags={handleChangeFeature}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="w-100 mt-4">
                          Preço do produto{' '}
                          <InputMask
                            kind="money"
                            name="product_price"
                            className="mt-3 input"
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <div className="w-100 mt-4">
                          <label>Produto em promoção?</label>
                          <div className="d-flex text-center mt-3">
                            <label
                              className={`btn w-100 mx-1 btn-radio ${
                                hasProductDiscount ? 'btn-gray' : 'btn-dark-3'
                              }`}
                            >
                              <span className="d-block py-2">YES</span>
                              <Input
                                type="radio"
                                onChange={() =>
                                  handleChangeHasProductDiscount(true)
                                }
                                name="has_product_discount"
                                id="has_product_discount_yes"
                                className="d-none"
                                value="yes"
                                checked={hasProductDiscount}
                              />
                            </label>
                            <label
                              className={`btn w-100 mx-1 btn-radio ${
                                !hasProductDiscount ? 'btn-gray' : 'btn-dark-3'
                              }`}
                            >
                              <span className="d-block py-2">NO</span>
                              <Input
                                type="radio"
                                onChange={() =>
                                  handleChangeHasProductDiscount(false)
                                }
                                name="has_product_discount"
                                id="has_product_discount_no"
                                className="d-none"
                                value="no"
                                checked={!hasProductDiscount}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {hasProductDiscount && (
                        <div className="col-lg-6">
                          <label className="w-100 mt-4">
                            Valor do produto na promoção{' '}
                            <InputMask
                              kind="money"
                              name="product_discount"
                              className="mt-3 input"
                            />
                          </label>
                        </div>
                      )}
                      <div className="col-lg-6">
                        <label className="w-100 mt-4">
                          Link de pagamento
                          <Input
                            type="url"
                            name="product_checkout_url"
                            className="mt-3 input"
                          />
                        </label>
                      </div>
                      <div className="col-12 pt-5 pb-4">
                        <hr />
                      </div>
                    </>
                  )}
                  {hasPlans && (
                    <>
                      <div className="col-12 pt-5 pb-4">
                        <hr />
                      </div>
                      {plans.map((plan, index) => (
                        <Plan
                          key={index.toString()}
                          className="col-12 position-relative"
                        >
                          <button
                            type="button"
                            className="btn-delete bg-transparent border-0 position-absolute"
                            onClick={() => handleClickDeletePlan(index)}
                          >
                            <MdDelete size={24} color="#FF333D" />
                          </button>
                          <div className="row">
                            <div className="col-lg-6">
                              <label className="w-100 mt-4">
                                Nome do plano{' '}
                                <Input
                                  name="planName"
                                  className="mt-3 input"
                                  value={plan.name}
                                  onChange={(e) =>
                                    handleChangePlanName(e, index)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-lg-3">
                              <label className="w-100 mt-4">
                                Preço do plano{' '}
                                <InputMask
                                  kind="money"
                                  name="plan_price"
                                  className="mt-3 input"
                                  value={formatPrice(plan.price)}
                                  onChange={(e) =>
                                    handleChangePlanPrice(e, index)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-lg-3">
                              <label className="w-100 mt-4">
                                Posição{' '}
                                <Input
                                  type="number"
                                  name="order"
                                  className="mt-3 input"
                                  min={1}
                                  value={plan.order}
                                  onChange={(e) => handleChangeOrder(e, index)}
                                />
                                {plan.orderError && (
                                  <span className="small text-error error">
                                    {plan.orderError}
                                  </span>
                                )}
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="w-100 mt-4">
                                Descrição do plano{' '}
                                <Textarea
                                  name="plan_description"
                                  className="mt-3"
                                  rows={4}
                                  onChange={(e) =>
                                    handleChangePlanDescritpion(e, index)
                                  }
                                  value={plan.description}
                                />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="w-100 mt-4">
                                Características{' '}
                                <InputTag
                                  name="features"
                                  className="mt-3 input"
                                  tags={plan.features}
                                  onChangeTags={(tags) =>
                                    handleChangePlanFeature(tags, index)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="w-100 mt-4">
                                <label>Plano em promoção?</label>
                                <div className="d-flex text-center mt-3">
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      plan.has_discount
                                        ? 'btn-gray'
                                        : 'btn-dark-3'
                                    }`}
                                  >
                                    <span className="d-block py-2">YES</span>
                                    <Input
                                      type="radio"
                                      onChange={() =>
                                        handleChangeHasPlanDiscount(true, index)
                                      }
                                      name="has_plan_discount"
                                      id="has_plan_discount_yes"
                                      className="d-none"
                                      value="yes"
                                      checked={plan.has_discount}
                                    />
                                  </label>
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      !plan.has_discount
                                        ? 'btn-gray'
                                        : 'btn-dark-3'
                                    }`}
                                  >
                                    <span className="d-block py-2">NO</span>
                                    <Input
                                      type="radio"
                                      onChange={() =>
                                        handleChangeHasPlanDiscount(
                                          false,
                                          index
                                        )
                                      }
                                      name="has_plan_discount"
                                      id="has_plan_discount_no"
                                      className="d-none"
                                      value="no"
                                      checked={!plan.has_discount}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                            {plan.has_discount && (
                              <div className="col-lg-6">
                                <label className="w-100 mt-4">
                                  Valor do plano na promoção{' '}
                                  <InputMask
                                    kind="money"
                                    name="plan_discount"
                                    className="mt-3 input"
                                    value={
                                      plan.discount
                                        ? formatPrice(plan.discount)
                                        : ''
                                    }
                                    onChange={(e) =>
                                      handleChangePlanDiscount(e, index)
                                    }
                                  />
                                </label>
                              </div>
                            )}
                            <div className="col-lg-6">
                              <label className="w-100 mt-4">
                                Link de pagamento
                                <Input
                                  type="url"
                                  name="plan_checkout_url"
                                  className="mt-3 input"
                                  value={plan.checkout_url}
                                  onChange={(e) =>
                                    handleChangePlanCheckoutUrl(e, index)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-12 pt-5 pb-4">
                              <hr />
                            </div>
                            <div
                              className={
                                plans.length === index + 1
                                  ? 'd-block mb-3'
                                  : 'd-none'
                              }
                            >
                              <button
                                type="button"
                                className="border-0 bg-transparent"
                                onClick={handleClickAddPlan}
                              >
                                <BsPlus size={24} color="#BBBBBB" />
                                <span className="text-gray fw-bold ms-2">
                                  Plano
                                </span>
                              </button>
                            </div>
                          </div>
                        </Plan>
                      ))}
                      <Input name="plans" className="d-none" />
                    </>
                  )}
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary py-2 fw-bold mt-5 px-5 ms-auto d-block"
                    >
                      Próximo
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Container>
      <Modal show={showCalendar} onHide={handleClose} close>
        <Form onSubmit={handleSave}>
          <Modal.Header className="border-0 ps-4 pt-4" closeButton>
            <h4>Data de lançamento</h4>
          </Modal.Header>
          <Modal.Body className="mb-4">
            <Calendar className="mx-auto">
              <DayPicker
                months={[
                  'Janeiro',
                  'Fevereiro',
                  'Março',
                  'Abril',
                  'Maio',
                  'Junho',
                  'Julho',
                  'Agosto',
                  'Setembro',
                  'Outubro',
                  'Novembro',
                  'Dezembro',
                ]}
                weekdaysShort={[
                  'Dom',
                  'Seg',
                  'Ter',
                  'Qua',
                  'Qui',
                  'Sex',
                  'Sab',
                ]}
                weekdaysLong={[
                  'Domingo',
                  'Segunda-feira',
                  'Terça-feira',
                  'Quarta-feira',
                  'Quinta-feira',
                  'Sexta-feira',
                  'Sábado',
                ]}
                showOutsideDays
                fromMonth={new Date()}
                initialMonth={selectedDate}
                selectedDays={selectedDate}
                modifiers={{
                  available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                }}
                onDayClick={handleDateChange}
                disabledDays={{ before: addDays(new Date(), 1) }}
              />
            </Calendar>
            <label className="w-100 mt-4 px-4">
              Hora do lançamento{' '}
              <InputMask
                kind="custom"
                options={{
                  mask: '99:99:99',
                }}
                placeholder="00:00:00"
                name="time"
                className="mt-3"
                onChange={handleChangeTime}
                value={time}
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary btn-save mx-auto">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ProductsCreate;
