import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  .question {
    transition-duration: 0.3s;
    background: #303030;
    color: #bbbbbb;
    border-radius: 20px;
    position: relative;
    z-index: 2;

    svg {
      transition-duration: 0.3s;
      min-width: 25px;
      height: 25px;
    }
  }

  .front {
    transform: rotateY(0deg);
    transition: 0.3s;
  }

  .front.flip {
    transform: rotateY(90deg);
  }

  .back {
    transition: 0.3s;
    transform: rotateY(90deg);
    form {
      transform: rotateY(180deg);
    }
  }

  .back.flip {
    transform: rotateY(180deg);
  }

  .add-question {
    background: #303030;
    border-radius: 20px 20px 0 0;
  }

  .answer {
    transition-duration: 0.3s;
    background: #242526;
    color: #bbbbbb;
    border-radius: 0 0 20px 20px;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;

    .btn-delete {
      opacity: 0;
      transition: 0.2s;
      height: 0;
      overflow: hidden;
    }

    .btn-edit {
      opacity: 0;
      transition: 0.2s;
      right: 40px;
      bottom: 0;
      z-index: 1;
      height: 0;
      overflow: hidden;
    }
  }

  .question-box.active {
    .question {
      border-radius: 20px 20px 0px 0px;

      svg {
        transform: rotate(180deg);
      }
    }

    .answer {
      height: 250px !important;

      p {
        height: 150px !important;
        margin-top: 1rem;
        overflow: auto;

        ::-webkit-scrollbar {
          background-color: #18191a;
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: #292929;
        }
      }

      .btn-delete,
      .btn-edit {
        bottom: 30px;
        opacity: 1;
        height: unset;
      }
    }
  }
`;
