/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import List from 'react-chatview';
import Swal from 'sweetalert2';

import api from '~/services/api';

import { Container, Modal, Box, RadioButton } from './styles';
import Course from '~/components/Course';

interface IParams {
  slugCategory: string;
  slug: string;
}

interface ICategory {
  id: string;
  name: string;
  selected?: boolean;
}

interface ICategoryData {
  current_page: number;
  last_page: number;
  data: ICategory[];
}

interface ICourse {
  id: string;
  title: string;
  thumbnail: string;
  slug: string;
}

const CategoryCourses: React.FC = () => {
  const params = useParams<IParams>();
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [courseSelected, setCourseSelected] = useState({} as ICourse);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const loadCategory = useCallback(
    async (pageData: number, category_id: string) => {
      const response = await api.get<ICategoryData>(
        `categories/subcategories`,
        {
          params: {
            page: pageData,
          },
        }
      );

      const data: ICategory[] = response.data.data.map((category) => {
        if (category.id === category_id) {
          return { ...category, selected: true };
        }

        return { ...category, selected: false };
      });

      setCategories((state) => [...state, ...data]);
      setLastPage(response.data.last_page);
    },
    []
  );

  useEffect(() => {
    api
      .get(`categories/${params.slugCategory}`)
      .then(async (responseCategory) => {
        const responseSubCategory = await api.get(`categories/${params.slug}`);
        const response = await api.get(
          `courses/categories/${responseSubCategory.data.id}`,
          {
            params: {
              all: true,
            },
          }
        );

        loadCategory(1, responseSubCategory.data.id);

        setCourses(response.data);
        setCategoryName(responseCategory.data.name);
        setSubCategoryId(responseSubCategory.data.id);
        setSubCategoryName(responseSubCategory.data.name);
      });
  }, [loadCategory, params.slug, params.slugCategory]);

  const handleClick = useCallback((course: ICourse) => {
    setCourseSelected(course);
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setCourseSelected({} as ICourse);
    setShow(false);
  }, []);

  const handleClickCategory = useCallback(
    (index) => {
      const newCategories: ICategory[] = categories.map((category, idx) => {
        if (idx === index) {
          return { ...category, selected: true };
        }
        return { ...category, selected: false };
      });

      setCategories(newCategories);
    },
    [categories]
  );

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadCategory(page + 1, subCategoryId);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [subCategoryId, lastPage, loadCategory, page]);

  const handleSubmit = useCallback(async () => {
    await api.delete(
      `courses-categories/${subCategoryId}/${courseSelected.id}`
    );

    const categorySelected = categories.find((category) => category.selected);
    if (categorySelected) {
      const formDataCourseCategory = {
        course_id: courseSelected.id,
        category_id: categorySelected.id,
        order: 1000,
      };

      await api.post('courses-categories', formDataCourseCategory);

      if (categorySelected.id !== subCategoryId) {
        const newCourses = courses.filter(
          (course) => course.id !== courseSelected.id
        );
        setCourses(newCourses);
      }
    }

    const newCategories: ICategory[] = categories.map((category) => {
      if (category.id === subCategoryId) {
        return { ...category, selected: true };
      }
      return { ...category, selected: false };
    });

    setCategories(newCategories);
    handleClose();
  }, [categories, subCategoryId, courseSelected.id, courses, handleClose]);

  return (
    <Container className="pb-5">
      <div className="container px-4 px-lg-3 pt-5">
        <div className="row">
          <div className="col-12 mb-5">
            <h1 className="h2">
              <Link
                to={`${process.env.PUBLIC_URL}/categorias/${params.slugCategory}`}
              >
                <span className="fw-normal text-gray">{categoryName}</span>
              </Link>
              <IoIosArrowForward size={36} color="#BBBBBB" />
              <span className="fw-semibold text-white">{subCategoryName}</span>
            </h1>
          </div>
        </div>
        {courses.length > 0 ? (
          <div className="row bg-dark-2 box px-4 py-5">
            {courses.map((course) => (
              <div key={course.id} className="col-lg-4">
                <Course
                  type="transfer"
                  thumb={course.thumbnail}
                  title={course.title}
                  onClick={() => handleClick(course)}
                />
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="ml-auto">Transferir Curso para:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <Box>
            <div className="p-4">
              <h6 className="big mb-0 fw-normal text-gray">Subcategorias</h6>
            </div>
            <List
              className="p-4"
              scrollLoadThreshold={100}
              onInfiniteLoad={handleLoad}
            >
              {categories.map((category, index) => (
                <RadioButton
                  key={category.id}
                  type="button"
                  onClick={() => handleClickCategory(index)}
                  selected={category.selected}
                >
                  <div />
                  <span>
                    {category.name} <br />
                    {category.id === subCategoryId && (
                      <small>(Categoria Atual)</small>
                    )}
                  </span>
                </RadioButton>
              ))}
            </List>
          </Box>
        </Modal.Body>
        <Modal.Footer className="border-0 px-5">
          <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-primary w-100 btn-submit"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CategoryCourses;
