import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosVideocam } from 'react-icons/io';
import { FileUploader } from 'react-drag-drop-files';

import { BsTrashFill } from 'react-icons/bs';
import { Container, VideoContainer, UploadVideo } from './styles';

interface IInputVideo {
  name: string;
  placeholder?: string;
  className?: string;
  onChange?(value: File): void;
  onRemove?(): void;
  value?: string;
  error?: string;
  refVideoPreview?: React.RefObject<HTMLVideoElement>;
}

const InputVideo: React.FC<IInputVideo> = ({
  name,
  placeholder,
  className,
  onChange,
  onRemove,
  value,
  error,
  refVideoPreview,
}) => {
  const [valuePreview, setValuePreview] = useState('');
  const [fileData, setFileData] = useState<File>({} as File);

  useEffect(() => {
    if (value) {
      setValuePreview(value);
    }
  }, [value]);

  const handleChange = useCallback(
    (file: File) => {
      if (file) {
        setValuePreview(URL.createObjectURL(file));
        if (onChange) {
          onChange(file);
        }
      } else {
        setValuePreview('');
      }
    },
    [onChange]
  );

  const handleClickRemoveFile = useCallback(() => {
    setValuePreview('');
    setFileData({} as File);
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  return (
    <Container>
      <div className="pb-0">
        <VideoContainer className={className}>
          {!valuePreview ? (
            <>
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={['MP4']}
              >
                <UploadVideo>
                  <div className="camera">
                    <IoIosVideocam size={50} color="#BBBBBB" />
                    {placeholder !== '' && (
                      <span className="fw-medium text-gray mt-3">
                        {typeof placeholder === 'string' ? (
                          placeholder
                        ) : (
                          <>
                            Carregue seu arquivo aqui ou <br />
                            <u className="text-white">procure o arquivo</u>
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </UploadVideo>
              </FileUploader>
            </>
          ) : (
            <>
              <video
                ref={refVideoPreview}
                src={valuePreview}
                controls
                className="w-100"
              >
                <track kind="captions" />
              </video>
            </>
          )}
        </VideoContainer>
      </div>
      {valuePreview && (
        <button
          type="button"
          onClick={handleClickRemoveFile}
          className="border-0 bg-transparent btn-remove-video mt-2"
        >
          Remover video
          <BsTrashFill size={20} color="#FC5D4A" className="ms-2" />
        </button>
      )}
      {error && <span className="small text-error error">{error}</span>}
    </Container>
  );
};

export default InputVideo;
