import styled from 'styled-components';
import List from 'react-chatview';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled(List)`
  .flex-wrap-row-reverse {
    flex-wrap: wrap-reverse;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }
  }
`;
