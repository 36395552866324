import styled from 'styled-components';
import List from 'react-chatview';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ICheckbox {
  checked?: boolean;
}

export const ContainerList = styled(List)`
  height: calc(100vh - 61px);

  .box {
    border-radius: 20px;

    button span {
      color: #bbbbbb;
      font-size: 18px;
    }

    .btn-add {
      height: 200px;
    }

    .padding-x-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

export const ContainerDiv = styled.div`
  .box {
    border-radius: 20px;

    button span {
      color: #bbbbbb;
      font-size: 18px;
    }

    .btn-add {
      height: 200px;
    }

    .padding-x-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

export const Steps = styled.div`
  hr {
    background-color: #333333;
  }
`;

export const Checkbox = styled.label<ICheckbox>`
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) => (props.checked ? '#e50914' : '#bbb')};
    border: ${(props) =>
      props.checked ? '1px solid #e50914' : '1px solid rgba(229, 9, 20, 0.24)'};
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .input {
      border-radius: 10px;
      border: 1px solid #454545;
      background-color: #3a3a3a;
      padding: 5px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      height: 44px;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-add {
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bbbbbb !important;
      border-radius: 7px;

      svg {
        position: unset;
      }
    }
  }
`;

export const CoursesList = styled(List)`
  height: calc(100vh - 300px);
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #3a3a3a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonDown = styled.button`
  background: none;
  border: none;

  div {
    background: rgba(32, 32, 32, 0.9);
    border: 1px solid #bbbbbb;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #bbbbbb;
  }

  ::before {
    display: none;
  }
`;
