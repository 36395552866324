import styled, { css } from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ContainerProps {
  hasValidateError?: boolean;
}

interface IQuestionButton {
  active?: boolean;
}

interface InputRadio {
  checked?: boolean;
}

export const Container = styled.div<ContainerProps>`
  .button-quiz {
    background: transparent;
    border-radius: 10px;
    border: 1.5px solid
      ${(props) => (props.hasValidateError ? '#c53030' : '#3e3e3e')};
    padding: 5px 10px;
    color: #828282;
  }
`;

export const Question = styled.div`
  border-radius: 10px;
  padding: 10px;

  .checkbox {
    width: auto;
    padding: 0;
    background: none;
    border: 0;

    input {
      width: auto;
    }
  }

  + div {
    margin-top: 10px;
  }

  .answer {
    + .answer {
      margin-top: 10px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-add {
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bbbbbb !important;
      border-radius: 7px;

      svg {
        position: unset;
      }
    }
  }
`;

export const QuestionButton = styled.button<IQuestionButton>`
  position: relative;
  border-radius: 7px;
  border: none;
  width: 45px;
  height: 45px;
  transition-duration: 0.3s;
  font-weight: 600;
  opacity: ${(props) => (props.active ? 1 : 0.8)};
  background: ${(props) => (props.active ? '#3A3A3A' : '#303030')};
  color: ${(props) => (props.active ? '#FFFFFF' : '#4D4D4D')};

  :hover {
    background: #3a3a3a;
    color: #ffffff;
  }

  + button {
    margin-left: 10px;
  }

  svg {
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const InputRadio = styled.label<InputRadio>`
  .checkbox {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) => (props.checked ? '#FFFFFF' : 'transparent')};
    border: ${(props) =>
      props.checked ? '5px solid #E50914' : '1px solid #585858;'};

    > div {
      display: none;
    }
  }
`;
