import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import Swal from 'sweetalert2';

import { RiEditFill } from 'react-icons/ri';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Category } from './styles';
import Search from '~/components/Search';

interface ICategory {
  id: string;
  name: string;
  icon: string;
  slug: string;
}

interface ICategoryData {
  current_page: number;
  last_page: number;
  data: ICategory[];
}

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadCategories = useCallback(async (pageData: number, search = '') => {
    const response = await api.get<ICategoryData>(`categories`, {
      params: {
        all: true,
        page: pageData,
        search,
      },
    });

    if (pageData === 1) {
      setCategories(response.data.data);
    } else {
      setCategories((state) => [...state, ...response.data.data]);
    }
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadCategories(1);
  }, [loadCategories]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadCategories(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadCategories, page]);

  const handleClickDelete = useCallback(
    (category_id: string) => {
      Swal.fire({
        title: 'Deseja deletar essa categoria?',
        html: '<small>Ao deletar a categoria os cursos pertecente a ela ficarão em rascunhos, caso o curso não houver outra categoria.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`categories/${category_id}`);

            const newCategories = categories.filter(
              (category) => category.id !== category_id
            );
            setCategories(newCategories);

            Toast.fire({
              icon: 'success',
              title: 'Categoria deletada!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [categories]
  );

  const handleSearch = useCallback(
    (value) => {
      loadCategories(1, value);
    },
    [loadCategories]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4 d-flex justify-content-between align-items-center">
            <h1 className="fw-semibold text-white ms-4">Categorias</h1>
            <div className="d-flex align-items-center">
              <Search onSearch={handleSearch} className="search me-3" />
              <Link
                to={`${process.env.PUBLIC_URL}/categorias/cadastrar`}
                className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
              >
                <BsPlus size={24} color="#BBBBBB" />
                <span className="text-gray fw-bold ms-2">Categoria</span>
              </Link>
            </div>
          </div>
          {categories.map((category) => (
            <Category
              key={category.id}
              className="col-md-6 col-xl-4 mb-4 order-1"
            >
              <Link
                to={`${process.env.PUBLIC_URL}/categorias/${category.slug}`}
                className="d-flex flex-column justify-content-center align-items-center bg-dark-2 text-white text-center py-4 px-2 w-100 h-100"
              >
                <img
                  src={category.icon}
                  alt={category.name}
                  className="mt-3 mb-4"
                />
                <span className="text-gray">{category.name}</span>
              </Link>
              <button
                type="button"
                className="bg-transparent border-0 position-absolute"
                onClick={() => handleClickDelete(category.id)}
              >
                <MdDelete size={24} color="#FF333D" />
              </button>
              <Link
                to={`${process.env.PUBLIC_URL}/categorias/${category.slug}/editar`}
                className="btn-edit bg-white border-0 position-absolute d-flex align-items-center justify-content-center"
              >
                <RiEditFill size={20} color="#3A3A3A" />
              </Link>
            </Category>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Categories;
