import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IResourceContent {
  src: string;
}

export const Container = styled.div``;

export const ResourceContent = styled.button<IResourceContent>`
  text-decoration: none;
  transition-duration: 0.3s;
  border: none;
  background: transparent;

  div.thumb {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    display: flex;
    align-items: end;
  }

  div.resource-title {
    transition-duration: 0.3s;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  :hover {
    background: #242526;
    box-shadow: 1px 14px 13px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    div.resource-title {
      padding: 0 1rem;
    }
  }
`;

export const CertificateContent = styled.div<IResourceContent>`
  text-decoration: none;
  transition-duration: 0.3s;
  border: none;
  background: transparent;

  div.thumb {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    display: flex;
    align-items: end;
  }

  div.resource-title {
    transition-duration: 0.3s;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  :hover {
    background: #242526;
    box-shadow: 1px 14px 13px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    div.resource-title {
      padding: 0 1rem;
    }
  }
`;
