import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { GridContextProvider, GridItem, swap } from 'react-grid-dnd';

import { RiEditFill } from 'react-icons/ri';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, GridDropZone, Product } from './styles';
import Search from '~/components/Search';

interface IProduct {
  id: string;
  thumbnail_id: string;
  status_id: string;
  title: string;
  description: string;
  features?: string;
  checkout_url?: string;
  price?: number;
  has_discount: boolean;
  discount?: number;
  hasPlans: boolean;
  slug: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IProductData {
  current_page: number;
  last_page: number;
  data: IProduct[];
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [width, setWidth] = useState(0);
  const [isGrabing, setIsGrabing] = useState(false);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  const showPerRow = useMemo(() => {
    if (width >= 992) {
      return 3;
    }
    return 1;
  }, [width]);

  const loadProducts = useCallback(async (pageData: number, search = '') => {
    const response = await api.get<IProductData>(`products`, {
      params: {
        all: true,
        page: pageData,
        search,
      },
    });

    if (pageData === 1) {
      setProducts(response.data.data);
    } else {
      setProducts((state) => [...state, ...response.data.data]);
    }
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadProducts(1);
  }, [loadProducts]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadProducts(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadProducts, page]);

  const handleClickDelete = useCallback(
    (category_id: string) => {
      Swal.fire({
        title: 'Deseja deletar essa categoria?',
        html: '<small>Ao deletar a categoria os cursos pertecente a ela ficarão em rascunhos, caso o curso não houver outra categoria.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`products/${category_id}`);

            const newProducts = products.filter(
              (category) => category.id !== category_id
            );
            setProducts(newProducts);

            Toast.fire({
              icon: 'success',
              title: 'Categoria deletada!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [products]
  );

  const handleChange = useCallback(
    (_, sourceIndex, targetIndex) => {
      const newProducts = swap(products, sourceIndex, targetIndex);
      newProducts.forEach(async (product, index) => {
        const formData = {
          thumbnail_id: product.thumbnail_id,
          title: product.title,
          description: product.description,
          features: product.features,
          checkout_url: product.checkout_url,
          price: product.price,
          has_discount: product.has_discount,
          discount: product.discount,
          hasPlans: product.hasPlans,
          order: index + 1,
          status_id: product.status_id,
        };

        await api.put(`products/${product.id}`, formData);
      });
      setProducts(newProducts);
    },
    [products]
  );

  const handleGrab = useCallback(() => {
    setIsGrabing(true);
  }, []);

  const handleDrop = useCallback(() => {
    setIsGrabing(false);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadProducts(1, value);
    },
    [loadProducts]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4 d-flex justify-content-between align-items-center">
            <h1 className="fw-semibold text-white ms-4">Produtos</h1>
            <div className="d-flex align-items-center">
              <Search onSearch={handleSearch} className="search me-3" />
              <Link
                to={`${process.env.PUBLIC_URL}/produtos/cadastrar`}
                className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
              >
                <BsPlus size={24} color="#BBBBBB" />
                <span className="text-gray fw-bold ms-2">Produto</span>
              </Link>
            </div>
          </div>
          <div className="col-12">
            <GridContextProvider onChange={handleChange}>
              <GridDropZone
                id="items"
                boxesPerRow={showPerRow}
                rowHeight={350}
                height={Math.ceil(products.length / showPerRow) * 350}
              >
                {products.map((product) => (
                  <GridItem
                    key={product.id}
                    className={`px-3 ${
                      isGrabing ? 'cursor-grabbing' : 'cursor-grab'
                    }`}
                  >
                    <Product
                      src={product.thumbnail.thumbnail_url}
                      className="p-4 d-flex flex-column justify-content-between"
                      onMouseDown={handleGrab}
                      onMouseUp={handleDrop}
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <button
                          type="button"
                          className="bg-transparent border-0"
                          onClick={() => handleClickDelete(product.id)}
                        >
                          <MdDelete size={24} color="#FF333D" />
                        </button>
                        <Link
                          to={`${process.env.PUBLIC_URL}/produtos/${product.slug}`}
                          className="btn-edit bg-white border-0 d-flex align-items-center justify-content-center"
                        >
                          <RiEditFill size={20} color="#3A3A3A" />
                        </Link>
                      </div>
                      <p className="d-flex justify-content-between align-items-end text-white py-4 w-100 mb-0">
                        <span>
                          <span className="text-gray d-block mb-2 fw-bold">
                            {product.title}
                          </span>
                          <span className="text-gray d-block">
                            {product.description}
                          </span>
                        </span>
                      </p>
                    </Product>
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Products;
