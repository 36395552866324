import styled from 'styled-components';

export const Container = styled.div`
  .btn-submit {
    height: 44px;
  }

  .sub-graphs {
    h2 {
      font-size: 1.3rem !important;
    }
  }
`;
