import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.label<ContainerProps>`
  border-radius: 10px;
  border: 1px solid #454545;
  background-color: #3a3a3a;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;

  p {
    width: calc(100% - 24px);
    color: #bbbbbb;
    font-weight: 500;
  }

  svg {
    margin-right: 16px;
    color: #bbbbbb;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;
    `}
`;
