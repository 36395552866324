import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface ICourseLink {
  src: string;
  percent?: number;
}

interface ICourseButton {
  src: string;
  to?: string;
  percent?: number;
}

export const Container = styled.div``;

export const CourseLink = styled(Link)<ICourseLink>`
  text-decoration: none;
  transition-duration: 0.3s;
  position: relative;

  .flag {
    position: absolute;
    right: 20px;
    top: 0;
    width: 54px;
    height: 69px;
    background: #e50914;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 15px;
    }

    > span {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      width: max-content;
      position: absolute;
      top: -70px;
      background: #e50914;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;

      :after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #e50914;
        z-index: -1;
        bottom: -9px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    :hover {
      > span {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  div.thumb {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    display: flex;
    align-items: end;

    ${(props) =>
      props.percent &&
      css`
        div.progress {
          width: 100%;
          height: 6px;
          background: #3a3a3a;
          position: relative;
          border-radius: 0 0 10px 10px;
          overflow: hidden;

          :after {
            content: '';
            width: ${props.percent ? props.percent : 0}%;
            height: 6px;
            background: #e50914;
            position: absolute;
            transition-duration: 0.3s;
          }
        }
      `}
  }

  div.course-title {
    transition-duration: 0.3s;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    div.icon {
      transition-duration: 0.3s;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;

      svg {
        position: absolute;
        transition-duration: 0.3s;
        opacity: 1;
        visibility: visible;
        color: #bbbbbb;
      }
    }
  }

  @media screen and (min-width: 576px) {
    div.thumb {
      height: 200px;
    }
  }

  @media screen and (min-width: 992px) {
    div.thumb {
      height: 225px;
    }

    div.course-title {
      div.icon.hover {
        background: #e50914;

        svg {
          color: #fff;
        }
      }
    }

    :hover {
      background: #242526;
      box-shadow: 1px 14px 13px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      div.course-title {
        padding: 0 1rem;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    div.thumb {
      height: 200px;
    }
  }
`;

export const CourseButton = styled.button<ICourseButton>`
  text-decoration: none;
  transition-duration: 0.3s;
  position: relative;
  border: none;
  background: transparent;

  .flag {
    position: absolute;
    right: 20px;
    top: 0;
    width: 54px;
    height: 69px;
    background: #e50914;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 15px;
    }

    span {
      width: max-content;
      position: absolute;
      top: -40px;
      background: #e50914;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;

      :after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #e50914;
        z-index: -1;
        bottom: -5px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    }
  }

  .btn-highlight {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;
    right: 20px;
    top: 20px;
  }

  div.thumb {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    display: flex;
    align-items: end;

    ${(props) =>
      props.percent &&
      css`
        div.progress {
          width: 100%;
          height: 6px;
          background: #3a3a3a;
          position: relative;
          border-radius: 0 0 10px 10px;
          overflow: hidden;

          :after {
            content: '';
            width: ${props.percent ? props.percent : 0}%;
            height: 6px;
            background: #e50914;
            position: absolute;
            transition-duration: 0.3s;
          }
        }
      `}
  }

  div.course-title {
    transition-duration: 0.3s;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    div.icon {
      transition-duration: 0.3s;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;

      svg {
        position: absolute;
        transition-duration: 0.3s;
        opacity: 1;
        visibility: visible;
        color: #bbbbbb;
      }
    }
  }

  @media screen and (min-width: 576px) {
    div.thumb {
      height: 200px;
    }
  }

  @media screen and (min-width: 992px) {
    div.thumb {
      height: 225px;
    }

    div.course-title {
      div.icon.hover {
        background: #e50914;

        svg {
          color: #fff;
        }
      }
    }

    :hover {
      background: #242526;
      box-shadow: 1px 14px 13px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      div.course-title {
        padding: 0 1rem;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    div.thumb {
      height: 200px;
    }
  }
`;
