import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';

import { Container, Tabs, TabsButton, TabsGroup, Tab } from './styles';
import Search from '~/components/Search';
import Published from './Published';
import Drafts from './Drafts';
import Unlisted from './Unlisted';
import Scheduled from './Scheduled';

interface IHandleLoad {
  load(): void;
}

const Courses: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState('');
  const [openTabs, setOpenTabs] = useState(false);
  const [handleLoad, setHandleLoad] = useState<IHandleLoad>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    load: () => {},
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    switch (location.pathname.slice(8)) {
      case 'rascunhos':
        setTabSelected('drafts');
        break;
      case 'nao-listados':
        setTabSelected('unlisted');
        break;
      case 'agendados':
        setTabSelected('scheduled');
        break;

      default:
        setTabSelected('published');
        break;
    }
  }, [location.pathname]);

  const handleSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const handleSelectTab = useCallback(
    (value) => {
      setTabSelected(value);
      setOpenTabs(false);
      switch (value) {
        case 'drafts':
          history.push(`${process.env.PUBLIC_URL}/cursos/rascunhos`);
          break;
        case 'unlisted':
          history.push(`${process.env.PUBLIC_URL}/cursos/nao-listados`);
          break;
        case 'scheduled':
          history.push(`${process.env.PUBLIC_URL}/cursos/agendados`);
          break;

        default:
          history.push(`${process.env.PUBLIC_URL}/cursos/publicados`);
          break;
      }
    },
    [history]
  );

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  const handlePublishedReady = useCallback((onLoad) => {
    const newHandleload = {
      load: onLoad,
    };
    setHandleLoad(newHandleload);
  }, []);

  const handleDraftsReady = useCallback((onLoad) => {
    const newHandleload = {
      load: onLoad,
    };
    setHandleLoad(newHandleload);
  }, []);

  const handleUnlistedsReady = useCallback((onLoad) => {
    const newHandleload = {
      load: onLoad,
    };
    setHandleLoad(newHandleload);
  }, []);

  const handleScheduledsReady = useCallback((onLoad) => {
    const newHandleload = {
      load: onLoad,
    };
    setHandleLoad(newHandleload);
  }, []);

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad.load}>
      <div className="container py-5">
        <div className="row mb-5">
          <div className="col-lg-6">
            <h1>Listagem de cursos</h1>
          </div>
          <div className="col-lg-6">
            <div className="d-flex align-items-center justify-content-end">
              <Link
                to={`${process.env.PUBLIC_URL}/cursos/cadastrar`}
                className="d-flex align-items-center border-0 btn btn-dark-3 px-4 py-2"
              >
                <BsPlus size={24} color="#BBBBBB" />
                <span className="text-gray fw-bold ms-2">Curso</span>
              </Link>
              <Search onSearch={handleSearch} className="ms-4" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <Tabs className="py-3">
              <TabsButton
                type="button"
                onClick={handleClickTabsButton}
                className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
              >
                {tabSelected === 'published' && 'Publicados'}
                {tabSelected === 'drafts' && 'Rascunhos'}
                <IoIosArrowDown size={24} color="#bbbbbb" className="ms-2" />
              </TabsButton>
              <TabsGroup
                opened={openTabs}
                className="w-100 d-flex flex-column flex-md-row justify-content-md-start align-items-center"
              >
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('published')}
                  active={tabSelected === 'published'}
                  className="border-0 bg-transparent"
                >
                  Publicados
                </Tab>
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('drafts')}
                  active={tabSelected === 'drafts'}
                  className="border-0 bg-transparent"
                >
                  Rascunhos
                </Tab>
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('unlisted')}
                  active={tabSelected === 'unlisted'}
                  className="border-0 bg-transparent"
                >
                  Não listados
                </Tab>
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('scheduled')}
                  active={tabSelected === 'scheduled'}
                  className="border-0 bg-transparent"
                >
                  Agendados
                </Tab>
              </TabsGroup>
            </Tabs>
            <hr />
          </div>
        </div>
        {tabSelected === 'published' && (
          <Published onReady={handlePublishedReady} search={search} />
        )}
        {tabSelected === 'drafts' && (
          <Drafts onReady={handleDraftsReady} search={search} />
        )}
        {tabSelected === 'unlisted' && (
          <Unlisted onReady={handleUnlistedsReady} search={search} />
        )}
        {tabSelected === 'scheduled' && (
          <Scheduled onReady={handleScheduledsReady} search={search} />
        )}
      </div>
    </Container>
  );
};

export default Courses;
