/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* teste */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdLibraryAdd } from 'react-icons/md';
import { FaChevronDown } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';
import Swal from 'sweetalert2';

import api from '~/services/api';

import {
  ContainerList,
  ContainerDiv,
  Steps,
  Checkbox,
  Modal,
  CoursesList,
  Line,
  ButtonDown,
} from './styles';
import Course from '~/components/Course';
import Loading from '~/components/Loading';

interface IPlan {
  id: string;
  plan_id?: string;
  name: string;
  description: string;
  features: string;
  recommended: boolean;
  price: number;
  has_discount: boolean;
  discount: number;
  checkout_url: string;
  order: number;
}

interface IProduct {
  id: string;
  hasPlans: boolean;
  plans: IPlan[];
}

interface ICoursesProducts {
  id: string;
  product_id: string;
  plan_id?: string;
}

interface ICourse {
  id: string;
  course_product_id: string;
  title: string;
  thumbnail: string;
  highlight?: boolean;
  order?: number;
  checked?: boolean;
  coursesProducts?: ICoursesProducts[];
}

interface ICourseData {
  data: ICourse[];
  current_page: number;
  last_page: number;
  to: number;
  total: number;
}

interface IPlanCourse {
  plan_id: string;
  name: string;
  coursesData: ICourseData;
  previous_plan_id?: string;
}

interface IParams {
  slug: string;
}

const IncludedCourses: React.FC = () => {
  const params = useParams<IParams>();
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [show, setShow] = useState(false);
  const [modalCourses, setModalCourses] = useState<ICourse[]>([]);
  const [modalPage, setModalPage] = useState(1);
  const [modalLastPage, setModalLastPage] = useState(0);
  const [product, setProduct] = useState({} as IProduct);
  const [planCourses, setPlanCourses] = useState<IPlanCourse[]>([]);
  const [planSelected, setPlanSelected] = useState<IPlan | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const Container = useMemo(() => {
    if (product.hasPlans && product.plans.length > 0) {
      return ContainerDiv;
    }

    return ContainerList;
  }, [product.hasPlans, product.plans]);

  const loadCourses = useCallback(
    async (
      productIdData: string,
      pageData: number,
      all?: boolean,
      plan?: IPlan,
      search?: string
    ) => {
      if (plan) {
        const response = await api.get<ICourseData>(
          `courses/plans/${plan.id}`,
          {
            params: {
              page: pageData,
              all,
              admin: true,
              search,
            },
          }
        );

        if (all) {
          const data = response.data.data.map((course) => {
            if (course.coursesProducts) {
              const checkCourse = course.coursesProducts.find(
                (courseProduct) =>
                  courseProduct.plan_id && courseProduct.plan_id === plan.id
              );
              if (checkCourse) {
                return { ...course, checked: true };
              }
              return { ...course, checked: false };
            }
            return { ...course, checked: false };
          });
          if (pageData === 1) {
            setModalCourses(data);
          } else {
            setModalCourses((state) => [...state, ...data]);
          }
          setModalLastPage(response.data.last_page);
        } else {
          setPlanCourses((state) => {
            const newPlanCourses = state.slice();
            const planCourseIndex = newPlanCourses.findIndex(
              (planCourse) => planCourse.plan_id === plan.id
            );
            if (planCourseIndex >= 0) {
              newPlanCourses[planCourseIndex].previous_plan_id = plan.plan_id;
              if (pageData === 1) {
                newPlanCourses[planCourseIndex].coursesData = response.data;
              } else {
                newPlanCourses[planCourseIndex].coursesData.data.push(
                  ...response.data.data
                );
                newPlanCourses[planCourseIndex].coursesData.current_page =
                  response.data.current_page;
                newPlanCourses[planCourseIndex].coursesData.last_page =
                  response.data.last_page;
                newPlanCourses[planCourseIndex].coursesData.to =
                  response.data.to;
                newPlanCourses[planCourseIndex].coursesData.total =
                  response.data.total;
              }
            } else {
              newPlanCourses.push({
                plan_id: plan.id,
                name: plan.name,
                coursesData: response.data,
                previous_plan_id: plan.plan_id,
              });
            }

            return newPlanCourses;
          });
        }
      } else {
        const response = await api.get<ICourseData>(
          `courses/products/${productIdData}`,
          {
            params: {
              page: pageData,
              all,
              admin: true,
              search,
            },
          }
        );
        if (all) {
          const data = response.data.data.map((course) => {
            if (course.coursesProducts) {
              const checkCourse = course.coursesProducts.find(
                (courseProduct) => courseProduct.product_id === productIdData
              );
              if (checkCourse) {
                return { ...course, checked: true };
              }
              return { ...course, checked: false };
            }
            return { ...course, checked: false };
          });
          if (pageData === 1) {
            setModalCourses(data);
          } else {
            setModalCourses((state) => [...state, ...data]);
          }
          setModalLastPage(response.data.last_page);
        } else {
          if (pageData === 1) {
            setCourses(response.data.data);
          } else {
            setCourses((state) => [...state, ...response.data.data]);
          }
          setLastPage(response.data.last_page);
        }
      }
    },
    []
  );

  useEffect(() => {
    api.get<IProduct>(`products/${params.slug}`).then(async (response) => {
      if (response.data.hasPlans && response.data.plans.length > 0) {
        for (const plan of response.data.plans) {
          await loadCourses(response.data.id, 1, false, plan);
        }
      } else {
        loadCourses(response.data.id, 1);
      }
      setProduct(response.data);
    });
  }, [loadCourses, params.slug]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadCourses(product.id, page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadCourses, page, product]);

  const handleClickHighlight = useCallback(
    (index) => {
      const newCourses = courses.slice();
      const formData = {
        course_id: newCourses[index].id,
        product_id: product.id,
        highlight: !newCourses[index].highlight,
        order: newCourses[index].order,
      };

      api.put(
        `courses-products/${newCourses[index].course_product_id}`,
        formData
      );

      newCourses[index].highlight = !newCourses[index].highlight;
      setCourses(newCourses);
    },
    [courses, product]
  );

  const handleClickHighlightPlan = useCallback(
    (index, plan_id, courseData) => {
      const newCourses = courseData.slice();
      const formData = {
        course_id: newCourses[index].id,
        product_id: product.id,
        plan_id,
        highlight: !newCourses[index].highlight,
        order: newCourses[index].order,
      };

      api.put(
        `courses-products/${newCourses[index].course_product_id}`,
        formData
      );

      newCourses[index].highlight = !newCourses[index].highlight;
      const newPlanCourses = planCourses.slice();
      const planCourseIndex = newPlanCourses.findIndex(
        (planCourse) => planCourse.plan_id === plan_id
      );
      if (planCourseIndex >= 0) {
        newPlanCourses[planCourseIndex].coursesData.data = newCourses;
        setPlanCourses(newPlanCourses);
      }
    },
    [planCourses, product.id]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickAddCourse = useCallback(
    (planId?: string) => {
      let plan: IPlan | undefined;
      if (planId) {
        plan = product.plans.find((planData) => planData.id === planId);
      }
      loadCourses(product.id, 1, true, plan);
      setShow(true);
      setPlanSelected(plan);
      setModalPage(1);
    },
    [loadCourses, product]
  );

  const handleLoadModal = useCallback(async () => {
    try {
      if (modalPage < modalLastPage) {
        loadCourses(product.id, modalPage + 1, true, planSelected);
        setModalPage(modalPage + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [modalPage, modalLastPage, loadCourses, product.id, planSelected]);

  const handleChangeCheck = useCallback(
    async (courseSelected: ICourse) => {
      const newModalCourses = modalCourses.slice();
      const index = newModalCourses.findIndex(
        (course) => course.id === courseSelected.id
      );
      newModalCourses[index].checked = !newModalCourses[index].checked;

      if (!newModalCourses[index].checked) {
        if (courseSelected.coursesProducts) {
          const courseProduct = courseSelected.coursesProducts.find(
            (courseProductData) => {
              return courseProductData.product_id === product.id;
            }
          );
          if (courseProduct) {
            await api.delete(
              `courses-products/${product.id}/${courseSelected.id}`,
              {
                params: {
                  plan_id: planSelected ? planSelected.id : undefined,
                },
              }
            );

            if (planSelected) {
              const newPlanCourses = planCourses.slice();
              const planCourseIndex = newPlanCourses.findIndex(
                (planCourse) => planCourse.plan_id === planSelected.id
              );
              if (planCourseIndex >= 0) {
                newPlanCourses[planCourseIndex].coursesData.data =
                  newPlanCourses[planCourseIndex].coursesData.data.filter(
                    (course) => course.id !== courseSelected.id
                  );
                setPlanCourses(newPlanCourses);
              }
            } else {
              const newCourses = courses.filter(
                (course) => course.id !== courseSelected.id
              );

              const { coursesProducts } = newModalCourses[index];
              if (coursesProducts) {
                const newCoursesProducts = coursesProducts.filter(
                  (courseProductData) =>
                    courseProductData.id !== courseProduct.id
                );
                newModalCourses[index].coursesProducts = newCoursesProducts;
              }

              setCourses(newCourses);
            }
          }
        }
      } else {
        const formData = {
          course_id: newModalCourses[index].id,
          product_id: product.id,
          plan_id: planSelected?.id,
          highlight: false,
          order: 1000,
        };

        const response = await api.post('courses-products', formData);

        const { coursesProducts } = newModalCourses[index];
        if (coursesProducts) {
          const newCoursesProducts = coursesProducts.slice();
          newCoursesProducts.push({
            id: response.data.id,
            product_id: response.data.product_id,
          });
          newModalCourses[index].coursesProducts = newCoursesProducts;
        }

        if (planSelected) {
          const newPlanCourses = planCourses.slice();
          const planCourseIndex = newPlanCourses.findIndex(
            (planCourse) => planCourse.plan_id === planSelected.id
          );
          if (planCourseIndex >= 0) {
            newPlanCourses[planCourseIndex].coursesData.data.push(
              newModalCourses[index]
            );
            setPlanCourses(newPlanCourses);
          }
        } else {
          setCourses((state) => [...state, newModalCourses[index]]);
        }
      }

      setModalCourses(newModalCourses);
    },
    [courses, modalCourses, planCourses, planSelected, product.id]
  );

  const handleClickLoadMoreCourses = useCallback(
    (subCategoryIndex: number) => {
      try {
        const newPlanCourses = planCourses.slice();
        const { plan_id, coursesData } = newPlanCourses[subCategoryIndex];
        const plan = product.plans.find((planData) => planData.id === plan_id);
        if (plan) {
          if (coursesData.current_page < coursesData.last_page) {
            loadCourses(product.id, coursesData.current_page + 1, false, plan);
          }
        }
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [loadCourses, planCourses, product]
  );

  const handleChangeAddPreviousPlan = useCallback(
    async (e, index) => {
      try {
        setMessage('Linkando cursos...');
        setLoading(true);
        const newPlanCourses = planCourses.slice();
        const previousPlanCourseIndex = newPlanCourses.findIndex(
          (planCourse) => planCourse.plan_id === e.target.value
        );

        const plan = product.plans.find(
          (planData) => planData.id === newPlanCourses[index].plan_id
        );

        if (previousPlanCourseIndex >= 0) {
          if (e.target.checked) {
            const diffCourses = newPlanCourses[
              previousPlanCourseIndex
            ].coursesData.data.filter((course) => {
              return !newPlanCourses[index].coursesData.data.some(
                (courseData) => course.id === courseData.id
              );
            });

            if (diffCourses.length > 0) {
              const diffCoursesPromise = new Promise<void>((resolve) => {
                diffCourses.forEach(async (course, idx) => {
                  const formData = {
                    course_id: course.id,
                    product_id: product.id,
                    plan_id: newPlanCourses[index].plan_id,
                    highlight: course.highlight,
                    order: 1000,
                  };

                  const response = await api.post('courses-products', formData);

                  diffCourses[idx].course_product_id = response.data.id;

                  if (diffCourses.length === idx + 1) {
                    resolve();
                  }
                });
              });

              await diffCoursesPromise;
            }

            newPlanCourses[index].coursesData.data.push(...diffCourses);
            newPlanCourses[index].previous_plan_id =
              newPlanCourses[previousPlanCourseIndex].plan_id;

            if (plan) {
              const planFormData = {
                product_id: product.id,
                plan_id: e.target.value,
                name: plan.name,
                description: plan.description,
                features: plan.features,
                recommended: plan.recommended,
                price: plan.price,
                has_discount: plan.has_discount,
                discount: plan.discount,
                checkout_url: plan.checkout_url,
                order: plan.order,
              };
              await api.put(`plans/${plan.id}`, planFormData);
            }

            const promiseNewPlanCourses = new Promise<void>((resolve) => {
              let loop = true;
              newPlanCourses.forEach(async (planCourse, idx) => {
                if (loop && idx > index) {
                  if (planCourse.previous_plan_id) {
                    const diffCoursesData = newPlanCourses[
                      idx - 1
                    ].coursesData.data.filter((course) => {
                      return !planCourse.coursesData.data.some(
                        (courseData) => course.id === courseData.id
                      );
                    });

                    if (diffCoursesData.length > 0) {
                      const diffCoursesDataPromise = new Promise<void>(
                        (subResolve) => {
                          diffCoursesData.forEach(async (course, subIndex) => {
                            const formData = {
                              course_id: course.id,
                              product_id: product.id,
                              plan_id: planCourse.plan_id,
                              highlight: course.highlight,
                              order: 1000,
                            };

                            const response = await api.post(
                              'courses-products',
                              formData
                            );

                            diffCoursesData[subIndex].course_product_id =
                              response.data.id;

                            if (diffCourses.length === subIndex + 1) {
                              subResolve();
                            }
                          });
                        }
                      );

                      await diffCoursesDataPromise;
                    }

                    newPlanCourses[idx].coursesData.data.push(
                      ...diffCoursesData
                    );
                  } else {
                    loop = false;
                  }
                }

                if (newPlanCourses.length === idx + 1) {
                  resolve();
                }
              });
            });

            await promiseNewPlanCourses;
          } else {
            const promiseNewPlanCourses = new Promise<void>((resolve) => {
              let loop = true;
              newPlanCourses.forEach(async (planCourse, idx) => {
                if (loop && idx > index) {
                  if (planCourse.previous_plan_id) {
                    const equalCourses = newPlanCourses[
                      previousPlanCourseIndex
                    ].coursesData.data.filter((course) => {
                      return newPlanCourses[idx].coursesData.data.some(
                        (courseData) => course.id === courseData.id
                      );
                    });

                    if (
                      JSON.stringify(equalCourses) ===
                      JSON.stringify(
                        newPlanCourses[previousPlanCourseIndex].coursesData.data
                      )
                    ) {
                      const diffCourses = planCourse.coursesData.data.filter(
                        (course) => {
                          return !equalCourses.some(
                            (courseData) => course.id === courseData.id
                          );
                        }
                      );

                      newPlanCourses[idx].coursesData.data = diffCourses;
                      newPlanCourses[idx].coursesData.total -=
                        diffCourses.length;

                      if (equalCourses.length > 0) {
                        setMessage('Deslinkando cursos...');
                        const promiseEqualCourses = new Promise<void>(
                          (subResolve) => {
                            equalCourses.forEach(async (course, subIdx) => {
                              try {
                                await api.delete(
                                  `courses-products/${product.id}/${course.id}`,
                                  {
                                    params: {
                                      plan_id: planCourse.plan_id,
                                    },
                                  }
                                );
                              } catch (error) {
                                Swal.fire(
                                  'Opss...',
                                  'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
                                  'error'
                                );
                              }
                              if (equalCourses.length === subIdx + 1) {
                                subResolve();
                              }
                            });
                          }
                        );

                        await promiseEqualCourses;
                      }
                    }
                  } else {
                    loop = false;
                  }
                }

                if (newPlanCourses.length === idx + 1) {
                  resolve();
                }
              });
            });

            await promiseNewPlanCourses;

            const equalCourses = newPlanCourses[
              previousPlanCourseIndex
            ].coursesData.data.filter((course) => {
              return newPlanCourses[index].coursesData.data.some(
                (courseData) => course.id === courseData.id
              );
            });

            if (
              JSON.stringify(equalCourses) ===
              JSON.stringify(
                newPlanCourses[previousPlanCourseIndex].coursesData.data
              )
            ) {
              const diffCourses = newPlanCourses[index].coursesData.data.filter(
                (course) => {
                  return !equalCourses.some(
                    (courseData) => course.id === courseData.id
                  );
                }
              );

              newPlanCourses[index].coursesData.data = diffCourses;
              newPlanCourses[index].coursesData.total -= diffCourses.length;

              if (equalCourses.length > 0) {
                setMessage('Deslinkando cursos...');
                const promiseEqualCourses = new Promise<void>((resolve) => {
                  equalCourses.forEach(async (course, idx) => {
                    try {
                      await api.delete(
                        `courses-products/${product.id}/${course.id}`,
                        {
                          params: {
                            plan_id: plan ? plan.id : undefined,
                          },
                        }
                      );
                    } catch (error) {
                      Swal.fire(
                        'Opss...',
                        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
                        'error'
                      );
                    }
                    if (equalCourses.length === idx + 1) {
                      resolve();
                    }
                  });
                });

                await promiseEqualCourses;
              }
            }

            if (plan) {
              const planFormData = {
                product_id: product.id,
                plan_id: null,
                name: plan.name,
                description: plan.description,
                features: plan.features,
                recommended: plan.recommended,
                price: plan.price,
                has_discount: plan.has_discount,
                discount: plan.discount,
                checkout_url: plan.checkout_url,
                order: plan.order,
              };
              await api.put(`plans/${plan.id}`, planFormData);
            }

            newPlanCourses[index].previous_plan_id = undefined;
          }
        }

        setPlanCourses(JSON.parse(JSON.stringify(newPlanCourses)));
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      } finally {
        setLoading(false);
        setMessage('');
      }
    },
    [planCourses, product]
  );

  const handleSearch = useCallback(
    async (e) => {
      const { value } = e.target;

      await loadCourses(product.id, 1, true, planSelected, value);
    },
    [loadCourses, planSelected, product.id]
  );

  return (
    <>
      <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-4">
              <h1>Atualizar produto</h1>
            </div>
            <div className="col-12">
              <div className="row box bg-dark-2 p-5">
                <div className="col-12 mb-4">
                  <div className="row justify-content-center my-5">
                    <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                      <Steps className="d-flex ml-auto align-items-center min-width">
                        <Link
                          to={`${process.env.PUBLIC_URL}/produtos/${params.slug}`}
                          className="w-100 text-center bg-dark-3 rounded-pill"
                        >
                          <span className="d-block px-3 py-3 text-gray">
                            Sobre o produto
                          </span>
                        </Link>
                        <hr className="w-25 w-md-50 border-gray mx-2" />
                        <div className="w-100 text-center bg-gray rounded-pill">
                          <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                            Cursos Inclusos
                          </span>
                        </div>
                      </Steps>
                    </div>
                  </div>
                </div>
                {product.hasPlans && product.plans.length > 0 ? (
                  <>
                    {planCourses.map((planCourse, idx) => (
                      <div key={planCourse.plan_id} className="col-12">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h2>{planCourse.name}</h2>
                          {idx !== 0 && (
                            <Checkbox
                              checked={
                                planCourses[idx - 1].plan_id ===
                                planCourse.previous_plan_id
                              }
                              className="d-flex align-items-center"
                            >
                              <span>
                                {planCourses[idx - 1].plan_id ===
                                  planCourse.previous_plan_id && (
                                  <HiCheck size={12} color="#fff" />
                                )}
                              </span>
                              <input
                                type="checkbox"
                                name={`plan_${idx + 1}`}
                                value={planCourses[idx - 1].plan_id}
                                onChange={(e) =>
                                  handleChangeAddPreviousPlan(e, idx)
                                }
                                checked={
                                  planCourses[idx - 1].plan_id ===
                                  planCourse.previous_plan_id
                                }
                                className="d-none"
                              />{' '}
                              Adicionar cursos do plano anterior
                            </Checkbox>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-add btn-dark-3 border-0 w-100 d-flex flex-column align-items-center justify-content-center py-5"
                              onClick={() =>
                                handleClickAddCourse(planCourse.plan_id)
                              }
                            >
                              <MdLibraryAdd
                                size={55}
                                color="#BBBBBB"
                                className="mb-3"
                              />
                              <span>Adicionar curso</span>
                            </button>
                          </div>
                          {planCourse.coursesData.data.map((course, index) => (
                            <div key={course.id} className="col-lg-4">
                              <Course
                                type="product"
                                thumb={course.thumbnail}
                                title={course.title}
                                className="w-100 padding-x-0"
                                highlight={course.highlight}
                                onClickHighlight={() =>
                                  handleClickHighlightPlan(
                                    index,
                                    planCourse.plan_id,
                                    planCourse.coursesData.data
                                  )
                                }
                              />
                            </div>
                          ))}
                          <div>
                            {(idx + 1 < product.plans.length ||
                              planCourse.coursesData.to <
                                planCourse.coursesData.total) && (
                              <Line className="my-5">
                                {planCourse.coursesData.to <
                                  planCourse.coursesData.total && (
                                  <ButtonDown
                                    type="button"
                                    onClick={() =>
                                      handleClickLoadMoreCourses(idx)
                                    }
                                  >
                                    <div>
                                      <FaChevronDown />
                                    </div>
                                  </ButtonDown>
                                )}
                              </Line>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-add btn-dark-3 border-0 w-100 d-flex flex-column align-items-center justify-content-center py-5"
                        onClick={() => handleClickAddCourse()}
                      >
                        <MdLibraryAdd
                          size={55}
                          color="#BBBBBB"
                          className="mb-3"
                        />
                        <span>Adicionar curso</span>
                      </button>
                    </div>
                    {courses.map((course, index) => (
                      <div key={course.id} className="col-lg-4">
                        <Course
                          type="product"
                          thumb={course.thumbnail}
                          title={course.title}
                          className="w-100 padding-x-0"
                          highlight={course.highlight}
                          onClickHighlight={() => handleClickHighlight(index)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-Quiz"
          size="xl"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header
            className="border-0 align-items-center justify-content-start"
            closeButton
          >
            <div>
              <Modal.Title>Cursos</Modal.Title>
              <small className="text-gray">Adicionar/Remover cursos</small>
            </div>
            <div className="ps-5 ms-5 w-50">
              <input
                placeholder="Buscar"
                onChange={handleSearch}
                className="input"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <CoursesList
              scrollLoadThreshold={100}
              onInfiniteLoad={handleLoadModal}
            >
              <div className="row">
                {modalCourses.map((course) => (
                  <div key={course.id} className="col-lg-4">
                    <Course
                      type="check"
                      thumb={course.thumbnail}
                      title={course.title}
                      className="w-100 padding-x-0"
                      onChangeCheck={() => handleChangeCheck(course)}
                      checked={course.checked}
                    />
                  </div>
                ))}
              </div>
            </CoursesList>
          </Modal.Body>
          <Modal.Footer className="border-0" />
        </Modal>
      </Container>
      <Loading show={loading} message={message} />
    </>
  );
};

export default IncludedCourses;
