import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Background, Content } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo-white.svg';

interface IFormData {
  password: string;
  password_confirmation: string;
}

interface IParams {
  token: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('A nova senha é obrigatória'),
          password_confirmation: Yup.string().when('password', {
            is: (password: string) => password && password.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              'As senhas não combinam'
            ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('admins/sessions/change-password', {
          token: params.token,
          password: data.password,
        });

        Toast.fire({
          icon: 'success',
          title: 'Senha alterada!',
        });

        history.push(`${process.env.PUBLIC_URL}/`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [history, params.token]
  );

  return (
    <Container className="container container-lg-fluid">
      <div className="row">
        <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div className="mb-5">
              <h1 className="h2 fw-semibold mb-5">Criar uma nova senha</h1>
            </div>
            <div className="mb-5 py-5">
              <div className="mb-4">
                <label htmlFor="password" className="mb-2 fw-medium">
                  Nova senha
                </label>
                <Input
                  type="password"
                  placeholder="Nova senha"
                  name="password"
                />
              </div>
              <div>
                <label
                  htmlFor="password_confirmation"
                  className="mb-2 fw-medium"
                >
                  Confirmar nova senha
                </label>
                <Input
                  type="password"
                  placeholder="Repetir senha"
                  name="password_confirmation"
                />
              </div>
            </div>
            <div className="mb-5">
              <button
                type="submit"
                className="btn btn-primary mb-5 w-100 fw-semibold"
              >
                Enviar
              </button>
            </div>
          </Form>
        </Content>
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4">
          <div className="px-5 pb-5">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Background>
      </div>
    </Container>
  );
};

export default Login;
