import styled from 'styled-components';

export const Container = styled.div`
  .box {
    border-radius: 20px;

    .input-file {
      position: relative;

      span {
        position: absolute;
        left: 0px;
        bottom: -28px;
      }
    }
  }
`;
