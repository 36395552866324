import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import Select, { IOption } from '~/components/Select';

interface ICategory {
  id: string;
  name: string;
}

interface IFormData {
  title: string;
  description: string;
}

const WaysCreate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbail] = useState<File | undefined>(undefined);
  const [thumbnailError, setThumbailError] = useState('');
  const [categories, setCategories] = useState<IOption[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<IOption[]>([]);

  useEffect(() => {
    if (categoriesSelected.length === 0) {
      api
        .get<ICategory[]>('categories', {
          params: {
            all: true,
            noPagination: true,
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            Swal.fire({
              title: 'Atenção!!!',
              text: 'É preciso cadastrar ao menos uma categoria para cadastrar um caminho.',
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: 'Entendi',
              confirmButtonColor: '#e50914',
            })
              .then(async (result) => {
                if (result.isConfirmed) {
                  history.push(`${process.env.PUBLIC_URL}/categorias`);
                }
              })
              .catch(() => {
                Swal.fire(
                  'Opss...',
                  'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
                  'error'
                );
              });
          }
          const data: IOption[] = response.data.map((category) => ({
            id: category.id,
            value: category.name,
            selected: false,
          }));
          setCategories(data);
        });
    }
  }, [categoriesSelected.length, history]);

  const handleChangeSelect = useCallback((options: IOption[]) => {
    setCategoriesSelected(options);
  }, []);

  const handleChangeText = useCallback(
    async (value) => {
      try {
        const response = await api.get<ICategory[]>('categories', {
          params: {
            all: true,
            noPagination: true,
            search: value,
          },
        });

        const results = response.data.filter((category) => {
          return !categoriesSelected.some((categorySelected) => {
            return category.id === categorySelected.id;
          });
        });

        const data: IOption[] = results.map((category) => ({
          id: category.id,
          value: category.name,
          selected: false,
        }));

        setCategories(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [categoriesSelected]
  );

  const handleChangeThumbnail = useCallback((file) => {
    setThumbailError('');
    setThumbail(file);
  }, []);

  const handleRemoveThumbail = useCallback(() => {
    setThumbail(undefined);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          categories: Yup.string().when('$categories', {
            is: (categoriesCheck: boolean) => categoriesCheck,
            then: Yup.string().required(
              'Pelo menos uma categoria é obrigatória.'
            ),
            otherwise: Yup.string(),
          }),
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            categories: categoriesSelected.length === 0,
            thumbnail: !thumbnail,
          },
        });

        const formDataThumbail = new FormData();
        formDataThumbail.append('thumbnail', thumbnail as File);
        const responseThumbail = await api.post('thumbnails', formDataThumbail);

        const formData = {
          thumbnail_id: responseThumbail.data.id,
          title: data.title,
          description: data.description,
        };

        const response = await api.post('ways', formData);

        if (categoriesSelected.length > 0) {
          const waysCategoriesPromise = new Promise<void>((resolve) => {
            categoriesSelected.forEach(async (category, index) => {
              const formDataWayCategory = {
                category_id: category.id,
                way_id: response.data.id,
              };

              await api.post(`ways-categories`, formDataWayCategory);

              if (categoriesSelected.length === index + 1) {
                resolve();
              }
            });
          });

          await waysCategoriesPromise;
        }

        Toast.fire({
          icon: 'success',
          title: 'Caminho cadastrado!',
        });
        history.push(`${process.env.PUBLIC_URL}/caminhos`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          if (errors.thumbnail) {
            setThumbailError(errors.thumbnail);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [categoriesSelected, history, thumbnail]
  );

  return (
    <Container className="container py-5">
      <Form onSubmit={handleSubmit} className="row">
        <div className="col-12 mb-4">
          <h1>Cadastrar caminhos</h1>
        </div>
        <div className="col-lg-6">
          <div className="box bg-dark-2 p-5">
            <label className="w-100">
              Nome <Input name="title" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              Descrição <Textarea name="description" className="mt-3" />
            </label>
            <div className="w-100 mt-4">
              <label className="w-100">Elencar Categorias</label>
              <Select
                name="categories"
                options={categories}
                multiSelect
                className="mt-3 input-select"
                onChange={(options) =>
                  handleChangeSelect(options as unknown as IOption[])
                }
                onChangeText={handleChangeText}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="w-100 box bg-dark-2 p-5">
            <span className="h5 mb-3 d-block">Thumbnail</span>
            <InputImage
              name="thumbanil"
              className="mt-4"
              cropImage
              aspect={20.44 / 12.63}
              cropOptions={
                !thumbnail
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 12.63 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              onChange={handleChangeThumbnail}
              onRemove={handleRemoveThumbail}
              error={thumbnailError}
            />
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default WaysCreate;
