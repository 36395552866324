import styled from 'styled-components';

interface IContainer {
  show: boolean;
}

export const Container = styled.div<IContainer>`
  transition-duration: 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  > div {
    pointer-events: none;
  }
`;
