import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';

import Dashboard from '~/pages/Dashboard';
import Members from '~/pages/Members';
import MembersProfile from '~/pages/Members/Profile';
import MembersReleaseAccess from '~/pages/Members/ReleaseAccess';
import Groups from '~/pages/Groups';
import Categories from '~/pages/Categories';
import CategoriesCreate from '~/pages/Categories/Create';
import CategoriesUpdate from '~/pages/Categories/Update';
import Category from '~/pages/Categories/Category';
import CategoryCourses from '~/pages/Categories/Category/Courses';
import Courses from '~/pages/Courses';
import CoursesCreate from '~/pages/Courses/Create';
import CoursesUpdate from '~/pages/Courses/Update';
import Modules from '~/pages/Courses/Modules';
import Lessons from '~/pages/Courses/Lessons';
import LessonsCreate from '~/pages/Courses/Lessons/Create';
import LessonsUpdate from '~/pages/Courses/Lessons/Update';
import Downloads from '~/pages/Downloads';
import DownloadsCreate from '~/pages/Downloads/Create';
import DownloadsUpdate from '~/pages/Downloads/Create';
import Faq from '~/pages/Faq';
import Ways from '~/pages/Ways';
import WaysCreate from '~/pages/Ways/Create';
import WaysUpdate from '~/pages/Ways/Update';
import Products from '~/pages/Products';
import ProductsCreate from '~/pages/Products/Create';
import ProductsUpdate from '~/pages/Products/Update';
import IncludedCourses from '~/pages/Products/IncludedCourses';
import Employees from '~/pages/Employees';
import EmployeesCreate from '~/pages/Employees/Create';
import EmployeesUpdate from '~/pages/Employees/Update';
import Support from '~/pages/Support';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resetar-senha/:token`}
        exact
        component={ResetPassword}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/membros`}
        isPrivate
        exact
        component={Members}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/membros/:user_id`}
        isPrivate
        exact
        component={MembersProfile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/membros/:user_id/liberar-acesso/:type`}
        isPrivate
        exact
        component={MembersReleaseAccess}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/grupos`}
        isPrivate
        exact
        component={Groups}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias`}
        isPrivate
        exact
        component={Categories}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/cadastrar`}
        isPrivate
        component={CategoriesCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/:slug`}
        isPrivate
        exact
        component={Category}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/:slug/editar`}
        isPrivate
        exact
        component={CategoriesUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/:slugCategory/:slug/courses`}
        isPrivate
        exact
        component={CategoryCourses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos`}
        exact
        isPrivate
        component={Courses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/publicados`}
        isPrivate
        component={Courses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/rascunhos`}
        isPrivate
        component={Courses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/nao-listados`}
        isPrivate
        component={Courses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/agendados`}
        isPrivate
        component={Courses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/cadastrar`}
        isPrivate
        component={CoursesCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug`}
        exact
        isPrivate
        component={CoursesUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug/modulos`}
        isPrivate
        component={Modules}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug/aulas`}
        isPrivate
        component={Lessons}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug/:moduleSlug/aulas/cadastrar`}
        isPrivate
        component={LessonsCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug/:moduleSlug/aulas/:lessonSlug`}
        isPrivate
        component={LessonsUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/downloads`}
        isPrivate
        component={Downloads}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/downloads/cadastrar`}
        isPrivate
        component={DownloadsCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/downloads/:resource_id`}
        isPrivate
        component={DownloadsUpdate}
      />
      <Route path={`${process.env.PUBLIC_URL}/faq`} isPrivate component={Faq} />
      <Route
        path={`${process.env.PUBLIC_URL}/caminhos`}
        exact
        isPrivate
        component={Ways}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/caminhos/cadastrar`}
        isPrivate
        component={WaysCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/caminhos/:way_id`}
        isPrivate
        component={WaysUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos`}
        exact
        isPrivate
        component={Products}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos/cadastrar`}
        isPrivate
        component={ProductsCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos/:slug`}
        exact
        isPrivate
        component={ProductsUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos/:slug/cursos-inclusos`}
        isPrivate
        component={IncludedCourses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/funcionarios`}
        exact
        isPrivate
        component={Employees}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/funcionarios/cadastrar`}
        isPrivate
        component={EmployeesCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/funcionarios/:admin_id`}
        isPrivate
        component={EmployeesUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/suporte`}
        isPrivate
        component={Support}
      />
    </Switch>
  );
};

export default routes;
