import styled from 'styled-components';
import List from 'react-chatview';

interface IBanner {
  src: string;
}

export const Container = styled(List)`
  height: calc(100vh - 61px);
`;

export const Banner = styled.div<IBanner>`
  background: linear-gradient(
      0deg,
      rgba(48, 48, 48, 0.6),
      rgba(48, 48, 48, 0.6)
    ),
    url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  position: relative;

  h2 {
    line-height: 33.5px;
  }

  button.btn-white {
    top: 30px;
    right: 40px;
  }
`;
