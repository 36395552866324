import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import Select, { IOption } from '~/components/Select';

interface ICategory {
  id: string;
  name: string;
}

interface IWayCategory {
  category: ICategory;
}

interface IWay {
  id: string;
  title: string;
  description: string;
  thumbnail: {
    id: string;
    thumbnail_url: string;
  };
  waysCategories: IWayCategory[];
}

interface IFormData {
  title: string;
  description: string;
}

interface IParams {
  way_id: string;
}

const WaysUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [wayId, setWayId] = useState('');
  const [way, setWay] = useState({} as IFormData);
  const [thumbnailId, setThumbnailId] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [thumbnailError, setThumbnailError] = useState('');
  const [categories, setCategories] = useState<IOption[]>([]);
  const [oldCategories, setOldCategories] = useState<IOption[]>([]);
  const [categoriesSelected, setCategoriesSelected] = useState<IOption[]>([]);

  useEffect(() => {
    api.get<IWay>(`ways/${params.way_id}`).then((response) => {
      setWayId(response.data.id);
      setWay({
        title: response.data.title,
        description: response.data.description,
      });
      setThumbnailId(response.data.thumbnail.id);
      setThumbnailUrl(response.data.thumbnail.thumbnail_url);
      const data: IOption[] = response.data.waysCategories.map(
        (wayCategory) => ({
          id: wayCategory.category.id,
          value: wayCategory.category.name,
          selected: true,
        })
      );
      setOldCategories(data);
      setCategoriesSelected(data);
    });
  }, [params.way_id]);

  useEffect(() => {
    if (categoriesSelected.length === 0) {
      api
        .get<ICategory[]>('categories', {
          params: {
            all: true,
            noPagination: true,
          },
        })
        .then((response) => {
          const data: IOption[] = response.data.map((category) => ({
            id: category.id,
            value: category.name,
            selected: false,
          }));
          setCategories(data);
        });
    }
  }, [categoriesSelected.length]);

  useEffect(() => {
    setCategories((state) => {
      if (state.length > 0 && categoriesSelected.length > 0) {
        const results = state.filter((category) => {
          return !categoriesSelected.some((categorySelected) => {
            return category.id === categorySelected.id;
          });
        });
        return results;
      }

      return state;
    });
  }, [categoriesSelected]);

  const handleChangeSelect = useCallback((options: IOption[]) => {
    setCategoriesSelected(options);
  }, []);

  const handleChangeText = useCallback(
    async (value) => {
      try {
        const response = await api.get<ICategory[]>('categories', {
          params: {
            all: true,
            noPagination: true,
            search: value,
          },
        });

        const results = response.data.filter((category) => {
          return !categoriesSelected.some((categorySelected) => {
            return category.id === categorySelected.id;
          });
        });

        const data: IOption[] = results.map((category) => ({
          id: category.id,
          value: category.name,
          selected: false,
        }));

        setCategories(data);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    [categoriesSelected]
  );

  const handleChangeThumbnail = useCallback((file) => {
    setThumbnailError('');
    setThumbnail(file);
  }, []);

  const handleRemoveThumbnail = useCallback(() => {
    setThumbnail(undefined);
    setThumbnailUrl('');
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          categories: Yup.string().when('$categories', {
            is: (categoriesCheck: boolean) => categoriesCheck,
            then: Yup.string().required(
              'Pelo menos uma categoria é obrigatória.'
            ),
            otherwise: Yup.string(),
          }),
          thumbnail: Yup.string().when('$thumbnail', {
            is: (thumbnailCheck: boolean) => thumbnailCheck,
            then: Yup.string().required('A thumbnail é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            categories: categoriesSelected.length === 0,
            thumbnail: !thumbnail && !thumbnailUrl,
          },
        });

        let thumbnailIdData = thumbnailId;
        if (thumbnail) {
          const formDataThumbnail = new FormData();
          formDataThumbnail.append('thumbnail', thumbnail as File);
          const responseThumbnail = await api.post(
            'thumbnails',
            formDataThumbnail
          );
          thumbnailIdData = responseThumbnail.data.id;
        }

        const formData = {
          thumbnail_id: thumbnailIdData,
          title: data.title,
          description: data.description,
        };

        await api.put(`ways/${wayId}`, formData);

        if (categoriesSelected.length > 0) {
          const categoriesPromise = new Promise<void>((generalResolve) => {
            const deleteCategories = oldCategories.filter((category) => {
              return !categoriesSelected.some(
                (categoryData) => categoryData.id === category.id
              );
            });

            const newCategories = categoriesSelected.filter((category) => {
              return !oldCategories.some(
                (categoryData) => categoryData.id === category.id
              );
            });

            const deleteCategoriesPromise = new Promise<void>((resolve) => {
              if (deleteCategories.length > 0) {
                deleteCategories.forEach(async (category, index) => {
                  await api.delete(`ways-categories/${category.id}/${wayId}`);

                  if (deleteCategories.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            const newCategoriesPromise = new Promise<void>((resolve) => {
              if (newCategories.length > 0) {
                newCategories.forEach(async (category, index) => {
                  const formDataCourseCategory = {
                    way_id: wayId,
                    category_id: category.id,
                  };

                  await api.post('ways-categories', formDataCourseCategory);

                  if (newCategories.length === index + 1) {
                    resolve();
                  }
                });
              } else {
                resolve();
              }
            });

            deleteCategoriesPromise.then(() => {
              newCategoriesPromise.then(() => {
                generalResolve();
              });
            });
          });

          await categoriesPromise;
        }

        Toast.fire({
          icon: 'success',
          title: 'Caminho editado!',
        });
        history.push(`${process.env.PUBLIC_URL}/caminhos`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.thumbnail) {
            setThumbnailError(errors.thumbnail);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [
      categoriesSelected,
      history,
      oldCategories,
      thumbnail,
      thumbnailId,
      thumbnailUrl,
      wayId,
    ]
  );

  return (
    <Container className="container py-5">
      <Form initialData={way} onSubmit={handleSubmit} className="row">
        <div className="col-12 mb-4">
          <h1>Editar caminhos</h1>
        </div>
        <div className="col-lg-6">
          <div className="box bg-dark-2 p-5">
            <label className="w-100">
              Nome <Input name="title" className="mt-3" />
            </label>
            <label className="w-100 mt-4">
              Descrição <Textarea name="description" className="mt-3" />
            </label>
            <div className="w-100 mt-4">
              <label className="w-100">Elencar Categorias</label>
              <Select
                name="categories"
                options={categories}
                optionsSelected={categoriesSelected}
                multiSelect
                className="mt-3 input-select"
                onChange={(options) =>
                  handleChangeSelect(options as unknown as IOption[])
                }
                onChangeText={handleChangeText}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="w-100 box bg-dark-2 p-5">
            <span className="h5 mb-3 d-block">Thumbnail</span>
            <InputImage
              name="icon"
              className="mt-4"
              cropImage
              aspect={20.44 / 12.63}
              cropOptions={
                !thumbnail
                  ? {
                      unit: 'px',
                      width: 20.44 * 5,
                      height: 12.63 * 5,
                      x: 0,
                      y: 0,
                    }
                  : undefined
              }
              onChange={handleChangeThumbnail}
              onRemove={handleRemoveThumbnail}
              error={thumbnailError}
              value={thumbnailUrl}
            />
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default WaysUpdate;
