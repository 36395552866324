import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MdDelete } from 'react-icons/md';
import { IoIosAlbums } from 'react-icons/io';
import { BsPlus } from 'react-icons/bs';
import { Form } from '@unform/web';
import { GridContextProvider, GridItem, swap } from 'react-grid-dnd';
import { Link, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Steps, GridDropZone, Module, Modal } from './styles';
import Input from '~/components/Input';
import Toast from '~/utils/toast';
import Search from '~/components/Search';

interface IModule {
  id: string;
  course_id: string;
  status_id: string;
  title: string;
  order: number;
}

interface IModuleData {
  current_page: number;
  last_page: number;
  data: IModule[];
}

interface IParams {
  slug: string;
}

interface IFormData {
  name: string;
}

const Modules: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const [courseId, setCourseId] = useState('');
  const [modules, setModules] = useState<IModule[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(0);
  const [showPerRow, setShowPerRow] = useState(1);
  const [isGrabing, setIsGrabing] = useState(false);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    if (width >= 992) {
      setShowPerRow(3);
    } else {
      setShowPerRow(1);
    }
  }, [width]);

  const loadModules = useCallback(
    async (course_id: string, pageData: number, search = '') => {
      const response = await api.get<IModuleData>(
        `modules/courses/${course_id}`,
        {
          params: {
            page: pageData,
            search,
          },
        }
      );

      if (pageData === 1) {
        setModules(response.data.data);
      } else {
        setModules((state) => [...state, ...response.data.data]);
      }
      setLastPage(response.data.last_page);
    },
    []
  );

  useEffect(() => {
    api.get(`courses/${params.slug}`).then((response) => {
      loadModules(response.data.id, 1);
      setCourseId(response.data.id);
    });
  }, [loadModules, params.slug]);

  const handleLoad = useCallback(async () => {
    try {
      if (courseId && page < lastPage) {
        loadModules(courseId, page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [courseId, lastPage, loadModules, page]);

  const handleClickCreateModule = useCallback(() => {
    setShow(true);
  }, []);

  const handleClickDeleteModule = useCallback(
    (module_id) => {
      Swal.fire({
        title: 'Deseja deletar essa modulo?',
        html: '<small>Ao deletar o modulo as aulas pertecente a ela ficarão em rascunhos.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`modules/${module_id}`);

            const newModules = modules.filter(
              (module) => module.id !== module_id
            );

            setModules(newModules);

            Toast.fire({
              icon: 'success',
              title: 'Modulo deletado!',
            });
          }
        })
        .catch((error) => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [modules]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          course_id: courseId,
          status_id: '78ac8022-1e62-4b84-affc-c6eef397c649',
          title: data.name,
          order: modules.length + 1,
        };

        const response = await api.post('modules', formData);

        setModules((state) => [...state, response.data]);

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [courseId, handleClose, modules.length]
  );

  const handleChange = useCallback(
    (_, sourceIndex, targetIndex) => {
      const newModules = swap(modules, sourceIndex, targetIndex);
      newModules.forEach(async (module, index) => {
        const formData = {
          course_id: module.course_id,
          status_id: module.status_id,
          title: module.title,
          order: index + 1,
        };

        await api.put(`modules/${module.id}`, formData);
      });
      setModules(newModules);
    },
    [modules]
  );

  const handleGrab = useCallback(() => {
    setIsGrabing(true);
  }, []);

  const handleDrop = useCallback(() => {
    setIsGrabing(false);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadModules(courseId, 1, value);
    },
    [courseId, loadModules]
  );

  return (
    <>
      <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
        <div className="container py-5">
          <div className="box bg-dark-2 p-5">
            <div className="col-12">
              <div className="row align-items-center justify-content-end pb-5">
                <div className="col-12 mb-4">
                  <h1 className="fw-light text-center">
                    Atualizar <b className="fw-bold">curso</b>
                  </h1>
                  <Search
                    onSearch={handleSearch}
                    className="search w-lg-25 mt-3 mx-auto"
                    placeholder="Buscar Modulos"
                  />
                </div>
                <div className="col-12">
                  <div className="row justify-content-center my-5">
                    <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                      <Steps className="d-flex ml-auto align-items-center min-width">
                        <Link
                          to={`${process.env.PUBLIC_URL}/cursos/${params.slug}`}
                          className="w-100 text-center bg-dark-3 rounded-pill"
                        >
                          <span className="d-block px-3 py-3 text-gray">
                            Sobre do curso
                          </span>
                        </Link>
                        <hr className="w-25 w-md-50 border-gray mx-2" />
                        <div className="w-100 text-center bg-gray rounded-pill">
                          <span className="d-block px-3 py-3 text-dark-1 fw-semibold">
                            Módulos
                          </span>
                        </div>
                        <hr className="w-25 w-md-50 border-gray mx-2" />
                        <Link
                          to={`${process.env.PUBLIC_URL}/cursos/${params.slug}/aulas`}
                          className="w-100 text-center bg-dark-3 rounded-pill"
                        >
                          <span className="d-block px-3 py-3 text-gray">
                            Aulas
                          </span>
                        </Link>
                      </Steps>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 px-0 me-4">
                  <button
                    type="button"
                    className="d-flex align-items-center justify-content-center border-0 btn btn-dark-3 px-4 py-3 w-100"
                    onClick={handleClickCreateModule}
                  >
                    <BsPlus size={24} color="#BBBBBB" />
                    <span className="text-gray fw-bold ms-2">Modulo</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 modules-dnd">
                  <GridContextProvider onChange={handleChange}>
                    <GridDropZone
                      id="items"
                      boxesPerRow={showPerRow}
                      rowHeight={200}
                      height={Math.ceil(modules.length / showPerRow) * 200}
                    >
                      {modules.map((module) => (
                        <GridItem
                          key={module.id}
                          className={
                            isGrabing ? 'cursor-grabbing' : 'cursor-grab'
                          }
                        >
                          <Module
                            className="mx-3"
                            onMouseDown={handleGrab}
                            onMouseUp={handleDrop}
                          >
                            <div className="d-flex flex-column align-items-center bg-dark-2 text-white text-center py-4 px-2 w-100 h-100">
                              <IoIosAlbums
                                size={50}
                                color="#bbbbbb"
                                className="mt-3 mb-4"
                              />
                              <span className="text-gray">{module.title}</span>
                            </div>
                            <button
                              type="button"
                              className="bg-transparent border-0 position-absolute"
                              onClick={() => handleClickDeleteModule(module.id)}
                            >
                              <MdDelete size={24} color="#FF333D" />
                            </button>
                          </Module>
                        </GridItem>
                      ))}
                    </GridDropZone>
                  </GridContextProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} close>
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="border-0 ps-4 pt-4" closeButton>
            <h4>Adicionar novo módulo</h4>
          </Modal.Header>
          <Modal.Body className="mb-4 px-5">
            <label className="w-100">
              <span>Nome</span>
              <Input name="name" className="mt-3" placeholder="ex: Revit" />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0 px-5">
            <button
              type="submit"
              className="btn btn-primary fw-bold w-100 py-2"
            >
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Modules;
