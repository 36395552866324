import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format, parseISO } from 'date-fns';
import readXlsxFile from 'read-excel-file';
import { BsPlus, BsThreeDotsVertical } from 'react-icons/bs';
import { IoIosArrowRoundDown } from 'react-icons/io';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

import { Form } from '@unform/web';
import api from '~/services/api';
import mask from '~/utils/mask';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Modal, Options } from './styles';
import InputFile from '~/components/InputFile';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';

import Search from '~/components/Search';

interface IUserResponse {
  id: string;
  name: string;
  email: string;
}

interface ICourseResponse {
  id: string;
  title: string;
}

interface IProductResponse {
  id: string;
  title: string;
  plans: {
    id: string;
    name: string;
  }[];
}

interface IGroupResponse {
  id: string;
  name: string;
  created_at: string;
  groupUsers: {
    user: IUserResponse;
  }[];
  groupCourses: {
    course: ICourseResponse;
  }[];
  groupProducts: {
    product: IProductResponse;
  }[];
}

interface IGroupData {
  data: IGroupResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IGroup {
  id: string;
  group_id: string;
  name: string;
  created_at: string;
  users: IUserResponse[];
  courses: ICourseResponse[];
  products: IProductResponse[];
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IImportedUser {
  name: string;
  email: string;
  course: string;
  document: string;
  birthdate?: Date;
  phone: string;
  zipcode: string;
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  occupation?: string;
  origin: string;
  know_leiaut: string;
  other_instagram?: string;
  events?: string;
  college_name: string;
}

interface IFormData {
  name: string;
}

const Groups: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formEditRef = useRef<FormHandles>(null);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [importedUsers, setImportedUsers] = useState<IImportedUser[]>([]);
  const [users, setUsers] = useState<IOption[]>([
    {
      id: undefined,
      value: 'Selecione',
      selected: true,
      notSelectable: true,
    },
  ]);
  const [courses, setCourses] = useState<IOption[]>([
    {
      id: undefined,
      value: 'Selecione',
      selected: true,
      notSelectable: true,
    },
  ]);
  const [products, setProducts] = useState<IOption[]>([
    {
      id: undefined,
      value: 'Selecione',
      selected: true,
      notSelectable: true,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [page, setPage] = useState(1);
  const [groupSelected, setGroupSelected] = useState({} as IGroup);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [studentActionSelected, setStudentActionSelected] = useState('add');
  const [groupType, setGroupType] = useState('courses');
  const [usersSelected, setUsersSelected] = useState<IOption[]>([]);
  const [usersSelectedOld, setUsersSelectedOld] = useState<IOption[]>([]);
  const [coursesSelected, setCoursesSelected] = useState<IOption[]>([]);
  const [coursesSelectedOld, setCoursesSelectedOld] = useState<IOption[]>([]);
  const [productsSelected, setProductsSelected] = useState<IOption[]>([]);
  const [productsSelectedOld, setProductsSelectedOld] = useState<IOption[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<IOption[]>([]);
  const [deletedCourses, setDeletedCourses] = useState<IOption[]>([]);
  const [deletedProducts, setDeletedProducts] = useState<IOption[]>([]);

  const loadGroups = useCallback(async (pageData, search = '') => {
    const response = await api.get<IGroupData>('groups', {
      params: { page: pageData, search },
    });
    const data: IGroup[] = response.data.data.map((group, index) => ({
      id: (index + 1).toString().padStart(5, '0'),
      group_id: group.id,
      name: group.name,
      created_at: format(parseISO(group.created_at), 'dd/MM/yyyy'),
      courses: group.groupCourses.map((groupCourse) => groupCourse.course),
      users: group.groupUsers.map((groupUser) => groupUser.user),
      products: group.groupProducts.map((groupProduct) => groupProduct.product),
    }));

    setGroups(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    loadGroups(page);
  }, [loadGroups, page]);

  const handleClickDots = useCallback(
    (group: IGroup) => {
      if (groupSelected.id === group.id) {
        setUsers([]);
        setUsersSelectedOld([]);
        setCourses([]);
        setCoursesSelectedOld([]);
        setProducts([]);
        setProductsSelectedOld([]);
        setGroupSelected({} as IGroup);
      } else {
        const usersData = group.users.map<IOption>((user) => ({
          id: user.id,
          value: user.name,
          selected: true,
          notSelectable: true,
        }));

        const coursesData = group.courses.map<IOption>((course) => ({
          id: course.id,
          value: course.title,
          selected: true,
          notSelectable: true,
        }));

        const productsData = group.products.map<IOption>((product) => ({
          id: product.id,
          value: product.title,
          selected: true,
          notSelectable: true,
        }));

        setUsersSelected(usersData);
        setUsersSelectedOld(usersData);
        setCoursesSelected(coursesData);
        setCoursesSelectedOld(coursesData);
        setProductsSelected(productsData);
        setProductsSelectedOld(productsData);
        setGroupSelected(group);
      }
    },
    [groupSelected.id]
  );

  const handleClickEditGroup = useCallback(async () => {
    const response = await api.get<IGroupResponse>(
      `groups/${groupSelected.group_id}`
    );
    const usersData = response.data.groupUsers.map(
      (groupUser) => groupUser.user
    );
    const coursesData = response.data.groupCourses.map(
      (groupCourse) => groupCourse.course
    );
    const productsData = response.data.groupProducts.map(
      (groupProduct) => groupProduct.product
    );

    const usersSelectedData = usersData.map<IOption>((user) => ({
      id: user.id,
      value: user.name,
      selected: true,
      notSelectable: true,
    }));
    const coursesSelectedData = coursesData.map<IOption>((course) => ({
      id: course.id,
      value: course.title,
      selected: true,
      notSelectable: true,
    }));

    const productsSelectedData = productsData.map<IOption>((product) => ({
      id: product.id,
      value: product.title,
      selected: true,
      notSelectable: true,
    }));

    setGroupSelected((state) => ({
      id: state.id,
      group_id: response.data.id,
      name: response.data.name,
      created_at: response.data.created_at,
      users: usersData,
      courses: coursesData,
      products: productsData,
    }));
    setUsersSelected(usersSelectedData);
    setUsersSelectedOld(usersSelectedData);
    setCoursesSelected(coursesSelectedData);
    setCoursesSelectedOld(coursesSelectedData);
    setProductsSelected(productsSelectedData);
    setProductsSelectedOld(productsSelectedData);
    setShowEditGroup(true);
  }, [groupSelected.group_id]);

  const handleClickDeleteGroup = useCallback(async () => {
    Swal.fire({
      title: 'Deseja deletar esse grupo?',
      html: '<small>Ao deletar o grupo os acessos serão removidos.</small>',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#e50914',
      cancelButtonColor: '#303030',
      cancelButtonText: 'Não',
      reverseButtons: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          setLoadingMessage('Removendo os acessos');
          await api.delete(`groups/${groupSelected.group_id}`);

          const newGroups = groups.filter(
            (group) => group.group_id !== groupSelected.group_id
          );
          setGroups(newGroups);

          Toast.fire({
            icon: 'success',
            title: 'Grupo deletado!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [groupSelected.group_id, groups]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Grupo',
        selector: 'name',
      },
      {
        name: 'Data de criaçao',
        selector: 'joined_at',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IGroup) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options active={groupSelected.id === row.id}>
              <button type="button" onClick={handleClickEditGroup}>
                Editar grupo
              </button>
              <button type="button" onClick={handleClickDeleteGroup}>
                Deletar grupo
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleClickDots,
      handleClickEditGroup,
      handleClickDeleteGroup,
      groupSelected.id,
    ]
  );

  const modalColumns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'name',
        cell: (_, rowIndex) => (rowIndex + 1).toString().padStart(2, '0'),
      },
      {
        name: 'Usuário',
        selector: 'name',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Curso',
        selector: 'course',
      },
      {
        name: 'Documento',
        selector: 'document',
        cell: (row: IImportedUser) =>
          mask({
            kind: 'cpf',
            value: row.document,
          }),
      },
      {
        name: 'Data de nascimento',
        selector: 'birthdate',
        cell: (row: IImportedUser) =>
          row.birthdate ? format(row.birthdate, 'dd/MM/yyyy') : '-',
      },
      {
        name: 'Telefone/Celular',
        selector: 'phone',
        cell: (row: IImportedUser) =>
          mask({
            kind: 'cel-phone',
            value: row.phone,
          }),
      },
      {
        name: 'CEP',
        selector: 'zipcode',
        cell: (row: IImportedUser) =>
          mask({
            kind: 'zip-code',
            value: row.zipcode,
          }),
      },
      {
        name: 'Endereço',
        selector: 'street',
      },
      {
        name: 'Número',
        selector: 'number',
      },
      {
        name: 'Complemento',
        selector: 'complement',
        cell: (row: IImportedUser) => row.complement || '-',
      },
      {
        name: 'Bairro',
        selector: 'neighborhood',
      },
      {
        name: 'Cidade',
        selector: 'city',
      },
      {
        name: 'Estado',
        selector: 'state',
      },
      {
        name: 'Profissão',
        selector: 'occupation',
        cell: (row: IImportedUser) => row.occupation || '-',
      },
      {
        name: 'Origem',
        selector: 'origin',
      },
      {
        name: 'Como você conheceu a escola?',
        selector: 'know_leiaut',
      },
      {
        name: 'Se você selecionou "outro instagram" diga qual.',
        selector: 'other_instagram',
        cell: (row: IImportedUser) => row.other_instagram || '-',
      },
      {
        name: 'Se você escolheu "Eventos" diga qual.',
        selector: 'events',
        cell: (row: IImportedUser) => row.events || '-',
      },
      {
        name: 'Qual o nome de sua Faculdade/Universidade?',
        selector: 'college_name',
      },
    ],
    []
  );

  const handleClose = useCallback(() => {
    setShowAddGroup(false);
    setShowEditGroup(false);
    setImportedUsers([]);
    setDeletedUsers([]);
    setDeletedCourses([]);
    setDeletedProducts([]);
  }, []);

  const handleChange = useCallback(async (file) => {
    try {
      setLoadingMessage('Verificando arquivo...');
      setLoading(true);
      const rows = await readXlsxFile(file);
      if (rows.length > 0) {
        const usersData: IImportedUser[] = [];
        const rowsPromise = new Promise<void>((resolve) => {
          rows.forEach(async (row, index) => {
            if (index > 0) {
              const newUser = {} as IImportedUser;
              const rowPromise = new Promise<void>((resolveRow) => {
                row.forEach((data, idx) => {
                  switch (idx) {
                    case 0:
                      newUser.name = data as string;
                      break;
                    case 1:
                      newUser.email = data as string;
                      break;
                    case 2:
                      newUser.document = data
                        ? `${data}`.replaceAll('.', '').replace('-', '')
                        : '-';
                      break;
                    case 3:
                      newUser.birthdate = data
                        ? new Date(`${data}`)
                        : undefined;
                      break;
                    case 4:
                      newUser.phone = data
                        ? `${data}`
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('-', '')
                        : '-';
                      break;
                    case 5:
                      newUser.street = data ? `${data}` : '-';
                      break;
                    case 6:
                      newUser.number = data ? `${data}` : '-';
                      break;
                    case 7:
                      newUser.complement = data ? `${data}` : undefined;
                      break;
                    case 8:
                      newUser.neighborhood = data ? `${data}` : '-';
                      break;
                    case 9:
                      newUser.zipcode = data ? `${data}`.replace('-', '') : '-';
                      break;
                    case 10:
                      newUser.city = data ? `${data}` : '-';
                      break;
                    case 11:
                      newUser.state = data ? `${data}` : '-';
                      break;
                    case 12:
                      newUser.occupation = data ? `${data}` : '-';
                      break;
                    case 13:
                      newUser.course = data ? `${data}` : '-';
                      break;
                    case 14:
                      newUser.origin = data ? `${data}` : '-';
                      break;
                    case 15:
                      newUser.know_leiaut = data ? `${data}` : '-';
                      break;
                    case 16:
                      newUser.other_instagram = data ? `${data}` : '-';
                      break;
                    case 17:
                      newUser.events = data ? `${data}` : '-';
                      break;
                    case 18:
                      newUser.college_name = data ? `${data}` : '-';
                      break;
                    default:
                      break;
                  }
                  if (row.length === idx + 1) {
                    resolveRow();
                  }
                });
              });
              await rowPromise;
              usersData.push(newUser);
            }
            if (rows.length === index + 1) {
              resolve();
            }
          });
        });
        await rowsPromise;
        setImportedUsers(usersData);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }, []);

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleClickAddGroup = useCallback(() => {
    setShowAddGroup(true);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('groups', {
          name: data.name,
        });

        const usersData = usersSelected.slice();

        setLoadingMessage('Importando membros...');
        const formData = importedUsers.map((user) => ({
          name: user.name,
          email: user.email,
          document: user.document !== '-' ? user.document : undefined,
          birthdate: user.birthdate,
          phone: user.phone !== '-' ? user.phone : undefined,
          street: user.street !== '-' ? user.street : undefined,
          number: user.number !== '-' ? user.number : undefined,
          complement: user.complement,
          neighborhood:
            user.neighborhood !== '-' ? user.neighborhood : undefined,
          zipcode: user.zipcode !== '-' ? user.zipcode : undefined,
          city: user.city !== '-' ? user.city : undefined,
          state: user.state !== '-' ? user.state : undefined,
          occupation: user.occupation !== '-' ? user.occupation : undefined,
          course: user.course !== '-' ? user.course : undefined,
          origin: user.origin !== '-' ? user.origin : undefined,
          know_leiaut: user.know_leiaut !== '-' ? user.know_leiaut : undefined,
          other_instagram:
            user.other_instagram !== '-' ? user.other_instagram : undefined,
          events: user.events !== '-' ? user.events : undefined,
          college_name:
            user.college_name !== '-' ? user.college_name : undefined,
        }));
        const responseImported = await api.post('users/import', formData);

        const usersImported = responseImported.data.map(
          (userImported: any) => ({
            id: userImported.userData.id,
            value: userImported.userData.name,
            selected: false,
            notSelectable: false,
          })
        );

        if (usersImported.length > 0) {
          usersData.push(...usersImported);
        }

        const newUsers: IUserResponse[] = [];
        if (usersData.length > 0) {
          const dataUsers = usersData.filter(
            (user, index, self) =>
              index === self.findIndex((usr) => usr.id === user.id)
          );
          if (dataUsers.length > 0) {
            const usersPromise = new Promise<void>((resolve) => {
              dataUsers.forEach(async (user, index) => {
                const responseUser = await api.post('groups-users', {
                  group_id: response.data.id,
                  user_id: user.id,
                });

                console.log(newUsers);

                newUsers.push(responseUser.data);
                if (dataUsers.length === index + 1) {
                  resolve();
                }
              });
            });

            setLoadingMessage('Inserindo membros no grupo...');
            await usersPromise;
          }
        }

        const newCourses: ICourseResponse[] = [];
        if (coursesSelected.length > 0) {
          const coursesPromise = new Promise<void>((resolve) => {
            coursesSelected.forEach(async (course, index) => {
              const responseCourse = await api.post('groups-courses', {
                group_id: response.data.id,
                course_id: course.id,
              });

              newCourses.push(responseCourse.data);

              if (coursesSelected.length === index + 1) {
                resolve();
              }
            });
          });

          setLoadingMessage('Inserindo cursos no grupo...');
          await coursesPromise;
        }

        const newProducts: IProductResponse[] = [];
        if (productsSelected.length > 0) {
          const productsPromise = new Promise<void>((resolve) => {
            productsSelected.forEach(async (product, index) => {
              const [product_id, plan_id] = product.id
                ? product.id.toString().split('/')
                : [];

              const responseProduct = await api.post('groups-products', {
                group_id: response.data.id,
                product_id,
                plan_id,
              });

              newProducts.push(responseProduct.data);

              if (productsSelected.length === index + 1) {
                resolve();
              }
            });
          });

          setLoadingMessage('Inserindo produtos no grupo...');
          await productsPromise;
        }

        setLoadingMessage('Liberando acessos');
        await api.patch(`groups/link/${response.data.id}`);

        setGroups((state) => [
          ...state,
          {
            id: (state.length + 1).toString().padStart(5, '0'),
            group_id: response.data.id,
            name: response.data.name,
            created_at: format(
              parseISO(response.data.created_at),
              'dd/MM/yyyy'
            ),
            users: newUsers,
            courses: newCourses,
            products: newProducts,
          },
        ]);

        handleClose();
        Toast.fire({
          icon: 'success',
          title: 'Grupo criado!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      } finally {
        setLoading(false);
        setLoadingMessage('');
      }
    },
    [
      coursesSelected,
      handleClose,
      importedUsers,
      productsSelected,
      usersSelected,
    ]
  );

  const handleSubmitEdit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put(`groups/${groupSelected.group_id}`, {
          name: data.name,
        });

        const usersData = usersSelected.slice();

        setLoadingMessage('Importando membros...');
        const formData = importedUsers.map((user) => ({
          name: user.name,
          email: user.email,
          document: user.document !== '-' ? user.document : undefined,
          birthdate: user.birthdate,
          phone: user.phone !== '-' ? user.phone : undefined,
          street: user.street !== '-' ? user.street : undefined,
          number: user.number !== '-' ? user.number : undefined,
          complement: user.complement,
          neighborhood:
            user.neighborhood !== '-' ? user.neighborhood : undefined,
          zipcode: user.zipcode !== '-' ? user.zipcode : undefined,
          city: user.city !== '-' ? user.city : undefined,
          state: user.state !== '-' ? user.state : undefined,
          occupation: user.occupation !== '-' ? user.occupation : undefined,
          course: user.course !== '-' ? user.course : undefined,
          origin: user.origin !== '-' ? user.origin : undefined,
          know_leiaut: user.know_leiaut !== '-' ? user.know_leiaut : undefined,
          other_instagram:
            user.other_instagram !== '-' ? user.other_instagram : undefined,
          events: user.events !== '-' ? user.events : undefined,
          college_name:
            user.college_name !== '-' ? user.college_name : undefined,
        }));
        const responseImported = await api.post('users/import', formData);

        const usersImported = responseImported.data.map(
          (userImported: any) => ({
            id: userImported.id,
            value: userImported.name,
            selected: false,
            notSelectable: false,
          })
        );

        if (usersImported.length > 0) {
          usersData.push(...usersImported);
        }

        if (deletedUsers.length > 0) {
          const deletedUsersPromise = new Promise<void>((resolve) => {
            deletedUsers.forEach(async (user, index) => {
              await api.delete(
                `groups-users/${groupSelected.group_id}/${user.id}`
              );

              if (deletedUsers.length === index + 1) {
                resolve();
              }
            });
          });

          setLoadingMessage('Removendo membros do grupo...');
          await deletedUsersPromise;
        }

        const userDatas = usersData.filter(
          (user) =>
            !deletedUsers.find((userData) => userData.id === user.id) &&
            !usersSelectedOld.find((userData) => userData.id === user.id)
        );

        if (userDatas.length > 0) {
          const usersPromise = new Promise<void>((resolve) => {
            userDatas.forEach(async (user, index) => {
              await api.post('groups-users', {
                group_id: response.data.id,
                user_id: user.id,
              });

              if (userDatas.length === index + 1) {
                resolve();
              }
            });
          });

          setLoadingMessage('Inserindo membros no grupo...');
          await usersPromise;
        }

        if (deletedCourses.length > 0) {
          const deletedCoursesPromise = new Promise<void>((resolve) => {
            deletedCourses.forEach(async (course, index) => {
              await api.delete(
                `groups-courses/${groupSelected.group_id}/${course.id}`
              );

              if (deletedCourses.length === index + 1) {
                resolve();
              }
            });
          });

          setLoadingMessage('Removendo cursos do grupo...');
          await deletedCoursesPromise;
        }

        const coursesDatas = coursesSelected.filter(
          (course) =>
            !deletedCourses.find((courseData) => courseData.id === course.id) &&
            !coursesSelectedOld.find(
              (courseData) => courseData.id === course.id
            )
        );

        if (coursesDatas.length > 0) {
          const coursesPromise = new Promise<void>((resolve) => {
            coursesDatas.forEach(async (course, index) => {
              await api.post('groups-courses', {
                group_id: response.data.id,
                course_id: course.id,
              });

              if (coursesDatas.length === index + 1) {
                resolve();
              }
            });
          });
          setLoadingMessage('Inserindo cursos no grupo...');
          await coursesPromise;
        }

        if (deletedProducts.length > 0) {
          const deletedProductsPromise = new Promise<void>((resolve) => {
            deletedProducts.forEach(async (product, index) => {
              const [product_id, plan_id] = product.id
                ? product.id.toString().split('/')
                : [];

              if (plan_id) {
                await api.delete(
                  `groups-products/${groupSelected.group_id}/${product_id}/${plan_id}`
                );
              } else {
                await api.delete(
                  `groups-products/${groupSelected.group_id}/${product_id}`
                );
              }

              if (deletedProducts.length === index + 1) {
                resolve();
              }
            });
          });

          setLoadingMessage('Removendo produtos do grupo...');
          await deletedProductsPromise;
        }

        const productsDatas = productsSelected.filter(
          (product) =>
            !deletedProducts.find(
              (productData) => productData.id === product.id
            ) &&
            !productsSelectedOld.find(
              (productData) => productData.id === product.id
            )
        );

        if (productsDatas.length > 0) {
          const productsPromise = new Promise<void>((resolve) => {
            productsDatas.forEach(async (product, index) => {
              const [product_id, plan_id] = product.id
                ? product.id.toString().split('/')
                : [];
              await api.post('groups-products', {
                group_id: response.data.id,
                product_id,
                plan_id,
              });

              if (productsDatas.length === index + 1) {
                resolve();
              }
            });
          });
          setLoadingMessage('Inserindo produtos no grupo...');
          await productsPromise;
        }

        setLoadingMessage('Liberando acessos');
        await api.patch(`groups/link/${response.data.id}`);

        handleClose();
        Toast.fire({
          icon: 'success',
          title: 'Grupo editado!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      } finally {
        setLoading(false);
        setLoadingMessage('');
      }
    },
    [
      coursesSelected,
      coursesSelectedOld,
      deletedCourses,
      deletedProducts,
      deletedUsers,
      groupSelected.group_id,
      handleClose,
      importedUsers,
      productsSelected,
      productsSelectedOld,
      usersSelected,
      usersSelectedOld,
    ]
  );

  const handleSearch = useCallback(
    (value) => {
      loadGroups(1, value);
    },
    [loadGroups]
  );

  const handleSelectStudentAction = useCallback((action) => {
    setStudentActionSelected(action);
  }, []);

  const handleSelectGroupType = useCallback((option) => {
    setGroupType(option);
  }, []);

  const handleChangeUser = useCallback(async (value) => {
    try {
      const response = await api.get<IUserResponse[]>('users', {
        params: {
          search: value,
          noPagination: true,
        },
      });

      const data = response.data.map<IOption>((user) => ({
        id: user.id,
        value: user.name,
        selected: false,
        notSelectable: false,
      }));

      data.unshift({
        id: undefined,
        value: 'Selecione',
        selected: true,
        notSelectable: true,
      });

      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeCourse = useCallback(async (value) => {
    try {
      const response = await api.get<ICourseResponse[]>('courses', {
        params: {
          search: value,
          all: true,
          noPagination: true,
        },
      });

      const data = response.data.map<IOption>((course) => ({
        id: course.id,
        value: course.title,
        selected: false,
        notSelectable: false,
      }));

      data.unshift({
        id: undefined,
        value: 'Selecione',
        selected: true,
        notSelectable: true,
      });

      setCourses(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeProduct = useCallback(async (value) => {
    try {
      const response = await api.get<IProductResponse[]>('products', {
        params: {
          search: value,
          all: true,
          noPagination: true,
        },
      });

      const data: IOption[] = [];
      response.data.forEach((product) => {
        if (product.plans.length > 0) {
          const datas = product.plans.map((plan) => {
            return {
              id: `${product.id}/${plan.id}`,
              value: `${product.title} - ${plan.name}`,
              selected: false,
              notSelectable: false,
            };
          });

          data.push(...datas);
        } else {
          data.push({
            id: product.id,
            value: product.title,
            selected: false,
            notSelectable: false,
          });
        }
      });

      data.unshift({
        id: undefined,
        value: 'Selecione',
        selected: true,
        notSelectable: true,
      });

      setProducts(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangesUsers = useCallback((options) => {
    setUsersSelected(options);
  }, []);

  const handleChangesCourses = useCallback((options) => {
    setCoursesSelected(options);
  }, []);

  const handleChangesProducts = useCallback((options) => {
    setProductsSelected(options);
  }, []);

  const handleRemoveUser = useCallback(
    (option) => {
      const userOldSelected = usersSelectedOld.find(
        (data) => data.id === option.id
      );
      if (userOldSelected) {
        setDeletedUsers((state) => [...state, userOldSelected]);
      }
    },
    [usersSelectedOld]
  );

  const handleRemoveCourse = useCallback(
    (option) => {
      const courseOldSelected = coursesSelectedOld.find(
        (data) => data.id === option.id
      );
      if (courseOldSelected) {
        setDeletedCourses((state) => [...state, courseOldSelected]);
      }
    },
    [coursesSelectedOld]
  );

  const handleRemoveProduct = useCallback(
    (option) => {
      const productOldSelected = productsSelectedOld.find(
        (data) => data.id === option.id
      );
      if (productOldSelected) {
        setDeletedProducts((state) => [...state, productOldSelected]);
      }
    },
    [productsSelectedOld]
  );

  const handleClickDownloadExcelExample = useCallback(() => {
    const ws = XLSX.utils.json_to_sheet([
      {
        Nome: 'John Doe',
        'E-mail': 'john.doe@examplo.com',
        Documento: '',
        'Data de nascimento': '',
        Celular: '',
        Rua: '',
        Número: '',
        Complemento: '',
        Bairro: '',
        CEP: '',
        Cidade: '',
        Estado: '',
        Profissão: '',
        Curso: '',
        Origem: '',
        'Como conheceu a Leiaut': '',
        'Outros Instagrams': '',
        Eventos: '',
        'Nome da Faculdade/Universidade': '',
      },
    ]);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(excelData, 'membros-exemplo.xlsx');
  }, []);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h1>Grupos</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <button
                  type="button"
                  className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
                  onClick={handleClickAddGroup}
                >
                  <BsPlus size={24} color="#BBBBBB" />
                  <span className="text-gray fw-bold ms-2">Grupo</span>
                </button>
              </div>
            </div>
            <Table
              columns={columns}
              data={groups}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-groups mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Modal size="xl" show={showAddGroup} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Novo grupo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-3">
                <h5>Dados do grupo</h5>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100 fw-medium">
                  Nome
                  <Input
                    placeholder="Ex: Excel avançado"
                    name="name"
                    className="mt-2"
                  />
                </label>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 mb-3">
                <h5>Alunos</h5>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        studentActionSelected === 'add' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectStudentAction('add')}
                    >
                      <BsPlus size={24} color="#fff" /> Adicionar
                    </button>
                  </div>
                  <div className="col-lg-2 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        studentActionSelected === 'import' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectStudentAction('import')}
                    >
                      <IoIosArrowRoundDown size={24} color="#fff" /> Importar
                    </button>
                  </div>
                </div>
              </div>
              {studentActionSelected === 'add' && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label className="w-100 fw-medium">Usuários</label>
                      <Select
                        name="user"
                        options={users}
                        className="mt-2"
                        multiSelect
                        onChangeText={handleChangeUser}
                        onChange={handleChangesUsers}
                      />
                      <small className="d-block mt-2 mb-0">
                        Digite o nome do usuário para realizar a busca
                      </small>
                    </div>
                  </div>
                </div>
              )}
              {studentActionSelected === 'import' && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label className="w-100 fw-medium mb-2">Importar</label>
                      <InputFile
                        name="list-groups"
                        onChange={handleChange}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        noForm
                      />
                      <small className="download-example">
                        Baixe um exemplo{' '}
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={handleClickDownloadExcelExample}
                        >
                          clicando aqui
                        </button>
                      </small>
                      {importedUsers.length > 0 && (
                        <Table
                          columns={modalColumns}
                          data={importedUsers}
                          className="table-users mt-5"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-1 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        groupType === 'courses' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectGroupType('courses')}
                    >
                      Cursos
                    </button>
                  </div>
                  <div className="col-lg-2 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        groupType === 'products' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectGroupType('products')}
                    >
                      Produtos
                    </button>
                  </div>
                </div>
              </div>
              {groupType === 'courses' && (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <h5>Cursos</h5>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <Select
                            name="courses"
                            options={courses}
                            className="mt-2"
                            multiSelect
                            onChangeText={handleChangeCourse}
                            onChange={handleChangesCourses}
                          />
                          <small className="d-block mt-2 mb-0">
                            Digite o nome do curso para realizar a busca
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {groupType === 'products' && (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <h5>Produtos</h5>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <Select
                            name="products"
                            options={products}
                            className="mt-2"
                            multiSelect
                            onChangeText={handleChangeProduct}
                            onChange={handleChangesProducts}
                          />
                          <small className="d-block mt-2 mb-0">
                            Digite o nome do produto para realizar a busca
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary btn-submit px-5">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal size="xl" show={showEditGroup} onHide={handleClose}>
        <Form
          ref={formEditRef}
          initialData={groupSelected}
          onSubmit={handleSubmitEdit}
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Editar grupo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-3">
                <h5>Dados do grupo</h5>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100 fw-medium">
                  Nome
                  <Input
                    placeholder="Ex: Excel avançado"
                    name="name"
                    className="mt-2"
                  />
                </label>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 mb-3">
                <h5>Alunos</h5>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        studentActionSelected === 'add' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectStudentAction('add')}
                    >
                      <BsPlus size={24} color="#fff" /> Adicionar
                    </button>
                  </div>
                  <div className="col-lg-2 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        studentActionSelected === 'import' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectStudentAction('import')}
                    >
                      <IoIosArrowRoundDown size={24} color="#fff" /> Importar
                    </button>
                  </div>
                </div>
              </div>
              {studentActionSelected === 'add' && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label className="w-100 fw-medium">Usuários</label>
                      <Select
                        name="user"
                        options={users}
                        className="mt-2"
                        multiSelect
                        onChangeText={handleChangeUser}
                        onChange={handleChangesUsers}
                        optionsSelected={usersSelected}
                        onRemove={handleRemoveUser}
                      />
                      <small className="d-block mt-2 mb-0">
                        Digite o nome do usuário para realizar a busca
                      </small>
                    </div>
                  </div>
                </div>
              )}
              {studentActionSelected === 'import' && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label className="w-100 fw-medium mb-2">Importar</label>
                      <InputFile
                        name="list-groups"
                        onChange={handleChange}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        noForm
                      />
                      <small className="download-example">
                        Baixe um exemplo{' '}
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={handleClickDownloadExcelExample}
                        >
                          clicando aqui
                        </button>
                      </small>
                      {importedUsers.length > 0 && (
                        <Table
                          columns={modalColumns}
                          data={importedUsers}
                          className="table-users mt-5"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-1 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        groupType === 'courses' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectGroupType('courses')}
                    >
                      Cursos
                    </button>
                  </div>
                  <div className="col-lg-2 mb-4">
                    <button
                      type="button"
                      className={`btn btn-options ${
                        groupType === 'products' ? 'active' : ''
                      }`}
                      onClick={() => handleSelectGroupType('products')}
                    >
                      Produtos
                    </button>
                  </div>
                </div>
              </div>
              {groupType === 'courses' && (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <h5>Cursos</h5>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <Select
                            name="courses"
                            options={courses}
                            className="mt-2"
                            multiSelect
                            onChangeText={handleChangeCourse}
                            onChange={handleChangesCourses}
                            optionsSelected={coursesSelected}
                            onRemove={handleRemoveCourse}
                          />
                          <small className="d-block mt-2 mb-0">
                            Digite o nome do curso para realizar a busca
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {groupType === 'products' && (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <h5>Produtos</h5>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <Select
                            name="products"
                            options={products}
                            className="mt-2"
                            multiSelect
                            onChangeText={handleChangeProduct}
                            onChange={handleChangesProducts}
                            optionsSelected={productsSelected}
                            onRemove={handleRemoveProduct}
                          />
                          <small className="d-block mt-2 mb-0">
                            Digite o nome do produto para realizar a busca
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary btn-submit px-5">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading show={loading} message={loadingMessage} />
    </Container>
  );
};

export default Groups;
