import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { Container, Modal } from './styles';
import CanvasDraw from '../CanvasDraw';
import api from '~/services/api';
import Loading from '../Loading';

interface IOnSave {
  html: string;
  css: string;
}

export interface ICertificateData {
  front: IOnSave;
  back: IOnSave;
}

interface ICreateCertificateProps {
  data?: ICertificateData;
  onChange?(data: ICertificateData): void;
  error?: string;
}

interface IHandleLoad {
  onSave(): IOnSave | undefined;
}

const CreateCertificate: React.FC<ICreateCertificateProps> = ({
  data,
  error,
  onChange,
}) => {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState<'front' | 'back'>('front');
  const [certificateStep, setCertificateStep] = useState({} as IOnSave);
  const [frontCertificate, setFrontCertificate] = useState({} as IOnSave);
  const [backCertificate, setBackCertificate] = useState({} as IOnSave);
  const [certificateHasBeenCreated, setCertificateHasBeenCreated] =
    useState(false);
  const [handleLoad, setHandleLoad] = useState<IHandleLoad>({} as IHandleLoad);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setFrontCertificate(data.front);
      setBackCertificate(data.back);
      setCertificateStep(data.front);
    }
    setStep('front');
  }, [data]);

  const handleCanvasReady = useCallback((onSave) => {
    setHandleLoad({
      onSave,
    });
  }, []);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const handleClickPrevious = useCallback(() => {
    if (Object.keys(handleLoad).length > 0) {
      setBackCertificate(handleLoad.onSave() as IOnSave);
      setCertificateStep(frontCertificate);
      setStep('front');
    }
  }, [frontCertificate, handleLoad]);

  const handleClickNext = useCallback(() => {
    if (Object.keys(handleLoad).length > 0) {
      if (Object.keys(backCertificate).length > 0) {
        setCertificateStep(backCertificate);
      }

      setFrontCertificate(handleLoad.onSave() as IOnSave);
      setStep('back');
    }
  }, [backCertificate, handleLoad]);

  const handleClickSubmit = useCallback(() => {
    if (Object.keys(handleLoad).length > 0) {
      const cetificateBackData = handleLoad.onSave() as IOnSave;
      setBackCertificate(cetificateBackData);
      setStep('front');
      setCertificateHasBeenCreated(true);
      if (onChange) {
        const certificateData: ICertificateData = {
          front: frontCertificate,
          back: cetificateBackData,
        };
        onChange(certificateData);
      }
      handleClose();
    }
  }, [frontCertificate, handleClose, handleLoad, onChange]);

  const handleClickPreview = useCallback(async () => {
    try {
      setLoading(true);
      if (Object.keys(handleLoad).length > 0) {
        const cetificateBackData = handleLoad.onSave() as IOnSave;
        const formData = {
          html_front: frontCertificate.html,
          css_front: frontCertificate.css,
          html_back: cetificateBackData.html,
          css_back: cetificateBackData.css,
        };
        const response = await api.post('certificates/preview', formData);
        window.open(response.data, '_blank');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [frontCertificate.css, frontCertificate.html, handleLoad]);

  return (
    <Container hasError={!!error} className="order-2 order-lg-1 col-12">
      <div className="mt-3">
        <button
          type="button"
          className="d-flex align-items-center justify-content-between w-100 py-2 px-4 btn btn-dark-3"
          onClick={handleShow}
        >
          {(data && Object.keys(data).length > 0) || certificateHasBeenCreated
            ? 'Editar certificado'
            : 'Criar certificado'}
          <IoIosArrowForward size={24} color="#BBBBBB" />
        </button>
      </div>
      {error && <span className="small text-error error">{error}</span>}
      <Modal
        className="modal-Quiz"
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="ml-auto">
            {certificateHasBeenCreated ? 'Editar' : 'Criar'} Certificado (
            {step === 'front' ? 'Frente' : 'Verso'})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CanvasDraw
            onCanvasDrawReady={handleCanvasReady}
            html={certificateStep.html}
            css={certificateStep.css}
            step={step}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex justify-content-end w-100">
            {step !== 'front' && (
              <button
                type="button"
                onClick={handleClickPreview}
                className="me-auto btn btn-secondary"
              >
                Pré-visualizar
              </button>
            )}
            <div className="d-flex">
              {step !== 'front' && (
                <button
                  type="button"
                  onClick={handleClickPrevious}
                  className="btn btn-secondary"
                >
                  Voltar
                </button>
              )}
              {step === 'front' ? (
                <button
                  type="button"
                  onClick={handleClickNext}
                  className="btn btn-primary"
                >
                  Próximo
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleClickSubmit}
                  className="btn btn-primary ms-2"
                >
                  Salvar
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Loading show={loading} message="Gerando PDF..." />
    </Container>
  );
};

export default CreateCertificate;
