import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import readXlsxFile from 'read-excel-file';
import Swal from 'sweetalert2';

import { BsPlus } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import api from '~/services/api';
import mask from '~/utils/mask';

import { Container, Modal } from './styles';
import InputFile from '~/components/InputFile';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Search from '~/components/Search';
import Toast from '~/utils/toast';

interface IEmployeeResponse {
  id: string;
  name: string;
  email: string;
  created_at: string;
}

interface IEmployeeData {
  data: IEmployeeResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IEmployee {
  id: string;
  admin_id: string;
  name: string;
  email: string;
  joined_at: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IUser {
  name: string;
  email: string;
  course: string;
  document: string;
  birthdate: Date;
  phone: string;
  zipcode: string;
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  occupation?: string;
  origin: string;
  know_leiaut: string;
  other_instagram?: string;
  events?: string;
  college_name: string;
}

const Employees: React.FC = () => {
  const history = useHistory();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [page, setPage] = useState(1);
  // const [employeeSelected, setEmployeeSelected] = useState({} as IEmployee);

  const loadEmployees = useCallback(async (pageData, search = '') => {
    const response = await api.get<IEmployeeData>('admins', {
      params: { page: pageData, search },
    });
    const data: IEmployee[] = response.data.data.map((employee, index) => {
      const [firstName, secondName] = employee.name.split(' ');
      return {
        id: (index + 1).toString().padStart(5, '0'),
        admin_id: employee.id,
        name: `${firstName} ${secondName}`,
        email: employee.email,
        joined_at: format(parseISO(employee.created_at), 'dd/MM/yyyy'),
      };
    });

    setEmployees(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    loadEmployees(page);
  }, [loadEmployees, page]);

  const handleClickDelete = useCallback(
    (employee_id: string) => {
      Swal.fire({
        title: 'Deseja deletar esse funcionário?',
        html: '<small>Essa ação é irreversivel</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`admins/${employee_id}`);

            const newCategories = employees.filter(
              (employee) => employee.admin_id !== employee_id
            );
            setEmployees(newCategories);

            Toast.fire({
              icon: 'success',
              title: 'Funcionário deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [employees]
  );

  // const handleClickDots = useCallback((employee: IEmployee) => {
  //   setEmployeeSelected((state) =>
  //     state.id === employee.id ? ({} as IEmployee) : employee
  //   );
  // }, []);

  // const handleClickLoginUser = useCallback(async () => {
  //   const response = await api.post('tokens/admin-supports', {
  //     admin_id: employeeSelected.admin_id,
  //   });
  //   window.open(
  //     `${process.env.REACT_APP_APP_URL}/auto-login/${response.data}`,
  //     '_blank'
  //   );
  // }, [employeeSelected.admin_id]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Usuário',
        selector: 'name',
        cell: (row: IEmployee) => (
          <div className="d-flex align-items-center">
            {/* <Avatar src={row.avatar} className="me-2" /> */}
            <p className="mb-0">{row.name}</p>
          </div>
        ),
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      // {
      //   name: 'Plano',
      //   selector: 'plan',
      // },
      {
        name: 'Data de ingresso',
        selector: 'joined_at',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IEmployee) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent"
              onClick={() => handleClickDelete(row.admin_id)}
            >
              <MdDelete size={24} color="#FF333D" />
            </button>
          </div>
        ),
      },
    ],
    [handleClickDelete]
  );

  const modalColumns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'name',
        cell: (_, rowIndex) => (rowIndex + 1).toString().padStart(2, '0'),
      },
      {
        name: 'Usuário',
        selector: 'name',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Curso',
        selector: 'course',
      },
      {
        name: 'Documento',
        selector: 'document',
        cell: (row: IUser) =>
          mask({
            kind: 'cpf',
            value: row.document,
          }),
      },
      {
        name: 'Data de nascimento',
        selector: 'birthdate',
        cell: (row: IUser) => format(row.birthdate, 'dd/MM/yyyy'),
      },
      {
        name: 'Telefone/Celular',
        selector: 'phone',
        cell: (row: IUser) =>
          mask({
            kind: 'cel-phone',
            value: row.phone,
          }),
      },
      {
        name: 'CEP',
        selector: 'zipcode',
        cell: (row: IUser) =>
          mask({
            kind: 'zip-code',
            value: row.zipcode,
          }),
      },
      {
        name: 'Endereço',
        selector: 'street',
      },
      {
        name: 'Número',
        selector: 'number',
      },
      {
        name: 'Complemento',
        selector: 'complement',
        cell: (row: IUser) => row.complement || '-',
      },
      {
        name: 'Bairro',
        selector: 'neighborhood',
      },
      {
        name: 'Cidade',
        selector: 'city',
      },
      {
        name: 'Estado',
        selector: 'state',
      },
      {
        name: 'Profissão',
        selector: 'occupation',
        cell: (row: IUser) => row.occupation || '-',
      },
      {
        name: 'Origem',
        selector: 'origin',
      },
      {
        name: 'Como você conheceu a escola?',
        selector: 'know_leiaut',
      },
      {
        name: 'Se você selecionou "outro instagram" diga qual.',
        selector: 'other_instagram',
        cell: (row: IUser) => row.other_instagram || '-',
      },
      {
        name: 'Se você escolheu "Eventos" diga qual.',
        selector: 'events',
        cell: (row: IUser) => row.events || '-',
      },
      {
        name: 'Qual o nome de sua Faculdade/Universidade?',
        selector: 'college_name',
      },
    ],
    []
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setUsers([]);
  }, []);

  const handleChage = useCallback(async (file) => {
    try {
      setLoadingMessage('Verificando arquivo...');
      setLoading(true);
      const rows = await readXlsxFile(file);
      if (rows.length > 0) {
        const usersData: IUser[] = [];
        const rowsPromise = new Promise<void>((resolve) => {
          rows.forEach(async (row, index) => {
            if (index !== 0 && index !== 1) {
              const newUser = {} as IUser;
              const rowPromise = new Promise<void>((resolveRow) => {
                row.forEach((data, idx) => {
                  switch (idx) {
                    case 2:
                      newUser.name = `${data}`;
                      break;
                    case 3:
                      newUser.document = `${data}`
                        .replaceAll('.', '')
                        .replace('-', '');
                      break;
                    case 4:
                      newUser.birthdate = new Date(`${data}`);
                      break;
                    case 5:
                      newUser.email = `${data}`;
                      break;
                    case 6:
                      newUser.phone = `${data}`
                        .replace('(', '')
                        .replace(')', '')
                        .replace(' ', '')
                        .replace('-', '');
                      break;
                    case 7:
                      newUser.street = `${data}`;
                      break;
                    case 8:
                      newUser.number = `${data}`;
                      break;
                    case 9:
                      newUser.complement = data ? `${data}` : undefined;
                      break;
                    case 10:
                      newUser.neighborhood = `${data}`;
                      break;
                    case 11:
                      newUser.zipcode = `${data}`.replace('-', '');
                      break;
                    case 12:
                      newUser.city = `${data}`;
                      break;
                    case 13:
                      newUser.state = `${data}`;
                      break;
                    case 14:
                      newUser.occupation = data ? `${data}` : undefined;
                      break;
                    case 16:
                      newUser.course = `${data}`;
                      break;
                    case 22:
                      newUser.origin = `${data}`;
                      break;
                    case 32:
                      newUser.know_leiaut = `${data}`;
                      break;
                    case 33:
                      newUser.other_instagram = data ? `${data}` : undefined;
                      break;
                    case 34:
                      newUser.events = data ? `${data}` : undefined;
                      break;
                    case 35:
                      newUser.college_name = `${data}`;
                      break;
                    default:
                      break;
                  }
                  if (row.length === idx + 1) {
                    resolveRow();
                  }
                });
              });
              await rowPromise;
              usersData.push(newUser);
            }
            if (rows.length === index + 1) {
              resolve();
            }
          });
        });
        await rowsPromise;
        setUsers(usersData);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }, []);

  const handleClickSave = useCallback(async () => {
    try {
      setLoadingMessage('Importando membros...');
      setLoading(true);
      await api.post('users/import', users);
      await loadEmployees(page);
      handleClose();
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }, [handleClose, loadEmployees, page, users]);

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleRowDoubleClick = useCallback(
    (data: IEmployee) => {
      history.push(`funcionarios/${data.admin_id}`);
    },
    [history]
  );

  const handleSearch = useCallback(
    (value) => {
      loadEmployees(1, value);
    },
    [loadEmployees]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h1>Funcionários</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <Link
                  to={`${process.env.PUBLIC_URL}/funcionarios/cadastrar`}
                  className="d-flex align-items-center border-0 btn btn-dark-3 px-5 py-3"
                >
                  <BsPlus size={24} color="#BBBBBB" />
                  <span className="text-gray fw-bold ms-2">Funcionário</span>
                </Link>
              </div>
            </div>
            <Table
              columns={columns}
              data={employees}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-employees mt-5"
              pagination
              onChangePage={handleChangePage}
              onRowDoubleClick={handleRowDoubleClick}
            />
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="ml-auto">Importar membros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputFile
            name="list-employees"
            onChange={handleChage}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            noForm
          />
          {users.length > 0 && (
            <Table
              columns={modalColumns}
              data={users}
              className="table-users mt-5"
            />
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          {users.length > 0 && (
            <button
              type="button"
              className="btn btn-primary btn-submit px-5"
              onClick={handleClickSave}
            >
              Salvar
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Loading show={loading} message={loadingMessage} />
    </Container>
  );
};

export default Employees;
