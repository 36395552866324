import React, { useCallback, useState } from 'react';
import { Container, Tabs, TabsButton, TabsGroup, Tab } from './styles';
import CommentSupport from '../CommentSupport';

interface TabSupportProps {
  searchTerm: string;
}

const TabSupport: React.FC<TabSupportProps> = ({ searchTerm }) => {
  const [tabSelected, setTabSelected] = useState('preguntas');
  const [openTabs, setOpenTabs] = useState(false);

  const handleSelectTab = useCallback(
    (value) => {
      setTabSelected(value);
      setOpenTabs(false);
    },
    []
  );

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  return (
    <Container>
      <div className="container py-3">
        <div className="row">
          <div className="col-12 mb-4">
            <Tabs className="py-3">
              <TabsButton
                type="button"
                onClick={handleClickTabsButton}
                className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
              >
                {tabSelected === 'preguntas' && 'Preguntas con respuesta'}
                {tabSelected === 'sinResponder' && 'Sin responder'}
                {tabSelected === 'respondidas' && 'Respondidas'}
              </TabsButton>
              <TabsGroup
                opened={openTabs}
                className="w-100 d-flex flex-column flex-md-row justify-content-md-start align-items-center"
              >
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('preguntas')}
                  active={tabSelected === 'preguntas'}
                  className="border-0 bg-transparent"
                >
                  Preguntas
                </Tab>
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('sinResponder')}
                  active={tabSelected === 'sinResponder'}
                  className="border-0 bg-transparent"
                >
                  Sin responder
                </Tab>
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('respondidas')}
                  active={tabSelected === 'respondidas'}
                  className="border-0 bg-transparent"
                >
                  Respondidas
                </Tab>
              </TabsGroup>
            </Tabs>
          </div>
        </div>
        {/* Aquí puedes agregar el contenido de cada tab */}
        {tabSelected === 'preguntas' && <CommentSupport filter="new" searchTerm={searchTerm} />}
        {tabSelected === 'sinResponder' && <CommentSupport filter="unanswered" searchTerm={searchTerm} />}
        {tabSelected === 'respondidas' && <CommentSupport filter="answered" searchTerm={searchTerm} />}
      </div>
    </Container>
  );
};

export default TabSupport;